import React, { Component } from 'react'
import { LiveProps } from '../types/live' /* Importing the interface that allow the use of props */

import '../styles/components/live.css'

/**
 * Component that displays if an event is live or not
 *
 */
class LiveIndicator extends Component<LiveProps> {
  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    const date = new Date()

    if (this.props.dateSpan) {
      for (const pair of this.props.dateSpan) {
        if (date.toISOString() > pair.start && pair.end > date.toISOString()) {
          return (
            <div className="live-wrapper">
              <h4>LIVE</h4>
              <div className="live-dot"/>
            </div>
          )
        }
      }
    }
    return (<div/>)
  }
}

export default LiveIndicator
