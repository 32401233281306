import React from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { Route, Redirect } from 'react-router'  // Allows us to send a user to another route
import { ProtectedRouteProps } from '../../types/protected-routes'  // Component auth props

/**
 * Protects app from an unauthorized entry inside an input component. Redirects users to NoPermission view component
 * when they don't have the required permissions.
 *
 * @param Component Component that is going to be protected
 * @param path url of this component
 * @param permissions permissions object used to block access to users that do not belong to specific input groups
 * @param props rest of props that we want to pass to this component
 */
export const ProtectedRoute: React.FC<ProtectedRouteProps & GlobalProps> = ({
  component: Component,
  path,
  permissions,
  ...props
}) => {
  /* Computes and checks user permissions */
  let isAllowed = props.auth.isAuthenticated
  if (permissions) isAllowed = isAllowed && permissions?.includes(props.auth.user.getGroup())

  /* If user has the supposed credentials, then we send him to the request route; else redirects to no-permissions */
  if (isAllowed) {
    return (
      <Route {...props} exact path={path} render={(routeProps) => <Component auth={props.auth} {...routeProps} />} />
    )
  }
  return (
    <Redirect to="no-permission" />
  )
}
