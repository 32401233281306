import React, { Component } from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { EventStatsBarProps, EventStatsBarState } from '../types/events-stats-bar'

import '../styles/components/event-stats-bar.css'

/**
 * Creates the Bar that holds the different categories of statistics.
 */
class EventStatsBar extends Component<EventStatsBarProps, EventStatsBarState> {
  /**
   * ConsumableCategoryBar class constructor.
   *
   * @param props required props
   */
  constructor(props: EventStatsBarProps) {
    super(props)
    this.state = {
      highlight: -1, /* This default value refers to the "All"/"Todos" group */
      statsCategory: -1, /* This default value refers to the "All"/"Todos" group */
      statsCategories: [
        i18next.t('EventStatistics.tickets'),
        i18next.t('EventStatistics.consumables'),
        i18next.t('EventStatistics.collaborators'),
      ],
    }
  }

  /**
  * This is the onClick function that is passed to clicking on a group. It communicates to the parent component the new
  * highlighted group and alters the highlight and group states.
   *
  * @param event: corresponds to the click event that happened
  * @param index: corresponds to the index of the clicked group
  */
  private onClickFunction(event: React.MouseEvent, index: number): void {
    // TODO: I dont know why it works, but leave it
    this.props.changeStatCategory(event, index) /* Changing the parent state */
    this.props.changeStatCategory(event, index) /* Doubles it to do a full rotation */
    this.setState({ highlight: index, statsCategory: index }) /* Changing this component's state */
    this.setState({ highlight: index, statsCategory: index }) /* Doubles it to do a full rotation */
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="event-stats-bar-wrapper">
        {/* Sets up the "All" Button */}
        <div className="stat-button"
          id={this.state.highlight === -1 ? 'highlighted-stat-button' : undefined}
          onClick={(e) => this.onClickFunction(e, -1)}>
          {i18next.t('EventStatistics.general')}
        </div>
        {/* Sets up the other groups buttons */}
        {this.state.statsCategories.map((category, index) => {
          return (
            <div
              key={index}
              className="stat-button"
              id={this.state.highlight === index ? 'highlighted-stat-button' : undefined}
              onClick={ (e) => this.onClickFunction(e, index)}
            >
              {category}
            </div>
          )
        })}
      </div>
    )
  }
}

export default withTranslation()(EventStatsBar)
