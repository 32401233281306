import React, { Component } from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { EventTimeBarProps, EventTimeBarState } from '../types/events-time-bar'

import '../styles/components/consumables-category-bar.css'

/**
 * Creates the Bar that holds the different groups that collaborators could be on.
 */
class EventsTimeBar extends Component<EventTimeBarProps, EventTimeBarState> {
  /**
   * ConsumableCategoryBar class constructor.
   *
   * @param props required props
   */
  constructor(props: EventTimeBarProps) {
    super(props)
    this.state = {
      highlight: -1, /* This default value refers to the "All"/"Todos" group */
      time: -1, /* This default value refers to the "All"/"Todos" group */
      times: [
        i18next.t('MyEvents.current'),
        i18next.t('MyEvents.previous'),
      ],
    }
  }

  /**
  * This is the onClick function that is passed to clicking on a group. It communicates to the parent component the new
  * highlighted group and alters the highlight and group states.
   *
  * @param event: corresponds to the click event that happened
  * @param index: corresponds to the index of the clicked group
  */
  private onClickFunction(event: React.MouseEvent, index: number): void {  // FIXME: deves ter de a apagar
    // TODO: I dont know why it works, but leave it
    this.props.changeTime(event, index) /* Changing the parent state */
    this.props.changeTime(event, index) /* Doubles it to do a full rotation */
    this.setState({ highlight: index, time: index }) /* Changing this component's state */
    this.setState({ highlight: index, time: index }) /* Doubles it to do a full rotation */
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="consumable-bar-wrapper">
        {/* Sets up the "All" Button */}
        <div className="category-button"
          id={this.state.highlight === -1 ? 'highlighted-category-button' : undefined}
          onClick={(e) => this.onClickFunction(e, -1)}>
          {i18next.t('MyEvents.all')}
        </div>
        {/* Sets up the other groups buttons */}
        {this.state.times.map((time, index) => {
          return (
            <div
              key={index}
              className="category-button"
              id={this.state.highlight === index ? 'highlighted-category-button' : undefined}
              onClick={ (e) => this.onClickFunction(e, index)}
            >
              {time}
            </div>
          )
        })}
      </div>
    )
  }
}

export default withTranslation()(EventsTimeBar)
