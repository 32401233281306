import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './app'
import reportWebVitals from './reportWebVitals'
import Amplify from 'aws-amplify'
import awsmobile from './aws-exports'

import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

/* Configure Amplify on the client so that we can use it to interact with our backend services */
Amplify.configure(awsmobile)

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
