import React, { Component } from 'react'
import Amplify, { Auth } from 'aws-amplify'  // Used to call Cognito and then access it's Login properties
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { LoginFormValues, CognitoUser } from '../../types/auth'
import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import AuthButton from '../../components/AuthButton'  // Used to submit form
import { TextInput, PasswordInput } from '../../components/utils/FormFields'  // Form fields
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next'/* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'

import '../../styles/views/auth/login.css'
import User from '../../models/classes/Cognito-user'

/**
 * Login section component used to login an already registered user. Holds a form that prompts user about its handle
 * and password
 */
class Login extends Component<RouteComponentProps & GlobalProps> {
  /* Holds our form's initial values */
  private initialValues = {
    handle: '',
    password: '',
  }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    handle: Yup.string().required(),

    password: Yup.string().required(),
  })

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   *
   * @param setSubmitting function called to change the state of the button
   * @return Returns a void promise since it won't have a specific type
   */
  private handleSubmit = async (values: LoginFormValues, { setSubmitting }: FormikHelpers<LoginFormValues>):
  Promise<void> => {
    /* Accesses Cognito and tries to login an already registered user */
    try {
      await Auth.signIn(values.handle, values.password)
      const helperUser = await Auth.currentUserInfo() as CognitoUser
      const user = new User(helperUser)

      /* Tells App component (parent) that the user logged in successfully by changing App's properties */
      this.props.auth.setAuthState(true)
      this.props.auth.setUserState(user)

      /* Enables submit button again */
      setSubmitting(false)

      /* After a user logins, we tell amplify that we want to use cognito to authenticate our user's queries */
      Amplify.configure({ aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS })

      /* Redirects user to our landing page. Only used for convenience and should be changed in the future */
      this.props.history.push('/')
    } catch (error) {
      if ((error as any).message) {
        alert((error as any).message)
      } else {
        alert(JSON.stringify(error))
      }

      /* Enables submit button again */
      setSubmitting(false)
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    return (
      <div className="login-background">
        <div className="login-main">
          {/* Button used to go back to landing page */}
          <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft} /></a>
          <div id="login-left-half" className="main-half">
            {/*
             <h2>{i18next.t('Login.register-option.catchphrase')}</h2>
             <p>{i18next.t('Login.register-option.secondaryText')}</p>
             <a href="/register">
              <AuthButton text={i18next.t('Login.register-option.signUp')} property="alternative-color-scheme" />
            </a>*/
            }
          </div>
          <div id="login-right-half" className="main-half">
            <h1>{i18next.t('Login.loginCatchphrase')}</h1>
            {/* To do the input animation I followed this video https://www.youtube.com/watch?v=IxRJ8vplzAo&t=456s */}
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <form className="login-form-wrapper" onSubmit={props.handleSubmit}>
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={'false'}
                    value={props.values.handle}
                    onChange={props.handleChange}
                    label={i18next.t('Login.handle')}
                    name="handle"
                  />
                  <PasswordInput
                    value={props.values.password}
                    onChange={props.handleChange}
                    label={i18next.t('Login.password')}
                    name="password"
                  />
                  <div id="forgot-password">
                    <a href="/forgot-password">{i18next.t('Login.forgotPassword')}</a>
                  </div>
                  <AuthButton text={i18next.t('Login.signIn')} type="submit" disable={props.isSubmitting}/>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

    )
  }
}

export default withTranslation()(Login)
