import React, { Component } from 'react'
import { PopupProps } from '../../types/popup' /* Importing the interface that allow the use of props */
import { AddEditTicketFormValues, AddEditTicketProps } from '../../types/create-edit-event'  // Imports FormValues props
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { FieldArray, Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import { TextInput, DateInput, TimeInput, CheckboxInput } from '../utils/FormFields'  // Form fields
import { eventDate } from '../../views/events/util/types'
import AddEditButton from '../AddEditButton'
import Popup from '../Popup'

import '../../styles/components/create-event/add-edit-ticket.css'

/**
 * Creates the popup component that allows the user to add or edit a ticket. Props are explained on the @ type
 * file.
 */
class AddEditTicket extends Component<PopupProps & AddEditTicketProps, {helper: boolean[]}> {
  constructor(props: PopupProps & AddEditTicketProps) {
    super(props)
    this.state = {
      helper: Array(this.props.numberOfDays).fill(false),
    }
  }

  /* Holds a blank ticket. Used when we create a new ticket */
  private blankTicket = {
    validDates: Array(this.props.numberOfDays).fill(false),
    id: '',
    name: '',
    price: '',
    memberPrice: '',
    priceOverRideDate: eventDate,
    overridePrice: '',
    memberOverridePrice: '',
    nrAvailableTickets: '',
    addTickets: this.props.addTickets,
  } as AddEditTicketFormValues

  /* Holds our form's initial values */
  private initialValues = this.props.ticket ? this.props.ticket : this.blankTicket

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    name: Yup.string().required(i18next.t('AddEventErrors.addTickets.name.required'))
      .max(30, i18next.t('AddEventErrors.addTickets.name.size')),

    //TODO: addi18next
    addTickets: Yup.boolean(),

    price: Yup.number().required(i18next.t('AddEventErrors.addTickets.price.required'))
      .min(3, i18next.t('AddEventErrors.addTickets.price.min')),

    memberPrice: Yup.number()
      .min(3, i18next.t('AddEventErrors.addTickets.memberPrice.min')),

    validDates: Yup.array().when('addTickets', {
      is: true,
      then: Yup.array().required()
        .test({
          name: 'oneDateIsSelected',
          params: { },
          message: '',
          test(value) {
            if (value!.includes(true, 0)) {
              return true
            }
            alert(i18next.t('AddEventErrors.addTickets.validDates.test'))
            return false
          },
        }),
    }),

    priceOverRideDate: Yup.object().when('addTickets', {
      is: true,
      then: Yup.object().shape({

        /* Auxiliary fields for the correct comparison of the date */
        limitDate: Yup.date().default(() => new Date(new Date(this.props.eventSpan[0])
          .setDate(new Date(this.props.eventSpan[0]).getDate() - 1))),
        currentDate: Yup.date().default(() => new Date(new Date().setHours(0, 0, 0, 0))),

        time: Yup.string()
          .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, i18next.t('AddEventErrors.addTickets.priceOverRideDate.time.match')),

        date: Yup.date()
          .min(Yup.ref('currentDate'), i18next.t('AddEventErrors.addTickets.priceOverRideDate.date.min'))
          .max(Yup.ref('limitDate'), i18next.t('AddEventErrors.addTickets.priceOverRideDate.date.max')),
      }),
    }),

    nrAvailableTickets: Yup.number()
      .min(0, i18next.t('AddEventErrors.addTickets.nrAvailableTickets.min')),

    overridePrice: Yup.number()
      .min(0, i18next.t('AddEventErrors.addTickets.overridePrice.min')),

    memberOverridePrice: Yup.number()
      .min(0, i18next.t('AddEventErrors.addTickets.memberOverridePrice.min')),

  })

  /**
   * Verifies if dates are correct (end is greater than start and if the difference between them do not go beyond one day.
   *
   * @param inputDate holds the date inserted by the user
   *
   * @return boolean true if everything is alright and false if not
   */
  private dateIsValid = (inputDate: number): boolean => {
    /* Check if the override date is before the current time */
    if (inputDate - new Date().getTime() < 0) {
      alert(i18next.t('AddEventErrors.addTickets.priceOverRideDate.tooEarly'))
      return false
    } return true
  }

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   * @param resetForm function called to reset form input fields
   */
  private handleSubmit = (values: AddEditTicketFormValues, { setSubmitting, resetForm }:
    FormikHelpers<AddEditTicketFormValues>): void => {
    if (values.priceOverRideDate.date && values.priceOverRideDate.time
      && !this.dateIsValid(Date.parse(`${values.priceOverRideDate.date}T${values.priceOverRideDate.time}:00`))) return

    /* Creates info input object */
    const input: AddEditTicketFormValues = {
      ...values,
      __typename: 'Ticket',
    }

    /* Enables submit button again */
    setSubmitting(false)

    /* Closes popup */
    this.props.close()

    /* Sends info to parent component and tells it to render next phase */
    this.props.updateInfo(input)

    /* Sets new values only if we are updating a ticket */
    if (this.props.ticket) this.initialValues = values

    /* Resets form after submitting */
    resetForm()
  }

  private dayGenerator = (index: number): string => {
    switch (index) {
    case (0):
      return `${i18next.t('AddEvent.days.first')} ${i18next.t('AddEvent.days.day')}`
    case (1):
      return `${i18next.t('AddEvent.days.second')} ${i18next.t('AddEvent.days.day')}`
    case (2):
      return `${i18next.t('AddEvent.days.third')} ${i18next.t('AddEvent.days.day')}`
    case (3):
      return `${i18next.t('AddEvent.days.fourth')} ${i18next.t('AddEvent.days.day')}`
    case (4):
      return `${i18next.t('AddEvent.days.fifth')} ${i18next.t('AddEvent.days.day')}`
    case (5):
      return `${i18next.t('AddEvent.days.sixth')} ${i18next.t('AddEvent.days.day')}`
    case (6):
      return `${i18next.t('AddEvent.days.seventh')} ${i18next.t('AddEvent.days.day')}`
    default:
      return `${index + 1}º ${i18next.t('AddEvent.days.day')}`
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      <Popup show={this.props.show} close={this.props.close}>
        <div className="add-edit-ticket-body-main">
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            validateOnBlur={false}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={this.handleSubmit}
          >
            {(props) => (
              <form className="form-wrapper" onSubmit={props.handleSubmit} autoComplete="off">
                <TextInput
                  value="name"
                  onChange={props.handleChange}
                  label={i18next.t('AddEvent.addTickets.name')}
                  name="name"
                />
                <h3 className="title-text">{i18next.t('AddEvent.addTickets.date')}:</h3>
                <FieldArray name="validDates" render={
                  () => (
                    <>
                      <div className="add-edit-ticket-date-container">
                        {props.values.validDates.map((value: boolean, index: number) => (
                          <div key={index} className="add-edit-ticket-date">
                            <CheckboxInput
                              name={`validDates.${index}`}
                              label={this.dayGenerator(index)}
                              onChange={props.handleChange}
                              checked={value}
                              disabled={this.props.mode === 'EDIT' && this.props.ticket !== undefined}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )
                }/>

                <div className="add-edit-ticket-price">
                  <div className="add-edit-ticket-price-single">
                    <TextInput
                      value={props.values.price}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addTickets.priceWithCurrency')}
                      name="price"/>
                  </div>
                  <div className="add-edit-ticket-price-single">
                    <TextInput
                      value={props.values.memberPrice}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addTickets.memberPriceWithCurrency')}
                      name="memberPrice"/>
                  </div>
                </div>
                <h3 className="title-text" id="optional-text">{i18next.t('AddEvent.addTickets.optional')}</h3>
                <TextInput
                  value="nrAvailableTickets"
                  onChange={props.handleChange}
                  label={i18next.t('AddEvent.addTickets.nrAvailableTickets')}
                  name="nrAvailableTickets"
                  disabled={this.props.mode === 'EDIT' && this.props.ticket !== undefined}
                />
                <h3 className="conditional-price">{i18next.t('AddEvent.addTickets.changePrice')}</h3>
                <div>
                  <div className="add-edit-ticket-conditional-date">
                    <div id="text-time-wrapper">
                      <h1>{i18next.t('AddEvent.addTickets.hour')}</h1>
                      <div id="time-wrapper">
                        <TimeInput
                          value={props.values.priceOverRideDate.time}
                          onChange={props.handleChange}
                          name="priceOverRideDate.time"
                          disabled={this.props.mode === 'EDIT' && this.props.ticket !== undefined}/>
                      </div>
                    </div>
                    <div id="text-date-wrapper">
                      <h1>{i18next.t('AddEvent.addTickets.day')}</h1>
                      <div id="date-wrapper">
                        <DateInput
                          value={props.values.priceOverRideDate.date}
                          onChange={props.handleChange}
                          name="priceOverRideDate.date"
                          disabled={this.props.mode === 'EDIT' && this.props.ticket !== undefined}/>
                      </div>
                    </div>
                  </div>
                  <div className="add-edit-ticket-price">
                    <div className="add-edit-ticket-price-single">
                      <TextInput
                        value={props.values.overridePrice}
                        onChange={props.handleChange}
                        label={i18next.t('AddEvent.addTickets.priceWithCurrency')}
                        name="overridePrice"/>
                    </div>
                    <div className="add-edit-ticket-price-single">
                      <TextInput
                        value={props.values.memberOverridePrice}
                        onChange={props.handleChange}
                        label={i18next.t('AddEvent.addTickets.memberPriceWithCurrency')}
                        name="memberOverridePrice"/>
                    </div>
                  </div>
                </div>

                <AddEditButton item={this.props.ticket} type="ticket" disable={props.isSubmitting}/>
              </form>
            )}
          </Formik>
        </div>
      </Popup>
    )
  }
}

export default withTranslation()(AddEditTicket)
