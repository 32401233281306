import React, { Component } from 'react'
import { StoreButtonProps, StoreButtonState } from '../../types/store-button'  /* Importing the interface that allow the use of props */

import i18next from 'i18next'  /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next'  /* Import needed for the use of the dictionary/translation  */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'  /* Import needed to be able to use the custom FontAwesome font */
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'  /* Import needed to get the desired font elements */

import '../../styles/components/landing-page/store-button.css'

/**
 * Creates the button that will redirect the user to the App Store / Google Play.
 */
class StoreButton extends Component<StoreButtonProps, StoreButtonState> {
  /**
  * React component render function. Holds our extended html code.
  */
  render() {
    let icon
    let storeName

    if (this.props.store === 'apple') {
      icon = faApple
      storeName = i18next.t('StoreButton.storeName.apple')
    } else {
      icon = faGooglePlay
      storeName = i18next.t('StoreButton.storeName.google')
    }

    return (
      <div className="store-button" id={this.props.alternative ? 'alternative' : 'normal'}>
        <FontAwesomeIcon id={this.props.store} icon={icon}/>
        <p>{storeName}</p>
      </div>
    )
  }
}

export default withTranslation()(StoreButton)
