import React, { Component } from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { GlobalProps } from '../../../types/global'
import { v4 as uuid } from 'uuid'
import * as Func from '../util/functions'
import {
  createCollaboratorEventRelation,
  deleteCollaboratorEventRelation,
  updateEvent,
  updateEventPoster,
} from '../../../models/event'
import { CreatedSuccessfullyProps, CreatedSuccessfullyState, AddEditStreakFormValues, AddEditTicketFormValues,
  AddEditConsumableFormValues } from '../../../types/create-edit-event'
import { uploadImage, Image, removeImage } from '../../../models/images'
import { createEventTicket, deleteTicket, updateTicket } from '../../../models/ticket'
import { createStreak, deleteStreak, updateStreak } from '../../../models/streak'
import { ConsumableSize, Consumable, CreateConsumableInput, UpdateConsumableInput } from '../../../API'
import {
  createEventConsumable,
  createPairStreak,
  deletePairStreak,
  updateConsumable, updatePairStreak,
} from '../../../models/consumable'

import '../../../styles/views/create-event/created-successfully.css'

/* eslint-disable */
/**
 * enum to describe the page current operation
 */
enum operation {
  LOADING,
  SUCCESS,
  FAILURE
}
/* eslint-enable */

/**
 * This page processes and submits all the information for the edit of the event
 */
class UpdateStage extends Component<GlobalProps & CreatedSuccessfullyProps &
  { originalImagesIDs: { id: string, name: string }[] }, CreatedSuccessfullyState> {
  /**
   * UpdateStage class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      pageOperation: operation.LOADING,
      errorMessage: '',
    }
  }

  /**
   * Check if the two consumables are the same
   *
   * @param consumable1
   * @param consumable2
   */

  private equalConsumables = (consumable1: AddEditConsumableFormValues, consumable2: AddEditConsumableFormValues):
  boolean => {
    return !(consumable1.id !== consumable2.id
        || consumable1.name !== consumable2.name
        || consumable1.price !== consumable2.price
        || consumable1.memberPrice !== consumable2.memberPrice
        || consumable1.category !== consumable2.category
        || consumable1.largeVersionName !== consumable2.largeVersionName
        || consumable1.largeVersionPrice !== consumable2.largeVersionPrice
        || consumable1.largeVersionMemberPrice !== consumable2.largeVersionMemberPrice
        || consumable1.promotion !== consumable2.promotion
        || consumable1.promotion.prices.regularSize !== consumable2.promotion.prices.regularSize
        || consumable1.promotion.prices.largeSize !== consumable2.promotion.prices.regularSize
        || Func.parseDateTimeToISO(consumable1.promotion.date.start)
        !== Func.parseDateTimeToISO(consumable2.promotion.date.start)
        || Func.parseDateTimeToISO(consumable1.promotion.date.end)
        !== Func.parseDateTimeToISO(consumable2.promotion.date.end))
  }

  /**
   * Checks if two streaks are equal.
   *
   * @param streak1
   * @param streak2
   */
  private equalStreaks = (streak1: AddEditStreakFormValues, streak2: AddEditStreakFormValues): boolean => {
    if (streak1.consumablePair.length !== streak2.consumablePair.length) return false

    let key: keyof typeof streak1
    for (key in streak1) {
      if (key !== '__typename' && key !== 'id' && key !== 'consumablePair') {
        /* eslint-disable */
        if (streak1.hasOwnProperty(key) && streak1[key] !== streak2[key]) {
        /* eslint-enable */
          return false
        }
      }
    }

    let k: keyof typeof streak1.consumablePair[0]
    for (let i = 0; i < streak1.consumablePair.length; i += 1) {
      for (k in streak1.consumablePair[i]) {
        if (streak1.consumablePair[i][k] !== streak2.consumablePair[i][k]) {
          return false
        }
      }
    }

    return true
  }

  /**
   * Checks if two tickets are equal.
   *
   * @param ticket1
   * @param ticket2
   */
  private equalTickets = (ticket1: AddEditTicketFormValues, ticket2: AddEditTicketFormValues): boolean => {
    if ((ticket1.validDates.length !== ticket2.validDates.length)
    || (Func.parseDateTimeToISO(ticket1.priceOverRideDate) !== Func.parseDateTimeToISO(ticket2.priceOverRideDate))) {
      return false
    }

    let key: keyof typeof ticket1
    for (key in ticket1) {
      if (key !== '__typename' && key !== 'priceOverRideDate') {
        /* eslint-disable */
        if (ticket1.hasOwnProperty(key) && ticket1[key] !== ticket2[key]) {
        /* eslint-enable */
          return false
        }
      }
    }

    for (let i = 0; i < ticket1.validDates.length; i += 1) {
      if (ticket1.validDates[i] !== ticket2.validDates[i]) {
        return false
      }
    }

    return true
  }

  /**
   * Checks if only the price differs in two consumables.
   *
   * @param consumable1
   * @param consumable2
   */

  /**
   * Updates event component in our database, uploads event images and creates a poster for it.
   *
   * @param eventID the identity id of the event to be updated
   */
  private updateEvent = async (eventID: string): Promise<void> => {
    try {
      /* Updates the images of the event to the database */
      const files = this.props.values.general!.images.filter((image) => image !== undefined) as Array<File>
      const filenames = Array.from(files, (file) => file.name)
      const originalFiles = this.props.originalValues?.general!.images
        .filter((image) => image !== undefined) as Array<File>
      const originalFilenames = Array.from(originalFiles, (ogFile) => ogFile.name)
      const images: Image[] = []

      /* Checks if an image was deleted by the user */
      for (let i = 0; i < originalFilenames.length; i += 1) {
        if (!filenames.includes(originalFilenames[i])) {
          await removeImage(originalFilenames[i], this.props.originalImagesIDs[this.props.originalImagesIDs
            .findIndex((value) => {
              return value.name === originalFilenames[i]
            })].id)
        }
      }

      /* Checks if an image was added */
      for (let i = 0; i < filenames.length; i += 1) {
        if (!originalFilenames.includes(filenames[i])) {
          images.concat([
            await uploadImage(
              files.find((image) => image.name === filenames[i])!,
              this.props.auth.user,
              eventID,
            ),
          ])
        }
      }

      /* Creates event's poster */
      const poster = await updateEventPoster({
        id: this.props.originalValues?.general!.posterID ?? '',
        owner: this.props.auth.user.getSub(),
        artists: Array.from(this.props.values.general!.daysOfEvent, (day) => {
          if (day.artists[0].name !== undefined && day.artists[0].name !== '') {
            return Array.from(day.artists, (artist) => {
              return {
                name: artist.name,
                performanceDateTime: Func.parseDateTimeToISO(artist.startDateTime),
              }
            })
          }
          return null
        }),
      })

      /* Update an event with its general information */
      await updateEvent({
        id: eventID,
        owner: this.props.auth.user.getSub(),
        name: this.props.values.general!.name,
        promotions: [],
        eventPosterId: poster.id,
        startDate: Func.parseDateTimeToISO(this.props.values.general!.daysOfEvent[0].date[0]),
        location: Func.parseGoogleMapsUrlToLocation(this.props.values.general!.address!),
        addressAlias: this.props.values.general!.addressAlias,
        companyID: this.props.auth.user.getSub(),
        description: this.props.values.general!.description,

        /* We don't update the date, since it is never changed after the event creation*/
        // date: this.props.values.general.daysOfEvent.map(({ date }) => {
        //   return [Func.parseDateTimeToISO(date[0]), Func.parseDateTimeToISO(date[1])]}),

        eventBannerId: originalFilenames[0] === filenames[0]
          ? this.props.originalImagesIDs[0].id!
          : images[0].object.id!,
      })
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while updating the event: ', error)

      let message = 'Failed to update event'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }
  }

  /**
   * Updates event's consumables.
   *
   * @param eventID the identity id of the event to be updated
   */
  private updatesEventConsumables = async (eventID: string):
  Promise<{regular: Consumable, large: Consumable | null}[]> => {
    const consumablesList: {regular: Consumable, large: Consumable | null}[] = []
    let newConsumablesList: {regular: Consumable, large: Consumable | null}[] = []

    try {
      /* We use this arrays to check if the user deleted, created or updated consumables */
      const originalConsumables: string[] = Array.from(
        this.props.originalValues?.consumables!.each ?? [],
        (consumable) => (consumable.id ?? '')
      )

      for (let i = 0; i < originalConsumables.length; i += 1) {
        /* We retrieve both consumables to analyse if a change was made */
        const originalConsumable: AddEditConsumableFormValues = this.props.originalValues!.consumables!.each
          .find((consumable) => consumable.id === originalConsumables[i])!
        const newConsumable: AddEditConsumableFormValues = this.props.values.consumables!.each
          .find((consumable) => consumable.id === originalConsumables[i])!

        /* If they are the same consumable but something was changed, we update the original consumable */
        if (!this.equalConsumables(originalConsumable, newConsumable)) {
          /* Creates the object carrying the information to update the regular consumable */
          const regularConsumable = {
            id: newConsumable.id!,
            name: newConsumable.name,
            prices: {
              price: parseFloat(newConsumable.price),
              memberPrice: newConsumable.memberPrice !== ''
                ? parseFloat(newConsumable.memberPrice)
                : null,
              promoPrices: newConsumable.promotion.date.start.date !== ''
                ? [{
                  price: newConsumable.promotion.prices.regularSize.price !== ''
                    ? parseFloat(newConsumable.promotion.prices.regularSize.price)
                    : null,
                  memberPrice: newConsumable.promotion.prices.regularSize.memberPrice !== ''
                    ? parseFloat(newConsumable.promotion.prices.regularSize.memberPrice)
                    : null,
                  dateSpan: {
                    start: newConsumable.promotion.date.start.date !== ''
                      ? Func.parseDateTimeToISO(newConsumable.promotion.date.start)
                      : null,
                    end: newConsumable.promotion.date.end.date !== ''
                      ? Func.parseDateTimeToISO(newConsumable.promotion.date.end)
                      : null,
                  },
                }]
                : [],
            },
          } as UpdateConsumableInput

          let largeConsumableToAdd = null

          // If there is a large consumable in the submission
          if (newConsumable.largeVersionName !== null && newConsumable.largeVersionName !== '') {
            // If there was already an old version of this large consumable, we only edit it
            if (originalConsumable.largeVersionName !== null && originalConsumable.largeVersionName !== '') {
              const largeConsumable = {
                id: newConsumable.consumableSmallOrLargeRefId,
                name: newConsumable.largeVersionName,
                prices: {
                  price: parseFloat(newConsumable.largeVersionPrice),
                  memberPrice: newConsumable.largeVersionMemberPrice !== ''
                    ? parseFloat(newConsumable.largeVersionMemberPrice)
                    : null,
                  promoPrices: newConsumable.promotion.date.start.date !== ''
                    ? [{
                      price: newConsumable.promotion.prices.largeSize.price !== ''
                        ? parseFloat(newConsumable.promotion.prices.largeSize.price)
                        : null,
                      memberPrice: newConsumable.promotion.prices.largeSize.memberPrice !== ''
                        ? parseFloat(newConsumable.promotion.prices.largeSize.memberPrice)
                        : null,
                      dateSpan: {
                        start: newConsumable.promotion.date.start.date !== ''
                          ? Func.parseDateTimeToISO(newConsumable.promotion.date.start)
                          : null,
                        end: newConsumable.promotion.date.end.date !== ''
                          ? Func.parseDateTimeToISO(newConsumable.promotion.date.end)
                          : null,
                      },
                    }]
                    : [],
                },
              } as UpdateConsumableInput
              largeConsumableToAdd = await updateConsumable(largeConsumable)
            } else {
              // Else, we need to create a new large consumable
              /* Creates id to be linked */
              const largeConsumableID = uuid()

              const largeConsumable = {
                id: largeConsumableID,
                owner: this.props.auth.user.getSub(),
                companyID: this.props.auth.user.getSub(),
                type: Func.getConsumableType(newConsumable.category),
                name: newConsumable.largeVersionName,
                size: ConsumableSize.LARGE,
                prices: {
                  price: parseFloat(newConsumable.largeVersionPrice),
                  memberPrice: newConsumable.largeVersionMemberPrice !== ''
                    ? parseFloat(newConsumable.largeVersionMemberPrice)
                    : null,
                  promoPrices: newConsumable.promotion.date.start.date !== ''
                    ? [{
                      price: newConsumable.promotion.prices.largeSize.price !== ''
                        ? parseFloat(newConsumable.promotion.prices.largeSize.price)
                        : null,
                      memberPrice: newConsumable.promotion.prices.largeSize.memberPrice !== ''
                        ? parseFloat(newConsumable.promotion.prices.largeSize.memberPrice)
                        : null,
                      dateSpan: {
                        start: newConsumable.promotion.date.start.date !== ''
                          ? Func.parseDateTimeToISO(newConsumable.promotion.date.start)
                          : null,
                        end: newConsumable.promotion.date.end.date !== ''
                          ? Func.parseDateTimeToISO(newConsumable.promotion.date.end)
                          : null,
                      },
                    }]
                    : [],
                },
                consumableSmallOrLargeRefId: newConsumable.id,
                eventID,
              } as CreateConsumableInput

              regularConsumable.consumableSmallOrLargeRefId = largeConsumableID

              largeConsumableToAdd = await createEventConsumable(largeConsumable)
            }
          }
          /* Updates the regular consumable */
          const regularConsumableToAdd = await updateConsumable(regularConsumable)

          consumablesList.push({
            regular: regularConsumableToAdd,
            large: largeConsumableToAdd,
          })
        }
      }

      let consumablesToBeAdded: AddEditConsumableFormValues[] = []

      if (this.props.originalValues === undefined || this.props.originalValues.consumables!.each.length === 0) {
        consumablesToBeAdded = this.props.values.consumables!.each
      } else {
        for (let i = 0; i < this.props.values.consumables!.each.length; i += 1) {
          if (this.props.values.consumables!.each[i].id === undefined) {
            consumablesToBeAdded.push(this.props.values.consumables!.each[i])
          }
        }
      }

      /* Creates the new consumables and associate them with this event */
      newConsumablesList = await Promise.all(consumablesToBeAdded.map(async (consumable) => {
        /* Creates id to be linked */
        const largeConsumableID = uuid()

        const consumableObject1 = {
          owner: this.props.auth.user.getSub(),
          companyID: this.props.auth.user.getSub(),
          type: Func.getConsumableType(consumable.category),
          name: consumable.name,
          size: ConsumableSize.REGULAR,
          prices: {
            price: parseFloat(consumable.price),
            memberPrice: consumable.memberPrice !== ''
              ? parseFloat(consumable.memberPrice)
              : null,
            promoPrices: consumable.promotion.date.start.date !== ''
              ? [{
                price: consumable.promotion.prices.regularSize.price !== ''
                  ? parseFloat(consumable.promotion.prices.regularSize.price)
                  : null,
                memberPrice: consumable.promotion.prices.regularSize.memberPrice !== ''
                  ? parseFloat(consumable.promotion.prices.regularSize.memberPrice)
                  : null,
                dateSpan: {
                  start: consumable.promotion.date.start.date !== ''
                    ? Func.parseDateTimeToISO(consumable.promotion.date.start)
                    : null,
                  end: consumable.promotion.date.end.date !== ''
                    ? Func.parseDateTimeToISO(consumable.promotion.date.end)
                    : null,
                },
              }]
              : [],
          },
          eventID,
        } as CreateConsumableInput

        /* If we added a group to this consumable, we need to add it to the object */
        // if (consumable.stallID !== "") consumableObject1.groupID = consumable.stallID
        if (consumable.largeVersionName !== '') consumableObject1.consumableSmallOrLargeRefId = largeConsumableID

        const regularConsumable = await createEventConsumable(consumableObject1) // no call to update???

        /* This means that we have a larger version of this consumable, so we need to add it too */
        if (consumable.largeVersionName !== '') {
          const consumableObject2 = {
            id: largeConsumableID,
            owner: this.props.auth.user.getSub(),
            companyID: this.props.auth.user.getSub(),
            type: Func.getConsumableType(consumable.category),
            name: consumable.largeVersionName,
            size: ConsumableSize.LARGE,
            prices: {
              price: parseFloat(consumable.largeVersionPrice),
              memberPrice: consumable.largeVersionMemberPrice !== ''
                ? parseFloat(consumable.largeVersionMemberPrice)
                : null,
              promoPrices: consumable.promotion.date.start.date !== ''
                ? [{
                  price: consumable.promotion.prices.largeSize.price !== ''
                    ? parseFloat(consumable.promotion.prices.largeSize.price)
                    : null,
                  memberPrice: consumable.promotion.prices.largeSize.memberPrice !== ''
                    ? parseFloat(consumable.promotion.prices.largeSize.memberPrice)
                    : null,
                  dateSpan: {
                    start: consumable.promotion.date.start.date !== ''
                      ? Func.parseDateTimeToISO(consumable.promotion.date.start)
                      : null,
                    end: consumable.promotion.date.end.date !== ''
                      ? Func.parseDateTimeToISO(consumable.promotion.date.end)
                      : null,
                  },
                }]
                : [],
            },
            consumableSmallOrLargeRefId: regularConsumable.id,
            eventID,
          } as CreateConsumableInput

          /* If we added a group to this consumable, we need to add it to the object */
          // if (consumable.stallID !== "") consumableObject2.groupID = consumable.stallID

          const largeConsumable = await createEventConsumable(consumableObject2)

          return {
            regular: regularConsumable,
            large: largeConsumable,
          }
        }
        return {
          regular: regularConsumable,
          large: null,
        }
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while adding the consumables to the event: ', error)

      let message = 'Failed to add consumables to the event'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }

    newConsumablesList.forEach((value) => consumablesList.push(value))
    return consumablesList
  }

  /**
   * Updates event's streaks.
   *
   * @param eventID the identity id of the event to be updated
   * @param consumables array of event consumables
   */
  private updatesEventStreaks = async (eventID: string, consumables: {regular: Consumable, large: Consumable | null}[]):
    Promise<void> => {
    try {
      /* We use this arrays to check if the user deleted, created or updated streaks */
      const originalIDs: string[] = Array.from(this.props.originalValues?.streaks!.each ?? [], (streak) => streak.id)
      const newIDs: string[] = Array.from(this.props.values.streaks!.each, (streak) => streak.id)

      for (let i = 0; i < originalIDs.length; i += 1) {
        /* These dates are used to decide if we can change a streak's state. We can only update it's values if the event
        *  hasn't started yet */
        const startDate: string = Func.parseDateTimeToISO(this.props.values.general!.daysOfEvent[0].date[0])
        const currentDate: string = new Date().toISOString()

        /* Checks if the user deleted streaks, if so, we only allow the delete operation if the event hasn't started */
        if (!newIDs.includes(originalIDs[i])) {
          if (startDate < currentDate) {
            throw new Error('Can\'t delete a streak after event has started!')
          } else {
            await deleteStreak({ id: originalIDs[i] })
          }
          continue
        }

        /* We retrieve both streaks to analyse if a change was made */
        const originalStreak: AddEditStreakFormValues = this.props.originalValues!.streaks!.each.find((streak) => {
          return streak.id === originalIDs[i]
        })!
        const newStreak: AddEditStreakFormValues = this.props.values.streaks!.each.find((streak) => {
          return streak.id === originalIDs[i]
        })!

        /* If they are the same streak but something was changed, we update the original streak */
        if (!this.equalStreaks(originalStreak, newStreak)) {
          const originalPairs = originalStreak.consumablePair
          const newPairs = newStreak.consumablePair

          /* Gather the pairs that already existed on the streak and still exist, to be updated */
          const pairsToUpdate = originalPairs.filter((pair) => this.filterConsumablePairById(pair, newPairs))

          /* Gather the pairs that didn't exist on the streak yet, to be added */
          const pairsToAdd = newPairs.filter((pair) => !this.filterConsumablePairById(pair, originalPairs))

          /* Gather the pairs that existed on the streak but no longer exist, to be removed */
          const pairsToDelete = originalPairs.filter((pair) => !this.filterConsumablePairById(pair, newPairs))

          /* First we need to add new consumable pairs */
          await Promise.all(pairsToAdd.map(async (pair) => {
            return await createPairStreak({
              owner: this.props.auth.user.getSub(),
              streakID: newStreak.id,
              amount: parseInt(pair.amount, 10),
              pairStreakConsumableId: Func.findConsumableID(pair.consumable, consumables),
            })
          }))

          /* Next, we need to delete the removed consumable pairs */
          await Promise.all(pairsToDelete.map(async (pair) => {
            return await deletePairStreak({
              id: pair.id,
            })
          }))

          /* Now, we update the consumable pairs remaining */
          await Promise.all(pairsToUpdate.map(async (pair) => {
            return await updatePairStreak({
              id: pair.id,
              owner: this.props.auth.user.getSub(),
              streakID: newStreak.id,
              amount: parseInt(pair.amount, 10),
              pairStreakConsumableId: Func.findConsumableID(pair.consumable, consumables),
            })
          }))

          await updateStreak({
            id: newStreak.id,
            streakPromoPairId: (await updatePairStreak({
              id: newStreak.prizeId,
              streakID: newStreak.id,
              owner: this.props.auth.user.getSub(),
              amount: parseInt(newStreak.amountPrize, 10),
              pairStreakConsumableId: Func.findConsumableID(newStreak.consumablePrize, consumables),
            })).id!,
            name: newStreak.name,
            owner: this.props.auth.user.getSub(),
            eventID,
            promoPrice: parseFloat(newStreak.prizePrice),
          })
        }
      }

      let streaksToBeAdded: AddEditStreakFormValues[] = []

      if (this.props.originalValues === undefined || this.props.originalValues.streaks!.each.length === 0) {
        streaksToBeAdded = this.props.values.streaks!.each
      } else {
        for (let i = 0; i < this.props.values.streaks!.each.length; i += 1) {
          if (this.props.values.streaks!.each[i].id === undefined) {
            streaksToBeAdded.push(this.props.values.streaks!.each[i])
          }
        }
      }

      /* Add new streaks in the database and link them to this event if they were not in the initial state */
      await Promise.all(streaksToBeAdded.map(async (streak) => {
        /* Used to put in pairs */
        const streakID = uuid()

        /* Creates streak items/tasks */
        await Promise.all(streak.consumablePair.map(async (pair) => {
          return await createPairStreak({
            streakID,
            owner: this.props.auth.user.getSub(),
            amount: parseInt(pair.amount, 10),
            pairStreakConsumableId: Func.findConsumableID(pair.consumable, consumables),
          })
        }))

        /* Creates streak */
        return await createStreak({
          id: streakID,
          promotions: [],
          isRepeatable: true,
          streakPromoPairId: (await createPairStreak({
            streakID,
            owner: this.props.auth.user.getSub(),
            amount: parseInt(streak.amountPrize, 10),
            pairStreakConsumableId: Func.findConsumableID(streak.consumablePrize, consumables),
          })).id,
          name: streak.name,
          owner: this.props.auth.user.getSub(),
          eventID,
          promoPrice: parseFloat(streak.prizePrice),
        })
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while updating the event streaks: ', error)

      let message = 'Failed to update event streaks'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }
  }

  /**
   * Updates event's tickets.
   *
   * @param eventID the identity id of the event to be updated
   */
  private updatesEventTickets = async (eventID: string): Promise<void> => {
    try {
      /* These dates are used to decide if we can change a ticket's state. We can only update it's values if the event
      *  hasn't started yet */
      const startDate: string = Func.parseDateTimeToISO(this.props.values.general!.daysOfEvent[0].date[0])
      const currentDate: string = new Date().toISOString()

      /* We use this arrays to check if the user deleted, created or updated tickets */
      const originalIDs: string[] = Array.from(this.props.originalValues?.tickets?.each ?? [], (ticket) => ticket.id!)
      const newIDs: string[] = Array.from(this.props.values.tickets!.each, (ticket) => ticket.id!)

      /* Checks if the user deleted tickets, if so, we only allow the delete operation if the event hasn't started */
      for (let i = 0; i < originalIDs.length; i += 1) {
        if (!newIDs.includes(originalIDs[i])) {
          if (startDate < currentDate) {
            throw new Error('Can\'t delete a ticket after event has started!')
          } else {
            await deleteTicket({ id: originalIDs[i] })
          }
        }

        /* We retrieve both tickets to analyse if a change was made */
        const originalTicket: AddEditTicketFormValues = this.props.originalValues!.tickets!.each.find((ticket) => {
          return ticket.id === originalIDs[i]
        })!
        const newTicket: AddEditTicketFormValues = this.props.values.tickets!.each.find((ticket) => {
          return ticket.id === originalIDs[i]
        })!

        /* If they are the same ticket but something was changed, we update the original ticket */
        if (!this.equalTickets(originalTicket, newTicket)) {
          await updateTicket({
            id: newTicket.id!,
            owner: this.props.auth.user.getSub(),
            name: newTicket.name,
            prices: {
              price: parseFloat(newTicket.price),
              memberPrice: newTicket.memberPrice ? parseFloat(newTicket.memberPrice) : null,
              promoPrices: newTicket.priceOverRideDate.date !== ''
                ? [{
                  price: parseFloat(newTicket.overridePrice),
                  memberPrice: newTicket.memberOverridePrice !== '' ? parseFloat(newTicket.memberOverridePrice) : null,
                  dateSpan: {
                    start: Func.parseDateTimeToISO(newTicket.priceOverRideDate),
                    end: Func.endOfTimesInISO(),
                  },
                }]
                : [],
            },
            validDates: Func.extractTicketDates(Array.from(this.props.values.general!.daysOfEvent, (day) => {
              return day.date
            }), newTicket.validDates),
            eventID,
          })
        }
      }

      /* Add the new tickets in the database and link them to this event */
      await Promise.all(this.props.values.tickets!.each.map(async (ticket) => {
        if (ticket.id === '') {
          return await createEventTicket({
            eventName: this.props.values.general!.name,
            name: ticket.name,
            owner: this.props.auth.user.getSub(),
            stock: ticket.nrAvailableTickets !== '' ? parseInt(ticket.nrAvailableTickets, 10) : null,
            initialStock: ticket.nrAvailableTickets !== '' ? parseInt(ticket.nrAvailableTickets, 10) : null,
            prices: {
              price: parseFloat(ticket.price),
              memberPrice: ticket.memberPrice ? parseFloat(ticket.memberPrice) : null,
              promoPrices: ticket.priceOverRideDate.date !== ''
                ? [{
                  price: parseFloat(ticket.overridePrice),
                  memberPrice: ticket.memberOverridePrice !== '' ? parseFloat(ticket.memberOverridePrice) : null,
                  dateSpan: {
                    start: Func.parseDateTimeToISO(ticket.priceOverRideDate),
                    end: Func.endOfTimesInISO(),
                  },
                }]
                : [],
            },
            validDates: Func.extractTicketDates(Array.from(this.props.values.general!.daysOfEvent, (day) => {
              return day.date
            }), ticket.validDates),
            eventID,
          })
        }
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while updating the event tickets: ', error)

      let message = 'Failed to add tickets to the event'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }
  }

  /**
   * Updates event's collaborators.
   *
   * @param eventID the identity id of the event to be updated
   */
  private updatesEventCollaborators = async (eventID: string): Promise<void> => {
    try {
      /* Holds the id's of the assigned collaborators */
      const originalCollaborators: string[] = Array.from(this.props.originalValues?.collaborators?.each ?? [])

      /* Checks if we have removed a collaborator from an event */
      for (let i = 0; i < originalCollaborators.length; i += 1) {
        if (!Array.from(this.props.values.collaborators!.each).includes(originalCollaborators[i])) {
          await deleteCollaboratorEventRelation({ id: originalCollaborators[i] })
        }
      }

      /* Links a collaborator with an event */
      await Promise.all(this.props.values.collaborators!.each.filter((value) => {
        return !originalCollaborators.includes(value)
      }).map(async (collaboratorKey) => {
        return await createCollaboratorEventRelation({
          collaboratorID: collaboratorKey,
          eventID,
        })
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while updating the event collaborators: ', error)

      /* Sends error to frontend and tells it how to proceed with the error */
      let message = 'Failed to update event collaborators'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }
  }

  /**
   * Is ran right after the component mounts and tries to update the event.
   */
  public componentDidMount(): void {
    this.updatesEvent().then(() => {
      this.setState({
        pageOperation: operation.SUCCESS,
      })
    })
      .catch((error) => {
        this.setState({
          pageOperation: operation.FAILURE,
          errorMessage: error.message,
        })
      })
  }

  private filterConsumablePairById(
    pair: {consumable: string, amount: string, id: string},
    array: {consumable: string, amount: string, id: string}[],
  ): boolean {
    let isInArray = false
    array.forEach((value) => {
      if (value.id === pair.id) {
        isInArray = true
      }
    })
    return isInArray
  }

  /**
   * Sends event info to database and tries to update an event and all of its components.
   */
  private updatesEvent = async () => {
    /* Pre-sets event id so that we can use it to upload the images */
    const eventID = this.props.values.general!.id!

    try {
      /* Creates event main component */
      await this.updateEvent(eventID)

      /* Creates consumables for this event */
      const consumables = await this.updatesEventConsumables(eventID)

      /* Creates event streaks */
      await this.updatesEventStreaks(eventID, consumables)

      /* Creates event tickets */
      await this.updatesEventTickets(eventID)

      /* Adds collaborators to event */
      await this.updatesEventCollaborators(eventID)
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while editing the event: ', error)

      /* Sends error to frontend and tells it how to proceed with the error */
      let message = 'Failed to edit event'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }
  }

  /**
   * Uses the current page's state to decide what to show the user.
   */
  private getDisplayableText() {
    switch (this.state.pageOperation) {
    case operation.SUCCESS:
      return i18next.t('AddEvent.createdSuccessfully.success')
    case operation.FAILURE:
      return i18next.t('AddEvent.createdSuccessfully.failed') + this.state.errorMessage
    case operation.LOADING:
      return i18next.t('AddEvent.createdSuccessfully.loading')
    default:
      return ''
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    const stringToShow = this.getDisplayableText()
    return (
      <div className="created-successfully-total">
        <h2>{stringToShow}</h2>
      </div>
    )
  }
}

export default withTranslation()(UpdateStage)
