import React, { Component } from 'react'
import { GlobalProps } from '../types/global'
import { RouteComponentProps } from 'react-router-dom'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/event-page.css'

import { dateGenerator, twoDigitNumber } from '../helper/Dates'
import { fetchSetOfImages } from '../models/images'
import { getEvent } from '../models/event'
import { GetEventQueryVariables, Event, Artist, Person, PersonTicket, Ticket } from '../API'
import { ConfirmTicket } from '../components/payments/ConfirmTicket'
import locationPing from '../images/locationPing.png'
import { Auth } from 'aws-amplify'
import { getsPersonInfo } from '../models/person'
import { TicketCardBought } from '../components/events/TicketCardBought'
import { TailSpin } from 'react-loader-spinner'

interface EventPageState {
    eventId: string
    event: Event | null
    person: Person | null
    image: string | null
    date: string
    hour: string
    address: string
    description: string
    highlight: Highlight
    modalVisible: boolean
    personHasTicket: PersonTicket | undefined | null
    loading: boolean
}

enum Highlight {
    PRICES,
    LINEUP,
    TICKETS,
}

// const setModelVisible = useState(false)[1]

class EventPage extends Component<RouteComponentProps<{ eventId: string }> & GlobalProps, EventPageState> {
  constructor(props: RouteComponentProps<{ eventId: string }> & GlobalProps) {
    super(props)
    this.state = {
      eventId: this.props.match.params.eventId,
      event: null,
      person: null,
      image: null,
      date: '',
      hour: '',
      address: '',
      description: '',
      highlight: Highlight.TICKETS,
      modalVisible: false,
      personHasTicket: undefined,
      loading: true,
    }
  }

  async componentDidMount() {
    const eventid: GetEventQueryVariables = { id: this.state.eventId }
    const event = await getEvent(eventid)
    let person = null
    if (await Auth.currentUserInfo() !== undefined) {
      person = await getsPersonInfo({ id: this.props.auth.user.getSub() })
    }
    const address = event.addressAlias
    const description = event.description
    const personHasTicket = this.checkTicketPerson(person, event)

    const aux = dateGenerator(event?.date, true, true, true, 'pt').split(' ')
    const date = aux.slice(0, 5).join(' ')
    const hour = aux.slice(5).join(' ')

    const events = [event]
    const eventImages = await fetchSetOfImages(Array.from(events, (event) => {
      return {
        filename: event.banner?.filename ? event.banner?.filename : '',
        identityId: event.banner?.identityID ? event.banner?.identityID : '',
      }
    }))
    this.setState({ event, person, image: eventImages[0] as string, date, hour, address, description, personHasTicket, loading: false })
    // this.state.event?.poster?.artists.map((dayliartist) => {
    //     const art = dayliartist as unknown as Artist[]
    //     art.map((artist) => {
    //         const d = new Date(artist.performanceDateTime)

    //         console.log(artist.name)
    //         console.log(d.getHours() + ':' + twoDigitNumber(d.getMinutes()))
    //     })

    // })
  }

  /**
   * Function that checks if a ticket as been bought by a user
   *
   * @param personInput Person objects that olds the users tickets
   * @param event
   * @returns returns a PersonTicket object if the user as already has the ticket, else returns null
   */
  checkTicketPerson = (personInput: Person | null, event: Event) => {
    if (personInput === null) {
      return null
    }
    return personInput?.tickets?.items.find((ticket) => {
      const BoughtTicket = event?.tickets?.items.find((aux) => aux?.id === ticket?.personTicketTicketId)
      return BoughtTicket !== undefined
    })
  }

  render() {
    return (
      <div className="event-page">
        { this.state.loading
          && <div className="loading-wrapper">
            <div className="loading">
              <TailSpin
                visible={true}
                height="150"
                width="150"
                color="#02112E"
                radius="0"
              />
            </div>
          </div>
        }
        { !this.state.loading
          && <div className="content-wrapper">
            <div className="event-image">
              {
                this.state.image
                    && <img src={this.state.image} alt="event" />
              }
            </div>
            <div className='event-sections-wrapper'>
              <div className="event-info">
                <p className="event-hour">{this.state.date} | {this.state.hour}</p>
                <h1 className="event-name">{this.state.event?.name}</h1>
                <div className="event-location">
                  <img className="location-ping" src={locationPing} alt="location ping" />
                  <p className="event-address">{this.state.address}</p>
                </div>
                <div className="event-description">
                  <h3>{i18next.t('EventPage.info.about')}</h3>
                  <p>
                    {this.state.description}
                  </p>
                </div>
              </div>
              <div className="sections-wrapper">
                <div className="sections-buttons-wrapper">
                  <div className={this.state.highlight === Highlight.PRICES ? 'section-button-div-highlight' : 'section-button-div'}
                    onClick={() => this.setState({ highlight: Highlight.PRICES })}>
                    {i18next.t('EventPage.info.price')}
                  </div>
                  <div className={this.state.highlight === Highlight.LINEUP ? 'section-button-div-highlight' : 'section-button-div'}
                    onClick={() => this.setState({ highlight: Highlight.LINEUP })}>
                    {i18next.t('EventPage.info.artists')}
                  </div>
                  <div className={this.state.highlight === Highlight.TICKETS ? 'section-button-div-highlight' : 'section-button-div'}
                    onClick={() => this.setState({ highlight: Highlight.TICKETS })}>
                    {i18next.t('EventPage.info.tickets')}
                  </div>
                </div>
                { this.state.highlight === Highlight.PRICES
              && <div className="prices-section">
                {this.state.event?.consumables
                  && this.state.event.consumables.items.map((consumable, index) => {
                    if (consumable === null) return null
                    return (
                      <div key={index} className="consumable-div">
                        <p>{consumable.name}</p>
                        <p>{consumable.prices.price}€</p>
                      </div>
                    )
                  })
                }
              </div>
                }
                { this.state.highlight === Highlight.LINEUP
              && <div className="lineup-section">
                {
                  this.state.event?.poster?.artists.map((dailyArtist, index) => {
                    const artistsArray = dailyArtist as unknown as Artist[]
                    return (
                      <div key={`day${index}`}>
                        { dailyArtist
                          && artistsArray.map((artist, index) => {
                            const d = new Date(artist.performanceDateTime)
                            return (
                              <div key={index} className="artist-div">
                                <h2>{artist.name}</h2>
                                <p>{`${d.getHours()}:${twoDigitNumber(d.getMinutes())}`}</p>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
                }
                { this.state.highlight === Highlight.TICKETS
              && <div className="tickets-section">
                <div className="tickets-wrapper">
                  { this.state.personHasTicket !== null && this.state.personHasTicket !== undefined
                    && <div className="bought-ticket-div" >
                      <h2>{i18next.t('EventPage.info.ticket_bought')}</h2>
                      <TicketCardBought personTicket={this.state.personHasTicket}/>
                    </div>}
                  { (this.state.personHasTicket === undefined || this.state.personHasTicket === null) && this.state.event?.tickets
                    && this.state.event?.tickets.items.map((ticket, index) => {
                      if (ticket) {
                        return (
                          <div key={index} className="ticket-div">
                            <ConfirmTicket ticket={ticket} ticketFee={this.state.event?.company?.ticketsFee}
                              person={this.state.person} user={this.props.auth.user}
                              companyID={this.state.event?.companyID}/>
                          </div>
                        )
                      }
                      return null
                    })
                  }
                </div>
              </div>
              }
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(EventPage)
