import React, { Component } from 'react'

import '../styles/components/create-event/progress-bar.css'

/**
 * Creates a progress bar. The current filled bars and the total bars can be chosen
 *
 */
class ProgressBar extends Component<{filled: number, total: number}> {
  /**
  * React component render function. Holds our extended html code.
  */
  render() {
    const progressBar = []
    for (let i = 0; i < this.props.total; i += 1) {
      progressBar.push(<div
        key={i}
        className="progress-bar-line"
        style={{ backgroundColor: i < this.props.filled ? '#26324B' : '#CBD0D3' }}
      />)
    }

    return (
      <div className="progress-bar-total">{progressBar}</div>
    )
  }
}

export default ProgressBar
