import React, { Component } from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { PopupProps } from '../../types/popup' /* Importing the interface that allow the use of props */
import { AddEditConsumableFormValues, AddEditConsumableProps } from '../../types/create-edit-event' // Imports FormValues props
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { Formik, FormikHelpers } from 'formik' // Needed to create responsive forms
import * as Yup from 'yup' // Used to validate our form
import { TimeInput, DateInput, SelectInput, TextInput } from '../utils/FormFields' // Form fields
import { consumablesKeys } from '../../views/events/util/types'
import AddEditButton from '../AddEditButton'
import Popup from '../Popup'

import '../../styles/components/create-event/add-edit-consumable.css'

/* Used to store list of translated consumables */
let consumables: string[] = []

/**
 * Creates the popup component that allows the user to add or edit a consumable.
 */
class AddEditConsumable extends Component<GlobalProps & PopupProps & AddEditConsumableProps> {
  private popupRef

  constructor(props: GlobalProps & PopupProps & AddEditConsumableProps) {
    super(props)
    this.popupRef = React.createRef<any>()
  }

  /* Holds a blank consumable. Used when we create a new consumable */
  private blankConsumable = {
    name: '',
    type: '',
    category: '',
    price: '',
    memberPrice: '',
    stallID: '',
    largeVersionName: '',
    largeVersionPrice: '',
    largeVersionMemberPrice: '',
    promotion: {
      date: {
        start: {
          date: '',
          time: '',
        },
        end: {
          date: '',
          time: '',
        },
      },
      prices: {
        regularSize: {
          price: '',
          memberPrice: '',
        },
        largeSize: {
          price: '',
          memberPrice: '',
        },
      },
    },
  } as AddEditConsumableFormValues

  /* Holds our form's initial values */
  private initialValues = this.props.consumable
    ? {
      ...this.props.consumable,
      largeMustExist: this.props.hasOriginalLargeVersion,
    }
    : {
      ...this.blankConsumable,
      largeMustExist: false,
    }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({

    largeMustExist: Yup.boolean(),

    name: Yup.string().required(i18next.t('AddEventErrors.addConsumables.name.required'))
      .max(23, i18next.t('AddEventErrors.addConsumables.name.size')),

    category: Yup.string().required(i18next.t('AddEventErrors.addConsumables.category.required'))
      .oneOf(this.getCategories()),

    price: Yup.number().required(i18next.t('AddEventErrors.addConsumables.price.required'))
      .min(0, i18next.t('AddEventErrors.addConsumables.price.min')),

    memberPrice: Yup.number()
      .min(0, i18next.t('AddEventErrors.addConsumables.memberPrice.min')),

    largeVersionName: Yup.string().max(23, i18next.t('AddEventErrors.addConsumables.largeVersionName.size'))
      .when('largeMustExist', {
        is: true,
        then: Yup.string().required(i18next.t('AddEventErrors.addConsumables.largeVersionName.required')),
      }),

    largeVersionPrice: Yup.number().min(0, i18next.t('AddEventErrors.addConsumables.largeVersionPrice.min'))
      .when('largeMustExist', {
        is: true,
        then: Yup.number().required(i18next.t('AddEventErrors.addConsumables.largeVersionPrice.required')),
      }),

    largeVersionMemberPrice: Yup.number().min(0, i18next.t('AddEventErrors.addConsumables.largeVersionMemberPrice.min')),

    promotion: Yup.object().shape({
      date: Yup.object().shape({

        /* Used purely for validation */
        eventStart: Yup.number().default(() => this.props.eventSpan[0]),
        eventEnd: Yup.number().default(() => this.props.eventSpan[1]),

        start: Yup.object().shape({
          date: Yup.date(),
          time: Yup.string()
            .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, i18next.t('AddEventErrors.addBasic.time.match'))
            .test({
              name: 'hasBothElements',
              params: { },
              message: i18next.t('AddEventErrors.addConsumables.promotions.date.hasBoth'),
              test(value) {
                if (value !== undefined && this.parent.date !== undefined) return true
                return value === undefined && this.parent.date === undefined
              },
            }),
        }),

        end: Yup.object().shape({
          date: Yup.date(),
          time: Yup.string()
            .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, i18next.t('AddEventErrors.addBasic.time.match'))
            .test({
              name: 'hasBothElements',
              params: { },
              message: i18next.t('AddEventErrors.addConsumables.promotions.date.hasBoth'),
              test(value) {
                if (value !== undefined && this.parent.date !== undefined) return true
                return value === undefined && this.parent.date === undefined
              },
            }),
        })
          .test({
            name: 'happyHourIsInDateSpan',
            params: { },
            message: i18next.t('AddEventErrors.addConsumables.promotions.date.test'),
            test(_) {
              function hasPromo(promo: any): boolean {
                return Boolean(promo.start.time && promo.start.date && promo.end.time && promo.end.time)
              }

              const pr = this.parent

              if (!hasPromo(pr)) return true

              /* Gets info related to the day of the event */
              const startTimeArray = pr.start.time.split(':')
              const promoStart = new Date(Date.parse(pr.start.date)).setHours(
                parseInt(startTimeArray[0], 10),
                parseInt(startTimeArray[1], 10),
              )
              const endTimeArray = pr.end.time.split(':')
              const promoEnd = new Date(Date.parse(pr.end.date)).setHours(
                parseInt(endTimeArray[0], 10),
                parseInt(endTimeArray[1], 10),
              )

              /* Checks if all the artists are performing inside the span of the date-time of the event */
              if ((promoStart < pr.eventStart || promoStart > pr.eventEnd)
              || (promoEnd < pr.eventStart || promoEnd > pr.eventEnd)
              || promoEnd < promoStart) {
                alert(i18next.t('AddEventErrors.addConsumables.promotions.date.test'))
                return false
              } return true
            },
          }),

      }),
      prices: Yup.object().shape({

        regularSize: Yup.object().shape({
          price: Yup.number().min(0, i18next.t('AddEventErrors.addConsumables.price.min')),
          memberPrice: Yup.number().min(0, i18next.t('AddEventErrors.addConsumables.price.min'))
            .test({
              name: 'hasBothPrices',
              params: { },
              message: i18next.t('AddEventErrors.addConsumables.promotions.prices.test'),
              test(value) {
                if (`${value}` !== '' && `${this.parent.price}` !== '') return true
                return `${value}` === '' && `${this.parent.price}` === ''
              },
            }),
        }),

        // TODO: Have a test to see if there was an earlier Consumable Name. If there was, FORCE THE USER TO PUT A NAME
        largerSize: Yup.object().shape({
          price: Yup.number().min(0, i18next.t('AddEventErrors.addConsumables.price.min')),
          memberPrice: Yup.number().min(0, i18next.t('AddEventErrors.addConsumables.price.min'))
            .test({
              name: 'hasBothPrices',
              params: { },
              message: i18next.t('AddEventErrors.addConsumables.promotions.prices.test'),
              test(value) {
                if (`${value}` !== '' && `${this.parent.price}` !== '') return true
                return `${value}` === '' && `${this.parent.price}` === ''
              },
            }),
        }),

      }),
    }),

  })

  /**
   * Converts the keysList to the list of the current locale, for reference for the validation
   */
  private getCategories() : string[] {
    consumables = []
    for (let i = 0; i < 11; i += 1) consumables.push(i18next.t(`Consumables.subCategories.${consumablesKeys[i]}`))
    return consumables
  }

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   *
   * @param setSubmitting function called to change the state of the button
   * @return Returns a void promise since it won't have a specific type
   * @param resetForm function called to reset form input fields
   */
  private handleSubmit = (values: AddEditConsumableFormValues, { setSubmitting, resetForm }:
      FormikHelpers<AddEditConsumableFormValues>): void => {
    /* Currently holds "what is shown to the user" but we need to put the default one before sending to DB */
    values.type = consumablesKeys[consumables.indexOf(values.category)].toUpperCase()

    /* Creates info input object */
    const input: AddEditConsumableFormValues = {
      ...values,
      __typename: 'Consumable',
    }

    /* Enables submit button again */
    setSubmitting(false)

    this.popupRef.current.closePage()

    /* Sends info to parent component and tells it to render next phase */
    this.props.updateInfo(input)

    /* Sets new values only if we are updating a consumable */
    if (this.props.consumable) this.initialValues = values

    /* Resets form after submitting */
    resetForm()
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    /* Creates a list of consumables (translated) */
    consumables = []
    for (let i = 0; i < 11; i += 1) consumables.push(i18next.t(`Consumables.subCategories.${consumablesKeys[i]}`))

    return (
      <Popup ref={this.popupRef} show={this.props.show} close={this.props.close}>
        <div className="add-edit-consumable-body-main">
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            validateOnBlur={false}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={this.handleSubmit}
          >
            {(props) => (
              <form className="form-wrapper" onSubmit={props.handleSubmit} autoComplete="off">
                <TextInput
                  value={props.values.name}
                  onChange={props.handleChange}
                  label={i18next.t('AddEvent.addConsumables.nameMandatory')}
                  name="name"
                />
                <SelectInput
                  value={props.values.category}
                  onChange={props.handleChange}
                  label={i18next.t('AddEvent.addConsumables.subCategoryMandatory')}
                  name="category"
                  setfield={props.setFieldValue}
                  onBlur={props.handleBlur}
                  options={consumables}
                  disabled={!this.props.editCategory}
                />
                <div className="add-edit-consumable-price">
                  <div className="add-edit-consumable-price-single">
                    <TextInput
                      value={props.values.price}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addConsumables.priceWithCurrency')}
                      name="price"
                    />
                  </div>
                  <div className="add-edit-consumable-price-single">
                    <TextInput
                      value={props.values.memberPrice}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addConsumables.memberPriceWithCurrency')}
                      name="memberPrice"
                    />
                  </div>
                </div>
                <h3 className="optional-text">{i18next.t('AddEvent.addConsumables.optional')}</h3>
                {/*
                <h3 className="large-size-text">{i18next.t('AddEvent.addConsumables.largeVersion')}</h3>
                <TextInput
                  value={props.values.largeVersionName}
                  onChange={props.handleChange}
                  label={i18next.t('AddEvent.addConsumables.name')}
                  name="largeVersionName"
                  disabled={!this.props.editCategory}
                />
                <div className="add-edit-consumable-price">
                  <div className="add-edit-consumable-price-single">
                    <TextInput
                      value={props.values.largeVersionPrice}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addConsumables.priceWithCurrency')}
                      name="largeVersionPrice"
                      disabled={!this.props.editCategory}
                    />
                  </div>
                  <div className="add-edit-consumable-price-single">
                    <TextInput
                      value={props.values.largeVersionMemberPrice}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addConsumables.memberPriceWithCurrency')}
                      name="largeVersionMemberPrice"
                    />
                  </div>
                </div>
                */}

                <h3 className="promo-text">{`${i18next.t('AddEvent.addConsumables.promotion')}:`}</h3>

                <div className="add-edit-consumable-promo-date">
                  <h2>{i18next.t('AddEvent.addConsumables.start')}</h2>

                  <div id="time-wrapper">
                    <TimeInput
                      value={props.values.promotion.date.start.time}
                      onChange={props.handleChange}
                      name="promotion.date.start.time"/>
                  </div>
                  <div id="date-wrapper">
                    <DateInput
                      value={props.values.promotion.date.start.date}
                      onChange={props.handleChange}
                      name="promotion.date.start.date"/>
                  </div>
                </div>

                <div className="add-edit-consumable-promo-date">
                  <h2>{i18next.t('AddEvent.addConsumables.end')}</h2>

                  <div id="time-wrapper">
                    <TimeInput
                      value={props.values.promotion.date.end.time}
                      onChange={props.handleChange}
                      name="promotion.date.end.time"/>
                  </div>

                  <div id="date-wrapper">
                    <DateInput
                      value={props.values.promotion.date.end.date}
                      onChange={props.handleChange}
                      name="promotion.date.end.date"/>
                  </div>
                </div>

                <h4>{i18next.t('AddEvent.addConsumables.pricesNormalSize')}</h4>
                <div className="add-edit-consumable-price">
                  <div className="add-edit-consumable-price-single">
                    <TextInput
                      value={props.values.promotion.prices.regularSize.price}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addConsumables.priceWithCurrency')}
                      name="promotion.prices.regularSize.price"
                    />
                  </div>
                  <div className="add-edit-consumable-price-single">
                    <TextInput
                      value={props.values.promotion.prices.regularSize.memberPrice}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addConsumables.memberPriceWithCurrency')}
                      name="promotion.prices.regularSize.memberPrice"
                    />
                  </div>
                </div>

                { props.values.largeVersionName && props.values.largeVersionPrice
                && <>
                  <h4>{i18next.t('AddEvent.addConsumables.pricesLargeSize')}</h4>
                  <div className="add-edit-consumable-price">
                    <div className="add-edit-consumable-price-single">
                      <TextInput
                        value={props.values.promotion.prices.largeSize.price}
                        onChange={props.handleChange}
                        label={i18next.t('AddEvent.addConsumables.priceWithCurrency')}
                        name="promotion.prices.largeSize.price"
                      />
                    </div>
                    <div className="add-edit-consumable-price-single">
                      <TextInput
                        value={props.values.promotion.prices.largeSize.memberPrice}
                        onChange={props.handleChange}
                        label={i18next.t('AddEvent.addConsumables.memberPriceWithCurrency')}
                        name="promotion.prices.largeSize.memberPrice"
                      />
                    </div>
                  </div>
                </>
                }

                <AddEditButton item={this.props.consumable} type="consumable" disable={props.isSubmitting}/>

              </form>
            )}
          </Formik>
        </div>
      </Popup>
    )
  }
}

export default withTranslation()(AddEditConsumable)
