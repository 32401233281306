import React, { Component } from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { RouteComponentProps } from 'react-router-dom'
import AuthButton from '../../components/AuthButton'

import '../../styles/views/auth/welcome.css'

/**
 * Welcome page. Is shown after completing successfully a registration.
 */
class Welcome extends Component<RouteComponentProps & GlobalProps> {
  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="welcome-background">
        <div className="welcome-main">
          <div className="welcome-container">
            {/* Button used to go back to the login page */}
            <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
            <h1 id="welcome-catchphrase">{i18next.t('Welcome.catchphrase')}</h1>
            <p>{i18next.t('Welcome.secondaryText')}</p>
            <div onClick={() => this.props.history.push('/login')}>
              <AuthButton type="button" text={i18next.t('Welcome.button')} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Welcome)
