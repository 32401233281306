import { Component } from 'react'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */
import { Link, RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronRight } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import Amplify, { Auth } from 'aws-amplify'
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import User from '../models/classes/Cognito-user'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/profile.css'

/**
 * Simple page that allows user to logout and check his profile
 */

class Profile extends Component<GlobalProps & RouteComponentProps> {
  handleLogout = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    /* Since we are overriding the default behavior, we need to block further submits */
    event.preventDefault()

    try {
      /* Logs user out */
      await Auth.signOut()

      /* Tells App component (parent) that the user logged out successfully by changing App's properties */
      this.props.auth.setAuthState(false)
      this.props.auth.setUserState(new User(undefined))
      Amplify.configure({ aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AWS_IAM })

      /* Used to redirect the user to the landing page */
      this.props.history.push('/')
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      } else if (error instanceof String) {
        console.log(error)
      }
    }
  }

  render() {
    return (

      <div className="profile-total">
        <div className="profile-title">
          <h2>{i18next.t('Profile.title')}</h2>
        </div>
        <div className="profile-main">
          <div className="actions-div">
            <Link to="/change-password" style={{ textDecoration: 'none' }}>
              <div className="container-action">
                <p className={'text-action'}>{i18next.t('Profile.changePassword')}</p>
                <FontAwesomeIcon className={'icon-action'} icon={faChevronRight}/>
              </div>
            </Link>
          </div>
          <button className="logout-button" onClick={this.handleLogout}>
            {i18next.t('Profile.logout')}
          </button>
        </div>
      </div>

    )
  }
}

export default withTranslation()(Profile)
