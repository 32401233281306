import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */
import { EventRefundState } from '../types/event-refund'

import Layout from '../components/Layout'
import ProgressBar from '../components/ProgressBar'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Slider } from '@material-ui/core'
import StyledCheckbox from 'src/components/utils/StyledCheckbox'
import SearchBar from '../components/utils/SearchBar'
import ConfirmRefund from '../components/ConfirmRefund'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { TailSpin } from 'react-loader-spinner'
import UserTicketInfoWrapper from '../components/UserTicketInfoWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faArrowRight, faRemove } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import { listEventTicketHolders } from '../models/event'
import { PersonTicket } from '../API'

import i18next from 'i18next'
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/event-refund.css'

/* eslint-disable */
enum refundStages {
  VALUE,
  PEOPLE,
  FEEDBACK,
}
/* eslint-enable */

/**
 * This page shows the privacy policy of our platform.
 */
class EventRefund extends Component<RouteComponentProps<undefined, Record<string, never>, { eventID: string }>
& GlobalProps,
EventRefundState
> {
  /**
   * EventRefund class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      refundValue: 0,
      subPage: refundStages.VALUE,
      eventID: this.props.location.state.eventID,
      isLoading: true,
      eventGoers: [],
      selectedForRefund: [],
      nextToken: null,
      searchText: '',
      loading: false,
      showConfirmPopup: false,
      successfulRefund: false,
      updateStock: false,
    }
  }

  /**
   * Fetches initial batch of event goers
   */
  async componentDidMount() {
    const eventGoersResponse = await listEventTicketHolders(this.state.eventID)

    /* Since we have finished gathering information, we set page as not loading to render the rest of the components */
    this.setState({
      eventGoers: eventGoersResponse.items,
      nextToken: eventGoersResponse.nextToken,
    })
  }

  /* eslint-disable */
  /* Handles changes to the slider that selects the refund percentage */
  private handleSliderChange = (_event: React.ChangeEvent<{}>, newValue: number | number[]): void => {
    if (typeof newValue === 'number') {
      /* This new value is between 0 - 100 and not 0 - 1 */
      this.setState({ refundValue: newValue })
    }
  }
  /* eslint-enable */

  /* Navigates to the next page */
  private nextPage = (): void => {
    this.setState({ subPage: this.state.subPage + 1 })
  }

  /* Navigates to the previous page */
  private previousPage = (): void => {
    this.setState({ subPage: this.state.subPage - 1 })
  }

  /* Selects a user for refund and removes him from the list of selected people for refund on the right  */
  private selectUser = (item: PersonTicket): void => {
    const selectedForRefundHelper = this.state.selectedForRefund
    const eventGoersHelper = this.state.eventGoers

    selectedForRefundHelper.push(item)
    const index = eventGoersHelper.indexOf(item)
    if (index > -1) {
      eventGoersHelper.splice(index, 1)
    }

    this.setState({ eventGoers: eventGoersHelper, selectedForRefund: selectedForRefundHelper })
  }

  /* Unselects a user for refund and adds him back to the list of event goers on the left  */
  private unselectUser = (item: PersonTicket): void => {
    const selectedForRefundHelper = this.state.selectedForRefund
    const eventGoersHelper = this.state.eventGoers

    eventGoersHelper.push(item)
    const index = selectedForRefundHelper.indexOf(item)
    if (index > -1) {
      selectedForRefundHelper.splice(index, 1)
    }

    this.setState({ eventGoers: eventGoersHelper, selectedForRefund: selectedForRefundHelper })
  }

  /* Fetches more event goers when the event goers list reaches the bottom */
  private fetchingEventGoers = async (): Promise<void> => {
    let eventGoersHelper = this.state.eventGoers
    let newFilteredHelper: PersonTicket[] = []
    let nextTokenHelper = this.state.nextToken
    while (newFilteredHelper.length < 10 && nextTokenHelper !== null) {
      const eventGoersResponse = await listEventTicketHolders(this.state.eventID, nextTokenHelper)
      nextTokenHelper = eventGoersResponse.nextToken
      eventGoersHelper = [...eventGoersHelper, ...eventGoersResponse.items]
      newFilteredHelper = [
        ...newFilteredHelper,
        ...eventGoersResponse.items.filter((item) => (item.person.handle?.startsWith(this.state.searchText))),
      ]
    }
    this.setState({ nextToken: nextTokenHelper, eventGoers: eventGoersHelper })
  }

  /* Removes all the selected users from refund */
  private deselectAll = (): void => {
    this.setState({
      eventGoers: [...this.state.eventGoers, ...this.state.selectedForRefund],
      selectedForRefund: [],
    })
  }

  private showPopup = (): void => {
    this.setState({
      showConfirmPopup: true,
    })
  }

  private closePopup = (): void => {
    this.setState({
      showConfirmPopup: false,
    })
  }

  private feedbackAction = (success: boolean): void => {
    this.setState({ subPage: refundStages.FEEDBACK, successfulRefund: success })
  }

  /* Selects all users for refund. This function takes a while since it has to fetch everyone going to the
  event before adding them to the list */
  private selectAll = async (): Promise<void> => {
    if (this.state.eventGoers.length === 0 || this.state.loading) return

    let eventGoersHelper = this.state.eventGoers
    if (this.state.nextToken !== null) {
      this.setState({ loading: true })
      do {
        const eventGoersResponse = await listEventTicketHolders(this.state.eventID, this.state.nextToken)
        eventGoersHelper = [...eventGoersHelper, ...eventGoersResponse.items]
        this.setState({
          nextToken: eventGoersResponse.nextToken,
        })
      } while (this.state.nextToken !== null)
      this.setState({ loading: false })
    }
    this.setState({
      eventGoers: [],
      selectedForRefund: [...this.state.selectedForRefund, ...eventGoersHelper],
    })
  }

  /* Performs a search */
  private search = async (value: string): Promise<void> => {
    this.setState({ searchText: value })
    let eventGoersHelper = this.state.eventGoers
    let filteredHelper = this.state.eventGoers
      .filter((item) => (item.person.handle?.startsWith(this.state.searchText)))
    let nextTokenHelper = this.state.nextToken
    while (filteredHelper.length < 10 && nextTokenHelper !== null) {
      const eventGoersResponse = await listEventTicketHolders(this.state.eventID, nextTokenHelper)
      nextTokenHelper = eventGoersResponse.nextToken
      eventGoersHelper = [...eventGoersHelper, ...eventGoersResponse.items]
      filteredHelper = [
        ...filteredHelper,
        ...eventGoersResponse.items.filter((item) => (item.person.handle?.startsWith(this.state.searchText))),
      ]
    }
    this.setState({ nextToken: nextTokenHelper, eventGoers: eventGoersHelper })
  }

  /* Used to style the slider */
  private muiTheme = createTheme({
    overrides: {
      MuiSlider: {
        thumb: {
          color: '#02112E',
        },
        track: {
          color: '#02112E',
        },
        rail: {
          color: '#02112E',
        },
      },
    },
  });

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    if (this.state.subPage === refundStages.VALUE) {
      return (
        <Layout label={i18next.t('EventRefund.title')}>
          <>
            <h3 className="event-refund-info-text">
              {i18next.t('EventRefund.firstStep')}
            </h3>
            <div className="event-refund-slider-container">
              <div className="event-refund-slider-wrapper">
                <ThemeProvider theme={this.muiTheme}>
                  <Slider
                    value={this.state.refundValue}
                    defaultValue={0}
                    max={100}
                    onChange={this.handleSliderChange}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </ThemeProvider>
              </div>
              <h2 className="event-refund-value-text">
                {`${this.state.refundValue} %`}
              </h2>
            </div>
            <div className="event-refund-update-stock-wrapper">
              <h3 className="event-refund-info-text">
                {i18next.t('EventRefund.updateStock')}
              </h3>
              <StyledCheckbox checked={this.state.updateStock}
                onClick= {() => this.setState({ updateStock: !this.state.updateStock })}/>
            </div>
            <div className="event-refund-progress-bar">
              <ProgressBar filled={this.state.subPage + 1} total={2} />
              <button className="navigation-button" onClick={this.nextPage}>
                {i18next.t('EventRefund.next')}
              </button>
            </div>
          </>
        </Layout>
      )
    }
    if (this.state.subPage === refundStages.PEOPLE) {
      return (
        <Layout label={i18next.t('EventRefund.title')}>
          <>
            <ConfirmRefund show={this.state.showConfirmPopup} close={this.closePopup}
              feedbackAction={this.feedbackAction}
              input={{ companyID: this.props.auth.user.getSub(),
                refundPercentage: this.state.refundValue / 100,
                eventID: this.state.eventID,
                updateStock: this.state.updateStock,
                refundInfo: this.state.selectedForRefund.map((item) => (
                  { personID: item.person.id, ticketID: item.ticket.id, personTicketID: item.id }
                )) }}/>
            <h3 className="event-refund-info-text">
              {i18next.t('EventRefund.secondStep')}
            </h3>
            <div className="event-refund-lists-container">
              <div>
                <h3 className="event-refund-list-title">{i18next.t('EventRefund.usersWithTicket')}</h3>
                <div className="event-refund-search-bar-wrapper">
                  <SearchBar changeText={this.search} text={this.state.searchText} />
                </div>
                <InfiniteScroll
                  dataLength={this.state.eventGoers.length}
                  next={this.fetchingEventGoers}
                  scrollThreshold={0.7}
                  height="39vw"
                  hasMore={this.state.nextToken !== null}
                  loader={<div className="event-refund-loading">
                    <TailSpin visible={true} height="4vw" width="4vw" color= {'#02112E'} radius="0"/>
                  </div>}
                  endMessage={ this.state.eventGoers.length !== 0
                  && <div className="event-refund-loading">
                    <p>{i18next.t('EventRefund.allUsersLoaded')}</p>
                  </div>
                  }
                  className="event-refund-list">
                  {this.state.eventGoers.filter((item) => (item.person.handle?.startsWith(this.state.searchText)))
                    .map((item) => (
                      <div key={item.person.id}>
                        <UserTicketInfoWrapper person={item.person} ticket={item.ticket}>
                          <button className="event-refund-button" onClick={() => this.selectUser(item)}>
                            <FontAwesomeIcon className="event-refund-button-icon" icon={ faArrowRight }/>
                          </button>
                        </UserTicketInfoWrapper>
                      </div>
                    ))}
                </InfiniteScroll>
              </div>
              <div>
                <h3 className="event-refund-list-title">{i18next.t('EventRefund.usersSelected')}</h3>
                <div className="event-refund-full-select-wrapper">
                  <button id="alternative" className="regular-button full-select-button" onClick={this.deselectAll}>
                    {i18next.t('EventRefund.deselectAll')}
                  </button>
                  <button className="regular-button full-select-button" onClick={this.selectAll}>
                    { this.state.loading
                      ? <TailSpin visible={true} height="1.8vw" width="1.8vw" color= {'#FFF'} radius="0" />
                      : i18next.t('EventRefund.selectAll')
                    }
                  </button>
                </div>
                <div className="event-refund-list">
                  {this.state.selectedForRefund.map((item) => (
                    <UserTicketInfoWrapper person={item.person} ticket={item.ticket}>
                      <button className="event-refund-button" onClick={() => this.unselectUser(item)}>
                        <FontAwesomeIcon className="event-refund-button-icon" icon={ faRemove }/>
                      </button>
                    </UserTicketInfoWrapper>
                  ))}
                </div>
              </div>
            </div>
            <div className="event-refund-progress-bar">
              <ProgressBar filled={this.state.subPage + 1} total={2} />
              <div className="navigation-buttons-total">
                <button id="alternative" className="regular-button navigation-button" onClick={this.previousPage}>
                  {i18next.t('EventRefund.previous')}
                </button>
                <button className="regular-button navigation-button" onClick={this.showPopup}>
                  {i18next.t('EventRefund.refund')}
                </button>
              </div>
            </div>
          </>
        </Layout>
      )
    }
    return (
      <Layout label={i18next.t('EventRefund.title')}>
        <div className="refund-wrapper">
          <h3 className="refund-feedback-text">
            {this.state.successfulRefund ? i18next.t('EventRefund.successfulRefund') : i18next.t('EventRefund.unsuccessfulRefund')}
          </h3>
        </div>
      </Layout>
    )
  }
}

export default withTranslation()(EventRefund)
