import React, { Component, useState } from 'react'
import { GlobalProps } from '../types/global'
import { Link } from 'react-router-dom'  // Used to redirect without refreshing whole page
import { Auth } from 'aws-amplify'  // Used to call current user
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import AuthButton from './AuthButton'  // Holds login button
import SelectLanguage from './SelectLanguage'
import { Modal } from '@material-ui/core'

import '../styles/components/qrcodepage-navbar.css'
import User from '../models/classes/Cognito-user'

import logo from '../images/logoClear.png'
import exitButton from '../images/exitbutton.png'

// import { ReactComponent as Profile } from 'profile.svg';

/* eslint-disable */
/** The function of enum is to limit the choice of highlighted content  */
enum Highlight { profile, collaborators, myEvents, members, allEvents }
/* eslint-enable */

/**
 * Creates the navbar that will be present in many pages.
 */
class QRCodePageNavBar extends Component<GlobalProps, { show: boolean, highlight: Highlight | null, modalVisible: boolean}> {
  constructor(props: GlobalProps) {
    super(props)
    this.state = {
      show: false, /* Controls if the select language is being shown or not */
      highlight: null, /* When a page in the navbar is clicked we want to highlight that page thus we keep this state */
      modalVisible: false,
    }
  }

  /**
  * Logs out currently signed in user. TODO: Not currently being used. Will be used later on in user's profile.
  *
  * @param event mouse click at 'a' html tag.
  */
  public handleLogout = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    /* Since we are overriding the default behavior, we need to block further submits */
    event.preventDefault()

    try {
      /* Logs user out */
      await Auth.signOut()

      /* Tells App component (parent) that the user logged out successfully by changing App's properties */
      this.props.auth.setAuthState(false)
      this.props.auth.setUserState(new User(undefined))
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      } else if (error instanceof String) {
        console.log(error)
      }
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    const profileImage: string = require('../assets/profileImage.svg').default
    /* No account Version */
    if (!this.props.auth.isAuthenticated || !this.props.auth.user.isLoggedIn()) {
      return (
        <nav className="nav-bar" id="not-auth">
          {/* I used a wrapper because this component is off the body of
              the landing page so without a wrapper the height wasn't being responsive */}
          <div className="nav-bar-wrapper">
            <div className="buttons-wrapper">
              <button className="language-button" type="button" onClick={() => this.setState({ show: true })}>
                {i18next.t('NavBar.currentLanguage')}
              </button>
              <div>
                <Link className="page-selector-special" id={this.state.highlight === Highlight.allEvents ? 'highlighted' : undefined}
                  onClick={() => { this.setState({ highlight: Highlight.allEvents }) }} to="/all-events">
                  {i18next.t('NavBar.allEvents')}
                </Link>
                {/* <a> i used instead of <Link> because it works better since the page Login doesn't use the Navbar */}
                <a href="/login">
                  <AuthButton text={i18next.t('NavBar.login')} property="line-only"/>
                </a>
              </div>
            </div>
          </div>
          {/* This is the pop up that will allow the user to select the language */}
          <SelectLanguage show={this.state.show} close={() => this.setState({ show: false })}/>
        </nav>
      )

      /* Individual version */
    } else if (this.props.auth.isAuthenticated && this.props.auth.user.isPerson()) {
      return (
        <>
          <nav className="nav-bar">
            {/* I used a wrapper because this component is off the body of
                the landing page so without a wrapper the height wasn't being responsive */}
            <div className="nav-bar-wrapper">
              <div className="buttons-wrapper">
                <button className="language-button" type="button" onClick={() => this.setState({ show: true })}>
                  {i18next.t('NavBar.currentLanguage')}
                </button>
                {/* <Link className="page-selector" id={this.state.highlight === Highlight.allEvents ? 'highlighted' : undefined}
                onClick={() => { this.setState({ highlight: Highlight.allEvents }) }} to="/all-events">
                {i18next.t('NavBar.allEvents')}
                Eventos
              </Link>
                <Link className="logo-clear" to="/"
                  onClick={() => this.setState({ highlight: null })}>
                  <img src={logo} />
                </Link>
                */}
                { !window.location.href.includes('payment-feedback') && <div className="right-navbar-buttons">
                  <Link className={this.state.highlight === Highlight.allEvents ? 'events-button-highlight' : 'events-button'}
                    onClick={() => this.setState({ highlight: Highlight.allEvents })}
                    to="/all-events">
                    {i18next.t('NavBar.events')}
                  </Link>
                  <div className="profile-img" onClick={() => this.setState({ highlight: Highlight.profile, modalVisible: true })}>
                    <img src={profileImage} />
                  </div>
                </div>}
              </div>
            </div>
            {/* This is the pop up that will allow the user to select the language */}
            <SelectLanguage show={this.state.show} close={() => this.setState({ show: false })}/>
          </nav>
          {this.state.modalVisible
          && <div>
            <Modal style={{ zIndex: 3000 }} open={this.state.modalVisible} onClose={() => this.setState({ modalVisible: false })}>
              <div className="modal-wrapper">
                <div className="header-wrapper">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                  <img src={exitButton} onClick={() => this.setState({ modalVisible: false })}/>
                </div>
                <div className="links-wrapper">
                  <div className="name-container">
                    <h1>
                      {this.props.auth.user.getName()}
                    </h1>
                  </div>
                  <Link className="name-container" to="/qrcodepage"
                    onClick={() => this.setState({ modalVisible: false })}>
                    <h2>
                      {i18next.t('NavBar.myTickets')}
                    </h2>
                  </Link>
                  <Link className="name-container" to="/profile"
                    onClick={() => this.setState({ modalVisible: false })}>
                    {/* {i18next.t('NavBar.profile')} */}
                    <h2>
                      {i18next.t('NavBar.settings')}
                    </h2>
                  </Link>
                </div>
              </div>
            </Modal>
          </div>
          }
        </>

      )

      /* Organizer version */
    } else if (this.props.auth.isAuthenticated && this.props.auth.user.isCompany()) {
      return (
        <nav className="nav-bar">
          {/* I used a wrapper because this component is off the body of
            the landing page so without a wrapper the height wasn't being responsive */}
          <div className="nav-bar-wrapper">
            <div className="buttons-wrapper">
              {/*              <Link className="page-selector" id={this.state.highlight === Highlight.allEvents ? 'highlighted' : undefined}
                onClick={() => { this.setState({ highlight: Highlight.allEvents }) }} to="/all-events">
                {i18next.t('NavBar.allEvents')}
              </Link>*/}
              <Link className="page-selector" id={this.state.highlight === Highlight.myEvents ? 'highlighted' : undefined}
                onClick={() => { this.setState({ highlight: Highlight.myEvents }) }} to="/my-events">
                {i18next.t('NavBar.myEvents')}
              </Link>
              <Link className="page-selector" id={this.state.highlight === Highlight.collaborators ? 'highlighted' : undefined}
                onClick={() => { this.setState({ highlight: Highlight.collaborators }) }} to="/collaborators">
                {i18next.t('NavBar.collaborators')}
              </Link>
              <Link className="page-selector" id={this.state.highlight === Highlight.members ? 'highlighted' : undefined}
                onClick={() => { this.setState({ highlight: Highlight.members }) }} to="/members">
                {i18next.t('NavBar.members')}
              </Link>
              <Link className="page-selector" id={this.state.highlight === Highlight.profile ? 'highlighted' : undefined}
                onClick={() => { this.setState({ highlight: Highlight.profile }) }} to="/profile">
                {i18next.t('NavBar.profile')}
              </Link>
            </div>
          </div>
        </nav>
      )
    }

    return (<div/>)
  }
}

export default withTranslation()(QRCodePageNavBar)
