/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPersonUpdate = /* GraphQL */ `
  subscription OnPersonUpdate($id: ID!) {
    onPersonUpdate(id: $id) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const onCollaboratorUpdate = /* GraphQL */ `
  subscription OnCollaboratorUpdate($companyID: ID!) {
    onCollaboratorUpdate(companyID: $companyID) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const onWalletTransactionUpdate = /* GraphQL */ `
  subscription OnWalletTransactionUpdate($personID: ID!) {
    onWalletTransactionUpdate(personID: $personID) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const onFriendUpdate = /* GraphQL */ `
  subscription OnFriendUpdate($personID: ID!) {
    onFriendUpdate(personID: $personID) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const onFriendCreate = /* GraphQL */ `
  subscription OnFriendCreate($friendID: ID!) {
    onFriendCreate(friendID: $friendID) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($owner: String) {
    onCreateCompany(owner: $owner) {
      id
      owner
      name
      email
      iban
      initials
      handle
      consumablesFee
      ticketsFee
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      collaborators {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          readTickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          history {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          chargeHistory {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          collaboratorAvatarId
        }
        nextToken
      }
      events {
        items {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        nextToken
      }
      promoters {
        items {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          name
          internalID
          email
          phone
          companyID
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAvatarId
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($owner: String) {
    onUpdateCompany(owner: $owner) {
      id
      owner
      name
      email
      iban
      initials
      handle
      consumablesFee
      ticketsFee
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      collaborators {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          readTickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          history {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          chargeHistory {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          collaboratorAvatarId
        }
        nextToken
      }
      events {
        items {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        nextToken
      }
      promoters {
        items {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          name
          internalID
          email
          phone
          companyID
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAvatarId
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($owner: String) {
    onDeleteCompany(owner: $owner) {
      id
      owner
      name
      email
      iban
      initials
      handle
      consumablesFee
      ticketsFee
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      collaborators {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          readTickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          history {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          chargeHistory {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          collaboratorAvatarId
        }
        nextToken
      }
      events {
        items {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        nextToken
      }
      promoters {
        items {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          name
          internalID
          email
          phone
          companyID
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAvatarId
    }
  }
`;
export const onCreateMember = /* GraphQL */ `
  subscription OnCreateMember($owner: String) {
    onCreateMember(owner: $owner) {
      id
      owner
      name
      internalID
      email
      phone
      companyID
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMember = /* GraphQL */ `
  subscription OnUpdateMember($owner: String) {
    onUpdateMember(owner: $owner) {
      id
      owner
      name
      internalID
      email
      phone
      companyID
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMember = /* GraphQL */ `
  subscription OnDeleteMember($owner: String) {
    onDeleteMember(owner: $owner) {
      id
      owner
      name
      internalID
      email
      phone
      companyID
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCollaborator = /* GraphQL */ `
  subscription OnCreateCollaborator($owner: String) {
    onCreateCollaborator(owner: $owner) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const onUpdateCollaborator = /* GraphQL */ `
  subscription OnUpdateCollaborator($owner: String) {
    onUpdateCollaborator(owner: $owner) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const onDeleteCollaborator = /* GraphQL */ `
  subscription OnDeleteCollaborator($owner: String) {
    onDeleteCollaborator(owner: $owner) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const onCreatePromoter = /* GraphQL */ `
  subscription OnCreatePromoter($owner: String) {
    onCreatePromoter(owner: $owner) {
      id
      owner
      name
      companyID
      events {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePromoter = /* GraphQL */ `
  subscription OnUpdatePromoter($owner: String) {
    onUpdatePromoter(owner: $owner) {
      id
      owner
      name
      companyID
      events {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePromoter = /* GraphQL */ `
  subscription OnDeletePromoter($owner: String) {
    onDeletePromoter(owner: $owner) {
      id
      owner
      name
      companyID
      events {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($owner: String) {
    onCreateEvent(owner: $owner) {
      id
      owner
      name
      promotions
      location {
        lon
        lat
      }
      addressAlias
      companyID
      description
      startDate
      endDate
      date {
        start
        end
      }
      maxCapacity
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      banner {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      poster {
        id
        owner
        artists {
          name
          performanceDateTime
        }
        createdAt
        updatedAt
      }
      streaks {
        items {
          id
          owner
          name
          isRepeatable
          promotions
          eventID
          promoPrice
          promoPair {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          set {
            items {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            nextToken
          }
          createdAt
          updatedAt
          streakPromoPairId
        }
        nextToken
      }
      photos {
        items {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      collaborators {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      consumables {
        items {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        nextToken
      }
      peopleState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      transactionsTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      transactionsConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      promoters {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      eventBannerId
      eventPosterId
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($owner: String) {
    onUpdateEvent(owner: $owner) {
      id
      owner
      name
      promotions
      location {
        lon
        lat
      }
      addressAlias
      companyID
      description
      startDate
      endDate
      date {
        start
        end
      }
      maxCapacity
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      banner {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      poster {
        id
        owner
        artists {
          name
          performanceDateTime
        }
        createdAt
        updatedAt
      }
      streaks {
        items {
          id
          owner
          name
          isRepeatable
          promotions
          eventID
          promoPrice
          promoPair {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          set {
            items {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            nextToken
          }
          createdAt
          updatedAt
          streakPromoPairId
        }
        nextToken
      }
      photos {
        items {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      collaborators {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      consumables {
        items {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        nextToken
      }
      peopleState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      transactionsTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      transactionsConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      promoters {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      eventBannerId
      eventPosterId
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($owner: String) {
    onDeleteEvent(owner: $owner) {
      id
      owner
      name
      promotions
      location {
        lon
        lat
      }
      addressAlias
      companyID
      description
      startDate
      endDate
      date {
        start
        end
      }
      maxCapacity
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      banner {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      poster {
        id
        owner
        artists {
          name
          performanceDateTime
        }
        createdAt
        updatedAt
      }
      streaks {
        items {
          id
          owner
          name
          isRepeatable
          promotions
          eventID
          promoPrice
          promoPair {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          set {
            items {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            nextToken
          }
          createdAt
          updatedAt
          streakPromoPairId
        }
        nextToken
      }
      photos {
        items {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      collaborators {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      consumables {
        items {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        nextToken
      }
      peopleState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      transactionsTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      transactionsConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      promoters {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      eventBannerId
      eventPosterId
    }
  }
`;
export const onCreatePersonEventState = /* GraphQL */ `
  subscription OnCreatePersonEventState($owner: String) {
    onCreatePersonEventState(owner: $owner) {
      id
      owner
      personID
      eventID
      score
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      streaks {
        items {
          id
          owner
          personStateID
          currentState
          prizeState
          nrOfTimesCompleted
          streak {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          createdAt
          updatedAt
          streakStateStreakId
        }
        nextToken
      }
      previous {
        id
        owner
        score
        streaks {
          id
          currentState
          prizeState
          nrOfTimesCompleted
          streakID
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personEventStatePreviousId
    }
  }
`;
export const onUpdatePersonEventState = /* GraphQL */ `
  subscription OnUpdatePersonEventState($owner: String) {
    onUpdatePersonEventState(owner: $owner) {
      id
      owner
      personID
      eventID
      score
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      streaks {
        items {
          id
          owner
          personStateID
          currentState
          prizeState
          nrOfTimesCompleted
          streak {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          createdAt
          updatedAt
          streakStateStreakId
        }
        nextToken
      }
      previous {
        id
        owner
        score
        streaks {
          id
          currentState
          prizeState
          nrOfTimesCompleted
          streakID
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personEventStatePreviousId
    }
  }
`;
export const onDeletePersonEventState = /* GraphQL */ `
  subscription OnDeletePersonEventState($owner: String) {
    onDeletePersonEventState(owner: $owner) {
      id
      owner
      personID
      eventID
      score
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      streaks {
        items {
          id
          owner
          personStateID
          currentState
          prizeState
          nrOfTimesCompleted
          streak {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          createdAt
          updatedAt
          streakStateStreakId
        }
        nextToken
      }
      previous {
        id
        owner
        score
        streaks {
          id
          currentState
          prizeState
          nrOfTimesCompleted
          streakID
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personEventStatePreviousId
    }
  }
`;
export const onCreateStreakState = /* GraphQL */ `
  subscription OnCreateStreakState($owner: String) {
    onCreateStreakState(owner: $owner) {
      id
      owner
      personStateID
      currentState
      prizeState
      nrOfTimesCompleted
      streak {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          nextToken
        }
        createdAt
        updatedAt
        streakPromoPairId
      }
      createdAt
      updatedAt
      streakStateStreakId
    }
  }
`;
export const onUpdateStreakState = /* GraphQL */ `
  subscription OnUpdateStreakState($owner: String) {
    onUpdateStreakState(owner: $owner) {
      id
      owner
      personStateID
      currentState
      prizeState
      nrOfTimesCompleted
      streak {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          nextToken
        }
        createdAt
        updatedAt
        streakPromoPairId
      }
      createdAt
      updatedAt
      streakStateStreakId
    }
  }
`;
export const onDeleteStreakState = /* GraphQL */ `
  subscription OnDeleteStreakState($owner: String) {
    onDeleteStreakState(owner: $owner) {
      id
      owner
      personStateID
      currentState
      prizeState
      nrOfTimesCompleted
      streak {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          nextToken
        }
        createdAt
        updatedAt
        streakPromoPairId
      }
      createdAt
      updatedAt
      streakStateStreakId
    }
  }
`;
export const onCreatePreviousEventState = /* GraphQL */ `
  subscription OnCreatePreviousEventState($owner: String) {
    onCreatePreviousEventState(owner: $owner) {
      id
      owner
      score
      streaks {
        id
        currentState
        prizeState
        nrOfTimesCompleted
        streakID
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePreviousEventState = /* GraphQL */ `
  subscription OnUpdatePreviousEventState($owner: String) {
    onUpdatePreviousEventState(owner: $owner) {
      id
      owner
      score
      streaks {
        id
        currentState
        prizeState
        nrOfTimesCompleted
        streakID
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePreviousEventState = /* GraphQL */ `
  subscription OnDeletePreviousEventState($owner: String) {
    onDeletePreviousEventState(owner: $owner) {
      id
      owner
      score
      streaks {
        id
        currentState
        prizeState
        nrOfTimesCompleted
        streakID
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTicket = /* GraphQL */ `
  subscription OnCreateTicket($owner: String) {
    onCreateTicket(owner: $owner) {
      id
      owner
      name
      initialStock
      stock
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      validDates {
        start
        end
      }
      eventName
      metadata
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTicket = /* GraphQL */ `
  subscription OnUpdateTicket($owner: String) {
    onUpdateTicket(owner: $owner) {
      id
      owner
      name
      initialStock
      stock
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      validDates {
        start
        end
      }
      eventName
      metadata
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTicket = /* GraphQL */ `
  subscription OnDeleteTicket($owner: String) {
    onDeleteTicket(owner: $owner) {
      id
      owner
      name
      initialStock
      stock
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      validDates {
        start
        end
      }
      eventName
      metadata
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePoster = /* GraphQL */ `
  subscription OnCreatePoster($owner: String) {
    onCreatePoster(owner: $owner) {
      id
      owner
      artists {
        name
        performanceDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePoster = /* GraphQL */ `
  subscription OnUpdatePoster($owner: String) {
    onUpdatePoster(owner: $owner) {
      id
      owner
      artists {
        name
        performanceDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePoster = /* GraphQL */ `
  subscription OnDeletePoster($owner: String) {
    onDeletePoster(owner: $owner) {
      id
      owner
      artists {
        name
        performanceDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTicketTransaction = /* GraphQL */ `
  subscription OnCreateTicketTransaction($owner: String) {
    onCreateTicketTransaction(owner: $owner) {
      id
      owner
      date
      fee
      price
      personID
      eventID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      ticketTransactionTicketId
      ticketTransactionRefundId
      ticketTransactionPersonTicketId
      ticketTransactionPromoterId
    }
  }
`;
export const onUpdateTicketTransaction = /* GraphQL */ `
  subscription OnUpdateTicketTransaction($owner: String) {
    onUpdateTicketTransaction(owner: $owner) {
      id
      owner
      date
      fee
      price
      personID
      eventID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      ticketTransactionTicketId
      ticketTransactionRefundId
      ticketTransactionPersonTicketId
      ticketTransactionPromoterId
    }
  }
`;
export const onDeleteTicketTransaction = /* GraphQL */ `
  subscription OnDeleteTicketTransaction($owner: String) {
    onDeleteTicketTransaction(owner: $owner) {
      id
      owner
      date
      fee
      price
      personID
      eventID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      ticketTransactionTicketId
      ticketTransactionRefundId
      ticketTransactionPersonTicketId
      ticketTransactionPromoterId
    }
  }
`;
export const onCreateWalletTransaction = /* GraphQL */ `
  subscription OnCreateWalletTransaction($owner: String) {
    onCreateWalletTransaction(owner: $owner) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWalletTransaction = /* GraphQL */ `
  subscription OnUpdateWalletTransaction($owner: String) {
    onUpdateWalletTransaction(owner: $owner) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWalletTransaction = /* GraphQL */ `
  subscription OnDeleteWalletTransaction($owner: String) {
    onDeleteWalletTransaction(owner: $owner) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConsumableTransaction = /* GraphQL */ `
  subscription OnCreateConsumableTransaction($owner: String) {
    onCreateConsumableTransaction(owner: $owner) {
      id
      owner
      date
      isRefunded
      personHandle
      price
      collaboratorID
      personID
      eventID
      cart {
        items {
          id
          owner
          personHandle
          transactionID
          amount
          isRefunded
          wasUsedInPromo
          streakID
          wasPersonConsumable
          pricePaid
          whoGaveIt
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairTransactionConsumableId
        }
        nextToken
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConsumableTransaction = /* GraphQL */ `
  subscription OnUpdateConsumableTransaction($owner: String) {
    onUpdateConsumableTransaction(owner: $owner) {
      id
      owner
      date
      isRefunded
      personHandle
      price
      collaboratorID
      personID
      eventID
      cart {
        items {
          id
          owner
          personHandle
          transactionID
          amount
          isRefunded
          wasUsedInPromo
          streakID
          wasPersonConsumable
          pricePaid
          whoGaveIt
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairTransactionConsumableId
        }
        nextToken
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConsumableTransaction = /* GraphQL */ `
  subscription OnDeleteConsumableTransaction($owner: String) {
    onDeleteConsumableTransaction(owner: $owner) {
      id
      owner
      date
      isRefunded
      personHandle
      price
      collaboratorID
      personID
      eventID
      cart {
        items {
          id
          owner
          personHandle
          transactionID
          amount
          isRefunded
          wasUsedInPromo
          streakID
          wasPersonConsumable
          pricePaid
          whoGaveIt
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairTransactionConsumableId
        }
        nextToken
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePersonTicket = /* GraphQL */ `
  subscription OnCreatePersonTicket($owner: String) {
    onCreatePersonTicket(owner: $owner) {
      id
      owner
      readDates
      privacy
      eventID
      collaboratorID
      personID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      createdAt
      updatedAt
      personTicketTicketId
      personTicketRefundId
      personTicketTransactionId
    }
  }
`;
export const onUpdatePersonTicket = /* GraphQL */ `
  subscription OnUpdatePersonTicket($owner: String) {
    onUpdatePersonTicket(owner: $owner) {
      id
      owner
      readDates
      privacy
      eventID
      collaboratorID
      personID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      createdAt
      updatedAt
      personTicketTicketId
      personTicketRefundId
      personTicketTransactionId
    }
  }
`;
export const onDeletePersonTicket = /* GraphQL */ `
  subscription OnDeletePersonTicket($owner: String) {
    onDeletePersonTicket(owner: $owner) {
      id
      owner
      readDates
      privacy
      eventID
      collaboratorID
      personID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      createdAt
      updatedAt
      personTicketTicketId
      personTicketRefundId
      personTicketTransactionId
    }
  }
`;
export const onCreateTicketRefund = /* GraphQL */ `
  subscription OnCreateTicketRefund($owner: String) {
    onCreateTicketRefund(owner: $owner) {
      id
      owner
      refundedAmount
      refundedPercentage
      ticketID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      eventID
      personID
      createdAt
      updatedAt
      ticketRefundPersonTicketId
      ticketRefundTransactionId
    }
  }
`;
export const onUpdateTicketRefund = /* GraphQL */ `
  subscription OnUpdateTicketRefund($owner: String) {
    onUpdateTicketRefund(owner: $owner) {
      id
      owner
      refundedAmount
      refundedPercentage
      ticketID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      eventID
      personID
      createdAt
      updatedAt
      ticketRefundPersonTicketId
      ticketRefundTransactionId
    }
  }
`;
export const onDeleteTicketRefund = /* GraphQL */ `
  subscription OnDeleteTicketRefund($owner: String) {
    onDeleteTicketRefund(owner: $owner) {
      id
      owner
      refundedAmount
      refundedPercentage
      ticketID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      eventID
      personID
      createdAt
      updatedAt
      ticketRefundPersonTicketId
      ticketRefundTransactionId
    }
  }
`;
export const onCreatePerson = /* GraphQL */ `
  subscription OnCreatePerson($owner: String) {
    onCreatePerson(owner: $owner) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const onUpdatePerson = /* GraphQL */ `
  subscription OnUpdatePerson($owner: String) {
    onUpdatePerson(owner: $owner) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const onDeletePerson = /* GraphQL */ `
  subscription OnDeletePerson($owner: String) {
    onDeletePerson(owner: $owner) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const onCreateAuxiliaryIdentity = /* GraphQL */ `
  subscription OnCreateAuxiliaryIdentity($owner: String) {
    onCreateAuxiliaryIdentity(owner: $owner) {
      id
      owner
      validDate
      personID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAuxiliaryIdentity = /* GraphQL */ `
  subscription OnUpdateAuxiliaryIdentity($owner: String) {
    onUpdateAuxiliaryIdentity(owner: $owner) {
      id
      owner
      validDate
      personID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAuxiliaryIdentity = /* GraphQL */ `
  subscription OnDeleteAuxiliaryIdentity($owner: String) {
    onDeleteAuxiliaryIdentity(owner: $owner) {
      id
      owner
      validDate
      personID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFriend = /* GraphQL */ `
  subscription OnCreateFriend($owner: String) {
    onCreateFriend(owner: $owner) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFriend = /* GraphQL */ `
  subscription OnUpdateFriend($owner: String) {
    onUpdateFriend(owner: $owner) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFriend = /* GraphQL */ `
  subscription OnDeleteFriend($owner: String) {
    onDeleteFriend(owner: $owner) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConsumable = /* GraphQL */ `
  subscription OnCreateConsumable($owner: String) {
    onCreateConsumable(owner: $owner) {
      id
      owner
      type
      size
      name
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      companyID
      eventID
      smallOrLargeRef {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      consumableSmallOrLargeRefId
    }
  }
`;
export const onUpdateConsumable = /* GraphQL */ `
  subscription OnUpdateConsumable($owner: String) {
    onUpdateConsumable(owner: $owner) {
      id
      owner
      type
      size
      name
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      companyID
      eventID
      smallOrLargeRef {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      consumableSmallOrLargeRefId
    }
  }
`;
export const onDeleteConsumable = /* GraphQL */ `
  subscription OnDeleteConsumable($owner: String) {
    onDeleteConsumable(owner: $owner) {
      id
      owner
      type
      size
      name
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      companyID
      eventID
      smallOrLargeRef {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      consumableSmallOrLargeRefId
    }
  }
`;
export const onCreateStreak = /* GraphQL */ `
  subscription OnCreateStreak($owner: String) {
    onCreateStreak(owner: $owner) {
      id
      owner
      name
      isRepeatable
      promotions
      eventID
      promoPrice
      promoPair {
        id
        owner
        streakID
        amount
        consumable {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        pairStreakConsumableId
      }
      set {
        items {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        nextToken
      }
      createdAt
      updatedAt
      streakPromoPairId
    }
  }
`;
export const onUpdateStreak = /* GraphQL */ `
  subscription OnUpdateStreak($owner: String) {
    onUpdateStreak(owner: $owner) {
      id
      owner
      name
      isRepeatable
      promotions
      eventID
      promoPrice
      promoPair {
        id
        owner
        streakID
        amount
        consumable {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        pairStreakConsumableId
      }
      set {
        items {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        nextToken
      }
      createdAt
      updatedAt
      streakPromoPairId
    }
  }
`;
export const onDeleteStreak = /* GraphQL */ `
  subscription OnDeleteStreak($owner: String) {
    onDeleteStreak(owner: $owner) {
      id
      owner
      name
      isRepeatable
      promotions
      eventID
      promoPrice
      promoPair {
        id
        owner
        streakID
        amount
        consumable {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        pairStreakConsumableId
      }
      set {
        items {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        nextToken
      }
      createdAt
      updatedAt
      streakPromoPairId
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage($owner: String) {
    onCreateImage(owner: $owner) {
      id
      owner
      eventID
      filename
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage($owner: String) {
    onUpdateImage(owner: $owner) {
      id
      owner
      eventID
      filename
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage($owner: String) {
    onDeleteImage(owner: $owner) {
      id
      owner
      eventID
      filename
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePairPerson = /* GraphQL */ `
  subscription OnCreatePairPerson($owner: String) {
    onCreatePairPerson(owner: $owner) {
      id
      owner
      personID
      whoGaveIt
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      eventID
      pricePaid
      createdAt
      updatedAt
      pairPersonConsumableId
    }
  }
`;
export const onUpdatePairPerson = /* GraphQL */ `
  subscription OnUpdatePairPerson($owner: String) {
    onUpdatePairPerson(owner: $owner) {
      id
      owner
      personID
      whoGaveIt
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      eventID
      pricePaid
      createdAt
      updatedAt
      pairPersonConsumableId
    }
  }
`;
export const onDeletePairPerson = /* GraphQL */ `
  subscription OnDeletePairPerson($owner: String) {
    onDeletePairPerson(owner: $owner) {
      id
      owner
      personID
      whoGaveIt
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      eventID
      pricePaid
      createdAt
      updatedAt
      pairPersonConsumableId
    }
  }
`;
export const onCreatePairStreak = /* GraphQL */ `
  subscription OnCreatePairStreak($owner: String) {
    onCreatePairStreak(owner: $owner) {
      id
      owner
      streakID
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairStreakConsumableId
    }
  }
`;
export const onUpdatePairStreak = /* GraphQL */ `
  subscription OnUpdatePairStreak($owner: String) {
    onUpdatePairStreak(owner: $owner) {
      id
      owner
      streakID
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairStreakConsumableId
    }
  }
`;
export const onDeletePairStreak = /* GraphQL */ `
  subscription OnDeletePairStreak($owner: String) {
    onDeletePairStreak(owner: $owner) {
      id
      owner
      streakID
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairStreakConsumableId
    }
  }
`;
export const onCreatePairTransaction = /* GraphQL */ `
  subscription OnCreatePairTransaction($owner: String) {
    onCreatePairTransaction(owner: $owner) {
      id
      owner
      personHandle
      transactionID
      amount
      isRefunded
      wasUsedInPromo
      streakID
      wasPersonConsumable
      pricePaid
      whoGaveIt
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairTransactionConsumableId
    }
  }
`;
export const onUpdatePairTransaction = /* GraphQL */ `
  subscription OnUpdatePairTransaction($owner: String) {
    onUpdatePairTransaction(owner: $owner) {
      id
      owner
      personHandle
      transactionID
      amount
      isRefunded
      wasUsedInPromo
      streakID
      wasPersonConsumable
      pricePaid
      whoGaveIt
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairTransactionConsumableId
    }
  }
`;
export const onDeletePairTransaction = /* GraphQL */ `
  subscription OnDeletePairTransaction($owner: String) {
    onDeletePairTransaction(owner: $owner) {
      id
      owner
      personHandle
      transactionID
      amount
      isRefunded
      wasUsedInPromo
      streakID
      wasPersonConsumable
      pricePaid
      whoGaveIt
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairTransactionConsumableId
    }
  }
`;
export const onCreateCollaboratorEventConnection = /* GraphQL */ `
  subscription OnCreateCollaboratorEventConnection($owner: String) {
    onCreateCollaboratorEventConnection(owner: $owner) {
      id
      collaboratorID
      eventID
      collaborator {
        id
        owner
        name
        email
        handle
        phone
        activity
        companyID
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        readTickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        history {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        chargeHistory {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        collaboratorAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCollaboratorEventConnection = /* GraphQL */ `
  subscription OnUpdateCollaboratorEventConnection($owner: String) {
    onUpdateCollaboratorEventConnection(owner: $owner) {
      id
      collaboratorID
      eventID
      collaborator {
        id
        owner
        name
        email
        handle
        phone
        activity
        companyID
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        readTickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        history {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        chargeHistory {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        collaboratorAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCollaboratorEventConnection = /* GraphQL */ `
  subscription OnDeleteCollaboratorEventConnection($owner: String) {
    onDeleteCollaboratorEventConnection(owner: $owner) {
      id
      collaboratorID
      eventID
      collaborator {
        id
        owner
        name
        email
        handle
        phone
        activity
        companyID
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        readTickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        history {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        chargeHistory {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        collaboratorAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePromoterEventConnection = /* GraphQL */ `
  subscription OnCreatePromoterEventConnection($owner: String) {
    onCreatePromoterEventConnection(owner: $owner) {
      id
      promoterID
      eventID
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePromoterEventConnection = /* GraphQL */ `
  subscription OnUpdatePromoterEventConnection($owner: String) {
    onUpdatePromoterEventConnection(owner: $owner) {
      id
      promoterID
      eventID
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePromoterEventConnection = /* GraphQL */ `
  subscription OnDeletePromoterEventConnection($owner: String) {
    onDeletePromoterEventConnection(owner: $owner) {
      id
      promoterID
      eventID
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
