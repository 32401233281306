import { Component } from 'react'
import { GlobalProps } from '../../../types/global'
import { CreateEventState } from '../../../types/create-edit-event'
import { eventDate, eventArtist, nextPage, SubPages } from '../util/types'

/* Sub pages that are going to be shown */
import AddGeneralInfo from '../sub-pages/AddGeneralInfo'
import AddCollaborators from '../sub-pages/AddCollaborators'
import AddConsumables from '../sub-pages/AddConsumables'
import AddTickets from '../sub-pages/AddTickets'
import AddStreaks from '../sub-pages/AddStreaks'
import CreatedSuccessfully from './CreateStage'

/**
 * Component used in creating a new event.
 */
class CreateEvent extends Component<GlobalProps, CreateEventState> {
  /**
   * Holds the start and end date of the event.
   */
  private eventSpan: [number, number] = [0, 0]

  /**
   * CreateEvent class constructor.
   *
   * @param props all the needed props
   */
  constructor(props: GlobalProps) {
    super(props)
    this.state = {
      general: {
        __typename: 'Event',
        name: '',
        address: '',
        editAddress: true,
        addressAlias: '',
        description: '',
        editDates: true,
        daysOfEvent: [{
          date: [eventDate, eventDate],
          artists: [eventArtist],
        }],
        images: [undefined, undefined, undefined, undefined],
      },
      tickets: {
        each: [],
        addTickets: true,
        showAddTicketPopup: false,
      },
      consumables: {
        each: [],
        showAddConsumablePopup: false,
        category: -1,
        editCategory: true,
      },
      streaks: {
        each: [],
        showAddStreakPopup: false,
      },
      collaborators: {
        each: [],
      },
      page: SubPages.GENERAL,
      loading: false,
    }
  }

  /**
   * Sets eventSpan value. First value is the start and second is the end.
   */
  private setEventSpan = (): void => {
    const parsedDatesStart: number[] = []
    const parsedDatesEnd: number[] = []
    for (let i = 0; i < this.state.general!.daysOfEvent.length; i += 1) {
      parsedDatesStart.push(Date.parse(`${this.state.general!.daysOfEvent[i].date[0].date}T${this.state.general!.daysOfEvent[i].date[0].time}:00`))
      parsedDatesEnd.push(Date.parse(`${this.state.general!.daysOfEvent[i].date[1].date}T${this.state.general!.daysOfEvent[i].date[1].time}:00`))
    }
    this.eventSpan[0] = Math.min(...parsedDatesStart)
    this.eventSpan[1] = Math.max(...parsedDatesEnd)
  }

  /**
   * Submits info and changes current page.
   *
   * @param info new state
   * @param next_page next page to render
   */
  public submitInfo = (info: any, nextPageParam: number): void => {
    let pageIncrement: number
    if (nextPageParam === nextPage.NEXT) pageIncrement = 1
    else this.state.page === 0 ? pageIncrement = 0 : pageIncrement = -1

    /* Updates info related to a specific page */
    switch (this.state.page) {
    case SubPages.GENERAL:
      this.setState({ general: info, page: this.state.page + pageIncrement })
      this.setEventSpan()
      break
    case SubPages.TICKETS:
      this.setState({ tickets: info, page: this.state.page + pageIncrement })
      break
    case SubPages.CONSUMABLES:
      this.setState({ consumables: info, page: this.state.page + pageIncrement })
      break
    case SubPages.STREAKS:
      this.setState({ streaks: info, page: this.state.page + pageIncrement })
      break
    case SubPages.COLLABORATORS:
      this.setState({ collaborators: info, page: this.state.page + pageIncrement })
      break
    default:
      break
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    /* Decides which page to render */
    switch (this.state.page) {
    /* Displays general info page */
    case SubPages.GENERAL:
      return (
        <AddGeneralInfo
          initValues={this.state.general!}
          submitInfo={this.submitInfo}
          originalConsumableHasLargeVersion={() => false}
          {...this.props}
        />
      )

      /* Displays tickets info page */
    case SubPages.TICKETS:
      return (
        <AddTickets
          initValues={this.state.tickets!}
          submitInfo={this.submitInfo}
          eventSpan={this.eventSpan}
          eventDates={Array.from(this.state.general!.daysOfEvent, (day) => { return day.date })}
          numberOfDays={this.state.general!.daysOfEvent.length}
          originalConsumableHasLargeVersion={() => false}
          mode = "CREATE"
          {...this.props}
        />
      )

      /* Displays consumables info page */
    case SubPages.CONSUMABLES:
      return (
        <AddConsumables
          initValues={this.state.consumables!}
          submitInfo={this.submitInfo}
          eventSpan={this.eventSpan}
          originalConsumableHasLargeVersion={() => false}
          mode = "CREATE"
          {...this.props}
        />
      )

      /* Displays streaks info page */
    case SubPages.STREAKS:
      return (
        <AddStreaks
          initValues={this.state.streaks!}
          submitInfo={this.submitInfo}
          eventSpan={this.eventSpan}
          consumablesList={this.state.consumables!.each}
          originalConsumableHasLargeVersion={() => false}
          {...this.props}
        />
      )

      /* Displays collaborators info page */
    case SubPages.COLLABORATORS:
      return (
        <AddCollaborators
          initValues={this.state.collaborators!}
          originalValues={undefined}
          submitInfo={this.submitInfo}
          originalConsumableHasLargeVersion={() => false}
          {...this.props}
        />
      )

      /* Displays success page */
    case SubPages.SUCCESS:
      return (
        <CreatedSuccessfully
          {...this.props}
          values={this.state}
        />
      )

    default:
      return (null)
    }
  }
}

export default CreateEvent
