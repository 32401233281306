import React, { Component } from 'react'
import { GlobalProps } from '../../../types/global'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { Formik, FormikHelpers, FieldArray, Field, useFormikContext } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import { TextInput, TextAreaInput, DateInput, TimeInput } from '../../../components/utils/FormFields'  // Form fields
import ProgressBar from '../../../components/ProgressBar' // Import of the bar that shows the progress in creating event
import { AddGeneralInfoFormValues, DateTime, DaysOfEvent, SubPagesProps } from '../../../types/create-edit-event'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Needed to be able to use the custom FontAwesome font */
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import { eventDate, eventArtist, nextPage } from '../util/types'

import '../../../styles/views/create-event/add-basic.css'

type ImageFieldProps = React.ComponentProps<typeof Field> & {
  index: number
  image: File | undefined
  mode?: 'CREATE' | 'EDIT'
}

/*
 * Custom field for Formik image uploading
 */
const ImageField: React.FC<ImageFieldProps> = ({ index, image, mode, ...props }) => {
  const { values } = useFormikContext()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  image = values.images[index]
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null)

  const handleClick = () => {
    if (hiddenFileInput.current === null || mode === 'EDIT') {
      return
    }
    hiddenFileInput.current.click()
  }

  const addImageClassname = (modeParam?: 'CREATE' | 'EDIT') => {
    if (modeParam === 'EDIT') {
      return 'add-basic-image-button-disabled'
    }
    return 'add-basic-image-button'
  }

  if (image) {
    props.value = image
  }

  return (
    <Field {...props} type={'file'}>
      {({ field: { value, ...field }, form }: ImageFieldProps) => {
        return (
          // If there is a file, show its preview
          value !== undefined
            ? <div className="add-basic-preview-container">
              {/* Render the label if its the first image container */}
              {index === 0
                ? <div className="main-container">
                  <h1>{i18next.t('AddEvent.addBasic.main')}</h1>
                </div>
                : null}

              {value instanceof File
                ? <img className="add-basic-image-preview" src={URL.createObjectURL(value)} alt="promo1"/>
                : <img className="add-basic-image-preview" src={value as string} alt="promo1"/>
              }

              { mode !== 'EDIT'
              && <div className="trash-container" onClick={() => {
                form.setFieldValue(field.name, undefined)
              }}>
                <FontAwesomeIcon className="trash-icon" icon={faTrashAlt} />
              </div>
              }
            </div>
            : <div className={addImageClassname(mode)} onClick={handleClick}>
              {index === 0
                ? <div className="main-container">
                  <h1>{i18next.t('AddEvent.addBasic.main')}</h1>
                </div>
                : null}
              <input
                {...props}
                {...field}
                ref={hiddenFileInput}
                type={'file'}
                style={{
                  display: 'none',
                }}
                onChange={(event) => {
                  if (event.target.files === null) return
                  form.setFieldValue(field.name, event.target.files[0])
                }}
                accept="image/*"
              />
              <FontAwesomeIcon className="plus-icon" icon={faPlus}/>
            </div>
        )
      }}
    </Field>
  )
}

/**
 * In this page the user will add the initial information like name, date, location, description about the event.
 */
class AddGeneralInfo extends Component<GlobalProps & SubPagesProps> {
  private initialValues = this.props.initValues as AddGeneralInfoFormValues

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    name: Yup.string().required(i18next.t('AddEventErrors.addBasic.name.required'))
      .max(30, i18next.t('AddEventErrors.addBasic.name.size')),

    editAddress: Yup.boolean(),

    address: Yup.string().when('editAddress', {
      is: true,
      then: Yup.string().required(i18next.t('AddEventErrors.addBasic.address.required'))
        .url(i18next.t('AddEventErrors.addBasic.address.url'))
        .test({
          name: 'urlIsGoogleMaps',
          params: {},
          message: i18next.t('AddEventErrors.addBasic.address.maps'),
          test(value) {
            /* If we find this elements, we can say that it is a google maps url because we are editing the event */
            if (!value) return false

            if (value.split('https://www.google.com/maps/search/?api=1&query=').length === 2) return true

            const splitUrl = value.split('!3d')
            const latLong = splitUrl[splitUrl.length - 1].split('!4d')
            let longitude

            if (latLong.indexOf('?') !== -1) longitude = latLong[1].split('\\?')[0]
            else longitude = latLong[1]
            const latitude = latLong[0]

            return !isNaN(parseFloat(latitude)) && latitude.toString().indexOf('.') !== -1
                  && !isNaN(parseFloat(longitude)) && longitude.toString().indexOf('.') !== -1
          },
        }),
    }),

    addressAlias: Yup.string().required(i18next.t('AddEventErrors.addBasic.addressAlias.required'))
      .max(256, i18next.t('AddEventErrors.addBasic.addressAlias.size')),

    description: Yup.string().required(i18next.t('AddEventErrors.addBasic.description.required'))
      .max(2000, i18next.t('AddEventErrors.addBasic.description.size')),

    editDates: Yup.boolean(),

    /* FIXME: @Rodrigo uncomment this when the GeneralInfo Date Inputs are fixed for the browser version*/

    daysOfEvent: Yup.array().required()
      .of(Yup.object().shape({

        date: Yup.array().length(2)
          .of(Yup.object().shape({

            time: Yup.string().required(i18next.t('AddEventErrors.addBasic.time.required'))
              .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, i18next.t('AddEventErrors.addBasic.time.match')),

            date: Yup.date().required(i18next.t('AddEventErrors.addBasic.date.required')),

          }))
          .test({
            name: 'minValidDate',
            params: { },
            message: i18next.t('AddEventErrors.addBasic.date.min'),
            test: function(value) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              if (!this.from[1].value.editDates) {
                return true
              }
              if (!value || !value[0].time || !value[0].date || !value[1].time || !value[1].date) {
                return false
              }
              const helperDate = new Date()
              const startDate = value[0].date
              startDate.setHours(parseFloat(value[0].time?.substring(0, 2)), parseFloat(value[0].time?.substring(3)))
              const endDate = value[1].date
              endDate.setHours(parseFloat(value[1].time?.substring(0, 2)), parseFloat(value[1].time?.substring(3)))
              if (startDate.toISOString() < helperDate.toISOString()
              || endDate.toISOString() < helperDate.toISOString()) {
                alert(i18next.t('AddEventErrors.addBasic.date.min'))
                return false
              }
              return true
            },
          })
          .test({
            name: 'maxValidDate',
            params: { },
            message: i18next.t('AddEventErrors.addBasic.date.max'),
            test: function(value) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              /* @ts-ignore */
              if (!this.from[1].value.editDates) {
                return true
              }
              if (!value || !value[0].time || !value[0].date || !value[1].time || !value[1].date) {
                return false
              }
              const helperDate = new Date()
              helperDate.setFullYear(helperDate.getFullYear() + 2)
              const startDate = value[0].date
              startDate.setHours(parseFloat(value[0].time?.substring(0, 2)), parseFloat(value[0].time?.substring(3)))
              const endDate = value[1].date
              endDate.setHours(parseFloat(value[1].time?.substring(0, 2)), parseFloat(value[1].time?.substring(3)))
              if (startDate.toISOString() > helperDate.toISOString()
              || endDate.toISOString() > helperDate.toISOString()) {
                alert(i18next.t('AddEventErrors.addBasic.date.max'))
                return false
              }
              return true
            },
          }),

        artists: Yup.array().required()
          .of(Yup.object().shape({ name: Yup.string()
            .max(30, i18next.t('AddEventErrors.addBasic.artist.name.max'))
            .test({
              name: 'artistHasAllInfo',
              params: { },
              message: i18next.t('AddEventErrors.addBasic.artist.name.test'),
              test: function(value) {
                if (value && this.parent.startDateTime.date && this.parent.startDateTime.time) {
                  return true
                }
                const returnValue = value === undefined && this.parent.startDateTime.date === undefined
                  && this.parent.startDateTime.time === undefined
                return returnValue
              },
            }),

          startDateTime: Yup.object().shape({

            time: Yup.string()
              .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, i18next.t('AddEventErrors.addBasic.time.match')),

            date: Yup.date(),

          }) }))
          .test({
            name: 'artistPerformanceIsInDateSpan',
            params: { },
            message: i18next.t('AddEventErrors.addBasic.daysOfEvent.test'),
            test: function(_) {
              function artistExists(artist: any): boolean {
                if (artist.name && artist.startDateTime.date && artist.startDateTime.time) {
                  return true
                } else if (artist.name === undefined && artist.startDateTime.date === undefined
                  && artist.startDateTime.time === undefined) { return false }
                return false
              }

              /* Uses a cast to get type check */
              const info = this.parent as DaysOfEvent

              if (!(info.date[0].date && info.date[1].date && info.date[0].time && info.date[1].time)) {
                return false
              }

              /* Gets info related to the day of the event */
              const startTimeArray = info.date[0].time.split(':')
              const eventStart = new Date(Date.parse(info.date[0].date))
                .setHours(parseInt(startTimeArray[0], 10), parseInt(startTimeArray[1], 10))
              const endTimeArray = info.date[1].time.split(':')
              const eventEnd = new Date(Date.parse(info.date[1].date))
                .setHours(parseInt(endTimeArray[0], 10), parseInt(endTimeArray[1], 10))

              /* Checks if all the artists are performing inside the span of the date-time of the event */
              for (let i = 0; i < info.artists.length; i += 1) {
                if (!artistExists(info.artists[i])) continue
                const timePerformanceArray = info.artists[i].startDateTime.time.split(':')
                const datetimePerformance = new Date(Date.parse(info.artists[i].startDateTime.date))
                  .setHours(parseInt(timePerformanceArray[0], 10), parseInt(timePerformanceArray[1], 10))
                if (datetimePerformance < eventStart || datetimePerformance > eventEnd) {
                  alert(i18next.t('AddEventErrors.addBasic.daysOfEvent.test'))
                  return false
                }
              }
              return true
            },
          }),

      })),

  })

  /**
   * Verifies if dates are correct (end is greater than start and if the difference between them do not go beyond one day.
   *
   * @param dates array with all the input dates
   *
   * @return boolean true if everything is alright and false if not
   */
  private dateSpanIsValid = (dates: [DateTime, DateTime][]): boolean => {
    /* Validates every date */
    for (let i = 0; i < dates.length; i += 1) {
      /* Gets start and end date to perform some calculus */
      const startDate = Date.parse(`${dates[i][0].date}T${dates[i][0].time}:00`)
      const endDate = Date.parse(`${dates[i][1].date}T${dates[i][1].time}:00`)

      /* Start date should be before end date */
      if (startDate > endDate) {
        alert(i18next.t('AddEventErrors.addBasic.startBeforeEnd'))
        return false
      }

      /* Should not be more than one day (86400000 is the number of milliseconds in one day) */
      if (((endDate - startDate) / 86400000) >= 1) {
        alert(i18next.t('AddEventErrors.addBasic.eventDateSpan'))
        return false
      }
    }
    return true
  }

  /**
   * Verifies if the date of the field precedes the current date, to check if a field is editable or not
   *
   * @param date of the specified field
   */
  private dateNotEditable = (date: DateTime): boolean => {
    if (date.date === '' || date.time === '') return false

    const dateReceived = Date.parse(`${date.date}T${date.time}:00`)
    const dateToday = (new Date()).valueOf()

    return dateReceived <= dateToday
  }

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   */
  private handleSubmit = (values: AddGeneralInfoFormValues, { setSubmitting }:
    FormikHelpers<AddGeneralInfoFormValues>): void => {
    /* Checks if user added a banner. If not, interrupts */
    if (values.images[0] === undefined) {
      alert(i18next.t('AddEventErrors.addBasic.image.required'))
      return
    }

    /* Checks if each date only represents one day */
    if (!this.dateSpanIsValid(Array.from(values.daysOfEvent, (day) => { return day.date }))) return

    /* Creates info input object */
    const input: object = {
      ...values,
      __typename: 'Event',
    }

    /* Enables submit button again */
    setSubmitting(false)

    /* Sends info to parent component and tells it to render next phase */
    this.props.submitInfo(input, nextPage.NEXT)
  }

  private dayGenerator = (index: number): string => {
    switch (index) {
    case (0):
      return `${i18next.t('AddEvent.days.first')} ${i18next.t('AddEvent.days.day')}:`
    case (1):
      return `${i18next.t('AddEvent.days.second')} ${i18next.t('AddEvent.days.day')}:`
    case (2):
      return `${i18next.t('AddEvent.days.third')} ${i18next.t('AddEvent.days.day')}:`
    case (3):
      return `${i18next.t('AddEvent.days.fourth')} ${i18next.t('AddEvent.days.day')}:`
    case (4):
      return `${i18next.t('AddEvent.days.fifth')} ${i18next.t('AddEvent.days.day')}:`
    case (5):
      return `${i18next.t('AddEvent.days.sixth')} ${i18next.t('AddEvent.days.day')}:`
    case (6):
      return `${i18next.t('AddEvent.days.seventh')} ${i18next.t('AddEvent.days.day')}:`
    default:
      return `${index + 1}º ${i18next.t('AddEvent.days.day')}:`
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="add-basic-total">
        <div className="add-basic-title">
          <h2>{i18next.t('AddEvent.addBasic.title')}</h2>
        </div>
        <div className="add-basic-main">
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={this.handleSubmit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} autoComplete="off">
                <div className="add-basic-top">
                  <div className="add-basic-image-container">
                    {[0, 1, 2, 3].map((_, index) => <div key={index} className="add-basic-image-box">
                      <ImageField index={index} name={`images.${index}`} image={this.initialValues.images[index]} mode={this.props.mode}/>
                    </div>)}
                  </div>
                  <div className="main-form-wrapper">
                    <TextInput
                      value={props.values.name}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addBasic.name')}
                      name="name" />
                    <TextInput
                      value={props.values.address}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addBasic.address')}
                      name="address"
                      disabled={!this.initialValues.editAddress} />
                    <TextInput
                      value={props.values.addressAlias}
                      onChange={props.handleChange}
                      label={i18next.t('AddEvent.addBasic.addressAlias')}
                      name="addressAlias" />
                  </div>
                </div>
                <FieldArray name="daysOfEvent" render={
                  (arrayHelpers) => (
                    <div>
                      <div className="date-title-div">
                        <h2 className="date-title">{i18next.t('AddEvent.addBasic.info.date')}:</h2>
                        <div className="date-title-buttons">
                          {this.initialValues.editDates
                              && <button className="add-extra" type="button" onClick={() => arrayHelpers.push({
                                date: [eventDate, eventDate],
                                artists: [eventArtist],
                              })}>
                                +
                              </button> }
                          {this.initialValues.editDates && props.values.daysOfEvent.length > 1
                              && <button className="add-extra" type="button" onClick={() => arrayHelpers.pop()}>
                                  -
                              </button> }
                        </div>
                      </div>
                      {props.values.daysOfEvent.map((day: DaysOfEvent, index: number) => (
                        <div className="date-container" key={index}>
                          <h2 className="date-semi-title">{this.dayGenerator(index)}</h2>
                          <div className="add-basic-form-date-wrapper">
                            <div className="add-basic-form-date">
                              <h3 className="date-type">{i18next.t('AddEvent.addBasic.info.start')}:</h3>
                              <div id="time-wrapper">
                                <TimeInput
                                  value={day.date[0].time}
                                  onChange={props.handleChange}
                                  name={`daysOfEvent.${index}.date.${0}.time`}
                                  disabled={!this.initialValues.editDates}
                                />
                              </div>
                              <div id="date-wrapper">
                                <DateInput
                                  value={day.date[0].date}
                                  onChange={props.handleChange}
                                  name={`daysOfEvent.${index}.date.${0}.date`}
                                  disabled={!this.initialValues.editDates}
                                />
                              </div>
                            </div>
                            <div className="add-basic-form-date">
                              <h3 className="date-type">{i18next.t('AddEvent.addBasic.info.end')}:</h3>
                              <div id="time-wrapper">
                                <TimeInput
                                  value={day.date[1].time}
                                  onChange={props.handleChange}
                                  name={`daysOfEvent.${index}.date.${1}.time`}
                                  disabled={!this.initialValues.editDates}
                                />
                              </div>
                              <div id="date-wrapper">
                                <DateInput
                                  value={day.date[1].date}
                                  onChange={props.handleChange}
                                  name={`daysOfEvent.${index}.date.${1}.date`}
                                  disabled={!this.initialValues.editDates}
                                />
                              </div>
                            </div>
                          </div>

                          <FieldArray name={`daysOfEvent.${index}.artists`} render={
                            (arrayFunctions) => (
                              <div>
                                <div className="poster-title-wrapper">
                                  <h2 className="poster-title">{`${i18next.t('AddEvent.addBasic.poster')} ${this.dayGenerator(index)}`}</h2>
                                  <div className="date-title-buttons">
                                    <button className="add-extra" id="small" type="button" onClick={() => arrayFunctions.push(eventArtist)}>
                                            +
                                    </button>
                                    {day.artists.length > 1
                                          && <button className="add-extra" id="small" type="button" onClick={() => arrayFunctions.pop()}>
                                              -
                                          </button> }
                                  </div>
                                </div>
                                {day.artists.map((artist, idx) => (
                                  <div key={idx}>
                                    <div className="add-basic-form-poster-wrapper">
                                      <div className="artist-wrapper">
                                        <TextInput
                                          value={artist.name}
                                          onChange={props.handleChange}
                                          label={i18next.t('AddEvent.addBasic.artist') }
                                          name={`daysOfEvent.${index}.artists.${idx}.name`}
                                        />
                                      </div>
                                      <div className="add-basic-form-date">
                                        <h3 className="date-type">{i18next.t('AddEvent.addBasic.info.start')}:</h3>
                                        <div id="time-wrapper">
                                          <TimeInput
                                            value={artist.startDateTime.time}
                                            onChange={props.handleChange}
                                            name={`daysOfEvent.${index}.artists.${idx}.startDateTime.time`}
                                          />
                                        </div>
                                        <div id="date-wrapper">
                                          <DateInput
                                            value={artist.startDateTime.date}
                                            onChange={props.handleChange}
                                            name={`daysOfEvent.${index}.artists.${idx}.startDateTime.date`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  )
                }/>
                <div id="form-description">
                  <TextAreaInput
                    value={props.values.description}
                    onChange={props.handleChange}
                    label={i18next.t('AddEvent.addBasic.description')}
                    name="description" />
                </div>
                <div className="add-basic-bottom">
                  <ProgressBar filled={1} total={5}/>
                  <button id="single-next" className="navigation-button" type="submit" formNoValidate>
                    {i18next.t('AddEvent.navigationButtons.next')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default withTranslation()(AddGeneralInfo)
