import { Artist, Streak, Consumable, ConsumableType, Image, Ticket, PairStreak } from '../../../API'
import i18next from 'i18next'
import { consumablesKeys, eventArtist, eventDate } from './types'
import { DateSpanInput, DateSpan } from '../../../API'
import {
  AddEditConsumableFormValues, AddEditStreakFormValues,
  AddEditTicketFormValues,
  DateTime,
  DaysOfEvent, EventConsumablesState, EventStreaksState,
} from '../../../types/create-edit-event'
import { fetchSetOfImages } from '../../../models/images'

/**
 * Extracts consumable ID from the newly created consumables set.
 *
 * @param consumableName consumable's name whose ID we want
 * @param newConsumables list of newly created consumables
 *
 * @return consumableID
 */
export const findConsumableID = (
  consumableName: string,
  newConsumables: {regular: Consumable,
  large: Consumable | null}[],
): string => {
  // FIXME: God we need to refactor this
  for (let i = 0; i < newConsumables.length; i += 1) {
    if (newConsumables[i].regular.name === consumableName) {
      return newConsumables[i].regular.id!
    } else if (newConsumables[i]?.large?.name && newConsumables[i]!.large!.name === consumableName) {
      return newConsumables[i]!.large!.id
    }
  }

  return ''
}

/**
 * Parses consumable type to one of the database.
 *
 * @param consumableType consumable type to be parsed
 *
 * @return ConsumableType
 */
export const getConsumableType = (consumableType: string): ConsumableType => {
  for (let i = 0; i < 11; i += 1) {
    if (consumableType === i18next.t(`Consumables.subCategories.${consumablesKeys[i]}`)) {
      return consumablesKeys[i].toUpperCase() as ConsumableType
    }
  }
  return '' as ConsumableType // It will never get here, is just being used to suppress the error
}

/**
 * Parses DateTime format into Date ISO Format.
 *
 * @param datetime
 */
export const parseDateTimeToISO = (datetime: DateTime): string => {
  if (datetime.date !== '' && datetime.time !== '') {
    return new Date(Date.parse(`${datetime.date}T${datetime.time}:00`)).toISOString()
  }
  return ''
}

/**
 * Generates ISO Date that represents a date very very far away
 *
 * @param datetime
 */
export const endOfTimesInISO = (): string => {
  return new Date(2099, 11, 31, 23, 59, 59).toISOString()
}

/**
 * Parses a google maps url to a valid Location object.
 *
 * @param url google maps url
 * @return Location object
 */
export const parseGoogleMapsUrlToLocation = (url: string): {lon: number, lat: number} => {
  const splits = url.split('https://www.google.com/maps/search/?api=1&query=')
  if (splits.length === 2) {
    const splits2 = splits[1].split(',')
    return {
      lat: parseFloat(splits2[0]),
      lon: parseFloat(splits2[1]),
    }
  }

  /* Gets last floating numbers from url because they are more accurate instead of after @ */
  const splitUrl = url.split('!3d')
  const latLong = splitUrl[splitUrl.length - 1].split('!4d')
  let longitude

  /* Fetches longitude and latitude from var */
  if (latLong.indexOf('?') !== -1) longitude = latLong[1].split('\\?')[0]
  else longitude = latLong[1]
  const latitude = latLong[0]

  /* Returns object to be passed to db */
  return {
    lon: parseFloat(longitude),
    lat: parseFloat(latitude),
  }
}

/**
 * Uses validDates to create a 2d array that represents days and date spans.
 *
 * @param eventDates days of the event
 * @param validDates boolean array to be used to map to the valid dates
 *
 * @return 2Dimensional-array containing our days and date spans
 */
export const extractTicketDates = (eventDates: [DateTime, DateTime][], validDates: boolean[]):
Array< DateSpanInput > => {
  const ticketDates: Array< DateSpanInput > = []
  for (let i = 0; i < validDates.length; i += 1) {
    if (validDates[i]) {
      const start = parseDateTimeToISO(eventDates[i][0])
      const end = parseDateTimeToISO(eventDates[i][1])
      ticketDates.push({
        start: start,
        end: end,
      })
    }
  }
  return ticketDates
}

function getLocaleDateString() {
  const formats = {
    'af-ZA': 'yyyy/MM/dd',
    'am-ET': 'd/M/yyyy',
    'ar-AE': 'dd/MM/yyyy',
    'ar-BH': 'dd/MM/yyyy',
    'ar-DZ': 'dd-MM-yyyy',
    'ar-EG': 'dd/MM/yyyy',
    'ar-IQ': 'dd/MM/yyyy',
    'ar-JO': 'dd/MM/yyyy',
    'ar-KW': 'dd/MM/yyyy',
    'ar-LB': 'dd/MM/yyyy',
    'ar-LY': 'dd/MM/yyyy',
    'ar-MA': 'dd-MM-yyyy',
    'ar-OM': 'dd/MM/yyyy',
    'ar-QA': 'dd/MM/yyyy',
    'ar-SA': 'dd/MM/yy',
    'ar-SY': 'dd/MM/yyyy',
    'ar-TN': 'dd-MM-yyyy',
    'ar-YE': 'dd/MM/yyyy',
    'arn-CL': 'dd-MM-yyyy',
    'as-IN': 'dd-MM-yyyy',
    'az-Cyrl-AZ': 'dd.MM.yyyy',
    'az-Latn-AZ': 'dd.MM.yyyy',
    'ba-RU': 'dd.MM.yy',
    'be-BY': 'dd.MM.yyyy',
    'bg-BG': 'dd.M.yyyy',
    'bn-BD': 'dd-MM-yy',
    'bn-IN': 'dd-MM-yy',
    'bo-CN': 'yyyy/M/d',
    'br-FR': 'dd/MM/yyyy',
    'bs-Cyrl-BA': 'd.M.yyyy',
    'bs-Latn-BA': 'd.M.yyyy',
    'ca-ES': 'dd/MM/yyyy',
    'co-FR': 'dd/MM/yyyy',
    'cs-CZ': 'd.M.yyyy',
    'cy-GB': 'dd/MM/yyyy',
    'da-DK': 'dd-MM-yyyy',
    'de-AT': 'dd.MM.yyyy',
    'de-CH': 'dd.MM.yyyy',
    'de-DE': 'dd.MM.yyyy',
    'de-LI': 'dd.MM.yyyy',
    'de-LU': 'dd.MM.yyyy',
    'dsb-DE': 'd. M. yyyy',
    'dv-MV': 'dd/MM/yy',
    'el-GR': 'd/M/yyyy',
    'en-029': 'MM/dd/yyyy',
    'en-AU': 'd/MM/yyyy',
    'en-BZ': 'dd/MM/yyyy',
    'en-CA': 'dd/MM/yyyy',
    'en-GB': 'dd/MM/yyyy',
    'en-IE': 'dd/MM/yyyy',
    'en-IN': 'dd-MM-yyyy',
    'en-JM': 'dd/MM/yyyy',
    'en-MY': 'd/M/yyyy',
    'en-NZ': 'd/MM/yyyy',
    'en-PH': 'M/d/yyyy',
    'en-SG': 'd/M/yyyy',
    'en-TT': 'dd/MM/yyyy',
    'en-US': 'M/d/yyyy',
    'en-ZA': 'yyyy/MM/dd',
    'en-ZW': 'M/d/yyyy',
    'es-AR': 'dd/MM/yyyy',
    'es-BO': 'dd/MM/yyyy',
    'es-CL': 'dd-MM-yyyy',
    'es-CO': 'dd/MM/yyyy',
    'es-CR': 'dd/MM/yyyy',
    'es-DO': 'dd/MM/yyyy',
    'es-EC': 'dd/MM/yyyy',
    'es-ES': 'dd/MM/yyyy',
    'es-GT': 'dd/MM/yyyy',
    'es-HN': 'dd/MM/yyyy',
    'es-MX': 'dd/MM/yyyy',
    'es-NI': 'dd/MM/yyyy',
    'es-PA': 'MM/dd/yyyy',
    'es-PE': 'dd/MM/yyyy',
    'es-PR': 'dd/MM/yyyy',
    'es-PY': 'dd/MM/yyyy',
    'es-SV': 'dd/MM/yyyy',
    'es-US': 'M/d/yyyy',
    'es-UY': 'dd/MM/yyyy',
    'es-VE': 'dd/MM/yyyy',
    'et-EE': 'd.MM.yyyy',
    'eu-ES': 'yyyy/MM/dd',
    'fa-IR': 'MM/dd/yyyy',
    'fi-FI': 'd.M.yyyy',
    'fil-PH': 'M/d/yyyy',
    'fo-FO': 'dd-MM-yyyy',
    'fr-BE': 'd/MM/yyyy',
    'fr-CA': 'yyyy-MM-dd',
    'fr-CH': 'dd.MM.yyyy',
    'fr-FR': 'dd/MM/yyyy',
    'fr-LU': 'dd/MM/yyyy',
    'fr-MC': 'dd/MM/yyyy',
    'fy-NL': 'd-M-yyyy',
    'ga-IE': 'dd/MM/yyyy',
    'gd-GB': 'dd/MM/yyyy',
    'gl-ES': 'dd/MM/yy',
    'gsw-FR': 'dd/MM/yyyy',
    'gu-IN': 'dd-MM-yy',
    'ha-Latn-NG': 'd/M/yyyy',
    'he-IL': 'dd/MM/yyyy',
    'hi-IN': 'dd-MM-yyyy',
    'hr-BA': 'd.M.yyyy.',
    'hr-HR': 'd.M.yyyy',
    'hsb-DE': 'd. M. yyyy',
    'hu-HU': 'yyyy. MM. dd.',
    'hy-AM': 'dd.MM.yyyy',
    'id-ID': 'dd/MM/yyyy',
    'ig-NG': 'd/M/yyyy',
    'ii-CN': 'yyyy/M/d',
    'is-IS': 'd.M.yyyy',
    'it-CH': 'dd.MM.yyyy',
    'it-IT': 'dd/MM/yyyy',
    'iu-Cans-CA': 'd/M/yyyy',
    'iu-Latn-CA': 'd/MM/yyyy',
    'ja-JP': 'yyyy/MM/dd',
    'ka-GE': 'dd.MM.yyyy',
    'kk-KZ': 'dd.MM.yyyy',
    'kl-GL': 'dd-MM-yyyy',
    'km-KH': 'yyyy-MM-dd',
    'kn-IN': 'dd-MM-yy',
    'ko-KR': 'yyyy. MM. dd',
    'kok-IN': 'dd-MM-yyyy',
    'ky-KG': 'dd.MM.yy',
    'lb-LU': 'dd/MM/yyyy',
    'lo-LA': 'dd/MM/yyyy',
    'lt-LT': 'yyyy.MM.dd',
    'lv-LV': 'yyyy.MM.dd.',
    'mi-NZ': 'dd/MM/yyyy',
    'mk-MK': 'dd.MM.yyyy',
    'ml-IN': 'dd-MM-yy',
    'mn-MN': 'yy.MM.dd',
    'mn-Mong-CN': 'yyyy/M/d',
    'moh-CA': 'M/d/yyyy',
    'mr-IN': 'dd-MM-yyyy',
    'ms-BN': 'dd/MM/yyyy',
    'ms-MY': 'dd/MM/yyyy',
    'mt-MT': 'dd/MM/yyyy',
    'nb-NO': 'dd.MM.yyyy',
    'ne-NP': 'M/d/yyyy',
    'nl-BE': 'd/MM/yyyy',
    'nl-NL': 'd-M-yyyy',
    'nn-NO': 'dd.MM.yyyy',
    'nso-ZA': 'yyyy/MM/dd',
    'oc-FR': 'dd/MM/yyyy',
    'or-IN': 'dd-MM-yy',
    'pa-IN': 'dd-MM-yy',
    'pl-PL': 'dd.MM.yyyy',
    'prs-AF': 'dd/MM/yy',
    'ps-AF': 'dd/MM/yy',
    'pt-BR': 'd/M/yyyy',
    'pt-PT': 'dd-MM-yyyy',
    'qut-GT': 'dd/MM/yyyy',
    'quz-BO': 'dd/MM/yyyy',
    'quz-EC': 'dd/MM/yyyy',
    'quz-PE': 'dd/MM/yyyy',
    'rm-CH': 'dd/MM/yyyy',
    'ro-RO': 'dd.MM.yyyy',
    'ru-RU': 'dd.MM.yyyy',
    'rw-RW': 'M/d/yyyy',
    'sa-IN': 'dd-MM-yyyy',
    'sah-RU': 'MM.dd.yyyy',
    'se-FI': 'd.M.yyyy',
    'se-NO': 'dd.MM.yyyy',
    'se-SE': 'yyyy-MM-dd',
    'si-LK': 'yyyy-MM-dd',
    'sk-SK': 'd. M. yyyy',
    'sl-SI': 'd.M.yyyy',
    'sma-NO': 'dd.MM.yyyy',
    'sma-SE': 'yyyy-MM-dd',
    'smj-NO': 'dd.MM.yyyy',
    'smj-SE': 'yyyy-MM-dd',
    'smn-FI': 'd.M.yyyy',
    'sms-FI': 'd.M.yyyy',
    'sq-AL': 'yyyy-MM-dd',
    'sr-Cyrl-BA': 'd.M.yyyy',
    'sr-Cyrl-CS': 'd.M.yyyy',
    'sr-Cyrl-ME': 'd.M.yyyy',
    'sr-Cyrl-RS': 'd.M.yyyy',
    'sr-Latn-BA': 'd.M.yyyy',
    'sr-Latn-CS': 'd.M.yyyy',
    'sr-Latn-ME': 'd.M.yyyy',
    'sr-Latn-RS': 'd.M.yyyy',
    'sv-FI': 'd.M.yyyy',
    'sv-SE': 'yyyy-MM-dd',
    'sw-KE': 'M/d/yyyy',
    'syr-SY': 'dd/MM/yyyy',
    'ta-IN': 'dd-MM-yyyy',
    'te-IN': 'dd-MM-yy',
    'tg-Cyrl-TJ': 'dd.MM.yy',
    'th-TH': 'd/M/yyyy',
    'tk-TM': 'dd.MM.yy',
    'tn-ZA': 'yyyy/MM/dd',
    'tr-TR': 'dd.MM.yyyy',
    'tt-RU': 'dd.MM.yyyy',
    'tzm-Latn-DZ': 'dd-MM-yyyy',
    'ug-CN': 'yyyy-M-d',
    'uk-UA': 'dd.MM.yyyy',
    'ur-PK': 'dd/MM/yyyy',
    'uz-Cyrl-UZ': 'dd.MM.yyyy',
    'uz-Latn-UZ': 'dd/MM yyyy',
    'vi-VN': 'dd/MM/yyyy',
    'wo-SN': 'dd/MM/yyyy',
    'xh-ZA': 'yyyy/MM/dd',
    'yo-NG': 'd/M/yyyy',
    'zh-CN': 'yyyy/M/d',
    'zh-HK': 'd/M/yyyy',
    'zh-MO': 'd/M/yyyy',
    'zh-SG': 'd/M/yyyy',
    'zh-TW': 'yyyy/M/d',
    'zu-ZA': 'yyyy/MM/dd',
  }

  return formats[navigator.language as keyof typeof formats] || 'dd/MM/yyyy'
}

/**
 * Parses Date ISO Format into DateTime format.
 *
 * @param dateInISO
 */
export const parseISOToDateTime = (dateInISO: string): DateTime => {
  dateInISO = new Date(Date.parse(dateInISO)).toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })

  const date = dateInISO.substring(0, 10).split('/')
  let newDate = `${date[2]}-${date[1]}-${date[0]}`
  if (getLocaleDateString() === 'M/d/yyyy') {
    newDate = `${date[2]}-${date[0]}-${date[1]}`
  }

  return {
    time: dateInISO.substring(12, 17),
    date: newDate,
  }
}

/**
 * Checks if two dates are equal. A date here is represented as an array with two elements,
 * the beginning and the end of an event day, at positions 0 and 1, respectively.
 *
 * @param date1
 * @param date2
 */
export const datesEqual = (date1: [DateTime, DateTime], date2: [DateTime, DateTime]): boolean => {
  return date1[0].date === date2[0].date && date1[0].time === date2[0].time
    && date1[1].date === date2[1].date && date1[1].time === date2[1].time
}

/**
 * Builds a boolean array that represents what dates of the event are valid for the current ticket.
 *
 * @param ticketDates
 * @param eventDates
 */
export const getValidDates = (ticketDates: Array<DateSpan>, eventDates: [DateTime, DateTime][]):
Array<boolean> => {
  const parsedTicketDates: [DateTime, DateTime][] = Array.from(ticketDates, (date) => [
    { time: date.start.substring(11, 16), date: date.start.substring(0, 10) } as DateTime,
    { time: date.end.substring(11, 16), date: date.end.substring(0, 10) } as DateTime,
  ])
  const validDates: Array<boolean> = new Array<boolean>(eventDates.length).fill(false)

  for (let i = 0; i < parsedTicketDates.length; i += 1) {
    validDates[eventDates.findIndex((day) => datesEqual(day, parsedTicketDates[i]))] = true
  }

  return validDates
}

/**
 * Converts a string URI to a Blob.
 *
 * @param dataURI string to convert
 */
export const dataURItoBlob = (dataURI: string): Blob => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i += 1) ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

/**
 * Converts a Blob to a File.
 *
 * @param theBlob blob to convert
 * @param fileName filename to be attributed to the file
 * @param options
 */
export const blobToFile = (theBlob: Blob, fileName:string, options:FilePropertyBag): File => {
  return new File([theBlob], fileName, options)
}

/**
 * Builds this.state.tickets.each field in EditEvent.
 *
 * @param tickets tickets from database
 * @param daysOfEvent general state's daysOfEvent
 */
export const buildTickets = (tickets: Array<Ticket | null>, daysOfEvent: Array<DaysOfEvent>):
Array<AddEditTicketFormValues> => {
  const each: Array<AddEditTicketFormValues> = []

  for (let i = 0; i < tickets.length; i += 1) {
    if (tickets[i]) {
      each.push({
        __typename: 'Ticket',
        id: tickets[i]?.id,
        name: tickets[i]?.name ? tickets[i]!.name! : '',
        validDates: getValidDates(tickets[i]!.validDates!, Array.from(daysOfEvent, (day) => day.date),),
        price: tickets[i]!.prices.price.toString()!,
        memberPrice: tickets[i]!.prices.memberPrice !== null ? tickets[i]!.prices.memberPrice!.toString()! : '',
        priceOverRideDate: tickets[i]!.prices.promoPrices[0]
          ? parseISOToDateTime(tickets[i]!.prices.promoPrices[0].dateSpan.start)
          : eventDate,
        overridePrice: tickets[i]!.prices.promoPrices[0]
          ? tickets[i]!.prices.promoPrices[0].price.toString()
          : '',
        memberOverridePrice: tickets[i]!.prices.promoPrices[0] && tickets[i]!.prices.promoPrices[0].memberPrice
          ? tickets[i]!.prices.promoPrices[0].memberPrice!.toString()
          : '',
        nrAvailableTickets: tickets[i]!.stock !== null ? tickets[i]!.stock!.toString()! : '',
        addTickets: false,
      })
    }
  }

  return each
}

/**
 * Builds this.state.consumables.each field.
 *
 * @param consumables
 */
export const buildConsumables = (consumables: Array<Consumable | null>): Array<AddEditConsumableFormValues> => {
  const each: Array<AddEditConsumableFormValues> = []

  for (let i = 0; i < consumables.length; i += 1) {
    // We need to exclude consumables that are large, so that we don't create duplicate cards
    if (consumables[i]?.size && consumables[i]!.size! === 'LARGE') continue
    each.push({
      __typename: 'Consumable',
      id: consumables[i]?.id ?? '',
      name: consumables[i]?.name ?? '',
      type: consumables[i]?.type ?? '',
      category: i18next.t(`Consumables.subCategories.${consumables[i]?.type.toLowerCase()}`),
      price: consumables[i]?.prices.price?.toString() ?? '',
      memberPrice: consumables[i]?.prices.memberPrice !== null ? consumables[i]?.prices.memberPrice?.toString() ?? '' : '',
      stallID: /* consumables[i]?.groupID !== null ? consumables[i]?.groupID! : ""*/ '',
      largeMustExist: false,
      consumableSmallOrLargeRefId: consumables[i]?.consumableSmallOrLargeRefId ?? '',
      largeVersionName: consumables[i]?.smallOrLargeRef !== null
        ? consumables[i]?.smallOrLargeRef?.name ?? ''
        : '',
      largeVersionPrice: consumables[i]?.smallOrLargeRef !== null
        ? consumables[i]?.smallOrLargeRef?.prices.price!.toString() ?? ''
        : '',
      largeVersionMemberPrice: consumables[i]?.smallOrLargeRef?.prices.memberPrice !== null
        ? consumables[i]?.smallOrLargeRef?.prices.memberPrice!.toString() ?? ''
        : '',
      promotion: {
        date: {
          start: consumables[i]?.prices.promoPrices[0]
            ? parseISOToDateTime(consumables[i]?.prices.promoPrices[0].dateSpan.start ?? '')
            : eventDate,
          end: consumables[i]?.prices.promoPrices[0]
            ? parseISOToDateTime(consumables[i]?.prices.promoPrices[0].dateSpan.end ?? '')
            : eventDate,
        },
        prices: {
          regularSize: {
            price: consumables[i]?.prices.promoPrices[0]
              ? consumables[i]?.prices.promoPrices[0].price?.toString() ?? ''
              : '',
            memberPrice: consumables[i]?.prices.promoPrices[0]?.memberPrice
              ? consumables[i]!.prices.promoPrices[0].memberPrice!.toString()!
              : '',
          },
          largeSize: {
            price: consumables[i]?.smallOrLargeRef?.prices.promoPrices[0]
              ? consumables[i]?.smallOrLargeRef?.prices.promoPrices[0].price?.toString() ?? ''
              : '',
            memberPrice: consumables[i]?.smallOrLargeRef?.prices.promoPrices[0]?.memberPrice
              ? consumables[i]?.smallOrLargeRef?.prices.promoPrices[0].memberPrice!.toString() ?? ''
              : '',
          },
        },
      },
    })
  }

  return each
}

/**
 * Builds this.state.streaks.each field.
 *
 * @param streaks
 */
export const buildStreaks = (streaks: Array<Streak | null>): Array<AddEditStreakFormValues> => {
  const each: Array<AddEditStreakFormValues> = []

  for (let i = 0; i < streaks.length; i += 1) {
    each.push({
      __typename: 'Streak',
      id: streaks[i]?.id ?? '',
      name: streaks[i]?.name ?? '',
      consumablePair: streaks[i]?.set?.items.filter((value) => value !== null && value.id !== streaks[i]?.promoPair?.id)
        .map((pair: PairStreak | null) => {
        // I think this is the desired behavior, but please do check
        // TODO: Check this -> return { amount: pair?.amount?.toString()!, consumable: pair?.consumable?.id! }})!,
          return { amount: pair?.amount?.toString() ?? '', consumable: pair?.consumable?.name ?? '', id: pair?.id ?? '' }
        }) ?? [],
      prizeId: streaks[i]?.promoPair?.id ?? '',
      consumablePrize: streaks[i]?.promoPair?.consumable?.name ?? '',
      prizePrice: streaks[i]?.promoPrice?.toString() ?? '',
      amountPrize: streaks[i]?.promoPair.amount.toString() ?? '',
    })
  }
  return each
}

/**
 * Builds this.state.general.daysOfEvent field.
 *
 * @param dates
 * @param artistsFromEachDay
 */
export const buildDaysOfEvent = (dates: Array<DateSpan>, artistsFromEachDay: Array<Array<Artist> | null>):
Array<DaysOfEvent> => {
  const daysOfEvent: Array<DaysOfEvent> = []
  for (let i = 0; i < dates.length; i += 1) {
    daysOfEvent.push({ date: [
      {
        time: parseISOToDateTime(dates[i].start).time,
        date: parseISOToDateTime(dates[i].start).date,
      },

      {
        time: parseISOToDateTime(dates[i].end).time,
        date: parseISOToDateTime(dates[i].end).date,
      },
    ],

    // The default is to show a single artist
    artists: [eventArtist] })
  }

  for (let i = 0; i < artistsFromEachDay.length; i += 1) {
    if (artistsFromEachDay[i] !== null) {
      for (let j = 0; j < artistsFromEachDay[i]!.length!; j += 1) {
        // Replaces the original empty artist with the first received artist
        if (j === 0) {
          daysOfEvent[i].artists[0] = {
            name: artistsFromEachDay[i]![j]!.name!,
            startDateTime: parseISOToDateTime(artistsFromEachDay[i]![j]!.performanceDateTime!),
          }
        } else {
          daysOfEvent[i].artists.push({
            name: artistsFromEachDay[i]![j]!.name!,
            startDateTime: parseISOToDateTime(artistsFromEachDay[i]![j]!.performanceDateTime!),
          })
        }
      }
    }
  }

  return daysOfEvent
}

/**
 * Fetches the images associated with an event.
 *
 * @param photos
 */
export const getImages = (photos: Array<Image | null>):
// eslint-disable-next-line @typescript-eslint/ban-types
[Object | undefined, Object | undefined, Object | undefined, Object | undefined] => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const result: [Object | undefined, Object | undefined, Object | undefined, Object | undefined]
  = [undefined, undefined, undefined, undefined]

  fetchSetOfImages(photos.map((photo) => {
    return { filename: photo?.filename ?? '', identityId: photo?.identityID ?? '' }
  })).then((images) => {
    /**
     *
     * @Carl0smvs i'm removing this, since I don't see a way to get this to work as it was.
     * On Edit Event, instead of doing this we are using the image source directly to display it
     * If it is an image that is added in that form, it converts it to a displayable URL
     *
     * Leaving it here for future reference
     * **/

    for (let i = 0; i < images.length; i += 1) {
      result[i] = images[i]
    }
  })
  return result
}

/**
 * Creates a duplicate of the original consumables state
 *
 * @param values
 */
export const createConsumableOriginalValues = (values: EventConsumablesState): EventConsumablesState => {
  const newEach: AddEditConsumableFormValues[] = []

  values.each.forEach((form) => { newEach.push({ ...form }) })

  return {
    each: newEach,
    showAddConsumablePopup: values.showAddConsumablePopup,
    category: values.category,
  } as EventConsumablesState
}

export const createStreaksOriginalValues = (values: EventStreaksState): EventStreaksState => {
  const newEach: AddEditStreakFormValues[] = []

  values.each.forEach((form) => {
    const each = {
      __typename: form.__typename,
      id: form.id,
      name: form.name,
      consumablePair: Object.values({ // This is here to convert from object to an array
        ...form.consumablePair,
      }),
      prizeId: form.prizeId,
      consumablePrize: form.consumablePrize,
      prizePrice: form.prizePrice,
    } as AddEditStreakFormValues
    newEach.push(each)
  })

  return {
    each: newEach,
    showAddStreakPopup: values.showAddStreakPopup,
  } as EventStreaksState
}
