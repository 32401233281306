import React, { Component } from 'react'

import { GlobalProps } from '../../types/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { Redirect } from 'react-router-dom'

import '../../styles/views/auth/change-password-confirm.css'

/**
 * A simple redirect wrapper for NoMatch.
 */
export class NoMatchRedirect extends Component<GlobalProps> {
  render() {
    return (
      <Redirect to="/no-match" />
    )
  }
}

/**
 * Simple page that tells our user that the queried page does not exist.
 */
class NoMatch extends Component<GlobalProps> {
  render() {
    return (
      <div className="change-password-confirm-background">
        <div className="change-password-confirm-main">
          <div className="change-password-confirm-container">
            <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
            <h1>{i18next.t('NoMatch.title')}</h1>
            <p>{i18next.t('NoMatch.description')}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(NoMatch)
