import React, { Component } from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { TicketCardProps, TicketCardState, Dates } from '../../types/create-edit-event'
import AddEditTicket from './AddEditTicket'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import '../../styles/components/create-event/ticket-card.css'

/**
 * Creates a card that will display the information about a newly added ticket.
 */
class TicketCard extends Component<GlobalProps & TicketCardProps & Dates, TicketCardState> {
  /**
   * TicketCard class constructor.
   *
   * @param props all the needed props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      showAddTicketPopup: false,
      ticket: this.props.ticket,
    }
  }

  private dayGenerator = (index: number): string => {
    /* First we need to get the date which we are referring to */
    const parsedDate = new Date(Date.parse(`${this.props.eventDates[index][0].date}`))
    const day = parsedDate.getDate()
    const month = parsedDate.getMonth()

    switch (month) {
    case (0):
      return `${day} ${i18next.t('AddEvent.months.jan')}`
    case (1):
      return `${day} ${i18next.t('AddEvent.months.fev')}`
    case (2):
      return `${day} ${i18next.t('AddEvent.months.mar')}`
    case (3):
      return `${day} ${i18next.t('AddEvent.months.apr')}`
    case (4):
      return `${day} ${i18next.t('AddEvent.months.may')}`
    case (5):
      return `${day} ${i18next.t('AddEvent.months.jun')}`
    case (6):
      return `${day} ${i18next.t('AddEvent.months.jul')}`
    case (7):
      return `${day} ${i18next.t('AddEvent.months.aug')}`
    case (8):
      return `${day} ${i18next.t('AddEvent.months.sep')}`
    case (9):
      return `${day} ${i18next.t('AddEvent.months.oct')}`
    case (10):
      return `${day} ${i18next.t('AddEvent.months.nov')}`
    case (11):
      return `${day} ${i18next.t('AddEvent.months.dec')}`
    default:
      return ''
    }
  }

  private handleClick = (): void => {
    let index = 0
    for (let i = 0; i < this.props.ticket.validDates.length; i += 1) {
      if (this.props.ticket.validDates[i]) {
        index = i
        break
      }
    }

    const isBeforeTicketDay = new Date().valueOf() < Date.parse(`${this.props.eventDates[index][0].date}`)

    // We dont want the ticket to be editable if there are transactions of it or if it's already its during the event
    if (!isBeforeTicketDay) return
    this.setState({ showAddTicketPopup: true })
  }

  /**
  * React component render function. Holds our extended html code.
  */
  render() {
    return (
      <div>
        { this.props.mode === 'CREATE'
        && <AddEditTicket
          eventSpan={this.props.eventSpan ? this.props.eventSpan : [0, 0]}
          updateInfo={(input) => {
            this.setState({ ticket: input })
            this.props.setTicket(input)
          }}
          ticket={this.state.ticket}
          show={this.state.showAddTicketPopup}
          close={() => this.setState({ showAddTicketPopup: false })}
          numberOfDays={this.props.numberOfDays}
          addTickets={this.props.addTicket}
          mode = {this.props.mode}
        />
        }
        <div className="ticket-card-total" onClick={() => this.handleClick()}>
          { this.props.mode === 'CREATE'
          && <FontAwesomeIcon id="trash-icon" icon={faTrashAlt} onClick={this.props.deleteTicket}/>
          }
          <div className="ticket-card-title-info-combo">
            <h4>{i18next.t('AddEvent.addTickets.name')}</h4>
            <h2>{this.state.ticket.name}</h2>
          </div>
          <div className="ticket-card-title-info-combo" id="date">
            <h4>{i18next.t('AddEvent.addTickets.date')}</h4>
            {this.state.ticket.validDates.map((validDate : boolean, index: number) => (

              <div key={index}>
                { validDate
                  ? <h2>{this.dayGenerator(index)}</h2>
                  : null
                }
              </div>

            ))}
          </div>
          { this.state.ticket.nrAvailableTickets !== ''
          && <div className="ticket-card-title-info-combo">
            <h4>{i18next.t('AddEvent.addTickets.nrAvailableTickets')}</h4>
            <h2>{this.state.ticket.nrAvailableTickets}</h2>
          </div>}
          <div className="ticket-card-prices">
            <div className="ticket-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addTickets.price')}</h4>
              <h2>{`${this.state.ticket.price} €`}</h2>
            </div>
            { this.state.ticket.memberPrice !== ''
            && <div className="ticket-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addTickets.memberPrice')}</h4>
              <h2>{`${this.state.ticket.memberPrice} €`}</h2>
            </div>
            }
          </div>
          { this.state.ticket.overridePrice !== ''
          && <p>{`${this.state.ticket.priceOverRideDate.date} @ ${this.state.ticket.priceOverRideDate.time}`}</p>
          }
          { this.state.ticket.overridePrice !== ''
          && <div className="ticket-card-prices">
            <div className="ticket-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addTickets.price')}</h4>
              <h2>{`${this.state.ticket.overridePrice} €`}</h2>
            </div>
            { this.state.ticket.memberOverridePrice !== ''
            && <div className="ticket-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addTickets.memberPrice')}</h4>
              <h2>{`${this.state.ticket.memberOverridePrice} €`}</h2>
            </div>
            }
          </div>
          }
        </div>
      </div>
    )
  }
}

export default withTranslation()(TicketCard)
