import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */

import Layout from 'src/components/Layout'

import junitec from '../images/junitec.png'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/partners.css'

/**
 * This page shows the terms of our platform.
 */
class Terms extends Component<RouteComponentProps & GlobalProps> {
  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="partners-container">
        <Layout label={i18next.t('Partners.title')}>
          <div className="partner-wrapper">
            <div className="partner-info">
              <h3 className="partner-title">{i18next.t('Partners.junitec')}</h3>
              <p className="partner-description">{i18next.t('Partners.junitecDescription')}</p>
            </div>
            <a href="https://www.junitec.pt">
              <img src={junitec} alt="junitec" className="partner-image"/>
            </a>
          </div>
        </Layout>
      </div>
    )
  }
}

export default withTranslation()(Terms)
