import React, { Component } from 'react'
import { CollaboratorListProps, CollaboratorListState } from '../../types/collaborator-list' /* Import needed for the props */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faUser } from '@fortawesome/free-regular-svg-icons' /* Import needed to get the desired font elements */
import StyledCheckbox from './../utils/StyledCheckbox'  /* Used in Checkboxes */
import { Collaborator } from '../../API' /* Import needed to organize collaborator data */

import '../../styles/components/collaborators/collaborators-list.css'

/**
 * Creates the list of collaborators that will be shown to the user. Each line has basic information about the
 * collaborator and a checkbox to select multiple collaborators.
 */
class CollaboratorsList extends Component<CollaboratorListProps, CollaboratorListState> {
  /**
   * CollaboratorsList class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      highlight: -1, /* This default value refers to nobody being highlighted */
      /* It's null since in the beginning nobody is checked, unless it´s already passed in editEvent */
      localCheckedList: this.props.checkedList === undefined ? [] : this.props.checkedList,
      currentArraySize: props.collaboratorsArray.length,
    }
  }

  /* eslint-disable */
  /**
   * Is mainly being used to silence an error.
   *
   * @param _prevProps previous render's props
   * @param _prevState previous render's state
   * @param _snapshot previous component snapshot
   */
  componentDidUpdate(
    prevProps: Readonly<CollaboratorListProps>,
    prevState: Readonly<CollaboratorListState>,
    snapshot?: any,
  ) {}
  /* eslint-enable */

  /**
   * This function is called whenever a checkbox is clicked on
   *
   * @param event: corresponds to the click event that happened
   * @param index: corresponds to the index of the clicked checkbox
   * @param checkedList: corresponds to the current list of checked checkboxes
   */
  checkboxClick(event: React.MouseEvent, index: number, checkedList: Array<number>) {
    /* If the checkbox is already checked */
    if (checkedList.includes(index)) {
      let canBeRemoved

      if (this.props.originalCheckedList === undefined) canBeRemoved = true
      else canBeRemoved = !this.props.originalCheckedList?.includes(index)

      /* If the checked box is already checked and the collaborator can be removed, onClick we remove it from the list */
      if (canBeRemoved) checkedList.splice(checkedList.indexOf(index), 1)
    } else {
      /* If the checked box isn't checked, onClick we add it to the list */
      checkedList.push(index)
    }

    /* We sort the list to be easier to handle later on */
    checkedList.sort()

    /* Change the checked list on the parent component */
    this.props.changeCheckedList(event, checkedList)

    /* This function stops propagating the click and allows the div that his behind to not be clicked. */
    event.stopPropagation()
  }

  /**
   * This function is called whenever a line is clicked on
   *
   * @param event: corresponds to the click event that happened
   * @param index: corresponds to the index of the clicked line
   * @param collaborator: corresponds to the data of the collaborator of the clicked line
   */
  private onClickFunction(event: React.MouseEvent, index: number, collaborator?: Collaborator): void {
    /* If the current highlight is the clicked line it removes it from the highlight, else it makes the line the
    highlighted one */
    if (this.props.changeCollaboratorInfo !== undefined) {
      if (this.state.highlight === index) {
        /* Changing the collaborator on the side panel to undefined since we are removing the highlight */
        this.props.changeCollaboratorInfo(event, undefined)
        this.setState({ highlight: -1 })
      } else {
        /* Changing the collaborator on the side panel to the collaborator on the line since we are highlighting */
        this.props.changeCollaboratorInfo(event, collaborator)
        this.setState({ highlight: index })
      }
    }
  }

  /**
   * This function runs before the rendering. We use it to compare if there have been any group changes or if some
   * collaborators have been removed. Without this function some information like highlight indexes and checked boxes
   * would persist.
   */
  public getSnapshotBeforeUpdate(): null {
    /* Detecting if users have been removed */
    if (this.state.currentArraySize !== this.props.collaboratorsArray.length) {
      this.setState({ highlight: -1, localCheckedList: [], currentArraySize: this.props.collaboratorsArray.length })
    }

    return null
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="list-wrapper">
        {this.props.collaboratorsArray.map((collaborator, index) => {
          return (
            <div
              key={index}
              className="list-button"
              id={this.state.highlight === index ? 'highlighted-list-button' : undefined}
              onClick={(e) => this.onClickFunction(e, index, collaborator)}
            >
              <FontAwesomeIcon id="user-icon" icon={faUser}/>
              <h3>{collaborator.name}</h3>
              <p>{collaborator.phone}</p>
              <StyledCheckbox id="checkbox" checked={this.state.localCheckedList.includes(index)}
                onClick= {(e: React.MouseEvent) => this.checkboxClick(e, index, this.state.localCheckedList)}/>
            </div>
          )
        })}
      </div>
    )
  }
}

export default withTranslation()(CollaboratorsList)
