import React, { useEffect, useState } from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import '../styles/views/payment-feedback.css'
import { PaymentStatus, Person, WalletTransaction } from '../API'
import { doPingMBWay } from '../models/consumable'
import { getsPersonInfo, WalletTransactionByTransactionID } from '../models/person'
import { API, Auth } from 'aws-amplify'
import { onWalletTransactionUpdate } from '../graphql/subscriptions'
import { purchaseTicket } from '../models/ticket'
import { useLocation, RouteComponentProps, useParams } from 'react-router-dom'
import { getSibsFunctionURL } from '../helpers/Backend'
import axios from 'axios'
import { Circles } from 'react-loader-spinner'
import background from '../images/payment_pending.png'
import error from '../images/Error.png'
import sucess from '../images/sucess.png'

type Response = {
  status?: boolean;
  message?: string
}
enum PaymentFeedbackPages {
  PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  PAYMENT_PENDING,
  ERROR,
}

type TParams = { id: string };
interface PaymentFeedback {
  transactionID : string
  ticketID : string
}

export function PaymentFeedback({ history }: RouteComponentProps<TParams>) {
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const [person, setPerson] = useState<Person>({} as any)
  const [page, setPage] = useState(PaymentFeedbackPages.PAYMENT_PENDING)
  const [walletTransaction, setWalletTransaction] = useState<WalletTransaction>({} as any)
  const [checkStatusLoading, setcheckStatusLoading] = useState(false)
  const [loadingButton, setloadingButton] = useState(false)
  const { transactionID } = useParams<{ transactionID: string }>()

  /**
   * Cleanup function that cleans all the states and returns to the main page
   */
  function close() {
    setPage(PaymentFeedbackPages.PAYMENT_PENDING)
    history.push('/')
  }

  /**
   * Function that deals purchases the ticket and redirects according to the success/error status
   */
  async function purchaseTicketAction() {
    const response : Response = await purchaseTicket({ personID: person.id, ticketID: queryParameters.get('ticketID') as string ?? '' })
    if (response.status) {
      setTimeout(() => {
        setPage(PaymentFeedbackPages.PAYMENT_SUCCESS)
      }, 700)
    } else if (response.message === 'Not enough money to purchase this ticket!') {
      setPage(PaymentFeedbackPages.ERROR)
    } else if (response.message === 'There are no more tickets in stock to be sold!') {
      setPage(PaymentFeedbackPages.ERROR)
    } else if (response.message === 'The user already has a ticket for this event!') {
      setPage(PaymentFeedbackPages.ERROR)
    } else if (response.message?.toLowerCase().includes('aws')) {
      setPage(PaymentFeedbackPages.ERROR)
    } else {
      setPage(PaymentFeedbackPages.ERROR)
    }
  }

  /**
   * Function that checks our database to see if the payment has been accepted our declined
   *
   * @param TransactionID Id of the WalletTransaction we are trying to check
   * @returns boolean indicates if the function ran
   */
  async function checkPaymentStatus(TransactionID : string): Promise<boolean> {
    if (!transactionID) {
      await timer(4000)
      return false
    }
    // eslint-disable-next-line no-constant-condition
    if (walletTransaction.method === 'CREDITCARD' && false) {
      await axios.post(`${getSibsFunctionURL()}?transactionID=${transactionID}`)
        .then(() => {
          return true
        })
        .catch((errors) => {
          setPage(PaymentFeedbackPages.PAYMENT_FAILED)
          console.log(errors)
        })
    } else if (walletTransaction.method === 'MBWAY') {
      const walletTransactionAux = await WalletTransactionByTransactionID({ transactionID: transactionID })
      switch (walletTransactionAux.status) {
      case 'PENDING':
        /* Calls sibs to check if the payment has been completed and give feedback to the user */
        //TODO: FIX ESLINT
        // eslint-disable-next-line no-case-declarations
        const result = await doPingMBWay({ transactionID: TransactionID })
        /* Handles success case */
        if (result === 'Success' && queryParameters.get('ticketID') !== null) {
          console.log('Payment Status Sucess')
          await purchaseTicketAction()
          return true
        }
        /* Handles if the payment has been declined */
        if (result === 'Declined') {
          console.log('Payment Status Declined')
          setPage(PaymentFeedbackPages.PAYMENT_FAILED)
          return true
        }
        break
      case 'DENIED':
        setPage(PaymentFeedbackPages.PAYMENT_FAILED)
        break
      case 'APPROVED':
        await purchaseTicketAction()
        break
      default:
        break
      }
    }

    await timer(4000)
    return false
  }

  /**
   *  Hook runs only when the page is open, fetches all the info that is needed
   */
  useEffect(() => {
    setTimeout(() => setloadingButton(true), 10000)
    // Fetches the info related to the person
    async function getInfo() {
      const user = await Auth.currentUserInfo()
      if (user !== undefined) {
        const personaux = await getsPersonInfo({ id: user.attributes.sub })
        setPerson(personaux)
      } else {
        close()
      }
    }

    // Pings the SIBS server to check if the card payment was sucessfull
    async function getState() {
      await axios.post(`${getSibsFunctionURL()}?transactionID=${transactionID}`)
        .then((response) => {
          console.log(response)
        })
        .catch((errors) => {
          setPage(PaymentFeedbackPages.PAYMENT_FAILED)
          console.log(errors)
        })
    }
    if (queryParameters.get('id') !== null) {
      getState()
    }
    getInfo()
  }, [])

  useEffect(() => {
    async function checkStatus() {
      const walletTransactionAux = await WalletTransactionByTransactionID({ transactionID: transactionID })
      setWalletTransaction(walletTransactionAux)
      console.log('wallet transacion', walletTransactionAux)
      if (walletTransactionAux.status === 'PENDING') {
        return
      } if (walletTransactionAux.status === 'DENIED') {
        setPage(PaymentFeedbackPages.PAYMENT_FAILED)
      } if (walletTransactionAux.status === 'APPROVED') {
        setPage(PaymentFeedbackPages.PAYMENT_SUCCESS)
      } if (walletTransactionAux.status === 'FAILED') {
        setPage(PaymentFeedbackPages.PAYMENT_FAILED)
      }
    }
    // Checks if the transaction as already been validated
    if (page === PaymentFeedbackPages.PAYMENT_PENDING) {
      checkStatus()
    }
  }, [page])

  /**
   *  Hook runs when there is an update to the Transaction in the database and runs the corresponding logic
   */
  useEffect(() => {
    /* Initializes wallet subscription. In this subscription, the user waits for the SIBS request to our backend
     * confirming or denying the transaction. After we check if it was accepted or not, we change the page. */
    const updatePerson = async () => {
      return await getsPersonInfo({ id: person?.id })
    }

    const walletSubscription = (API.graphql({
      query: onWalletTransactionUpdate,
      variables: {
        personID: person.id,
      },
    }) as any).subscribe({
      next: async ({ value } : any) => {
        const transaction: WalletTransaction = value.data.onWalletTransactionUpdate!
        // SIBS had called our function in API Gateway, so we need to tell the user the result
        if (transaction.status === PaymentStatus.PENDING) {
          setPage(PaymentFeedbackPages.PAYMENT_PENDING)
          let counter = 0
          let stopLoop = false
          do {
            counter += 1
            stopLoop = await checkPaymentStatus(transaction.transactionID)
          } while (counter < 30 && !stopLoop)
        } else if (transaction.status === PaymentStatus.APPROVED && (transaction.method === 'CREDITCARD' || transaction.method === 'MBWAY')) {
          updatePerson().then((personResponse) => {
            if (personResponse) {
              setPerson(personResponse)
              purchaseTicketAction()
            }
          })
          setTimeout(() => {
            close()
          }, 10000)
        } else if (transaction!.status === PaymentStatus.DENIED && (transaction.method === 'CREDITCARD' || transaction.method === 'MBWAY')) {
          setPage(PaymentFeedbackPages.PAYMENT_FAILED)
        }
      },
    })

    return () => {
      walletSubscription?.unsubscribe()
    }
  }, [person])

  function handleCheckStatus() {
    if (!checkStatusLoading) {
      setcheckStatusLoading(true)
      // TODO: FIX Then
      checkPaymentStatus(transactionID).then((r) => console.log('Check Payment:', r))
      setcheckStatusLoading(false)
    }
  }
  /**
   * This functions forces the code to await ms milliseconds before running the next instruction
   *
   * @param ms number of milliseconds that will be waited
   */
  // eslint-disable-next-line no-promise-executor-return
  const timer = (ms: number) => new Promise((res) => setTimeout(res, ms))

  return (
    <div>
      {page === PaymentFeedbackPages.PAYMENT_PENDING
        && <div>
          <div className="image-wrapper">
            <img className={'image'} src={background} alt={'pending'}/>
            <Circles
              wrapperClass={'tail-spin'}
              visible={true}
              height="150"
              width="150"
              color="#0F6DF9"
            />
          </div>
          <div className={'text'} >
            <h1>{i18next.t('PaymentFeedback.Processing')}</h1>
            <h2>{i18next.t('PaymentFeedback.No_Close')}</h2>
          </div>
          {loadingButton && <button className="button" onClick={() => handleCheckStatus()} disabled={checkStatusLoading}>{i18next.t('PaymentFeedback.Update')}</button>}
        </div>}
      {page === PaymentFeedbackPages.PAYMENT_SUCCESS
        && <div>
          <div className="image-wrapper">
            <img className={'image'} src={sucess} alt={'sucess'}/>
          </div>
          <div className={'text'} >
            <h1>{i18next.t('PaymentFeedback.Sucess')}</h1>
            <h2>{i18next.t('PaymentFeedback.Bought_Ticket')}</h2>
          </div>
          <button className="button" onClick={() => history.goBack()}>{i18next.t('PaymentFeedback.Back')}</button>
        </div>}
      {page === PaymentFeedbackPages.PAYMENT_FAILED
        && <div>
          <div className="image-wrapper">
            <img className={'image'} src={error} alt={'error'}/>
          </div>
          <div className={'text'} >
            <h1>{i18next.t('PaymentFeedback.Error')}</h1>
            <h2>{i18next.t('PaymentFeedback.Payment_Error')}</h2>
          </div>
          <button className="button" onClick={() => history.push(`/event/${queryParameters.get('eventID')}`)}>{i18next.t('PaymentFeedback.Back')}</button>
        </div>}
      {page === PaymentFeedbackPages.ERROR
        && <div>
          <div className="image-wrapper">
            <img className={'image'} src={error} alt={'error'}/>
          </div>
          <div className={'text'} >
            <h1>{i18next.t('PaymentFeedback.Error')}</h1>
            <h2>{i18next.t('PaymentFeedback.No_Bought_Ticket')}</h2>
          </div>
          <button className="button" onClick={() => history.goBack()}>Voltar</button>
        </div>}
    </div>
  )
}

export default withTranslation()(PaymentFeedback)
