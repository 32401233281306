import { Component } from 'react'
import { GlobalProps } from '../../../types/global'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { v4 as uuid } from 'uuid'
import { createCollaboratorEventRelation, createEvent, createEventPoster } from '../../../models/event'
import { CreatedSuccessfullyProps, CreatedSuccessfullyState } from '../../../types/create-edit-event'
import { uploadSetOfImages } from '../../../models/images'
import { createEventTicket } from '../../../models/ticket'
import { ConsumableSize, Consumable, CreateConsumableInput } from '../../../API'
import { createEventConsumable, createPairStreak } from '../../../models/consumable'
import { createStreak } from '../../../models/streak'
import { TailSpin } from 'react-loader-spinner'
import * as Func from '../util/functions'

import '../../../styles/views/create-event/created-successfully.css'

/* eslint-disable */
/**
 * enum to describe the page current operation
 */
enum operation {
  LOADING,
  SUCCESS,
  FAILURE
}
/* eslint-enable */

/**
 * This page processes and submits all the information for the creation of the event
 */
class CreateStage extends Component<GlobalProps & CreatedSuccessfullyProps, CreatedSuccessfullyState> {
  /**
   * CreateStage class constructor.
   *
   * @param props required props
   */
  constructor(props: GlobalProps & CreatedSuccessfullyProps) {
    super(props)
    this.state = {
      pageOperation: operation.LOADING,
      errorMessage: '',
    }
  }

  /**
   * Creates event component in our database, uploads event images and creates a poster for it.
   *
   * @param eventID newly created event's identity id
   */
  private initEvent = async (eventID: string): Promise<void> => {
    try {
      /* Uploads all the images of the event to the database */
      const files = this.props.values.general!.images.filter((image) => image !== undefined) as Array<File>
      const images = await uploadSetOfImages(files, this.props.auth.user, eventID)

      /* Creates event's poster */
      const poster = await createEventPoster({
        owner: this.props.auth.user.getSub(),
        artists: Array.from(this.props.values.general!.daysOfEvent, (day) => {
          if (day.artists[0].name !== '') {
            return Array.from(day.artists, (artist) => {
              return {
                name: artist.name,
                performanceDateTime: Func.parseDateTimeToISO(artist.startDateTime),
              }
            })
          }
          return null
        }),
      })

      /* Create an event with its general information */
      await createEvent({
        id: eventID,
        owner: this.props.auth.user.getSub(),
        name: this.props.values.general!.name,
        promotions: [],
        eventPosterId: poster.id,
        startDate: Func.parseDateTimeToISO(this.props.values.general!.daysOfEvent[0].date[0]),
        endDate: Func.parseDateTimeToISO((
          this.props.values.general!.daysOfEvent[this.props.values.general!.daysOfEvent.length - 1].date[1]
        )),
        location: Func.parseGoogleMapsUrlToLocation(this.props.values.general!.address),
        addressAlias: this.props.values.general!.addressAlias,
        companyID: this.props.auth.user.getSub(),
        description: this.props.values.general!.description,
        date: this.props.values.general!.daysOfEvent.map(({ date }) => {
          return { start: Func.parseDateTimeToISO(date[0]), end: Func.parseDateTimeToISO(date[1]) }
        }),
        eventBannerId: images[0].object.id,  /* Our first image is our banner (main front page image) */
      })
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while initializing the event: ', error)

      let message = 'Failed to init event'

      if (error instanceof Error) {
        message = error.message
      }
      throw new Error(message)
    }
  }

  /**
   * Creates event's consumables.
   *
   * @param eventID newly created event's identity id
   */
  private addsEventConsumables = async (eventID: string):
  Promise<{regular: Consumable, large: Consumable | null}[]> => {
    try {
      /* Creates the consumables and associate them with this event */
      return await Promise.all(this.props.values.consumables!.each.map(async (consumable) => {
        /* Creates id to be linked */
        const largeConsumableID = uuid()

        const consumableObject1 = {
          owner: this.props.auth.user.getSub(),
          type: Func.getConsumableType(consumable.category),
          name: consumable.name,
          size: ConsumableSize.REGULAR,
          companyID: this.props.auth.user.getSub(),
          prices: {
            price: parseFloat(consumable.price),
            memberPrice: consumable.memberPrice !== '' ? parseFloat(consumable.memberPrice) : null,
            promoPrices: consumable.promotion.date.start.date !== '' && consumable.promotion.date.end.date !== ''
              ? [{
                price: consumable.promotion.prices.regularSize.price !== '' ? parseFloat(consumable.promotion.prices.regularSize.price) : null,
                memberPrice: consumable.promotion.prices.regularSize.memberPrice !== '' ? parseFloat(consumable.promotion.prices.regularSize.memberPrice) : null,
                dateSpan: {
                  start: consumable.promotion.date.start.date !== '' ? Func.parseDateTimeToISO(consumable.promotion.date.start) : null,
                  end: consumable.promotion.date.end.date !== '' ? Func.parseDateTimeToISO(consumable.promotion.date.end) : null,
                },
              }]
              : [],
          },
          eventID,
        } as CreateConsumableInput

        /* If we added a group to this consumable, we need to add it to the object */
        if (consumable.largeVersionName !== '') consumableObject1.consumableSmallOrLargeRefId = largeConsumableID

        const regularConsumable = await createEventConsumable(consumableObject1)

        /* This means that we have a larger version of this consumable, so we need to add it too */
        if (consumable.largeVersionName !== '') {
          const consumableObject2 = {
            id: largeConsumableID,
            owner: this.props.auth.user.getSub(),
            companyID: this.props.auth.user.getSub(),
            type: Func.getConsumableType(consumable.category),
            name: consumable.largeVersionName,
            size: ConsumableSize.LARGE,
            prices: {
              price: parseFloat(consumable.largeVersionPrice),
              memberPrice: consumable.largeVersionMemberPrice !== '' ? parseFloat(consumable.largeVersionMemberPrice) : null,
              promoPrices: consumable.promotion.date.start.date !== '' && consumable.promotion.date.end.date !== ''
                ? [{
                  price: consumable.promotion.prices.largeSize.price !== '' ? parseFloat(consumable.promotion.prices.largeSize.price) : null,
                  memberPrice: consumable.promotion.prices.largeSize.memberPrice !== '' ? parseFloat(consumable.promotion.prices.largeSize.memberPrice) : null,
                  dateSpan: {
                    start: consumable.promotion.date.start.date !== '' ? Func.parseDateTimeToISO(consumable.promotion.date.start) : null,
                    end: consumable.promotion.date.end.date !== '' ? Func.parseDateTimeToISO(consumable.promotion.date.end) : null,
                  },
                }]
                : [],
            },

            consumableSmallOrLargeRefId: regularConsumable.id,
            eventID,
          } as CreateConsumableInput

          const largeConsumable = await createEventConsumable(consumableObject2)

          return {
            regular: regularConsumable,
            large: largeConsumable,
          }
        }
        return {
          regular: regularConsumable,
          large: null,
        }
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while adding the consumables to the event: ', error)

      let message = 'Failed to add consumables to the event'

      if (error instanceof Error) {
        message = error.message
      }

      throw new Error(message)
    }
  }

  /**
   * Creates event's streaks.
   *
   * @param eventID newly created event's identity id
   * @param consumables array of newly created consumables
   */
  private addsEventStreaks = async (eventID: string, consumables: {regular: Consumable, large: Consumable | null}[]):
  Promise<void> => {
    try {
      /* Add the streaks in the database and link them to this event */
      await Promise.all(this.props.values.streaks!.each.map(async (streak) => {
        /* Used to put in pairs */
        const streakID = uuid()

        /* Creates streak items/tasks */
        await Promise.all(streak.consumablePair.map(async (pair) => {
          return await createPairStreak({
            owner: this.props.auth.user.getSub(),
            streakID,
            amount: parseInt(pair.amount, 10),
            pairStreakConsumableId: Func.findConsumableID(pair.consumable, consumables),
          })
        }))

        /* Creates streak */
        await createStreak({
          id: streakID,
          streakPromoPairId: (await createPairStreak({
            owner: this.props.auth.user.getSub(),
            streakID,
            amount: parseInt(streak.amountPrize, 10),
            pairStreakConsumableId: Func.findConsumableID(streak.consumablePrize, consumables),
          })).id,
          promotions: [],  // TODO: need to change frontend to support this operations
          isRepeatable: true,
          name: streak.name,
          owner: this.props.auth.user.getSub(),
          eventID,
          promoPrice: parseFloat(streak.prizePrice),
        })
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while adding the streaks to the event: ', error)

      let message = 'Failed to add streaks to the event'

      if (error instanceof Error) {
        message = error.message
      }

      throw new Error(message)
    }
  }

  /**
   * Creates event's tickets.
   *
   * @param eventID newly created event's identity id
   */
  private addsEventTickets = async (eventID: string): Promise<void> => {
    try {
      /* Add the tickets in the database and link them to this event */
      await Promise.all(this.props.values.tickets!.each.map(async (ticket) => {
        return await createEventTicket({
          owner: this.props.auth.user.getSub(),
          stock: ticket.nrAvailableTickets !== '' ? parseInt(ticket.nrAvailableTickets, 10) : null,
          name: ticket.name,
          initialStock: ticket.nrAvailableTickets !== '' ? parseInt(ticket.nrAvailableTickets, 10) : null,
          eventName: this.props.values.general!.name,
          prices: {
            price: parseFloat(ticket.price),
            memberPrice: ticket.memberPrice ? parseFloat(ticket.memberPrice) : null,
            promoPrices: ticket.priceOverRideDate.date !== '' && ticket.overridePrice !== ''
              ? [{
                price: parseFloat(ticket.overridePrice),
                memberPrice: ticket.memberOverridePrice !== '' ? parseFloat(ticket.memberOverridePrice) : null,
                dateSpan: {
                  start: Func.parseDateTimeToISO(ticket.priceOverRideDate),
                  end: Func.endOfTimesInISO(),
                },
              }]
              : [],
          },
          validDates: Func.extractTicketDates(Array.from(this.props.values.general!.daysOfEvent, (day) => {
            return day.date
          }), ticket.validDates),
          eventID,
        })
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while adding the tickets to the event: ', error)

      let message = 'Failed to add tickets to the event'

      if (error instanceof Error) {
        message = error.message
      }

      throw new Error(message)
    }
  }

  /**
   * Adds collaborators to the event.
   *
   * @param eventID newly created event's identity id
   */
  private addsEventCollaborators = async (eventID: string): Promise<void> => {
    try {
      /* Update in the database the collaborators that will be now linked to this event*/
      await Promise.all(this.props.values.collaborators!.each.map(async (collaboratorKey) => {
        return await createCollaboratorEventRelation({
          collaboratorID: collaboratorKey,
          eventID,
        })
      }))
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while adding the collaborators to the event: ', error)

      let message = 'Failed to add collaborators to the event'

      if (error instanceof Error) {
        message = error.message
      }

      throw new Error(message)
    }
  }

  /**
   * Is ran right after the component mounts and tries to create the event.
   */
  public componentDidMount(): void {
    this.deploysEvent().then(() => {
      this.setState({
        pageOperation: operation.SUCCESS,
      })
    })
      .catch((error) => {
        this.setState({
          pageOperation: operation.FAILURE,
          errorMessage: error.message,
        })
      })
  }

  /**
   * Sends event info to database and tries to create an event and all of its components.
   */
  private deploysEvent = async () => {
    /* Pre-sets event id so that we can use it to upload the images */
    const eventID = uuid()

    try {
      /* Creates event main component */
      await this.initEvent(eventID)

      /* Creates consumables for this event */
      const consumables = await this.addsEventConsumables(eventID)

      /* Creates event streaks */
      await this.addsEventStreaks(eventID, consumables)

      /* Creates event tickets */
      await this.addsEventTickets(eventID)

      /* Adds collaborators to event */
      await this.addsEventCollaborators(eventID)
    } catch (error) {
      /* Logs error on the console. Mainly done for debug purposes */
      console.error('Error while creating the event: ', error)

      let message = 'Failed to create event'

      if (error instanceof Error) {
        message = error.message
      }

      throw new Error(message)
    }
  }

  /**
   * Uses the current page's state to decide what to show the user.
   */
  private getDisplayableText() {
    switch (this.state.pageOperation) {
    case operation.SUCCESS:
      return i18next.t('AddEvent.createdSuccessfully.success')
    case operation.FAILURE:
      return i18next.t('AddEvent.createdSuccessfully.failed') + this.state.errorMessage
    case operation.LOADING:
      return i18next.t('AddEvent.createdSuccessfully.loading')
    default:
      return ''
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    const stringToShow = this.getDisplayableText()
    return (
      <div className="created-successfully-total">
        { this.state.pageOperation === operation.LOADING
        && <div>
          <TailSpin
            visible={true}
            height="110"
            width="110"
            color="#02112E"
            radius="0"
          />
        </div>
        }
        <h2>{stringToShow}</h2>
      </div>
    )
  }
}

export default withTranslation()(CreateStage)
