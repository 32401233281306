import { dateGenerator } from '../../helper/Dates' /* Import needed to generate string with date */

import { PersonTicket, Ticket } from '../../API' /* Database Access Import */
// import TicketBoughtBackground from '../../assets/ticketBoughtBackground.svg'

import { RouteComponentProps } from 'react-router-dom'
import '../../styles/components/ticket-card-bought.css'
import i18next from 'i18next'

/* These two constants refer to the sizes of the device we are working in and will be used in the styling */
// const window.innerWidth = Dimensions.get('window').width

/* Props of the ticket card component */
interface TicketCardBoughtProps {
    ticket?: Ticket;
    personTicket?: PersonTicket | null;
}

/* This component is used to display information about a ticket. */
export function TicketCardBought({ ticket, personTicket }: TicketCardBoughtProps, props: RouteComponentProps) {
  /**
   * This function determines if the ticket has been used, unused or expired.
   * Used: Ticket was used to enter an event
   * Unused: Ticket wasn't used and the event hasn't ocurred yet
   * Expired: Ticket wasn't used and the event has already ocurred
   *
   * @returns ticket status: "used", "unused", "expired"
   */
  function ticketReadStatus(personTicketParam: PersonTicket) {
    const today = new Date().toISOString()

    if (personTicket?.personTicketRefundId) {
      // return currentDictionary.TicketCardProfile.refunded
      return i18next.t('TicketCardBought.refunded')
    }

    if (personTicketParam.readDates.length !== 0) {
      // return currentDictionary.TicketCardProfile.used
      return i18next.t('TicketCardBought.used')
    }
    if (personTicketParam.readDates.length === 0
      && personTicketParam?.ticket?.validDates[personTicketParam?.ticket?.validDates?.length - 1]?.end
      && personTicketParam.ticket.validDates[personTicketParam.ticket.validDates.length - 1].end > today) {
      // return currentDictionary.TicketCardProfile.unused
      return i18next.t('TicketCardBought.unused')
    }
    // return currentDictionary.TicketCardProfile.expired
    return i18next.t('TicketCardBought.expired')
  }

  if (ticket || personTicket) {
    return (
      <div className="ticket-card-bought-wrapper">
        <div className="ticketInfoWrapper">
          <h1 className="ticketName">
            {ticket ? ticket.name : personTicket?.ticket.name}
          </h1>
          <div className="ticketDatesWrapper">
            <p className="ticketDates">
              {dateGenerator(
                ticket ? ticket.validDates : (personTicket?.ticket.validDates ?? []),
                true,
                true,
                false,
                i18next.t('TicketCardBought.currentLanguage'),
              ).split(' ')
                .slice(0, 5)
                .join(' ')}
            </p>
            <p className="ticketHours">
              {dateGenerator(
                ticket ? ticket.validDates : (personTicket?.ticket.validDates ?? []),
                true,
                true,
                false,
                'pt',
                // selectedLanguage,
              ).split(' ')
                .slice(5)
                .join(' ')}
            </p>
          </div>
        </div>
        <div className="validationInfoWrapper">
          { ticket
            ? <p className="validationInfoText">{'por validar'}</p>
            : <p className="validationInfoText">{ticketReadStatus(personTicket!)}</p>
          }
        </div>
      </div>
    )
  }
  return (<div/>)
}
