import React, { Component } from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { PopupProps } from '../../types/popup' /* Importing the interface that allow the use of props */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { Collaborator } from '../../API' /* Import needed to organize collaborator data */
import { AddEditCollaboratorsProps, AddEditCollaboratorFormValues, AddEditCollaboratorsState } from '../../types/add-collaborators'
import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import { PasswordInput, TextInput, StaticTextInput, PhoneNumberInput } from '../utils/FormFields'  // Form fields
import * as Yup from 'yup'  // Used to validate our form
import 'yup-phone'
import { checkIfAccountCreationIsValid } from '../../models/person'
import { updateCollaborator, getCollaborator } from '../../models/collaborators'
import { Auth } from 'aws-amplify'
import AddEditButton from '../AddEditButton'
import CognitoGroups from '../../constants/cognito-groups.json'

import '../../styles/components/collaborators/add-edit-collaborators.css'

interface CMD {
  companyID: string
  owner: string
  activity?: string
}

/**
 * Creates the popup component that allows the user to add or edit a collaborator. Props are explained on the @ type
 * file.
 */
class AddEditCollaborator extends Component<
  GlobalProps & PopupProps & AddEditCollaboratorsProps,
  AddEditCollaboratorsState
> {
  /**
   * AddEditCollaborator class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      phoneErrorMessage: '',
      handleErrorMessage: '',
      loading: false,
    }
  }

  /* We need this to convert the Collaborator, or pass it originally as AddEditCollaboratorFormValues */
  private originalCollaborator = {
    name: this.props.collaborator?.name,
    phoneNumber: this.props.collaborator?.phone,
    handle: '',
    activity: this.props.collaborator?.activity !== null ? this.props.collaborator?.activity : '',
    password: '',
    confirmPassword: '',
  } as AddEditCollaboratorFormValues

  /* Holds a blank Collaborator for use when creating a new Collaborator */
  private blankCollaborator = {
    name: '',
    phoneNumber: '',
    handle: '',
    activity: '',
    password: '',
    confirmPassword: '',
  } as AddEditCollaboratorFormValues

  /* Hold our initial form's values */
  private initialValues = this.props.collaborator ? this.originalCollaborator : this.blankCollaborator

  /* Holds validation for our form */
  private validationSchema = this.props.collaborator

    ? Yup.object().shape({

      name: Yup.string().required(i18next.t('Collaborators.errors.name.required'))
        .max(30, i18next.t('Collaborators.name.max')),

      activity: Yup.string().max(30, i18next.t('Collaborators.errors.description.max')),

    })
    : Yup.object().shape({

      name: Yup.string().required(i18next.t('Collaborators.errors.name.required'))
        .max(30, i18next.t('Collaborators.errors.name.max')),

      phoneNumber: Yup.string().phone()
        .required(i18next.t('Collaborators.errors.phoneNumber.required')),

      handle: Yup.string().required(i18next.t('Collaborators.errors.handle.required'))
        .min(5, i18next.t('Collaborators.errors.handle.min'))
        .max(30, i18next.t('Collaborators.errors.handle.max'))
        .matches(/^[a-z0-9_.]*$/, i18next.t('Collaborators.errors.handle.format')),

      activity: Yup.string().max(30, i18next.t('Collaborators.errors.description.max')),

      password: Yup.string().required(i18next.t('Collaborators.errors.password.required'))
        .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,16}$/, i18next.t('Collaborators.errors.password.format')),

      confirmPassword: Yup.string().required(i18next.t('Collaborators.errors.confirmPassword.required'))
        .oneOf([Yup.ref('password'), null], i18next.t('Collaborators.errors.confirmPassword.match')),
    })

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   * @param resetForm function called to reset form input fields
   */
  private handleSubmit = async (values: AddEditCollaboratorFormValues, { setSubmitting, resetForm }:
                          FormikHelpers<AddEditCollaboratorFormValues>): Promise<void> => {
    try {
      let collaborator: Collaborator

      this.setState({ loading: true })

      /* In this case we create a new Collaborator and send it to the database */
      if (this.props.collaborator === undefined || !this.props.collaborator.id) {
        /* Pre creates the cmd object as to solve the dynamo type mismatch error because of null fields in strings */
        const cmd = {
          companyID: this.props.auth.user.getSub(),
          owner: this.props.auth.user.getSub(),
          handle: values.handle,
        } as CMD
        if (values.activity !== '') cmd.activity = values.activity

        const res = await checkIfAccountCreationIsValid({ handle: values.handle })
        if (res === 'handle') {
          this.setState({ handleErrorMessage: i18next.t('Collaborators.errors.handle.alreadyInUse'), phoneErrorMessage: '' })
        } else if (res === 'success') {
          this.setState({ handleErrorMessage: '', phoneErrorMessage: '' })
          const { userSub } = await Auth.signUp({
            username: values.handle,
            password: values.password,
            attributes: {
              name: values.name,
              phone_number: values.phoneNumber,
              'custom:cognitogroup': CognitoGroups.COLLABORATOR,
            },
            clientMetadata: cmd as any,
          })

          collaborator = await getCollaborator({ id: userSub })

          /* We send the new collaborator back to the parent to update state */
          this.props.addEditCollaborator(collaborator)

          /* Enables submit button again */
          setSubmitting(false)
          this.setState({ loading: false })

          /* Closes popup */
          this.props.close()

          /* Resets form after submitting */
          resetForm()
        }
      /* In this case we modify the existing Collaborator */
      } else {
        collaborator = await updateCollaborator({
          id: this.props.collaborator.id,
          name: values.name,
          activity: values.activity,
        })

        /* We send the new collaborator back to the parent to update state */
        this.props.addEditCollaborator(collaborator)

        /* Enables submit button again */
        setSubmitting(false)
        this.setState({ loading: false })

        /* Closes popup */
        this.props.close()

        /* Resets form after submitting */
        resetForm()
      }
    } catch (error) {
      if ((error as any).message) {
        alert((error as any).message)
      } else {
        alert(JSON.stringify(error))
      }

      /* Enables submit button again */
      setSubmitting(false)
      this.setState({ loading: false })
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      /* This div is used to make the background more opaque. */
      <div onClick={this.props.close} className="background" style={{
        display: this.props.show ? 'flex' : 'none',
      }}>
        <div
          className="add-edit-collaborator-wrapper"
          id={this.props.collaborator === undefined ? '' : 'add-edit-collaborator-short-wrapper'}
          style={{
            opacity: this.props.show ? '1' : '0',
          }}
          /* This onClick method won't propagate the click thus it won't "reach" the background and won't close the page */
          onClick={(e) => { e.stopPropagation() }}
        >
          <div className="add-edit-collaborator-header">
            <span onClick={this.props.close}>+</span>
          </div>
          <div className="add-edit-collaborator-body">
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnBlur={false}
              enableReinitialize={true}
              validateOnChange={false}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <form className="form-wrapper" onSubmit={props.handleSubmit} autoComplete="off" >
                  <div className="form-div">
                    <TextInput
                      value={props.values.name}
                      onChange={props.handleChange}
                      label={i18next.t('Collaborators.addEditFields.name')}
                      name="name"
                    />
                  </div>

                  {this.props.collaborator === undefined
                    ? (
                      <div className="form-div">
                        <PhoneNumberInput
                          defaultCountry="pt"
                          onBlur={props.handleBlur}
                          value={props.values.phoneNumber}
                          setfield={props.setFieldValue}
                          onChange={props.handleChange}
                          label={i18next.t('Collaborators.addEditFields.phoneNumber')}
                          name="phoneNumber"
                        />
                        {/* We only want to display the error if the validation schema has no errors related to this field. */}
                        { !props.errors.phoneNumber && this.state.phoneErrorMessage !== ''
                          && <div className="error">{this.state.phoneErrorMessage}</div>
                        }
                      </div>
                    )
                    : (
                      <div className="form-div">
                        <StaticTextInput
                          value={props.values.phoneNumber}
                          label={i18next.t('Collaborators.addEditFields.phoneNumber')}
                        />
                      </div>
                    )}

                  {this.props.collaborator === undefined
                    ? (
                      <div className="form-div">
                        <TextInput
                          value={props.values.handle}
                          onChange={props.handleChange}
                          label={i18next.t('Collaborators.addEditFields.handle')}
                          name="handle"
                        />
                        {/* We only want to display the error if the validation schema has no errors related to this field. */}
                        { !props.errors.handle && this.state.handleErrorMessage !== ''
                          && <div className="error">{this.state.handleErrorMessage}</div>
                        }
                      </div>
                    )
                    : (
                      <div className="form-div">
                        <StaticTextInput
                          value={props.values.handle}
                          label={i18next.t('Collaborators.addEditFields.handle')}
                        />
                      </div>
                    )}

                  <div className="form-div">
                    <TextInput
                      value={props.values.activity}
                      onChange={props.handleChange}
                      label={i18next.t('Collaborators.addEditFields.activity')}
                      name="activity"
                    />
                  </div>

                  {this.props.collaborator === undefined
                    ? (
                      <div className="form-div" >
                        <PasswordInput
                          value={props.values.password}
                          onChange={props.handleChange}
                          label={i18next.t('Collaborators.addEditFields.password')}
                          name="password" />
                      </div>
                    )
                    : null }
                  {this.props.collaborator === undefined
                    ? (
                      <div className="form-div">
                        <PasswordInput
                          value={props.values.confirmPassword}
                          onChange={props.handleChange}
                          label={i18next.t('Collaborators.addEditFields.confirmPassword')}
                          name="confirmPassword" />
                      </div>
                    )
                    : null }
                  <AddEditButton item={this.props.collaborator} type="collaborator" disable={props.isSubmitting}/>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(AddEditCollaborator)
