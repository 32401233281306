import React, { Component } from 'react'
import { PopupProps } from '../../types/popup' /* Importing the interface that allow the use of props */
import { AddEditStreakProps, AddEditStreakFormValues } from '../../types/create-edit-event'  // Imports FormValues props
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { FieldArray, Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import { TextInput, SelectInput } from '../utils/FormFields'  // Form fields
import AddEditButton from '../AddEditButton'
import Popup from '../Popup'

import '../../styles/components/create-event/add-edit-streak.css'

/**
 * Creates the popup component that allows the user to add or edit a streak. Props are explained on the @ type
 * file.
 */
class AddEditStreak extends Component<PopupProps & AddEditStreakProps, {error?: string}> {
  /**
   * AddEditStreak class constructor.
   *
   * @param props all the required props
   */
  constructor(props: PopupProps & AddEditStreakProps) {
    super(props)
    this.state = {
      error: '',
    }
  }

  /* Holds a blank streak. Used when we create a new streak */
  private blankStreak = {
    name: '',
    consumablePair: [{
      consumable: '',
      amount: '',
    }],
    consumablePrize: '',
    amountPrize: '',
    prizePrice: '',
  } as AddEditStreakFormValues

  /* Holds our form's initial values */
  private initialValues = this.props.streak ? this.props.streak : this.blankStreak

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    name: Yup.string().required(i18next.t('AddEventErrors.addStreaks.name.required')),

    consumablePair: Yup.array().required()
      .of(Yup.object().shape({
        consumable: Yup.string().required(i18next.t('AddEventErrors.addStreaks.consumable.required')),

        amount: Yup.number().required(i18next.t('AddEventErrors.addStreaks.numberConsumables.required'))
          .min(1, i18next.t('AddEventErrors.addStreaks.numberConsumables.min')),
      })),

    consumablePrize: Yup.string().required(i18next.t('AddEventErrors.addStreaks.consumable.required')),

    amountPrize: Yup.number().required(i18next.t('AddEventErrors.addStreaks.numberConsumables.required'))
      .min(1, i18next.t('AddEventErrors.addStreaks.numberConsumables.min')),

    prizePrice: Yup.number().required(i18next.t('AddEventErrors.addConsumables.specialPrice.required'))
      .min(0, i18next.t('AddEventErrors.addConsumables.specialPrice.min')),
  })

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   * @param resetForm function called to reset form input fields
   */
  private handleSubmit = (values: AddEditStreakFormValues, { setSubmitting, resetForm }:
    FormikHelpers<AddEditStreakFormValues>): void => {
    const streakSize = values.consumablePair
      .reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount, 10), 0)

    if (streakSize > 12) {
      this.setState({ error: i18next.t('AddEventErrors.addStreaks.maxAmount') })

      /* Enables submit button again */
      setSubmitting(false)
    } else {
      this.setState({ error: '' })

      /* Creates info input object */
      const input: AddEditStreakFormValues = {
        ...values,
        __typename: 'Streak',
      }

      /* Enables submit button again */
      setSubmitting(false)

      /* Closes popup */
      this.props.close()

      /* Sends info to parent component and tells it to render next phase */
      this.props.updateInfo(input)

      /* Sets new values only if we are updating a streak */
      if (this.props.streak) this.initialValues = values

      /* Resets form after submitting */
      resetForm()
    }
  }

  /**
   * Get the names from the received Consumables
   */
  private getConsumablesNames(): string[] {
    return this.props.consumablesList.map((consumable) => consumable.name)
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    const isEventLive = this.props.eventSpan![0] < (new Date()).getTime()

    return (
      <Popup show={this.props.show} close={this.props.close}>
        <div className="add-edit-streak-body-main">
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            validateOnBlur={false}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={this.handleSubmit}
          >
            {(props) => (
              <form className="form-wrapper" onSubmit={props.handleSubmit} autoComplete="off">
                <TextInput
                  value={props.values.name}
                  onChange={props.handleChange}
                  label={i18next.t('AddEvent.addStreaks.streakNameMandatory')}
                  name="name"
                />
                <FieldArray name="consumablePair" render={
                  (arrayHelpers) => (
                    <div >
                      <div className="consumables-title-wrapper">
                        <h2 className="title-text">{i18next.t('AddEvent.addStreaks.consumables')}</h2>
                        <div className="consumables-buttons">
                          {/* Only allow to add pairs if it's not before the event */}
                          {!isEventLive
                              && <button className="add-extra" id="small" type="button"
                                onClick={() => arrayHelpers.push({
                                  consumable: '',
                                  amount: '',
                                })}>
                                +
                              </button>
                          }
                          {props.values.consumablePair.length > 1
                          && !isEventLive
                          && <button className="add-extra" id="small" type="button" onClick={() => arrayHelpers.pop()}>
                              -
                          </button> }
                        </div>
                      </div>
                      {props.values.consumablePair.map((
                        pair: {consumable: string, amount: string},
                        index: number,
                      ) => (
                        <div className="consumable-line" key={index}>
                          <SelectInput
                            value={pair.consumable}
                            onChange={props.handleChange}
                            label={i18next.t('AddEvent.addStreaks.consumableMandatory')}
                            name= {`consumablePair.${index}.consumable`}
                            setfield={props.setFieldValue}
                            onBlur={props.handleBlur}
                            options={this.getConsumablesNames()}
                            disabled={isEventLive}
                          />
                          <TextInput
                            value={pair.amount}
                            onChange={props.handleChange}
                            label={i18next.t('AddEvent.addStreaks.numberConsumablesMandatory')}
                            name={`consumablePair.${index}.amount`}
                            disabled={isEventLive}
                          />
                        </div>
                      ))}
                    </div>
                  )
                } />

                <div className="consumables-title-wrapper">
                  <h2 className="title-text">{i18next.t('AddEvent.addStreaks.prize')}</h2>
                </div>
                <div className="consumable-line" id="prize-line">
                  <SelectInput
                    value={props.values.consumablePrize}
                    onChange={props.handleChange}
                    label={i18next.t('AddEvent.addStreaks.consumableMandatory')}
                    name="consumablePrize"
                    setfield={props.setFieldValue}
                    onBlur={props.handleBlur}
                    options={this.getConsumablesNames()}
                    disabled={isEventLive}
                  />

                  <TextInput
                    value={props.values.amountPrize}
                    onChange={props.handleChange}
                    label={i18next.t('AddEvent.addStreaks.amountMandatory')}
                    name="amountPrize"
                    disabled={isEventLive}
                  />

                  <TextInput
                    value={props.values.prizePrice}
                    onChange={props.handleChange}
                    label={i18next.t('AddEvent.addStreaks.specialPriceWithCurrency')}
                    name="prizePrice"
                    disabled={isEventLive}
                  />
                </div>

                <div>
                  <h4 className="error-text">{this.state.error ? this.state.error : '' }</h4>
                  <AddEditButton item={this.props.streak} type="streak" disable={props.isSubmitting}/>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Popup>
    )
  }
}

export default withTranslation()(AddEditStreak)
