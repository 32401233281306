import React, { useEffect, useState } from 'react'
import '../../styles/components/confirm-ticket.css'
import { Modal } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { Ticket, Person, CheckoutMBwayResponse } from '../../API'
import User from '../../models/classes/Cognito-user'
import { dateGenerator } from '../../helper/Dates'
import { checkoutMBway } from '../../models/consumable'
import { CardPayment } from './CardPayment'
import { getsPersonInfo } from '../../models/person'
import { withTranslation } from 'react-i18next'
import { checkIfUserIsMember } from '../../models/company'
import {
  amountAdjustCard,
  amountAdjustMBway,
  practicedFeeCalculator,
  practicedPriceCalculator
} from '../../helper/Prices'
import exitButton from '../../images/exit-image.png'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/semantic-ui.css'
import { updatePersonAddress } from '../../models/person'
import { useHistory } from 'react-router-dom'
import { doPaymentMBWay } from '../../models/consumable'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-unused-vars
import validator from 'validator'
import { listEventTickets } from '../../models/ticket'
import PaymentButton from '../PaymentButton'
import AuthButton from '../AuthButton'

interface ConfirmTicketProps {
    ticket: Ticket,
    ticketFee: number | undefined
    person: Person | null
    user: User,
    companyID: string | undefined
}
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum BalanceModalPages {
  CHOOSE_PAYMENT_METHOD,
  ADD_ADDRESS,
  CARD_PAYMENT,
  MBWAY_PAYMENT,
  PAYMENT_OFF,
  NOT_LOGGED_IN,
  INVALID_ACCOUNT
}

export function ConfirmTicket(props: ConfirmTicketProps) {
  const [page, setPage] = useState(BalanceModalPages.CHOOSE_PAYMENT_METHOD)
  const [mbWayLoading, setMBWayLoading] = useState(false)
  const [cardLoading, setCardLoading] = useState(false)
  const [person, setPerson] = useState<Person>({} as any)
  const [formContext, setFormContext] = useState<CheckoutMBwayResponse>()
  const [isMember, setIsMember] = useState(false)
  const [practicedPrice, setPracticedPrice] = useState<number>(0)
  const [practicedFee, setPracticedFee] = useState<number>(0)
  const [email, setEmail] = useState<string>('')
  const [street, setStreet] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [dialCode, setDialCode] = useState<string>('')

  const history = useHistory()

  const { ticket } = props
  let { ticketFee } = props
  const { companyID } = props
  if (ticketFee === undefined) {
    ticketFee = 0
  }
  const [paymentModal, setPaymentModal] = useState(false)

  /**
   * Hook that runs when the component mounts, used for fetching data and check if the user is logged in
   */
  useEffect(() => {
    async function getInfo() {
      const useraux = await Auth.currentUserInfo()
      let personaux = null
      let member = false
      if (useraux !== undefined) {
        personaux = await getsPersonInfo({ id: useraux.attributes.sub })
        member = await checkIfUserIsMember(personaux?.phone ?? '', ticket.owner as string)
        setPerson(personaux)
      } else {
        setPage(BalanceModalPages.NOT_LOGGED_IN)
      }
      // eslint-disable-next-line require-await
      const getPracticedPrice = async () => (
        ticket ? practicedPriceCalculator(ticket.prices, member) : 0
      )
      getPracticedPrice().then((price) => {
        setPracticedPrice(price)
        setPracticedFee(practicedFeeCalculator(price, ticketFee as number))
      })
    }

    getInfo()
  }, [])

  /*
  /!**
   * Hook that runs when the ticket object is updated, gets the ticket price and the fee
   *!/
  useEffect(() => {
    let member = false
    console.log(person)
    async function getInfo() {
      member = await checkIfUserIsMember(person?.phone ?? '', ticket.owner as string)
    }
    getInfo()
    // eslint-disable-next-line require-await
    const getPracticedPrice = async () => (
      ticket ? practicedPriceCalculator(ticket.prices, member) : 0
    )

    getPracticedPrice().then((price) => {
      setPracticedPrice(price)
      setPracticedFee(practicedFeeCalculator(price, ticketFee as number))
    })
  }, [ticket])
*/

  /**
   *  Use effect runs when the person state is updated, checks if the user is valid
   */
  useEffect(() => {
    if (person?.wallet === undefined) {
      /* setPage(BalanceModalPages.INVALID_ACCOUNT) */
    }
  }, [person])

  /**
   * Function that cleans up when the component is closed
   */
  function close() {
    setEmail('')
    setStreet('')
    setZipCode('')
    setCity('')
    setCountry('')
    setPaymentModal(false)
    setFormContext(undefined)
    if (page !== BalanceModalPages.NOT_LOGGED_IN) {
      setPage(BalanceModalPages.CHOOSE_PAYMENT_METHOD)
    }
  }

  /**
   * Function that handles the MBway Payments logic, checks if the payments are active and redirects to the right page
   */
  const handleMBWay = async () => {
    if (!mbWayLoading) {
      setMBWayLoading(true)
      console.log(practicedPrice, practicedFee, person.id)
      const response = await checkoutMBway({ personID: person?.id ?? '', amount: amountAdjustMBway(Number(practicedPrice) + Number(practicedFee)), method: 'MBWAY' })
      if (response.transactionID === null && response.amount === null && response.formContext === null) {
        setPage(BalanceModalPages.PAYMENT_OFF)
        setMBWayLoading(false)
      } else {
        setFormContext(response)
        setPage(BalanceModalPages.MBWAY_PAYMENT)
        setMBWayLoading(false)
      }
    }
  }

  /**
   * Function that redirects the user to the right interface depending on if he already has given us his address or not
   */
  const handleCard = async () => {
    if (cardLoading) return
    /* If the user already has given us his address we go directly to the loading page otherwise we go to page 2
    to ask for the address */
    if (!(person?.address === null || person?.address === undefined)) {
      setCardLoading(true)
      const auxAmount = amountAdjustCard(Number(practicedPrice) + Number(practicedFee)) as unknown as number
      const response = await checkoutMBway({ personID: person?.id, amount: auxAmount, method: 'CARD' })
      if (response.transactionID === null && response.amount === null && response.formContext === null) {
        setPage(BalanceModalPages.PAYMENT_OFF)
        setCardLoading(false)
      } else {
        setFormContext(response)
        setPage(BalanceModalPages.CARD_PAYMENT)
        setCardLoading(false)
      }
    } else {
      setPage(BalanceModalPages.ADD_ADDRESS)
    }
  }

  const selectCreditCard = async () => {
    if (street !== '' && zipCode !== '' && city !== '' && country !== '') {
      const address = { street, zipCode, city, country }
      if (!cardLoading) {
        setCardLoading(true)
        await updatePersonAddress(address, person?.id ?? '', email)
        const auxAmount = amountAdjustCard(Number(practicedPrice) + Number(practicedFee)) as unknown as number
        const response = await checkoutMBway({ personID: person?.id, amount: auxAmount, method: 'CARD' })
        if (response.transactionID === null && response.amount === null && response.formContext === null) {
          setPage(BalanceModalPages.PAYMENT_OFF)
          setCardLoading(false)
        } else if (await verifyTicket(ticket.eventID, ticket.id)) {
          history.push(`/event/${ticket.eventID}`)
        } else {
          setStreet('')
          setZipCode('')
          setCity('')
          setCountry('')
          setEmail('')
          setFormContext(response)
          setPage(BalanceModalPages.CARD_PAYMENT)
          setCardLoading(false)
        }
      }
    }
  }

  const selectMBway = async () => {
    if (!phone) {
      // setError(currentDictionary.Register.errors.phoneNumberInvalid)
      return
    }
    if (!formContext?.transactionID || !setFormContext) return
    setMBWayLoading(true)
    const check = await verifyTicket(ticket.eventID, ticket.id)
    if (check) {
      window.location.reload()
      close()
    } else {
      await doPaymentMBWay({
        phone: phone.slice(dialCode.length),
        transactionID: formContext.transactionID,
      })
      history.push(`/payment-feedback/${formContext?.transactionID}?ticketID=${ticket?.id}&eventID=${ticket?.eventID}`)
    }
  }

  function ticketAvailability(stock?: number | null) {
    if (stock !== undefined && stock !== null) {
      return stock > 0
    }
    return true
  }

  function handlePhone(phone: string, data: CountryData | {}) {
    setPhone(phone)
    if ('dialCode' in data) {
      setDialCode(data?.dialCode)
    }
  }

  const verifyTicket = async (eventID: string, ticketID: string) => {
    const tickets = await listEventTickets({ eventID: eventID })
    const ticket = tickets.find((auxTicket) => { return auxTicket.id === ticketID })
    return !ticketAvailability(ticket?.stock)
  }

  const PurchaseActionView = () => {
    if (ticketAvailability(ticket?.stock)) {
      return (
        <div className="buy-button-container">
          <button className="buy-button" onClick={() => {
            window.scrollTo(0, 100)
            setPaymentModal(!paymentModal)
          }
          }>{i18next.t('ConfirmTicket.info.buy')}</button>
        </div>)
    }
    return (
      <div className="buy-button-container">
        <button className="sold-out-button">Esgotado</button>
      </div>
    )
  }

  return (
    <div className="confirm-ticket-background">
      <div className="confirm-ticket-info-wrapper">
        <h1>{ticket.name}</h1>
        <p className="confirm-ticket-dates">{dateGenerator(ticket.validDates, true, true, true, 'en')}</p>
      </div>
      <div className="confirm-ticket-section-wrapper">
        <div className="confirm-ticket-price">
          <div className="ticket-price">
            <p>{i18next.t('ConfirmTicket.info.price')}</p>
            <h1>{practicedPrice}€</h1>
          </div>
          <div className="ticket-price">
            <p style={{ textAlign: 'right' }}>{i18next.t('ConfirmTicket.info.tax')}</p>
            <h1>+ {practicedFee}€</h1>
          </div>
        </div>
        {/* TODO: ADD FOR PROD <PurchaseActionView></PurchaseActionView> */}
      </div>
      <Modal open={paymentModal} onClose={() => setPaymentModal(false)}>
        <div className="payment-modal">
          <div className="payment-modal-header" />
          <div className={page === BalanceModalPages.CARD_PAYMENT ? 'payment-modal-cardInfo' : 'payment-modal-info'}>
            <div className="exit-button">
              <img src={exitButton} alt="exit" onClick={() => close()} />
            </div>
            {page === BalanceModalPages.CHOOSE_PAYMENT_METHOD
              && <>
                <div className="buy-div">
                  <h1>{i18next.t('ConfirmTicket.info.buy')}</h1>
                </div>
                <div className="ticket-price-wrapper">
                  <p>{ticket.name}</p>
                  <h2>{practicedPrice}€</h2>
                </div>
                <div className="ticket-price-wrapper">
                  <p>{i18next.t('ConfirmTicket.info.transaction_tax')}</p>
                  <h2>{practicedFee}€</h2>
                </div>
                <div className="total-price-wrapper">
                  <p>{i18next.t('ConfirmTicket.info.total')}</p>
                  <h2>{(Number(practicedPrice) + Number(practicedFee)).toFixed(2)}€</h2>
                </div>
                <div className="payment-method-wrapper">
                  <p>{i18next.t('ConfirmTicket.info.payment_method')}</p>
                  <PaymentButton text={'Card'} disable={cardLoading} function={handleCard}/>
                  <PaymentButton text={'MBway'} disable={mbWayLoading} image={'mbWay'} function={handleMBWay}/>
                </div>
              </>
            }

            {page === BalanceModalPages.CARD_PAYMENT
              && <CardPayment formContext={formContext} ticket={ticket}/>}

            {page === BalanceModalPages.ADD_ADDRESS
              && <div className="address-page-wrapper">

                <h2>Email</h2>
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>

                <h1>Morada de Faturação</h1>
                <p>Cartão de Crédito</p>

                <h2>Rua</h2>
                <input type="text" value={street} onChange={(e) => setStreet(e.target.value)}/>
                <div className="address-page-city-wrapper">
                  <div className="item-wrapper-left">
                    <h2>Código Postal</h2>
                    <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)}/>
                  </div>
                  <div className="item-wrapper-left">
                    <h2>Cidade</h2>
                    <input type="text" value={city} onChange={(e) => setCity(e.target.value)}/>
                  </div>
                </div>
                <h2>País</h2>
                <input type="text" value={country} onChange={(e) => setCountry(e.target.value)}/>

                {/* <button className="address-page-button" onClick={() => selectCreditCard()}>Confirmar</button>*/}
                <PaymentButton text={'Confimar'} disable={cardLoading} function={selectCreditCard}/>
              </div>
            }

            {page === BalanceModalPages.MBWAY_PAYMENT
              && <div className="mbway-page-wrapper">
                <div className="mbway-page-header">
                  <h1>Valor a carregar</h1>
                  <h1>{Number(practicedPrice) + Number(practicedFee)}€</h1>
                </div>
                <div className="mbway-page-body">
                  <h2>Telemóvel</h2>
                  <PhoneInput
                    inputStyle={ window.innerWidth < 650 ? { height: '8.5vw', borderRadius: '2vw', fontSize: '3.7vw', maxWidth: '100%' } : { height: '2%', fontSize: '3vw', maxWidth: '100%' }}
                    country={'pt'}
                    value={phone}
                    onChange={(phone, data) => { handlePhone(phone, data) }}
                    isValid={(value) => {
                      if (value.match(/12345/)) {
                        return `Invalid value: ${value}`
                      } else if (value.match(/1234/)) {
                        return false
                      }
                      return true
                    }}
                  />
                  {/* <button className="mbway-page-button" onClick={() => selectMBway()}>Confirmar</button> */}
                </div>
                <PaymentButton text={i18next.t('ConfirmTicket.info.confirm')} disable={mbWayLoading} function={selectMBway}/>
              </div>
            }

            {page === BalanceModalPages.PAYMENT_OFF
              && <div className="modal-error-info">
                <h1>{i18next.t('ConfirmTicket.error.no_payments')}</h1>
                <button className="mbway-page-button" onClick={() => close()}>Voltar</button>
              </div>}

            {page === BalanceModalPages.INVALID_ACCOUNT
              && <div className="modal-error-info">
                <h1>{i18next.t('ConfirmTicket.error.invalid_account')}</h1>
                <button className="mbway-page-button" onClick={() => close()}>Voltar</button>
              </div>}

            {page === BalanceModalPages.NOT_LOGGED_IN
             && <div>
               <div className="modal-error-info">
                 <h1>{i18next.t('ConfirmTicket.errors.no_login')}</h1>
                 <button className="mbway-page-button" onClick={() => history.push('/login')}>Login</button>
               </div>
             </div>
            }

          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withTranslation()(ConfirmTicket)
