import React, { Component } from 'react'
import { GlobalProps } from '../../../types/global'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import AddEditConsumable from '../../../components/events/AddEditConsumable'
import ConsumableCard from '../../../components/events/ConsumableCard'
import ProgressBar from '../../../components/ProgressBar' // Import of the bar that shows the progress in creating event
import ConsumableCategoryBar from '../../../components/ConsumableCategoryBar'
import { EventConsumablesState, AddEditConsumableFormValues, SubPagesProps } from '../../../types/create-edit-event'
import { consumableCategoryMapping, nextPage } from '../util/types'

import '../../../styles/views/create-event/add-consumables.css'

/**
 * In this page the user will add the info related to this event's consumables.
 */
class AddConsumables extends Component<GlobalProps & SubPagesProps, EventConsumablesState> {
  /* Holds our page's initial values */
  private initialValues = this.props.initValues as EventConsumablesState

  /**
   * AddConsumables class constructor.
   *
   * @param props all the needed props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      ...this.initialValues,
      showAddConsumablePopup: false,
      category: -1,
    }
  }

  /**
   * Associates a new consumable to the event.
   *
   * @param input inputs needed to create a new consumable
   */
  private addNewConsumable = (input: AddEditConsumableFormValues): void => {
    this.setState({ each: [
      ...this.state.each,
      input,
    ] })
  }

  /**
   * Handles submission of all the newly added tickets.
   */
  private handleSubmit(nextPageParam: number): void {
    /* Creates info input object */
    const input: object = {
      each: [
        ...this.state.each,
      ],
      showAddConsumablePopup: false,
      category: '',
      editCategory: this.state.editCategory,
      nextPageParam,
    }

    /* Sends info to parent component and tells it to render next phase */
    this.props.submitInfo(input, nextPageParam)
  }

  /**
   * Changes displayed category.
   *
   * @param event clicking of a button
   * @param category new highlighted category
   */
  private changeCategory = (event: React.MouseEvent, category: number): void => {
    this.setState({ category })
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      <div className="add-consumables-total">
        <div className="add-consumables-title">
          <h2>{i18next.t('AddEvent.addConsumables.title')}</h2>
        </div>
        <AddEditConsumable
          eventSpan={this.props.eventSpan!}
          auth={this.props.auth}
          updateInfo={this.addNewConsumable}
          show={this.state.showAddConsumablePopup}
          close={() => this.setState({ showAddConsumablePopup: false })}
          editCategory={true}
          hasOriginalLargeVersion={false}
        />
        <button id="create-consumable" className="top-button" onClick={() => {
          this.setState({ showAddConsumablePopup: true })
        }}>
          <h4>{i18next.t('AddEvent.addConsumables.createConsumable')}</h4>
          <div className="add-button">+</div>
        </button>
        <div className="add-consumables-main">
          <ConsumableCategoryBar changeCategory={this.changeCategory}/>
          { this.state.each.length !== 0
            ? <div className="add-consumables-main-cards">
              {this.state.each.map((consumable: AddEditConsumableFormValues, index: number) => {
                if (this.state.category === -1
                  || consumableCategoryMapping[this.state.category].includes(consumable.type)) {
                  return (
                    <ConsumableCard
                      eventSpan={this.props.eventSpan!}
                      auth={this.props.auth}
                      key={index}
                      consumable={consumable}
                      hasOriginalLargeVersion={consumable.id === undefined
                        ? false
                        : this.props.originalConsumableHasLargeVersion(consumable.id)!}
                      setConsumable={(input: AddEditConsumableFormValues) => {
                        const newEach = this.state.each
                        newEach[index] = input
                        this.setState({ each: newEach })
                      }}
                      mode = {this.props.mode!}
                      deleteConsumable={() => {
                        this.state.each.splice(index, 1)
                        this.setState({ each: this.state.each })
                      }}
                      editCategory={this.state.editCategory}
                    />
                  )
                }
                return null
              })}
            </div>
            : <div className="add-tickets-no-consumable-wrapper">
              <h3>{i18next.t('AddEvent.addConsumables.noConsumables')}</h3>
            </div>}
        </div>
        <div className="add-consumables-bottom">
          <ProgressBar filled={3} total={5}/>
          <div className="navigation-buttons-total">
            <button id="previous" className="navigation-button" onClick={() => this.handleSubmit(nextPage.PREVIOUS)}>
              {i18next.t('AddEvent.navigationButtons.previous')}
            </button>
            <button className="navigation-button" onClick={() => this.handleSubmit(nextPage.NEXT)}>
              {i18next.t('AddEvent.navigationButtons.next')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(AddConsumables)
