import React, { Component } from 'react'
import { PopupProps } from '../types/popup' /* Importing the interface that allow the use of props */

import '../styles/components/popup.css'

/**
 * Creates the popup component that allows the user to confirm the refund
 */
class Popup extends Component<PopupProps> {
  private mainDiv

  constructor(props: PopupProps) {
    super(props)
    this.mainDiv = React.createRef<HTMLInputElement>()
  }

  public closePage() {
    if (this.mainDiv.current) {
      this.mainDiv.current.scrollTop = 0
    }
    this.props.close()
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      /* This div is used to make the background more opaque. */
      <div onClick={this.closePage} className="background" style={{
        display: this.props.show ? 'flex' : 'none',
      }}>
        {/* This div is the actual popup */}
        <div className="popup-wrapper"
          style={{ opacity: this.props.show ? '1' : '0' }}
          /* This onClick method won't propagate the click thus it won't "reach" the background and won't close the page */
          onClick={(e) => { e.stopPropagation() }}
        >
          <div className="popup-header">
            <span onClick={this.props.close}>+</span>
          </div>
          <div className="popup-body">
            <div className="popup-body-main" ref={this.mainDiv}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default (Popup)
