import * as Types from '../API'  // Imports types from our AWS Backend API
import { GraphQLResult } from '@aws-amplify/api'  // Used to type results from our API
import { API } from 'aws-amplify'
import {
  createTicket as createTicketMutation,
  ticketPurchase,
  hasTicket,
  doRefundTicket,
  deleteTicket as deleteTicketMutation,
  updateTicket as updateTicketMutation,
} from '../graphql/mutations'

/**
 * Gets all the tickets for an event.
 *
 * @param input inputs used to gather all the tickets for an event
 *
 * @return objects or error
 */
export async function listEventTickets(input: Types.TicketsByEventQueryVariables): Promise<Types.Ticket[]> {
  try {
    const query = `
    query TicketsByEvent(
      $eventID: ID!
      $sortDirection: ModelSortDirection
      $filter: ModelTicketFilterInput
      $limit: Int
      $nextToken: String
    ) {
      ticketsByEvent(
        eventID: $eventID
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
    `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.TicketsByEventQuery>

    /* Transforms gathered data into an array of object with this properties. If not found, we return an empty list */
    return (response.data?.ticketsByEvent?.items as Types.Ticket[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the tickets for an event: ', error)

    let message = 'Failed to gather event tickets'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Creates a ticket for an event.
 *
 * @param input inputs used to create a new ticket
 *
 * @return object or error
 */
export async function createEventTicket(input: Types.CreateTicketInput): Promise<Types.Ticket> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createTicketMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateTicketMutation>

    /* Returns created event */
    return (response.data?.createTicket) as Types.Ticket

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new ticket in the database: ', error)

    let message = 'Failed to create ticket'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Receives a list of info used to refund tickets to a set of users.
 *
 * @param input lambda function input
 *
 * @return boolean response or error
 */
export async function refundTickets(input: Types.RefundTicketsInput): Promise<boolean> {
  const variables: Types.DoRefundTicketMutationVariables = {
    input: input,
  }

  const response = (await API.graphql({
    query: doRefundTicket,
    variables: variables,
  })) as GraphQLResult<Types.DoRefundTicketMutation>

  if (response.data?.doRefundTicket?.status) {
    return true
  }

  /* Since something happened, we have to throw an error */
  console.error('Error while refunding tickets ', response.data?.doRefundTicket?.error || '')
  return false
}

/**
 * Updates an already existing ticket in our database.
 *
 * @param input inputs used to update a ticket
 *
 * @return object or error
 */
export async function updateTicket(input: Types.UpdateTicketInput): Promise<Types.Ticket> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updateTicketMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdateTicketMutation>

    /* Returns created event */
    return (response.data?.updateTicket) as Types.Ticket

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while updating a ticket in the database: ', error)

    let message = 'Failed to update ticket'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Removes a ticket from our database. A ticket can only be removed by the company that created it.
 *
 * @param input inputs used to delete a ticket
 *
 * @return object or error
 */
export async function deleteTicket(input: Types.DeleteTicketInput): Promise<Types.Ticket> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteTicketMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteTicketMutation>

    /* Returns created event */
    return (response.data?.deleteTicket) as Types.Ticket

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a ticket from the database: ', error)

    let message = 'Failed to delete a ticket'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Buys a ticket for the current user.
 *
 * @param input inputs used to purchase a ticket.
 *
 * @return boolean or error
 */
export async function purchaseTicket(input: Types.TicketPurchaseInput): Promise<object> {
  try {
    /* Creates our graphql input object */
    const inputObject = {
      query: ticketPurchase,
      variables: { input: input },
    }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.TicketPurchaseMutation>

    return {
      status: response.data?.ticketPurchase!.status === Types.Status.SUCCESS,
      message: '',
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while purchasing a ticket in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    return {
      status: false,

      message: error.errors[0].message,
    }
  }
}

/**
 * Reads a ticket from a user for an event.
 *
 * @param input inputs used to scan a ticket
 *
 * @return boolean or error
 */
export async function scanTicket(input: Types.HasTicketInput): Promise<object> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: hasTicket, variables: { input: { personID: input.personID, eventID: input.eventID } } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.HasTicketMutation>

    return {
      status: response.data?.hasTicket!.status === Types.Status.SUCCESS,
      message: '',
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while scanning a ticket in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    return {
      status: false,

      message: error.errors[0].message,
    }
  }
}
