import { CognitoUser } from '../../types/auth'
import groups from '../../constants/cognito-groups.json'

class User {
  user: CognitoUser | undefined

  constructor(user: CognitoUser | undefined) {
    this.user = user
  }

  getEmail(): string {
    return this.isLoggedIn() && this.user ? this.user.attributes.email : ''
  }

  getUsername(): string {
    return this.isLoggedIn() && this.user ? this.user.username : ''
  }

  getId(): string {
    return this.isLoggedIn() && this.user ? this.user.id : ''
  }

  getName(): string {
    return this.isLoggedIn() && this.user ? this.user.attributes.name : ''
  }

  getPhone(): string {
    return this.isLoggedIn() && this.user ? this.user.attributes.phone_number : ''
  }

  getGroup(): string {
    return this.isLoggedIn() && this.user ? this.user.attributes['custom:cognitogroup'] : ''
  }

  getSub(): string {
    return this.isLoggedIn() && this.user ? this.user.attributes.sub : ''
  }

  isPerson(): boolean {
    return this.getGroup() === groups.PERSON
  }

  isCollaborator(): boolean {
    return this.getGroup() === groups.COLLABORATOR
  }

  isCompany(): boolean {
    return this.getGroup() === groups.COMPANY
  }

  isLoggedIn(): boolean {
    return this.user !== undefined
  }
}

export default User
