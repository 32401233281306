/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons' /* Import needed to get the desired font elements */

import SelectLanguage from '../SelectLanguage' // Corresponds to the popup that allows the user to change language

import logoClear from '../../images/logoClear.png' // Our logo image

import '../../styles/components/landing-page/footer.css'

/**
 * Creates the footer of the Landing Page
 */
class Footer extends Component<{}, { show: boolean }> {
  constructor(props: {}) {
    super(props)
    this.state = {
      show: false, /* Controls if the select language is being shown or not */
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      /* This div was created because we need to return a single div */
      <div className="footer">
        <div className="div-white-line" />
        {/* This is the pop up that will allow the user to select the language */}
        <SelectLanguage show={this.state.show} close={() => this.setState({ show: false })}/>
        {/* This div was created to use a horizontal/row flex inside */}
        <div className="div-footer-main">
          {/* This div has an id to handle the specifics of containing images instead of text */}
          <div id="logo-and-social" className="footer-category">
            <img src={logoClear} alt="uniparty"/>
            <button className="language-button" type="button" onClick={() => this.setState({ show: true })}>
              {i18next.t('Footer.currentLanguage')}
            </button>
            <div className="div-social">
              <a href="https://www.linkedin.com/company/outgoingapp/" className="social-icon"> {/* TODO add proper href */}
                <FontAwesomeIcon icon={faLinkedin} size="lg"/>
              </a>
              <a href="https://www.instagram.com/outgoing.app/" className="social-icon"> {/* TODO add proper href */}
                <FontAwesomeIcon icon={faInstagram} size="lg"/>
              </a>
            </div>
          </div>
          <div className="footer-category">
            <h3>{i18next.t('Footer.aboutUs.title')}</h3>
            <a href="/under-construction">{i18next.t('Footer.aboutUs.topic1')}</a> {/* TODO add proper href */}
            <a href="/under-construction">{i18next.t('Footer.aboutUs.topic2')}</a> {/* TODO add proper href */}
            <a href="/partners">{i18next.t('Footer.aboutUs.topic3')}</a>
          </div>
          <div className="footer-category">
            <h3>{i18next.t('Footer.legal.title')}</h3>
            <a href="/terms">{i18next.t('Footer.legal.topic1')}</a>
            <a href="/privacy">{i18next.t('Footer.legal.topic2')}</a>
            <a href="https://livroreclamacoes.pt/Inicio/">{i18next.t('Footer.legal.topic3')}</a>
          </div>
        </div>
        <p className="text-copyright">{i18next.t('Footer.textCopyright')}</p>
      </div>
    )
  }
}

export default withTranslation()(Footer)
