import { Storage, API } from 'aws-amplify'
import { v4 as uuid } from 'uuid'
import { GraphQLResult } from '@aws-amplify/api' // We need to use an uuid as to not override existing files inside S3 bucket
import * as Types from '../API'
import {
  createImage as createImageMutation,
  deleteImage as deleteImageMutation,
} from '../graphql/mutations'
import User from './classes/Cognito-user'

/**
 * Represents our output from functions.
 */
export interface Image {
  key: string
  object: Types.Image
}

/**
 * Creates an image inside our database.
 *
 * @param input inputs used to create a new image
 *
 * @return object or error
 */
async function _createImage(input: Types.CreateImageInput): Promise<Types.Image> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createImageMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateImageMutation>

    /* Returns created event */
    return (response.data?.createImage) as Types.Image

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new image in the database: ', error)

    let message = 'Failed to create image'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Removes an image from our database.
 *
 * @param input inputs used to delete an image
 *
 * @return object or error
 */
async function _deleteImage(input: Types.DeleteImageInput): Promise<Types.Image> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteImageMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteImageMutation>

    /* Returns created event */
    return (response.data?.deleteImage) as Types.Image

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting an image from the database: ', error)

    let message = 'Failed to delete image'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Uploads an input image to the AWS S3 bucket.
 *
 * @param file path to file
 * @param user user that is uploading the image
 * @param eventID event id associated with this image
 *
 * @return image's key or null in case of error
 */
export async function uploadImage(file: File, user: User, eventID?: string): Promise<Image> {
  try {
    /* Creates a unique filename for our image so that we dont override images. Also normalizes non ascii chars */
    const filename = `${uuid()}-${file.name.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '1')}`

    /* Uploads image to our S3 bucket. Uses a protected visibility because anyone can see other images but they can only
     * change their own images */
    const image = await Storage.put(filename, file, {
      level: 'protected',
    }) as {key:string}

    /* Adds our image to the database */
    const imageDB = await _createImage({
      eventID: eventID || null,
      filename,
      identityID: user.getId(),
      owner: user.getSub(),
    })

    /* Returns uploaded image's object */
    return {
      key: image.key,
      object: imageDB,
    } as Image
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error(`Error while uploading ${file} image file to S3 bucket.`, error)

    let message = 'Failed to upload image'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Uploads a set of input images to the AWS S3 bucket.
 *
 * @param files arrays of paths to files
 * @param user user that is uploading the image
 * @param eventID event id associated with this images
 *
 * @return image's array of images keys
 */
export async function uploadSetOfImages(files: Array<File>, user: User, eventID?: string): Promise<Image[]> {
  try {
    /* Creates an array with unique names for each image. Also normalizes non ascii chars */
    const filenames = Array.from(files, (file) => {
      return `${uuid()}-${file.name.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '1')}`
    })

    /* Uploads all the images to our S3 bucket. Uses a protected visibility because anyone can see other images but
     * they can only change their own images */
    const images = await Promise.all(files.map(async (file, index) => {
      return await Storage.put(filenames[index], file, {
        level: 'protected',
      })
    })) as {key:string}[]

    /* Uploads images to our database */
    const imagesDB = await Promise.all(files.map(async (_, index) => {
      return await _createImage({
        eventID: eventID || null,
        filename: filenames[index],
        identityID: user.getId(),
        owner: user.getSub(),
      })
    }))

    /* Returns a set of uploaded images objects */
    return Array.from(images, (image, index) => {
      return {
        key: image.key,
        object: imagesDB[index],
      } as Image
    }) as Image[]
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error(`Error while uploading ${files} images files to S3 bucket.`, error)

    let message = 'Failed to upload images'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Gets an image from the AWS S3 bucket.
 *
 * @param filename path to file in S3
 * @param identityId identity ID of the user which holds the requested image
 *
 * @return image's key or null in case of error
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export async function fetchImage(filename: string, identityId: string): Promise<Object | string | null> {
  try {
    /* Fetches requested image from the S3 bucket */
    return await Storage.get(filename, {
      level: 'protected',
      identityId,
      download: false,
    })
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error(`Error while fetching ${filename} image file from the S3 bucket.`, error)

    let message = 'Failed to fetch image'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Gets a set of images from the database.
 *
 * @param images array of image info to be fetched
 *
 * @return image's object or string file
 */
export async function fetchSetOfImages(images: Array<{filename: string, identityId: string}>):
// eslint-disable-next-line @typescript-eslint/ban-types
Promise<Array<Object | string>> {
  try {
    /* Fetches requested images from the S3 bucket */
    return await Promise.all(images.map(async (image) => {
      return await Storage.get(image.filename, {
        level: 'protected',
        identityId: image.identityId,
        download: false,
      })
    }))
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error(`Error while fetching ${images} images files from the S3 bucket.`, error)

    let message = 'Failed to fetch images'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Removes an input image from the AWS S3 bucket.
 *
 * @param filename path to file
 * @param imageID image id inside our database
 */
export async function removeImage(filename: string, imageID: string): Promise<any> {
  try {
    /* Remove an image from the bucket */
    const result = await Storage.remove(filename, {
      level: 'protected',
    })

    /* Deletes image from our database */
    await _deleteImage({ id: imageID })

    /* Returns deletion object */
    return result
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error(`Error while deleting ${filename} image file from S3 bucket.`, error)

    let message = 'Failed to remove image'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}
