import { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'  // Used for Route management
import Amplify, { Auth } from 'aws-amplify'  // Called to access cognito and persist user login + session token when refreshing
import { ProtectedRoute } from './components/routes/ProtectedRoute'  // Used to protected unauthorized entries
import Groups from './constants/cognito-groups.json'  // Used to standardize cognito groups
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import User from './models/classes/Cognito-user'

import './styles/App.css'

/* Imports pages/components */
import QRCodePageNavbar from './components/QRCodePageNavbar'
import Navbar from './components/Navbar'
import Profile from './views/Profile'
import Collaborators from './views/Collaborators'
import MyEvents from './views/MyEvents'
import Privacy from './views/Privacy'
import Terms from './views/Terms'
import LandingPage from './views/LandingPage'
import Login from './views/auth/Login'
import Welcome from './views/auth/Welcome'
import ForgotPassword from './views/auth/ForgotPassword'
import ForgotPasswordVerification from './views/auth/ForgotPasswordVerification'
import ChangePasswordConfirmation from './views/auth/ChangePasswordConfirm'
import ChangePassword from './views/auth/ChangePassword'
import UnderConstruction from './views/misc/UnderConstruction'
import NoMatch, { NoMatchRedirect } from './views/misc/NoMatch'
import NoPermission from './views/misc/NoPermission'
import RegisterCompany from './views/auth/RegisterCompany'
import EmailCodeVerification from './views/auth/EmailCodeVerification'
import CreateEvent from './views/events/create/CreateEvent'
import EventStats from './views/event-stats/EventStats'
import EditEvent from './views/events/edit/EditEvent'
import AllEvents from './views/AllEvents'
import EventRefund from './views/EventRefund'
import Partners from './views/Partners'
import RedirectStores from './views/RedirectStores'
import Members from './views/Members'
import Register from './views/auth/Register'
import QRCodePage from './views/QRCodePage'
import EventPage from './views/EventPage'
import PaymentFeedback from './views/PaymentFeedback'

/**
 * Main page. Every component path should be used here and then this function will be used in index.tsx.
 *
 * @return Front-end of our program
 * */
class App extends Component {
  /**
   * Holds global state in our app.
   *
   * @param isAuthenticated is true when our user is signed in and false if not
   * @param isAuthenticating is true while we fetch auth user data upon component mounting and false when finished
   * @param user holds info about user (name, email, permissions, groups, etc...)
   */
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: new User(undefined),
  }

  /**
   * Changes state of our app if user signed it. Can be used by Login component
   *
   * @param auth is true if user signed in successfully and false if he has not done so yet / logged out.
   */
  public setAuthState = (auth: boolean): void => {
    this.setState({ isAuthenticated: auth })
  }

  /**
   * Changes state of our user if user signed in successfully. Can be used by Login component
   *
   * @param user holds info about user.
   */
  public setUserState = (user: User): void => {
    this.setState({ user })
  }

  /**
   * Called after mount component. Persists previously logged-in user after refreshing.
   */
  async componentDidMount(): Promise<void> {
    try {
      /* Loads the session object from local storage (is already put in there by AWS cognito when we sign in).
       * Also, automatically, refreshes token if and when necessary */
      await Auth.currentSession()

      /* Since we have retrieved our token, we can say that our user is logged in */
      this.setAuthState(true)

      /* Gets user info */
      const userHelper = await Auth.currentUserInfo()
      const fetchedUser = new User(userHelper)

      /* After retrieving our user, we save it in App's state */
      this.setUserState(fetchedUser)

      /* Since we found a user, we tell amplify that we want to use cognito to authenticate our user's queries */
      Amplify.configure({ aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS })
    } catch (error) {
      /* FIXME: Suppress first time user's error. Might be a bad thing to do and needs to be changed in the future */
      if (error === 'No current user') {
        /* Since no user was found, he does not have an account, and so we use IAM to grant him permissions */
        Amplify.configure({ aws_appsync_authenticationType: GRAPHQL_AUTH_MODE.AWS_IAM })
        this.setState({ isAuthenticating: false })
        return
      }

      if (error instanceof Error) {
        console.log(error.message)
      } else if (error instanceof String) {
        console.log(error)
      }
    }

    /* Since we have finished fetching user data, we can signal our component to render */
    this.setState({ isAuthenticating: false })
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element | false {
    /* Used to pass state to child components */
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthState: this.setAuthState,
      setUserState: this.setUserState,
    }

    /* Render pages with navbar */
    const DefaultRoutes = () => {
      return (
        <>
          <QRCodePageNavbar auth={authProps}/>
          <Switch>
            <Route exact path="/" render={(props) => <LandingPage {...props} auth={authProps} />} />
            <ProtectedRoute path="/profile" component={Profile} auth={authProps} />
            <ProtectedRoute path="/qrcodepage" component={QRCodePage} auth={authProps} permissions={[Groups.PERSON]} />
            <ProtectedRoute path="/collaborators" component={Collaborators} auth={authProps} permissions={[Groups.COMPANY]} />
            <ProtectedRoute path="/my-events" component={MyEvents} auth={authProps} permissions={[Groups.COMPANY]}/>
            <ProtectedRoute path="/create-event" component={CreateEvent} auth={authProps} permissions={[Groups.COMPANY]} />
            <ProtectedRoute path="/event-stats" component={EventStats} auth={authProps} permissions={[Groups.COMPANY]} />
            <ProtectedRoute path="/event-refund" component={EventRefund} auth={authProps} permissions={[Groups.COMPANY]} />
            <ProtectedRoute path="/edit-event" component={EditEvent} auth={authProps} permissions={[Groups.COMPANY]} />
            <ProtectedRoute path="/members" component={Members} auth={authProps} permissions={[Groups.COMPANY]} />
            <ProtectedRoute path="/payment-feedback/:transactionID" component={PaymentFeedback} auth={authProps} permissions={[Groups.PERSON]} />
            <Route exact path="/all-events" render={(props) => <AllEvents {...props} auth={authProps} />} />
            <Route exact path="/event/:eventId" render={(props) => <EventPage {...props} auth={authProps} />} />
            <Route exact path="*" render={(props) => <NoMatchRedirect {...props} auth={authProps} />} />
          </Switch>
        </>
      )
    }

    return (
      !this.state.isAuthenticating  /* While we are fetching auth user data, we block component rendering */
      && <BrowserRouter>
        <Switch>
          <Route exact path="/terms" render={(props) => <Terms {...props} auth={authProps} />} />
          <Route exact path="/privacy" render={(props) => <Privacy {...props} auth={authProps} />} />
          <Route exact path="/register-company" render={(props) => <RegisterCompany {...props} auth={authProps} />} />
          <Route exact path="/register" render={(props) => <Register {...props} auth={authProps} />} />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore -> Unfortunately, React-Router does not yet support Location state and defaults it to "unknown" so we need to ignore it */}
          <Route exact path="/code-verification/:id" render={(props) => <EmailCodeVerification {...props} auth={authProps} />} />
          <Route exact path="/login" render={(props) => <Login {...props} auth={authProps} />} />
          <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
          <Route exact path="/forgot-password-verification/:id" render={(props) => <ForgotPasswordVerification {...props} auth={authProps} />} />
          <ProtectedRoute path="/change-password" component={ChangePassword} auth={authProps} />
          <ProtectedRoute path="/change-password-confirmation/:id" component={ChangePasswordConfirmation} auth={authProps} />
          <Route exact path="/under-construction" render={(props) => <UnderConstruction {...props} auth={authProps} />} />
          <Route exact path="/no-permission" render={(props) => <NoPermission {...props} auth={authProps} />} />
          <Route exact path="/no-match" render={(props) => <NoMatch {...props} auth={authProps} />} />
          <Route exact path="/welcome" render={(props) => <Welcome {...props} auth={authProps} />} />
          <Route exact path="/partners" render={(props) => <Partners {...props} auth={authProps} />} />
          <Route exact path="/redirect-stores" render={() => <RedirectStores/>} />
          <Route component={DefaultRoutes} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
