import React, { Component } from 'react'
import { Auth } from 'aws-amplify'  // Used to call Cognito and then access it's signUp properties
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { RegisterState } from '../../types/auth'
import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import { CompanyRegisterFormValues } from '../../types/auth'
import * as Yup from 'yup'  // Used to validate our form
import AuthButton from '../../components/AuthButton'  // Used to submit form
import { TextInput, TermsCheckboxInput, PasswordInput } from '../../components/utils/FormFields'  // Form fields
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import CognitoGroups from '../../constants/cognito-groups.json'  /* Used to reference the cognito groups of our app */
import { ibanValidator } from '../../constants/iban-validation'  /* Used to validate iban code inputs */
import { checkIfAccountCreationIsValid } from '../../models/person'

import '../../styles/views/auth/register.css'

/**
 * SignUp section component used to register a new company. Holds a form and all it's validation capabilities.
 */
class RegisterCompany extends Component<RouteComponentProps & GlobalProps, RegisterState> {
  /**
   * RegisterCompany class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      handleErrorMessage: '',
    }
  }

  /* Holds our form's initial values */
  private initialValues = {
    name: '',
    initials: '',
    iban: '',
    email: '',
    handle: '',
    password: '',
    confirmPassword: '',
    authCode: '',
    acceptedTerms: false,
  }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    name: Yup.string().required(i18next.t('Register.errors.name.required'))
      .max(50, i18next.t('Register.errors.name.max'))
      .matches(/^[-'a-zA-Z0-9À-ÖØ-öø-ÿ ]+$/, i18next.t('Register.errors.name.format')),

    initials: Yup.string().required(i18next.t('Register.errors.initials.required'))
      .max(10, i18next.t('Register.errors.initials.max')),

    iban: Yup.string().required(i18next.t('Register.errors.iban.required'))
      .matches(ibanValidator, i18next.t('Register.errors.iban.format')),

    email: Yup.string().required(i18next.t('Register.errors.email.required'))
      .email(i18next.t('Register.errors.email.format')),

    handle: Yup.string().required(i18next.t('Register.errors.handle.required'))
      .min(5, i18next.t('Register.errors.handle.min'))
      .max(30, i18next.t('Register.errors.handle.max'))
      .matches(/^[a-z0-9_.]*$/, i18next.t('Register.errors.handle.format')),

    password: Yup.string().required(i18next.t('Register.errors.password.required'))
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,16}$/,
        i18next.t('Register.errors.password.format'),
      ),

    confirmPassword: Yup.string().required(i18next.t('Register.errors.confirmPassword.required'))
      .oneOf([Yup.ref('password'), null], i18next.t('Register.errors.confirmPassword.match')),

    authCode: Yup.string().required(i18next.t('Register.errors.authCode.required')),

    acceptedTerms: Yup.boolean().required()
      .oneOf([true], i18next.t('Register.errors.acceptedTerms.required')),
  })

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   *
   * @return Returns a void promise since it won't have a specific type
   */
  private handleSubmit = async (values: CompanyRegisterFormValues, { setSubmitting }:
                         FormikHelpers<CompanyRegisterFormValues>): Promise<void> => {
    /* Accesses Cognito and tries to register a new company */
    try {
      const res = await checkIfAccountCreationIsValid({ handle: values.handle })
      if (res === 'handle') {
        this.setState({ handleErrorMessage: i18next.t('Register.errors.handle.alreadyInUse') })
      } else if (res === 'success') {
        this.setState({ handleErrorMessage: '' })
        await Auth.signUp({
          username: values.handle,  /* Since we are using email/phone, we need to setup username as the email -> AWS doc */
          password: values.password,
          attributes: {
            email: values.email,
            name: values.name,
            phone_number: '+351939999999',  // Is just a place holder and holds no value
            'custom:cognitogroup': CognitoGroups.COMPANY,
          },
          clientMetadata: {
            initials: values.initials,
            iban: values.iban,
            handle: values.handle,
          },
          validationData: {
            authCode: values.authCode,
          },
        })

        /* Enables submit button again */
        setSubmitting(false)

        /* Redirects company to the welcome page after everything finishes */
        this.props.history.push('/welcome')
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      } else if (error instanceof String) {
        alert(error)
      } else {
        alert(JSON.stringify((error as any).message, null, 2))
      }

      /* Enables submit button again */
      setSubmitting(false)
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    return (
      <div className="register-background">
        <div className="register-main">
          {/* Button used to go back to landing page */}
          <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
          <div id="register-left-half" className="main-half">
            <h1>{i18next.t('Register.registerCatchphrase')}</h1>
            {/* This div is used to order the button horizontally (flex-direction:row) while the div is ordered
              vertically (flex-direction:column) with the rest of the elements */}
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <TextInput
                    value={props.values.name}
                    onChange={props.handleChange}
                    label={i18next.t('Register.name')}
                    name="name" />
                  <TextInput
                    value={props.values.initials}
                    onChange={props.handleChange}
                    label={i18next.t('Register.initials')}
                    name="initials" />
                  <TextInput
                    value={props.values.iban}
                    onChange={props.handleChange}
                    label={i18next.t('Register.iban')}
                    name="iban" />
                  <TextInput
                    value={props.values.email}
                    onChange={props.handleChange}
                    label={i18next.t('Register.email')}
                    name="email"/>
                  <TextInput
                    value={props.values.handle}
                    onChange={props.handleChange}
                    label={i18next.t('Register.handle')}
                    name="handle"/>
                  {/* We only want to display the error if the validation schema has no errors related to this field. */}
                  { !props.errors.handle && this.state.handleErrorMessage !== ''
                    && <div className="error">{this.state.handleErrorMessage}</div>
                  }
                  <PasswordInput
                    value={props.values.password}
                    onChange={props.handleChange}
                    label={i18next.t('Register.password')}
                    name="password" />
                  <PasswordInput
                    value={props.values.confirmPassword}
                    onChange={props.handleChange}
                    label={i18next.t('Register.confirmPassword')}
                    name="confirmPassword" />
                  <PasswordInput
                    value={props.values.authCode}
                    onChange={props.handleChange}
                    label={i18next.t('Register.code')}
                    name="authCode" />
                  <TermsCheckboxInput
                    name="acceptedTerms"
                    path="/"
                    onChange={props.handleChange}>
                  </TermsCheckboxInput>
                  <div id="register-button-wrapper">
                    <AuthButton text={i18next.t('Register.signUp')} type="submit" disable={props.isSubmitting}/>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div id="register-right-half" className="main-half">
            <h2>{i18next.t('Register.login-option.catchphrase')} </h2>
            <p>{i18next.t('Register.login-option.secondaryText')}</p>
            <a href="/login">
              <AuthButton text={i18next.t('Register.login-option.signIn')} property="alternative-color-scheme"/>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(RegisterCompany)
