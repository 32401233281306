import React, { Component } from 'react'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation */

import StoreButton from './StoreButton' /* Import needed to get the button for each application store  */

import eventsListAndConsumeLeft from '../../images/eventsListAndConsumeLeft.png'
import logoTilted from '../../images/logoTilted.png'

import '../../styles/components/landing-page/banner.css'

/**
 * Creates our main banner on the Landing Page
 */
class Banner extends Component {
  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      <div className="banner">
        <div className="banner-left-wrapper">
          <img src={logoTilted} alt="logo"/>
          <div className="banner-catchphrase-wrapper">
            <h2>{i18next.t('Banner.catchphrase.leftCatchphrase')} <b>{i18next.t('Banner.catchphrase.rightCatchphrase')}</b></h2>
          </div>
          <h4>{i18next.t('Banner.secondaryPhrase')}</h4>

          <div className="store-buttons-wrapper">
            <a href="https://apps.apple.com/pt/app/outgoing/id1585889075">
              <StoreButton store="apple" alternative={false}/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rodrigofernandes.outgoing">
              <StoreButton store="google" alternative={false}/>
            </a>
          </div>
        </div>
        <div className="banner-right-wrapper">
          <img src={eventsListAndConsumeLeft} alt="OutGoing"/>
        </div>

      </div>
    )
  }
}

export default withTranslation()(Banner)
