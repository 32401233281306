import { useEffect } from 'react'

import StoreButton from '../components/landing-page/StoreButton' /* Import needed to get the button for each application store  */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/redirect-stores.css'

const RedirectStores = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    const isIos = (/iphone|ipad|ipod/).test(userAgent)
    const isAndroid = (/android/).test(userAgent)

    if (isIos) {
      window.location.href = 'https://apps.apple.com/en/app/outgoing/id1585889075'
    } else if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.rodrigofernandes.outgoing'
    }
  }, [])

  return (
    <div className="redirect-stores-background">
      <div className="redirect-stores-main">
        <div className="redirect-stores-container">
          <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
          <h1>{i18next.t('RedirectStores.werentRedirected')}</h1>
          <p>{i18next.t('RedirectStores.useTheLinksBelow')}</p>
          <div className="redirect-stores-buttons-wrapper">
            <a href="https://apps.apple.com/pt/app/outgoing/id1585889075">
              <StoreButton store="apple" alternative={false}/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.rodrigofernandes.outgoing">
              <StoreButton store="google" alternative={false}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(RedirectStores)
