import React, { Component } from 'react'

import { EventStatsCardProps } from '../types/my-events'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faEdit } from '@fortawesome/free-regular-svg-icons' /* Import needed to get the desired font elements */
import { faChartPie, faArrowRotateLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import { Link } from 'react-router-dom'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/components/event-stats-card.css'

/**
 * Creates a card that will feature the stats of the event to the company.
 */
class EventStatsCard extends Component<EventStatsCardProps> {
  private editEventLink() {
    if (this.props.event.endDate < new Date().toISOString()) {
      return { }
    }
    return { pathname: '/edit-event', state: { eventID: this.props.event.id! } }
  }

  private editEventStyle() {
    if (this.props.event.endDate < new Date().toISOString()) {
      return 'event-stats-icon-disabled'
    }
    return ''
  }

  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    return (
      <div className="event-stats-card-total">
        <div className="event-stats-card-left-wrapper">
          <img src={this.props.eventCardImage} alt="Event Poster"/>
          <div className="event-stats-card-name-date">
            <h1>{this.props.event.name}</h1>
            <h3>{`${new Date(Date.parse(this.props.event.startDate!)).toLocaleDateString()} - ${new Date(Date.parse(this.props.event.endDate!)).toLocaleDateString()}`}</h3>
          </div>
        </div>
        <div className="event-stats-card-right-wrapper">
          <Link to={{ pathname: '/event-refund', state: { eventID: this.props.event.id! } }} className="event-action">
            <FontAwesomeIcon className="event-action-icon" icon={faArrowRotateLeft}/>
            <h4>{i18next.t('MyEvents.card.refundTickets')}</h4>
          </Link>
          <Link to={{ pathname: '/event-stats', state: { eventID: this.props.event.id! } }} className="event-action">
            <FontAwesomeIcon className="event-action-icon" icon={ faChartPie }/>
            <h4>{i18next.t('MyEvents.card.eventStats')}</h4>
          </Link>
          <Link to={this.editEventLink()} className="event-action" id={this.editEventStyle()}>
            <FontAwesomeIcon className="event-action-icon" icon={faEdit}/>
            <h4>{i18next.t('MyEvents.card.editEvent')}</h4>
          </Link>
        </div>
      </div>
    )
  }
}

export default withTranslation()(EventStatsCard)
