import React, { Component } from 'react'
import { Auth } from 'aws-amplify'  // Used to call Cognito and send a link via email
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { EmailCodeVerificationFormValues } from '../../types/auth'
import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import { TextInput } from '../../components/utils/FormFields'  // Form fields
import AuthButton from '../../components/AuthButton'  // Used to submit form
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../../styles/views/auth/email-code-verification.css'

/**
 * Email code verification page component. Prompts user for the code sent to their email after registration.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
class EmailCodeVerification extends Component<RouteComponentProps<{id: string}, {}, {
  companyID?: string,
  owner?: string,
}> & GlobalProps> {
  /* Holds our form's initial values */
  private initialValues = {
    code: '',
  }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    code: Yup.string().required(),
  })

  /**
   * Calls Cognito to check if code is correct and if so, to confirm the user.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting changes button's status
   */
  private codeVerificationHandler = async (values: EmailCodeVerificationFormValues, { setSubmitting }:
                                                 FormikHelpers<EmailCodeVerificationFormValues>): Promise<void> => {
    /* Calls Cognito and verifies if the user put the correct code */
    try {
      /* Confirms code and triggers post confirmation lambda */
      await Auth.confirmSignUp(this.props.match.params.id, values.code, {
        clientMetadata: this.props.location.state ? this.props.location.state : {} as any,
      })

      /* Enables submit button again */
      setSubmitting(false)

      /* Redirects user to password verification page if the code was correct */
      this.props.history.push('/welcome')
    } catch (error) {
      if ((error as any).message) {
        alert((error as any).message)
      } else {
        alert(JSON.stringify(error))
      }

      /* Enables submit button again */
      setSubmitting(false)
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="email-code-background">
        <div className="email-code-main">
          <div className="email-code-container">
            {/* Button used to go back to the login page */}
            <a href="/login" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
            <h1>{i18next.t('EmailCodeVerification.title')}</h1>
            <p id="email-code-secondary-text">
              {i18next.t('EmailCodeVerification.secondary')}
            </p>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.codeVerificationHandler}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <TextInput
                    value={props.values.code}
                    onChange={props.handleChange}
                    label={i18next.t('EmailCodeVerification.field')}
                    name="code"
                  />
                  <div id="email-code-button-wrapper">
                    <AuthButton type="submit" text={i18next.t('EmailCodeVerification.submit')} />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(EmailCodeVerification)
