import React from 'react'
import { FieldHookConfig, useField } from 'formik'  // Used to type field props and to gather info from each field
import StyledCheckbox from './StyledCheckbox'  // Used in Checkboxes
import { StyledAutoComplete } from './StyledAutoComplete'  // Used in Selection Field
import { PhoneNumberProps, SelectInputProps } from '../../types/form-field'  // Used to type props
import { Button } from '@material-ui/core'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { StyledPhoneNumber } from './StyledPhoneNumber'

import '../../styles/components/form-fields.css'

/**
 * Creates a custom text input field.
 *
 * @param label field's description
 * @param props any other html props that we may want to pass
 */
export const TextInput: React.FC<{label: string} & FieldHookConfig<string>> = ({ label, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="form" id="text-input-form">

        <input type="text" className="input" placeholder="" disabled={props.disabled} {...field} required/>

        <label htmlFor="input" className="label">
          <span className="content">{label}</span>
        </label>

      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom text input field.
 *
 * @param label field's description
 * @param props any other html props that we may want to pass
 */
export const StaticTextInput: React.FC<{label: string, value: string}> = ({ label, value }) => {
  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="form" id="text-input-form">

        <input type="text" className="input" placeholder="" value={value} readOnly required/>

        <label htmlFor="input" className="label" id="static-label">
          <span className="content">{label}</span>
        </label>

      </div>
    </>
  )
}

/**
 * Creates a custom date input field.
 *
 * @param props any other html props that we may want to pass
 */
export const TimeInput: React.FC<FieldHookConfig<string>> = ({ ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="form" id="time-input-form">

        <input type="time" className="input" placeholder="" disabled={props.disabled} {...field} required/>

        <label htmlFor="input" className="label"/>

      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom date input field.
 *
 * @param label a string that will label this field
 * @param props any other html props that we may want to pass
 */
export const DateInput: React.FC<{label?: string} & FieldHookConfig<string>> = ({ label, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="form" id="date-input-form">

        <input type="date" className="input" placeholder="" disabled={props.disabled} {...field} required/>

        <label htmlFor="input" className="label" id="static-label">
          <span className="content-date">{label}</span>
        </label>
      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom radio input field.
 *
 * @param label a string array with all the different options we want
 * @param props any other html props that we may want to pass
 */
export const RadioInput: React.FC<{label: string[]} & FieldHookConfig<string>> = ({ label, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="radio-div">
        {label.map((singleLabel, index) => {
          return (
            <label className="container" key={index}>{singleLabel}
              <input type="radio" disabled={props.disabled} {...field}/>
              <span className="checkmark"/>
            </label>
          )
        })}
      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom text area input field.
 *
 * @param label field's description
 * @param props any other html props that we may want to pass
 */
export const TextAreaInput: React.FC<{label: string} & FieldHookConfig<string>> = ({ label, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div id="text-area-input">

        <p>{label}</p>

        <textarea className="input" placeholder="" disabled={props.disabled} {...field} required/>

      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom password input field.
 *
 * @param label field's description
 * @param props any other html props that we may want to pass
 */
export const PasswordInput: React.FC<{label: string} & FieldHookConfig<string>> = ({ label, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="form" id="password-input-form">

        <input type="password" className="input" placeholder="" disabled={props.disabled} {...field} required/>

        <label htmlFor="input" className="label">
          <span className="content">{label}</span>
        </label>

      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom checkbox input field.
 *
 * @param path redirect path
 * @param props any other html props that we may want to pass
 */
export const TermsCheckboxInput: React.FC<{path: string} & FieldHookConfig<string>> = ({ path, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div className="div-checkbox">
        <StyledCheckbox disabled={props.disabled} {...field} />
        <label id="label-terms" htmlFor="checkbox">
          {i18next.t('Register.termsPartOne')}
          {/* Is sending to landing page just for testing. Also style on the css file wasn't working thus it was done here */}
          <a
            style={{ color: '#000000', fontWeight: (700), textDecoration: 'none' }}
            href={path}>
            {i18next.t('Register.termsPartTwo')}
          </a>
        </label>
      </div>
      {meta.touched && meta.error
        ? (
          <div id="checkboxError" className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

export const CheckboxInput: React.FC<{label: string} & {checked?: boolean} & FieldHookConfig<string>>
= ({ label, checked, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <div style={{ flexDirection: 'column' }}>
      <div className="div-checkbox">
        <StyledCheckbox className={'styled-checkbox'} checked={checked} disabled={props.disabled} {...field} />
        <label className={props.disabled ? 'disabled-label' : ''} id="label-terms" htmlFor="checkbox">
          {label}
        </label>
      </div>
      {meta.touched && meta.error
        ? (
          <div id="checkboxError" className="error"><p>{meta.error}</p></div>
        )
        : null}
    </div>
  )
}

/**
 * Creates a custom select input field.
 *
 * @param label field's description
 * @param options all options that will be shown and can be selected
 * @param setfield function used to change field's current value
 * @param props any other html props that we may want to pass
 */
export const SelectInput: React.FC<SelectInputProps> = ({ label, options, setfield, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      {/* Style on the css file wasn't working thus it was done here */}
      <div id="select-input-div" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '0.5vw', marginTop: '-0.1vw' }}>
        <StyledAutoComplete label={label} options={options} setfield={setfield} disabled={props.disabled} {...field} />
      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom image upload field.
 *
 * @param label button description
 * @param props any other html props that we may want to pass
 */
export const ImageUploadField: React.FC<{label: string} & FieldHookConfig<string>> = ({ label, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      <div>

        <input id="raised-button-file" hidden type="file" accept="image/*" disabled={props.disabled} {...field} required />

        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span" className="button">
            {label}
          </Button>
        </label>

      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </>
  )
}

/**
 * Creates a custom phone number input field.
 *
 * @param label field's description
 * @param props any other html props that we may want to pass
 * @param setfield function used to change field's current value
 * @param defaultCountry string of the default country code
 */
export const PhoneNumberInput: React.FC<PhoneNumberProps & FieldHookConfig<string>>
= ({ label, setfield, defaultCountry, ...props }) => {
  /* Used to gather field props and field metadata */
  const [field, meta] = useField(props)

  /* Creates a div with our input field. If an errors appears, we also create an error div */
  return (
    <>
      {/* Style on the css file wasn't working thus it was done here */}
      <div className="form" id="phone-input-form">
        <label htmlFor="input" className="label" id="static-label">
          <span className="content">{label}</span>
        </label>

        <div id="phone-input-div" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '0.5vw', marginTop: '-0.1vw' }}>
          <StyledPhoneNumber defaultCountry={defaultCountry} setfield={setfield} {...field} />
        </div>

        { window.innerWidth > 650 && <div style={{ width: '100%', height: '0.07vw', marginTop: '-0.6vw', backgroundColor: '#02122E' }}>

        </div>}

      </div>

      {meta.touched && meta.error
        ? (
          <div className="error">{i18next.t('Collaborators.errors.phoneNumber.format')}</div>
        )
        : null}
    </>
  )
}
