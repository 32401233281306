import React, { Component } from 'react'
import beer from '../images/beer.png'
import { EventCardProps, EventCardState } from '../types/event-card' /* Importing the interface that allow the use of props */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { Consumable, ConsumableType, Ticket } from '../API'
import { pt as months } from '../constants/months.json'

import '../styles/components/event-card.css'

/**
 * Creates a card that will feature the main information about an event
 *
 */
class EventCard extends Component<EventCardProps, EventCardState> {
  /**
   * EventCard class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      minPrice: this.getMinPrice(this.props.event!.tickets!.items!),
      startDate: this.getStartDate(this.props.event!.date![0].start),
      beerPrice: this.getBeerPrice(this.props.event!.consumables!.items!),
    }
  }

  /**
   * Gets event's ticket minimum price.
   *
   * @param tickets array of event's tickets
   *
   * @return min price
   */
  private getMinPrice = (tickets: (Ticket | null)[]): number => {
    if (tickets.length === 0) return 0
    return Math.min(...Array.from(tickets, (ticket: Ticket | null) => {
      return ticket !== null ? ticket.prices.price! : 0
    }))
  }

  /**
   * Parses received date from the database into a user friend string.
   *
   * @param startDate date in ISO string format
   *
   * @returns string user friendly string
   */
  private getStartDate = (startDate: string): string => {
    const date = new Date(Date.parse(startDate))
    return `${date.getDate()} de ${months[date.getMonth()]}`
  }

  /**
   * Finds lowest beer price from an input consumables array.
   *
   * @param consumables array of consumables
   *
   * @return price of beer or null if the event does not have beer
   */
  private getBeerPrice = (consumables: (Consumable | null)[]): number | null => {
    if (consumables.length === 0) return null
    let min = 9999999
    for (let i = 0; i < consumables.length; i += 1) {
      if (consumables[i]?.type === ConsumableType.BEER && consumables[i]!.prices.price! < min) {
        min = consumables[i]!.prices.price!
      }
    }
    return min === 9999999 ? null : min
  }

  /**
   * Opens new tab with the specified link.
   *
   * @param url website link to open
   */
  private openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  /**
   * Parses a location object to an url.
   *
   * @param longitude location longitude
   * @param latitude location latitude
   *
   * @return string url
   */
  private locationToUrl = (longitude: number, latitude: number) => {
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  }

  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    return (
      <div className="event-card-total">

        {/* This main components of the event-card are under this div */}
        <div className="event-card-core">
          <img src={this.props.eventImage as string} alt="Event"/>
          <div className="event-card-text">
            <div className="event-card-name-date-hour">
              <h2>{this.props.event.name!}</h2>
              <p>{this.state.startDate}</p>
              <p>{new Date(Date.parse(this.props.event.date![0].start)).toLocaleTimeString()
                .substr(0, 5)}</p>
            </div>
            <div className="event-card-location" onClick={() => this.openInNewTab(this.locationToUrl(this.props.event.location.lon, this.props.event.location.lat))}>
              <FontAwesomeIcon id="map-icon" icon={faMapMarkerAlt}/>
              <h3>{this.props.event.addressAlias!}</h3>
            </div>
            <button> {/* TODO: Add button function */}
              {this.state.minPrice === 0
                ? (
                  <h4>{i18next.t('EventCard.moreInfo')}</h4>
                )
                : (
                  <h4>{i18next.t('EventCard.tickets')}</h4>
                )}
              {this.state.minPrice === 0
                ? null
                : (
                  <p>{i18next.t('EventCard.starting')} {this.state.minPrice} €</p>
                )}
            </button>
          </div>
        </div>

        {/* This div display the beer info at the corner */}
        {
          false && this.state.beerPrice !== null
            ? <div className="beer-info">
              <img src={beer} alt="Beer Icon"/>
              <div className="beer-info-text">
                <h4>{i18next.t('EventCard.beer')}</h4>
                <p>{this.state.beerPrice!} €</p>
              </div>
            </div>
            : <div style={{ width: '6.1vw', height: '2.7vw' }} />
        }
      </div>
    )
  }
}

export default withTranslation()(EventCard)
