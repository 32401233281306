import React, { Component } from 'react'
import { Auth } from 'aws-amplify'  // Used to call Cognito and send a link via email
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { ForgotPasswordFormValues } from '../../types/auth'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form

import { TextInput } from '../../components/utils/FormFields'  // Form fields
import AuthButton from '../../components/AuthButton'  // Used to submit form

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../../styles/views/auth/forgot-password.css'

/**
 * Forgot password page component. Prompts user for his handle and upon submitting, AWS Cognito sends him a code to
 * reset his password.
 */
class ForgotPassword extends Component<RouteComponentProps & GlobalProps> {
  /* Holds our form's initial values */
  private initialValues = {
    handle: '',
  }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    handle: Yup.string().required(i18next.t('Register.errors.handle.required')),
  })

  /**
   * Calls Cognito to send an email with a code for altering user's password.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting changes button's status
   */
  private forgotPasswordHandler = async (values: ForgotPasswordFormValues, { setSubmitting }:
                                                 FormikHelpers<ForgotPasswordFormValues>): Promise<void> => {
    /* Calls Cognito and tells it to send an handle with a verification code for altering user's forgotten password */
    try {
      /* Sends link to handle */
      await Auth.forgotPassword(values.handle)

      /* Enables submit button again */
      setSubmitting(false)

      /* Redirects user to password verification page */
      this.props.history.push(`/forgot-password-verification/${values.handle}`)
    } catch (error) {
      if ((error as any).message) {
        alert((error as any).message)
      } else {
        alert(JSON.stringify(error))
      }

      /* Enables submit button again */
      setSubmitting(false)
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    return (
      <div className="forgot-password-background">
        <div className="forgot-password-main">
          <div className="forgot-password-container">
            {/* Button used to go back to the login page */}
            <a href="/login" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
            <h1>{i18next.t('ForgotPassword.catchphrase')}</h1>
            <p id="forgot-password-secondary-text">
              {i18next.t('ForgotPassword.secondaryText')}
            </p>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.forgotPasswordHandler}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <TextInput
                    value={props.values.handle}
                    onChange={props.handleChange}
                    label={i18next.t('ForgotPassword.handle')}
                    name="handle" />
                  <div id="forgot-password-button-wrapper">
                    <AuthButton text={i18next.t('ForgotPassword.change')} type="submit" />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ForgotPassword)
