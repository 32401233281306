import React, { Component } from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { AddCollaboratorsState } from '../../../types/add-collaborators'
import CollaboratorsList from './../../../components/collaborators/CollaboratorsList' /* List of collaborators */
import ProgressBar from '../../../components/ProgressBar' // Import of the bar that shows the progress in creating event
import { AddCollaboratorsProps, SubPagesProps } from '../../../types/create-edit-event'
import { GlobalProps } from '../../../types/global'
import { listCompanyCollaborators } from '../../../models/collaborators'
import { Collaborator } from '../../../API'
import { nextPage } from '../util/types'
import { TailSpin } from 'react-loader-spinner'

import '../../../styles/views/create-event/add-collaborators.css'

/**
 * In this page the organizer will add the collaborators that will be at the event.
 */
class AddCollaborators extends Component<GlobalProps & SubPagesProps & AddCollaboratorsProps, AddCollaboratorsState> {
  /**
   * AddCollaborators class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      each: [],  /* It's null since in the beginning nobody is checked */
      originalEach: [],
      collaboratorArray: [],
      showAddEditCollaborator: false, /* Initially we don't want to show the Add Edit Collaborator popup  */
      highlight: -1, /* This default value refers to the "All"/"Todos" group */
      loading: true,
    }
  }

  /**
   * Called when component finishes mounting. Used to gather all the collaborators
   */
  componentDidMount(): void {
    this.getAllCollaborators()
      .then((employees) => {
        const array = this.getCheckedCollaborators(this.props.initValues as string[], employees)
        const array2 = this.getCheckedCollaborators(this.props.originalValues as string[], employees)
        this.setState({
          collaboratorArray: employees,
          each: array,
          originalEach: array2,
          loading: false,
        })
      })
      .catch((error) => { console.log(error) })
  }

  /**
   * Configures the initial state with all existing Collaborators
   */
  private getAllCollaborators = async (): Promise<Array<Collaborator>> => {
    return await listCompanyCollaborators({ companyID: this.props.auth.user.getSub() })
  }

  /**
   * Gets the initial configuration of checked Collaborators, to pass to CollaboratorsList
   * @param collaboratorsIDS
   * @param collaborators
   */
  private getCheckedCollaborators = (collaboratorsIDS: string[], collaborators: Collaborator[]): Array<number> => {
    const result = []
    if (collaboratorsIDS !== undefined) {
      for (let i = 0; i < collaboratorsIDS.length; i += 1) {
        for (let j = 0; j < collaborators.length; j += 1) {
          if (collaboratorsIDS[i] === collaborators[j].id) {
            result.push(j)
          }
        }
      }
    }
    return result
  }

  /**
   * Handles submission of all the newly added tickets.
   */
  private handleSubmit = (nextPageParam: number): void => {
    /* Gets checked collaborators' ids */
    const collaboratorsIDs = Array.from(this.state.each, (index) => {
      return this.state.collaboratorArray[index].id
    })

    /* Creates info input object */
    const input: object = {
      each: [
        ...collaboratorsIDs,
      ],
      showAddEditCollaborator: false,
    }

    /* Sends info to parent component and tells it to render next phase */
    this.props.submitInfo(input, nextPageParam)
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    /* These methods must be passed to the child component so that they can alter the parent state. This one allows a
    child to submit a new Checked List state */
    const changeCheckedList = (event: React.MouseEvent, newCheckedList: Array<number>) => {
      this.setState({ each: newCheckedList })
    }

    return (
      <div className="add-collaborators-total">
        <div className="add-collaborators-title">
          <h2>{i18next.t('AddEvent.addCollaborators.title')}</h2>
        </div>
        {this.state.loading
        && <div className="no-events-div">
          <TailSpin
            visible={true}
            height="150"
            width="150"
            color="#02112E"
            radius="0"
          />
        </div>
        }
        {!this.state.loading
        && <div className="add-collaborators-main">
          <div className="add-collaborators-list-wrapper">
            <CollaboratorsList
              collaboratorsArray={this.state.collaboratorArray}
              changeCheckedList={(
                event: React.MouseEvent,
                newCheckedList: Array<number>,
              ) => changeCheckedList(event, newCheckedList)}
              checkedList={this.state.each}
              originalCheckedList={this.state.originalEach}
            />
          </div>
        </div>
        }
        {!this.state.loading
        && <div className="add-collaborators-bottom">
          <ProgressBar filled={5} total={5}/>
          <div className="navigation-buttons-total">
            <button id="previous" className="navigation-button" onClick={() => this.handleSubmit(nextPage.PREVIOUS)}>
              {i18next.t('AddEvent.navigationButtons.previous')}
            </button>
            <button className="navigation-button" onClick={() => this.handleSubmit(nextPage.NEXT)}>
              {i18next.t('AddEvent.navigationButtons.publish')}
            </button>
          </div>
        </div>
        }
      </div>
    )
  }
}

export default withTranslation()(AddCollaborators)
