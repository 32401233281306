import React, { Component } from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { StreakCardProps, StreakCardState } from '../../types/create-edit-event'
import AddEditStreak from './AddEditStreak'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import '../../styles/components/create-event/streak-card.css'

/**
 * Creates a card that will display the information about a newly added ticket.
 */
class StreakCard extends Component<GlobalProps & StreakCardProps, StreakCardState> {
  /**
   * StreakCard class constructor.
   *
   * @param props all the needed props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      showAddStreakPopup: false,
      streak: this.props.streak,
    }
  }

  /**
  * React component render function. Holds our extended html code.
  */
  render() {
    const isEventLive = this.props.eventSpan![0] < (new Date()).getTime()

    return (
      <div>
        {!isEventLive
          && <AddEditStreak
            eventSpan={this.props.eventSpan}
            updateInfo={(input) => {
              this.setState({ streak: input })
              this.props.setStreak(input)
            }}
            streak={this.state.streak}
            consumablesList={this.props.consumablesList}
            show={this.state.showAddStreakPopup}
            close={() => this.setState({ showAddStreakPopup: false })}
          />
        }
        <div className="streak-card-total" id={isEventLive ? 'streak-card-total-disabled' : ''}
          onClick={() => { this.setState({ showAddStreakPopup: true }) }}>
          {/* Streaks can be deleted before the event starts */}
          {!isEventLive
              && <FontAwesomeIcon id="trash-icon" icon={faTrashAlt} onClick={this.props.deleteStreak}/>
          }
          <div className="streak-card-title-info-combo">
            <h4>{i18next.t('AddEvent.addStreaks.streakName')}</h4>
            <h2>{this.state.streak.name}</h2>
          </div>
          <h3 className="large-size-text">{i18next.t('AddEvent.addStreaks.consumables')}</h3>
          {this.state.streak.consumablePair.map((consumable, index) => {
            return (
              <div className="consumable-card-prices" key={index}>
                <div className="consumable-card-title-info-combo">
                  <h4>{i18next.t('AddEvent.addStreaks.consumable')}</h4>
                  <h2>{consumable.consumable}</h2>
                </div>
                <div className="consumable-card-title-info-combo">
                  <h4>{i18next.t('AddEvent.addStreaks.numberConsumables')}</h4>
                  <h2>{consumable.amount}</h2>
                </div>
              </div>
            )
          })}
          <h3 className="large-size-text">{i18next.t('AddEvent.addStreaks.prize')}</h3>
          <div className="consumable-card-prices">
            <div className="consumable-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addStreaks.consumable')}</h4>
              <h2>{this.state.streak.consumablePrize}</h2>
            </div>
            <div className="consumable-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addStreaks.amount')}</h4>
              <h2>{this.state.streak.amountPrize}</h2>
            </div>
            <div className="consumable-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addStreaks.specialPrice')}</h4>
              <h2>{`${this.state.streak.prizePrice} €`}</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(StreakCard)
