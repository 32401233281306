import { Component } from 'react'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router

import '../styles/views/qrcodepage.css'

import { Auth } from 'aws-amplify'
import { getsPersonInfo, encryptUsername } from '../models/person'

import { TicketCardBought } from '../components/events/TicketCardBought'
import { QRCodePageState } from '../types/qr-code-page'
import QRCode from 'react-qr-code'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

export default class QRCodePage extends Component<GlobalProps & RouteComponentProps, QRCodePageState> {
  constructor(props: GlobalProps & RouteComponentProps) {
    super(props)
    this.state = {
      loading: true,
      tickets: undefined,
      filteredTickets: undefined,
      noTickets: true,
      personID: this.props.auth.user.getUsername(),
      qrcodeValue: 'loading',
      needsEncryption: true,
      notUsed: false,
    }
  }

  async componentDidMount(): Promise<void> {
    const encryptedUsername = await encryptUsername(this.props.auth.user.getSub())
    const aux = await Auth.currentUserInfo()
    const person = await getsPersonInfo({ id: aux.attributes.sub })
    const tickets = person?.tickets?.items

    tickets?.sort((a, b) => {
      if (!a?.ticket?.validDates[0]?.start) {
        return 1
      }
      if (!b?.ticket?.validDates[0]?.start) {
        return -1
      }
      if ((a?.ticket.validDates[0].start > b?.ticket.validDates[0].start)) {
        return -1
      } else if ((a?.ticket.validDates[0].start < b?.ticket.validDates[0].start)) {
        return 1
      }
      return 0
    })

    this.setState({ loading: false,
      tickets: tickets,
      qrcodeValue: encryptedUsername,
      needsEncryption: false })

    await this.handleUsedButton(true)()
  }

    handleUsedButton = (notUsed: boolean) => async () => {
      if (this.state.notUsed === notUsed) {
        return
      }
      this.setState({ notUsed: notUsed })

      const tickets = this.state.tickets
      const today = new Date().toISOString()

      const fileteredTickets = tickets?.filter((ticket) => {
        if (ticket?.personTicketRefundId) {
          return false
        }
        if (notUsed) {
          if (ticket?.readDates.length === 0 && ticket?.ticket?.validDates[ticket?.ticket?.validDates?.length - 1]?.end > today) {
            return true
          }

          return false
        }

        if (ticket?.readDates.length !== 0 || ticket?.ticket?.validDates[ticket?.ticket?.validDates?.length - 1]?.end < today) {
          return true
        }

        return false
      })

      console.log(fileteredTickets)
      this.setState({ filteredTickets: fileteredTickets,
        noTickets: fileteredTickets?.length === 0 })
    }

    render() {
      const tickets = this.state.filteredTickets
      const personID = this.state.personID
      const noTickets = this.state.noTickets
      const needsEncryption = this.state.needsEncryption
      const qrcodeValue = this.state.qrcodeValue
      const notUsed = this.state.notUsed
      return (
        <>
          <div className="wrapper">
            <h1 className="name">{personID}</h1>
            <div className="qrcode-wrapper">
              <div className="qrcode">
                {
                  !needsEncryption
                                && <QRCode value={qrcodeValue} size={window.innerWidth * 0.45} />
                }
              </div>
              <p className="qrcode-text">
                <span className="qrcode-text">{i18next.t('QRcodepage.qrcode')}</span>
              </p>

            </div>
            <div className="tickets">
              <h3 className="tickets-title">{i18next.t('QRcodepage.yourtickets')}</h3>
              <div className="ticket-buttons-wrapper">
                <button className={notUsed ? 'highlighted-used-button' : 'used-button'} type="button" onClick={this.handleUsedButton(true)}>
                  {i18next.t('QRcodepage.notused')}
                </button>
                <button className={notUsed ? 'used-button' : 'highlighted-used-button'} type="button" onClick={this.handleUsedButton(false)}>
                  {i18next.t('QRcodepage.used')}
                </button>
              </div>
              {
                (tickets && !noTickets)
                            && <div className="tickets-bought-wrapper">
                              {
                                tickets.map((ticket, index) => {
                                  if (ticket) {
                                    return (
                                      <div key={index} className="ticket-bought-div">
                                        <TicketCardBought personTicket={ticket} />
                                      </div>
                                    )
                                  }
                                  return null
                                })
                              }
                            </div>
              }
              {
                (!tickets || noTickets)
                            && <div className="no-tickets">
                              <p className="no-tickets-text">{notUsed ? i18next.t('QRcodepage.noticketstouse') : i18next.t('QRcodepage.noticketsused')}</p>
                            </div>
              }
            </div>

          </div>
        </>
      )
    }
}
