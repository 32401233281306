import React, { Component } from 'react'
import { AddEditButtonProps } from '../types/add-edit-button' /* Importing the interface that allow the use of props */
import { TailSpin } from 'react-loader-spinner'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/components/add-edit-button.css'

/**
 * Creates a button for the auth function (login, sign up, password change etc) with custom text, a normal or
 * alternative colorway, the button type and a method while it's disabled.
 *
 */
class AddEditButton extends Component<AddEditButtonProps> {
  private correctTextGenerator(): string {
    if (this.props.type === 'consumable') {
      if (this.props.item) {
        return (i18next.t('AddEvent.addConsumables.editConsumable'))
      }
      return (i18next.t('AddEvent.addConsumables.addConsumable'))
    }

    if (this.props.type === 'collaborator') {
      if (this.props.item) {
        return (i18next.t('Collaborators.editCollaborator'))
      }
      return (i18next.t('Collaborators.addCollaborator'))
    }

    if (this.props.type === 'ticket') {
      if (this.props.item) {
        return (i18next.t('AddEvent.addTickets.editTicket'))
      }
      return (i18next.t('AddEvent.addTickets.addTicket'))
    }

    if (this.props.item) {
      return (i18next.t('AddEvent.addStreaks.editStreak'))
    }
    return (i18next.t('AddEvent.addStreaks.addStreak'))
  }


  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    if (this.props.disable) {
      return (
        <div className="add-edit-button-loading">
          <TailSpin
            visible={true}
            height="2.4vw"
            width="2.4vw"
            color= {'#FFF'}
            radius="0"
          />
        </div>
      )
    }
    return (
      <button formNoValidate type="submit" className="add-edit-button">
        {this.props.label ? this.props.label : this.correctTextGenerator()}
      </button>
    )
  }
}

export default withTranslation()(AddEditButton)
