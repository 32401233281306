import React, { Component } from 'react'

import { GlobalProps } from '../../../types/global'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import AddEditTicket from '../../../components/events/AddEditTicket'
import TicketCard from '../../../components/events/TicketCard'
import ProgressBar from '../../../components/ProgressBar' // Import of the bar that shows the progress in creating event
import { AddEditTicketFormValues, EventTicketsState, SubPagesProps, Dates } from '../../../types/create-edit-event'
import { nextPage } from '../util/types'

import '../../../styles/views/create-event/add-tickets.css'

/**
 * In this page the user will add all the tickets related to this event.
 */
class AddTickets extends Component<GlobalProps & SubPagesProps & Dates, EventTicketsState> {
  /* Holds our page's initial values */
  private initialValues = this.props.initValues as EventTicketsState

  /**
   * AddTickets class constructor.
   *
   * @param props all the needed props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      ...this.initialValues,
    }
  }

  /**
   * Handles submission of all the newly added tickets.
   */
  private handleSubmit = (nextPageParam: number): void => {
    /* Creates info input object */
    const input: object = {
      each: [
        ...this.state.each,
      ],
      showAddTicketPopup: false,
      addTickets: this.state.addTickets,
    }

    /* Sends info to parent component and tells it to render next phase */
    this.props.submitInfo(input, nextPageParam)
  }

  /**
   * Associates a new ticket to the event.
   *
   * @param input inputs needed to create a new ticket
   */
  private addNewTicket = (input: AddEditTicketFormValues): void => {
    this.setState({ each: [
      ...this.state.each,
      input,
    ] })
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      <div className="add-tickets-total">
        <AddEditTicket
          updateInfo={this.addNewTicket}
          eventSpan={this.props.eventSpan ? this.props.eventSpan : [0, 0]}
          show={this.state.showAddTicketPopup}
          numberOfDays={this.props.numberOfDays}
          addTickets={this.state.addTickets}
          mode = {this.props.mode!}
          close={() => this.setState({ showAddTicketPopup: false })}
        />

        <div className="add-tickets-title">
          <h2>{i18next.t('AddEvent.addTickets.title')}</h2>
        </div>

        <button id="create-ticket" className="top-button" onClick={() => this.setState({ showAddTicketPopup: true })}>
          <h4>{i18next.t('AddEvent.addTickets.createTicket')}</h4>
          <div className="add-button">+</div>
        </button>

        { this.state.each.length !== 0
          ? <div className="add-tickets-main">
            { this.state.each.map((ticket: AddEditTicketFormValues, index: number) => (
              <TicketCard
                key={index}
                auth={this.props.auth}
                ticket={ticket}
                numberOfDays={this.props.numberOfDays}
                eventDates={this.props.eventDates}
                eventSpan={this.props.eventSpan ? this.props.eventSpan : [0, 0]}
                addTicket={this.state.addTickets}
                setTicket={(input: AddEditTicketFormValues) => {
                  const newEach = this.state.each
                  newEach[index] = input
                  this.setState({ each: newEach })
                }}
                mode = {this.props.mode!}
                deleteTicket={() => {
                  this.state.each.splice(index, 1)
                  this.setState({ each: this.state.each })
                }}
              />
            )) }
          </div>
          : <div className="add-tickets-no-ticket-wrapper">
            <h3>{i18next.t('AddEvent.addTickets.noTicket')}</h3>
          </div>}
        <div className="add-tickets-bottom">
          <ProgressBar filled={2} total={5}/>
          <div className="navigation-buttons-total">
            <button id="previous" className="navigation-button" onClick={() => this.handleSubmit(nextPage.PREVIOUS)}>
              {i18next.t('AddEvent.navigationButtons.previous')}
            </button>
            <button className="navigation-button" onClick={() => this.handleSubmit(nextPage.NEXT)}>
              {i18next.t('AddEvent.navigationButtons.next')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(AddTickets)
