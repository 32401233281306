import React, { Component } from 'react'
import { AuthButtonProps } from '../types/auth-button' /* Importing the interface that allow the use of props */
import { TailSpin } from 'react-loader-spinner'

import '../styles/components/auth-button.css'

/**
 * Creates a button for the auth function (login, sign up, password change etc) with custom text, a normal or
 * alternative colorway, the button type and a method while it's disabled.
 *
 */
class AuthButton extends Component<AuthButtonProps> {
  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    if (!this.props.disable) {
      return (
        <button formNoValidate onClick={this.props.function} className="auth-button" id={this.props.property} type={this.props.type} disabled={this.props.disable}>
          {this.props.text}
        </button>
      )
    }
    return (
      <div className="auth-button-loading" id={this.props.property}>
        <TailSpin
          visible={true}
          height="2.4vw"
          width="2.4vw"
          color= { this.props.property === 'alternative-color-scheme' ? '#02112E' : '#FFF'}
          radius="0"
        />
      </div>
    )
  }
}

export default AuthButton
