
import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */

import Layout from 'src/components/Layout'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TailSpin } from 'react-loader-spinner'
import { Member, CreateMemberInput } from 'src/API'
import { createMembers } from 'src/models/company'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Papa from 'papaparse'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/members.css'

/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
enum MemberProcessPhase {
  WAITING = 'WAITING',
  LOADING = 'LOADING',
  DONE = 'DONE',
}
/* eslint-enable no-unused-vars */

type State = {
  displayedMembers: Member[]
  totalMembers: Member[]
  invalidPhones: string[]
  memberProcessPhase: MemberProcessPhase
}

/**
 * This page shows the terms of our platform.
 */
class Members extends Component<RouteComponentProps & GlobalProps, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      displayedMembers: [],
      totalMembers: [],
      invalidPhones: [],
      memberProcessPhase: MemberProcessPhase.WAITING,
    }
  }

  public handleCSV = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = e.target.files
    if (files) {
      Papa.parse(files[0], { header: true, complete: (results: any) => {
        const filteredData = results.data.filter((item: any) => item.phone)
        this.setState({
          totalMembers: filteredData,
          displayedMembers: filteredData.slice(0, 20),
          invalidPhones: this.invalidPhonesFilter(filteredData),
        })
      } })
    }
  }

  public handleScroll = (): void => {
    const { displayedMembers, totalMembers } = this.state
    const newMembers = totalMembers.slice(displayedMembers.length, displayedMembers.length + 20)
    this.setState({
      displayedMembers: displayedMembers.concat(newMembers),
    })
  }

  public handleSubmit = async () => {
    try {
      this.setState({ memberProcessPhase: MemberProcessPhase.LOADING })
      for (let i = 0; i < this.state.totalMembers.length; i += 20) {
        const sub = this.state.totalMembers.slice(i, Math.min(this.state.totalMembers.length, i + 20))
        const parsed = sub
          .filter((member) => this.validatePhoneNumber(member.phone))
          .map((member): CreateMemberInput => (
            {
              phone: this.phoneNormalizer(member.phone),
              owner: this.props.auth.user.getSub(),
              companyID: this.props.auth.user.getSub(),
              ...(member.name !== undefined) && { name: member.name },
              ...(member.internalID !== undefined) && { internalID: member.internalID },
              ...(member.email !== undefined) && { email: member.email },
            }
          ))
        await createMembers(parsed)
      }
      this.setState({ memberProcessPhase: MemberProcessPhase.DONE })
    } catch (error) {
      console.error(error)
      alert('An error was found while trying to create members')
    }
  }

  public phoneNormalizer = (phoneNumber: string) => (
    phoneNumber[0] === '+' ? phoneNumber : `+${phoneNumber}`
  )

  public validatePhoneNumber = (phoneNumber: string) => {
    /* Unfortunately there is no way to tell for sure if a number is valid without knowing specifically the country code
    As such we use a General Regex to get an idea if the number is valid
    However since a Portuguese mobile number without the country code is valid, we need to check for that as it will
    be the most common case */
    const GENERAL_REGEX = /^\+(?:[0-9]\x20?){1,3}[0-9](?:\x20?[0-9]){3,14}(?:\x20?(?:#|x)\x20?[0-9]{1,5})?$/
    const PORTUGAL_MOBILE_NUMBER_PATTERN = /^(9[1236]\d{7})$/

    return GENERAL_REGEX.test(this.phoneNormalizer(phoneNumber))
    && !PORTUGAL_MOBILE_NUMBER_PATTERN.test(phoneNumber.replace('+', ''))
  }

  public invalidPhonesFilter = (members: Member[]) => (
    members.filter((member) => !this.validatePhoneNumber(member.phone)).map((member) => member.phone)
  )

  public invalidNumbersMessage = (invalidPhones: string[]) => {
    const count = invalidPhones.length
    if (count === 0) {
      return ''
    }
    if (count === 1) {
      return i18next.t('Members.invalidNumbers1')
        .replace('%v1', invalidPhones[0])
    } else if (count === 2) {
      return i18next.t('Members.invalidNumbers2')
        .replace('%v1', invalidPhones[0])
        .replace('%v2', invalidPhones[1])
    } else if (count === 3) {
      return i18next.t('Members.invalidNumbers3')
        .replace('%v1', invalidPhones[0])
        .replace('%v2', invalidPhones[1])
        .replace('%v3', invalidPhones[2])
    }
    const others = count - 3
    return i18next.t('Members.invalidNumbersN')
      .replace('%vn', invalidPhones.slice(0, 3).join(', '))
      .replace('%vx', others.toString())
  }

  public ButtonComponent = () => {
    if (this.state.totalMembers.length === 0 || this.state.invalidPhones.length > 0) return null
    if (this.state.memberProcessPhase === MemberProcessPhase.LOADING) {
      return (<button className="regular-button member-submit-button disabled" onClick={undefined}>
        <TailSpin visible={true} height="2.4vw" width="2.4vw" color= {'#FFFFFF'} radius="0"/>
      </button>)
    }
    if (this.state.memberProcessPhase === MemberProcessPhase.DONE) {
      return (<button className="regular-button member-submit-button disabled" onClick={undefined}>
        {i18next.t('Members.done')}
      </button>)
    }
    return (<button className="regular-button member-submit-button" onClick={this.handleSubmit}>
      {i18next.t('Members.createMembers')}
    </button>)
  }


  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <Layout label={i18next.t('Members.title')}>
        <div className="members-container">
          <div className="members-csv-info-container">
            <div className="members-csv-info-text">
              <h4>{i18next.t('Members.toAddMembers')}</h4>
              <p>{i18next.t('Members.headerInfo')}</p>
            </div>
            <input type="file" accept=".csv" className="custom-file-input" id={i18next.t('Members.lang')} onChange={this.handleCSV}/>
          </div>
          { this.state.invalidPhones.length !== 0
          && <h4 className="members-warning">{this.invalidNumbersMessage(this.state.invalidPhones)}</h4>
          }
          <InfiniteScroll
            dataLength={this.state.displayedMembers.length}
            next={this.handleScroll}
            scrollThreshold={0.9}
            height="37vh"
            hasMore={this.state.totalMembers.length !== this.state.displayedMembers.length}
            loader={<div className="event-refund-loading">
              <TailSpin visible={true} height="4vw" width="4vw" color= {'#02112E'} radius="0"/>
            </div>}
            endMessage={ this.state.totalMembers.length !== 0
                  && <div className="event-refund-loading">
                    <p>{i18next.t('EventRefund.allUsersLoaded')}</p>
                  </div>
            }
            className="members-list">
            {this.state.displayedMembers
              .map((item, index) => (
                <div key={index} className="member-container">
                  <div className="member-info-wrapper">
                    <h3>{item.name}</h3>
                    <h4>{`ID: ${item.internalID ?? ''} | email: ${item.email ?? ''}`}</h4>
                  </div>
                  <div className="member-phone-wrapper">
                    <h3>{this.phoneNormalizer(item.phone)}</h3>
                  </div>
                </div>
              ))}
          </InfiniteScroll>
          {this.ButtonComponent()}
        </div>
      </Layout>
    )
  }
}

export default withTranslation()(Members)
