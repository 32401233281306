import React, { Component } from 'react'
import { GlobalProps } from '../../types/global'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../../styles/views/auth/change-password-confirm.css'

/**
 * Simple page that tells our user that he does not hold the required permissions to access something.
 */
class NoPermission extends Component<GlobalProps> {
  render() {
    return (
      <div className="change-password-confirm-background">
        <div className="change-password-confirm-main">
          <div className="change-password-confirm-container">
            {/* Button used to go back to the landing page */}
            <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
            <h1>{i18next.t('NoPermission.title')}</h1>
            <p>{i18next.t('NoPermission.description')}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(NoPermission)
