import React, { Component } from 'react'
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import AddEditConsumable from './AddEditConsumable'
import { ConsumableCardProps, ConsumableCardState } from '../../types/create-edit-event'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import '../../styles/components/create-event/consumable-card.css'

/**
 * Creates a card that will display the information about a consumable
 */
class ConsumableCard extends Component<GlobalProps & ConsumableCardProps, ConsumableCardState> {
  /**
   * ConsumableCard class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      showAddConsumablePopup: false,
      consumable: this.props.consumable,
      hasOriginalLargeVersion: this.props.hasOriginalLargeVersion,
    }
  }

  /**
  * React component render function. Holds our extended html code.
  */
  render() {
    /* const auxDate = new Date()
    auxDate.setMinutes(auxDate.getMinutes() + 30) */

    return (
      <div>
        <AddEditConsumable
          eventSpan={this.props.eventSpan}
          updateInfo={(input: any) => {
            this.setState({ consumable: input })
            this.props.setConsumable(input)
          }}
          consumable={this.props.consumable}
          hasOriginalLargeVersion={this.props.hasOriginalLargeVersion}
          auth={this.props.auth}
          show={this.state.showAddConsumablePopup}
          close={() => this.setState({ showAddConsumablePopup: false })}
          editCategory={this.props.editCategory}
        />
        <div className="consumable-card-total" onClick={() => this.setState({ showAddConsumablePopup: true })}>
          {/* For now consumables can only be deleted while creating the event */}
          { this.props.mode === 'CREATE'
          && <FontAwesomeIcon id="trash-icon" icon={faTrashAlt} onClick={this.props.deleteConsumable} />
          }
          <div className="consumable-card-title-info-combo">
            <h4>{i18next.t('AddEvent.addConsumables.name')}</h4>
            <h2>{this.state.consumable.name}</h2>
          </div>
          <div className="consumable-card-title-info-combo">
            <h4>{i18next.t('AddEvent.addConsumables.subCategory')}</h4>
            <h2>{this.state.consumable.category}</h2>
          </div>
          <div className="consumable-card-prices">
            <div className="consumable-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addConsumables.price')}</h4>
              <h2>{`${this.state.consumable.price} €`}</h2>
            </div>
            { this.state.consumable.memberPrice
            && <div className="consumable-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addConsumables.memberPrice')}</h4>
              <h2>{`${this.state.consumable.memberPrice} €`}</h2>
            </div>
            }
          </div>
          {this.state.consumable.largeVersionName && this.state.consumable.largeVersionPrice
          && <div>
            <h3 className="large-size-text">{i18next.t('AddEvent.addConsumables.largeVersionCard')}</h3>
            <div className="consumable-card-title-info-combo">
              <h4>{i18next.t('AddEvent.addConsumables.name')}</h4>
              <h2>{this.state.consumable.largeVersionName}</h2>
            </div>
            <div className="consumable-card-prices">
              <div className="consumable-card-title-info-combo">
                <h4>{i18next.t('AddEvent.addConsumables.price')}</h4>
                <h2>{`${this.state.consumable.largeVersionPrice} €`}</h2>
              </div>
              { this.state.consumable.largeVersionMemberPrice
              && <div className="consumable-card-title-info-combo">
                <h4>{i18next.t('AddEvent.addConsumables.memberPrice')}</h4>
                <h2>{`${this.state.consumable.largeVersionMemberPrice} €`}</h2>
              </div>
              }
            </div>
          </div>
          }
        </div>
      </div>

    )
  }
}

export default withTranslation()(ConsumableCard)
