import { Component } from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */
import { CollaboratorsState } from '../types/collaborators'
import AddEditCollaborator from '../components/collaborators/AddEditCollaborator' /* Pop up component that allows the user to add or edit a collaborator */
import CollaboratorsInfo from './../components/collaborators/CollaboratorsInfo' /* Right panel with collaborator info */
import CollaboratorsList from './../components/collaborators/CollaboratorsList' /* List of collaborators */
import SearchBar from '../components/utils/SearchBar'
import { TailSpin } from 'react-loader-spinner'
import { Collaborator } from '../API'
import {
  listCompanyCollaborators,
  deleteCollaborator,
} from '../models/collaborators'
import { removeImage } from '../models/images'

import '../styles/views/collaborators.css'

/**
 * Page where the list of Collaborators will be available and will also allow the creation of new collaborators
*/
class Collaborators extends Component<GlobalProps, CollaboratorsState> {
  /**
   * Collaborators class constructor.
   *
   * @param props all the required props
   */
  constructor(props: GlobalProps) {
    super(props)
    this.state = {
      checkedList: [], /* It's null since in the beginning nobody is checked */
      collaboratorArray: [],
      showAddEditCollaborator: false, /* Initially we don't want to show the Add Edit Collaborator popup  */
      highlight: -1, /* This default value refers to the "All"/"Todos" group */
      search: '',
      loading: true,
      subscriptionUpdate: undefined,
    }
  }

  /**
   * Called when component finishes mounting. Used to gather all the collaborators
   */
  componentDidMount(): void {
    this.getAllCollaborators()
      .then((collaborators) => {
        this.setState({ collaboratorArray: collaborators, loading: false })
      })
      .catch((error) => { console.log(error) })
  }

  /**
   * Configures the initial state with all existing Collaborators
   */
  private getAllCollaborators = async (): Promise<Array<Collaborator>> => {
    return await listCompanyCollaborators({ companyID: this.props.auth.user.getSub() })
  }

  /**
  * This function is called when we click the remove selected button
  */
  private async removeFunction(): Promise<void> {
    /* We need to remove the checked elements. Thus we will use the splice ( on the old collaborator array state that we
    copied to new list ) using the indexes on the checkedList array. However we need to adjust these indexes. Because
    once I remove an element for instance the index 5 element the next element will go to the index 5. Thus if we wanted
    to remove the next element, which would be stored on the checked list array as element 6 we need to remove one number
    of that index. If we already removed "i" elements we need to do the adjustment by removing "i" from the original
    index stored on checked list. */
    let i = 0
    const newList = this.state.collaboratorArray
    for (let index of this.state.checkedList) {
      index -= i
      const collaboratorToRemove: Collaborator = newList[index]
      newList.splice(index, 1)

      /* Remove here each element in the database */
      if (!collaboratorToRemove.id) continue
      await deleteCollaborator({ id: collaboratorToRemove.id })
      if (collaboratorToRemove.avatar?.filename && collaboratorToRemove.avatar?.id) {
        await removeImage(collaboratorToRemove.avatar.filename, collaboratorToRemove.avatar.id)
      }
      i += 1
    }
    this.setState({ collaborator: undefined, collaboratorArray: newList, checkedList: [] })
  }

  /**
     * Changes search value.
     *
     * @param newSearch new search value from the search bar
     */
  private changeSearch = (newSearch: string): void => {
    this.setState({ search: newSearch })
  }

  /**
   * Returns the filtered array by the searched keyword(s)
   */
  private filterCollaboratorsBySearch(): Array<Collaborator> {
    let filteredCollaborators = this.state.collaboratorArray
    const searchExpression = this.state.search

    if (this.state.search !== '') {
      /* Now we want to make sure that each of the above words is in the given Collaborator */
      filteredCollaborators = filteredCollaborators
        .filter((collaborator) => this.filterFunction(collaborator, searchExpression))
    }
    return filteredCollaborators
  }

  /**
   * Search bar filter function.
   *
   * @param collaborator collaborator
   * @param searchExpression typed word
   * @private
   */
  private filterFunction = (collaborator: Collaborator, searchExpression: string): boolean => {
    /* Makes sure that the comparison isn't case sensitive nor accent sensitive */
    if (!collaborator.name) return false
    const nameWords = collaborator.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')
    const keyWords = searchExpression.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')

    for (const keyword in keyWords) {
      let passed = false
      for (const name in nameWords) {
        if (nameWords[name].startsWith(keyWords[keyword])) {
          passed = true
          break
        }
      }
      if (!passed) return false
    }
    return true
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    /* These methods must be passed to the child component so that they can alter the parent state. This one allows a
    child to submit a new Collaborator state */
    const changeCollaboratorInfo = (event: React.MouseEvent, newCollaborator?: Collaborator) => {
      this.setState({ collaborator: newCollaborator })
    }

    /* These methods must be passed to the child component so that they can alter the parent state. This one allows a
    child to submit a new Checked List state */
    const changeCheckedList = (event: React.MouseEvent, newCheckedList: Array<number>) => {
      this.setState({ checkedList: newCheckedList })
    }

    /* These methods must be passed to the child component so that they can alter the parent state. This one allows a
    child to submit a new Collaborators List state */
    const addEditCollaborator = (newCollaborator: Collaborator) => {
      /* If we edited an existing Collaborator, we delete him */
      const newArray = this.state.collaboratorArray
        .filter((collaborator) => collaborator.id !== newCollaborator.id)
        .concat(newCollaborator)
      this.setState({
        collaboratorArray: newArray,
      })
    }

    return (

      <div className="collaborators-total">

        <AddEditCollaborator
          auth={this.props.auth}
          show={this.state.showAddEditCollaborator}
          close={() => this.setState({ showAddEditCollaborator: false })}
          addEditCollaborator={(newCollaborator: Collaborator) => addEditCollaborator(newCollaborator)}
        />

        <div className="collaborators-title">
          <h2>{i18next.t('Collaborators.title')}</h2>
        </div>

        <button id="add-collaborator" className="top-button" onClick={() => this.setState({ showAddEditCollaborator: true })}>
          <h4>{i18next.t('Collaborators.addCollaborator')}</h4>
          <div className="add-button">+</div>
        </button>

        <div className="collaborators-main">

          <div className="collaborators-horiz-container">
            <div className="search-bar-wrapper">
              <SearchBar text={this.state.search} changeText={this.changeSearch}/>
            </div>
          </div>

          <div className="collaborators-list-info-wrapper">

            { this.state.loading
              ? <div style={{ width: '100%', height: 'auto', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <TailSpin
                  visible={true}
                  height="130"
                  width="130"
                  color="#02112E"
                  radius="0"
                />
              </div>
              : <CollaboratorsList
                collaboratorsArray={this.filterCollaboratorsBySearch()}
                changeCollaboratorInfo={
                  (
                    event: React.MouseEvent,
                    newCollaborator: Collaborator | undefined,
                  ) => changeCollaboratorInfo(event, newCollaborator)
                }
                changeCheckedList={
                  (
                    event: React.MouseEvent,
                    newCheckedList: Array<number>,
                  ) => changeCheckedList(event, newCheckedList)}
                checkedList={undefined}
                originalCheckedList={undefined}
              />
            }

            <div className="collaborators-info-options-wrapper">
              <CollaboratorsInfo
                auth={this.props.auth}
                collaborator={this.state.collaborator}
                addEditCollaborator={(newCollaborator: Collaborator) => addEditCollaborator(newCollaborator)}
              />

              {this.state.checkedList.length !== 0
                ? (
                  <button id="remove-button" onClick={this.removeFunction.bind(this)}>
                    {i18next.t('Collaborators.remove')}
                  </button>
                )
                : null}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Collaborators)
