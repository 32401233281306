import awsmobile from '../aws-exports'

export function getEnvName() {
  const envStart = awsmobile.aws_user_files_s3_bucket.lastIndexOf('-')
  const env = awsmobile.aws_user_files_s3_bucket.slice(envStart + 1)

  return env
}

export function getSibsFunctionURL(): string {
  const env = getEnvName()
  switch (env) {
    case 'hot':
      return 'https://bco7fgvl66fvdjrxo7zrakyjry0nrrjt.lambda-url.eu-west-2.on.aws/'
    case 'dev':
      return 'https://acc4kihihknjq6lxyg27uox67m0xrgfq.lambda-url.eu-west-2.on.aws/'
    case 'test':
      return 'https://5pzf7eyeabviyaigbirc4enuiu0yorxd.lambda-url.eu-west-2.on.aws/'
    case 'main':
      return 'https://athzjvvuwn6avtjojjgqsmvi3a0noofr.lambda-url.eu-west-2.on.aws/'
    default:
      return 'error'
  }
}

export function getSibsEndpointURL(): string {
  const env = getEnvName()
  switch (env) {
    case 'hot':
      return 'https://spg.qly.site1.sibs.pt/assets/js/widget.js'
    case 'dev':
      return 'https://spg.qly.site1.sibs.pt/assets/js/widget.js'
    case 'test':
      return 'https://spg.qly.site1.sibs.pt/assets/js/widget.js'
    case 'main':
      return 'https://api.sibspayments.com/assets/js/widget.js'
    default:
      return 'error'
  }
}
