import { Component } from 'react'
import { PopupProps } from '../types/popup' /* Importing the interface that allow the use of props */
import { ConfirmRefundProps } from '../types/confirm-refund'

import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import { TextInput } from './utils/FormFields'  // Form fields
import AddEditButton from './AddEditButton'
import Popup from './Popup'

import { refundTickets } from '../models/ticket'
import { RefundTicketsInput } from 'src/API'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/components/confirm-refund.css'

/**
 * Creates the popup component that allows the user to confirm the refund
 */
class ConfirmRefund extends Component<PopupProps & ConfirmRefundProps> {
  /* Holds our form's initial values */
  private initialValues = { confirm: '' }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    confirm: Yup.string().required(i18next.t('ConfirmRefund.error'))
      .matches(/(Confirm|Confirmar)/, i18next.t('ConfirmRefund.error')),
  })

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   * @param resetForm function called to reset form input fields
   */
  private handleSubmit = async (values: { confirm: string }, { setSubmitting, resetForm }:
    FormikHelpers<{ confirm: string }>): Promise<void> => {
    const helperSize = 10
    let response = false
    for (let i = 0; i < this.props.input.refundInfo.length; i += helperSize) {
      const helperArray = this.props.input.refundInfo.slice(i, i + helperSize)
      const helperInput: RefundTicketsInput = {
        refundInfo: helperArray,
        refundPercentage: this.props.input.refundPercentage,
        eventID: this.props.input.eventID,
        companyID: this.props.input.companyID,
        updateStock: this.props.input.updateStock,
      }
      response = await refundTickets(helperInput)
      if (!response) {
        break
      }
    }
    this.props.feedbackAction(response)

    /* Enables submit button again */
    setSubmitting(false)

    /* Closes popup */
    this.props.close()

    /* Resets form after submitting */
    resetForm()
  }


  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      <Popup close={this.props.close} show={this.props.show}>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.validationSchema}
          validateOnBlur={false}
          enableReinitialize={true}
          validateOnChange={false}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <form className="confirm-refund-form-wrapper" onSubmit={props.handleSubmit} autoComplete="off">
              <h4>{i18next.t('ConfirmRefund.description')}</h4>
              <TextInput
                onChange={props.handleChange}
                label={i18next.t('ConfirmRefund.confirm')}
                name="confirm"
              />
              <AddEditButton label={i18next.t('ConfirmRefund.confirm')} disable={props.isSubmitting}/>
            </form>
          )}
        </Formik>
      </Popup>
    )
  }
}

export default withTranslation()(ConfirmRefund)
