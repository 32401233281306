import React from 'react'
import { CreateEventState } from '../../../types/create-edit-event'
import { GlobalProps } from '../../../types/global'
import { Artist } from '../../../API'
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { eventDate, eventArtist, nextPage, SubPages } from '../util/types'

import AddGeneralInfo from '../sub-pages/AddGeneralInfo'
import AddCollaborators from '../sub-pages/AddCollaborators'
import AddConsumables from '../sub-pages/AddConsumables'
import AddTickets from '../sub-pages/AddTickets'
import AddStreaks from '../sub-pages/AddStreaks'
import EditedSuccessfully from './UpdateStage'
import {
  buildTickets,
  buildDaysOfEvent,
  getImages,
  buildConsumables,
  buildStreaks,
  createConsumableOriginalValues,
  createStreaksOriginalValues,
} from '../util/functions'
import { TailSpin } from 'react-loader-spinner'
import { getEvent } from '../../../models/event'

/**
 * Component used in editing an event.
 */
class EditEvent extends React.Component<GlobalProps & RouteComponentProps<
 // eslint-disable-next-line @typescript-eslint/ban-types
 {}, {}, {eventID: string}>,
 CreateEventState> {
  /**
   * Holds the start and end date of the event.
   */
  private eventSpan: [number, number] = [0, 0]

  /**
   * Holds values of the event before being updated. They are used for comparisons when submitting
   */
  private originalValues: CreateEventState = {
    general: {
      __typename: 'Event',
      name: '',
      address: '',
      editAddress: false,
      addressAlias: '',
      description: '',
      editDates: false,
      daysOfEvent: [{
        date: [eventDate, eventDate],
        artists: [eventArtist],
      }],
      images: [undefined, undefined, undefined, undefined],
    },
    tickets: {
      each: [],
      addTickets: false,
      showAddTicketPopup: false,
    },
    consumables: {
      each: [],
      showAddConsumablePopup: false,
      category: -1,
      editCategory: false,
    },
    streaks: {
      each: [],
      showAddStreakPopup: false,
    },
    collaborators: {
      each: [],
    },
    page: SubPages.GENERAL,
    loading: true,
  }

  /**
   * Holds IDs of the event's original images.
   */
  private originalImagesIDs: Array<{id: string, name: string}> = []

  /**
   * EditEvent class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    // All that is in the constructor will go to componentDidMount (getEvent first + the rest)
    /* I had to pass general, tickets, consumables, streaks and collaborators as undefined since we can't call
    an async function to fetch the event on the constructor */
    super(props)
    this.state = {
      loading: true,
      general: undefined,
      tickets: undefined,
      consumables: undefined,
      streaks: undefined,
      collaborators: undefined,
      page: SubPages.GENERAL,
    }
  }

  updateImages = () => {
    getEvent({ id: this.props.location.state.eventID }).then((value) => {
      this.setState({
        general: {
          images: getImages(value.photos?.items ?? []),
          __typename: 'Event',
          name: value.name!,
          id: value.id,
          posterID: value.poster?.id,
          address: `https://www.google.com/maps/search/?api=1&query=${value.location.lat!},${value.location.lon!}`,
          editAddress: false,
          addressAlias: value.addressAlias!,
          description: value.description!,
          editDates: false,
          daysOfEvent: buildDaysOfEvent(value.date!, value.poster?.artists as Array<Array<Artist> | null>),
        },
      })
    })
  }

  /**
   * Called after component is built to parse event info
   */
  public async componentDidMount() {
    /* These values are used at the end of the submission to check if something was deleted/updated/created */
    /* TODO: Create function for every state, and comment why its needed */
    /* Note: createConsumableOriginalValues is there to create an independent duplicate of the original state.
    */
    const event = await getEvent({ id: this.props.location.state.eventID })

    this.setState({
      loading: true,
      general: {
        images: getImages(event.photos?.items ?? []),
        __typename: 'Event',
        name: event.name!,
        id: event.id,
        posterID: event.poster?.id,
        address: `https://www.google.com/maps/search/?api=1&query=${event.location.lat!},${event.location.lon!}`,
        editAddress: false,
        addressAlias: event.addressAlias!,
        description: event.description!,
        editDates: false,
        daysOfEvent: buildDaysOfEvent(event.date!, event.poster?.artists as Array<Array<Artist> | null>),
      },
      tickets: {
        each: buildTickets(
          event.tickets?.items ?? [],
          buildDaysOfEvent(event.date!, event.poster?.artists as Array<Array<Artist> | null>),
        ),
        addTickets: false,
        showAddTicketPopup: false,
      },
      consumables: {
        each: buildConsumables(event.consumables?.items ?? []),
        showAddConsumablePopup: false,
        category: -1,
        editCategory: false,
      },
      streaks: {
        each: buildStreaks(event.streaks?.items ?? []),
        showAddStreakPopup: false,
      },
      collaborators: {
        each: event.collaborators?.items?.map((collaborator) => collaborator?.collaboratorID ?? '') ?? [],
      },
      page: SubPages.GENERAL,
    })

    this.originalValues.general = {
      images: getImages(event.photos?.items ?? []),
      __typename: 'Event',
      name: event.name!,
      id: event.id,
      posterID: event.poster?.id,
      address: `https://www.google.com/maps/search/?api=1&query=${event.location.lat!},${event.location.lon!}`,
      editAddress: false,
      addressAlias: event.addressAlias!,
      description: event.description!,
      editDates: false,
      daysOfEvent: buildDaysOfEvent(event.date!, event.poster?.artists as Array<Array<Artist> | null>),
    }
    this.originalValues.tickets = {
      each: buildTickets(
        event.tickets?.items ?? [],
        buildDaysOfEvent(event.date!, event.poster?.artists as Array<Array<Artist> | null>),
      ),
      addTickets: false,
      showAddTicketPopup: false,
    },
    this.originalValues.consumables = createConsumableOriginalValues({
      each: buildConsumables(event.consumables?.items ?? []),
      showAddConsumablePopup: false,
      category: -1,
      editCategory: false,
    },)
    this.originalValues.streaks = createStreaksOriginalValues({
      each: buildStreaks(event.streaks?.items ?? []),
      showAddStreakPopup: false,
    })
    this.originalValues.collaborators = {
      each: event.collaborators?.items?.map((collaborator) => collaborator?.collaboratorID ?? '') ?? [],
    }

    /* Gathers original image id's so that they can be used to delete an image */
    this.originalImagesIDs = event.photos!.items!.map((image) => {
      return { id: image!.id, name: image!.filename }
    })

    /* Callback func used to make sure the images are rendered on the first tick */
    this.setState({
      loading: false,
    }, this.updateImages)
  }

  /**
   * Sets eventSpan value. First value is the start and second is the end.
   */
  private setEventSpan = (): void => {
    if (!this.state.general) return

    const parsedDatesStart: number[] = []
    const parsedDatesEnd: number[] = []
    for (let i = 0; i < this.state.general.daysOfEvent.length; i += 1) {
      parsedDatesStart.push(Date.parse(`${this.state.general.daysOfEvent[i].date[0].date}T${this.state.general.daysOfEvent[i].date[0].time}:00`))
      parsedDatesEnd.push(Date.parse(`${this.state.general.daysOfEvent[i].date[1].date}T${this.state.general.daysOfEvent[i].date[1].time}:00`))
    }
    this.eventSpan[0] = Math.min(...parsedDatesStart)
    this.eventSpan[1] = Math.max(...parsedDatesEnd)
  }

  public updateExistingTicketsDates = (): void => {
    if (!this.state.tickets || !this.state.general) return

    const newTickets = []
    for (let i = 0; i < this.state.tickets.each.length; i += 1) {
      const ticket = this.state.tickets.each[i]
      const dayDifference = this.state.general.daysOfEvent.length - this.state.tickets.each[i].validDates.length
      if (dayDifference > 0) {
        // this.state.tickets.each[i].validDates.push(...Array(dayDifference).fill(false))
        ticket.validDates.push(...Array(dayDifference).fill(false))
      } else if (dayDifference < 0) {
        const array = []
        for (let j = 0; j < this.state.general.daysOfEvent.length; j += 1) {
          array.push(this.state.tickets.each[j].validDates[j])
        }
        // this.state.tickets.each[i].validDates = array
        ticket.validDates = array
      }
      newTickets.push(ticket)
    }
    this.setState({
      tickets: {
        each: newTickets,
        addTickets: false,
        showAddTicketPopup: this.state.tickets.showAddTicketPopup,
      },
    })
  }

  /**
   * Function passed to AddConsumables, to know if a Consumable Card had originally a large version of the consumable
   *
   * @param consumableID
   */
  public originalConsumableHasLargeVersion = (consumableID: string): boolean => {
    if (!this.originalValues.consumables) return false

    let hasLargeVersion = false
    this.originalValues.consumables.each.forEach((consumableForm) => {
      if (consumableForm.id === consumableID && consumableForm.largeVersionName !== '') hasLargeVersion = true
    })
    return hasLargeVersion
  }

  /**
   * Submits info and changes current page.
   *
   * @param info new state
   * @param next_page next page to render
   */
  public submitInfo = (info: any, nextPageParam: number): void => {
    let pageIncrement: number
    if (nextPageParam === nextPage.NEXT) pageIncrement = 1
    else this.state.page === 0 ? pageIncrement = 0 : pageIncrement = -1

    /* Updates info related to a specific page */
    switch (this.state.page) {
    case SubPages.GENERAL:
      // this.updateExistingTicketsDates()
      this.setState({ general: info, page: this.state.page + pageIncrement })
      this.updateExistingTicketsDates()
      this.setEventSpan()
      break
    case SubPages.TICKETS:
      this.setState({ tickets: info, page: this.state.page + pageIncrement })
      break
    case SubPages.CONSUMABLES:
      this.setState({ consumables: info, page: this.state.page + pageIncrement })
      break
    case SubPages.STREAKS:
      this.setState({ streaks: info, page: this.state.page + pageIncrement })
      break
    case SubPages.COLLABORATORS:
      this.setState({ collaborators: info, page: this.state.page + pageIncrement })
      break
    default:
      break
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    if (this.state.loading) {
      return (
        <div style={{ width: '50%', justifyContent: 'center', alignItems: 'center' }}>
          <TailSpin
            visible={true}
            height="100"
            width="100"
            color="#02112E"
            radius="0"
          />
        </div>
      )
    }

    /* Decides which page to render */
    switch (this.state.page) {
    /* Displays general info page */
    case SubPages.GENERAL:
      return (
        <AddGeneralInfo
          initValues={this.state.general!}
          submitInfo={this.submitInfo}
          originalConsumableHasLargeVersion={() => false}
          mode = "EDIT"
          {...this.props}
        />
      )

      /* Displays tickets info page */
    case SubPages.TICKETS:
      return (
        <AddTickets
          initValues={this.state.tickets!}
          submitInfo={this.submitInfo}
          eventSpan={this.eventSpan}
          eventDates={Array.from(this.state.general!.daysOfEvent, (day) => { return day.date })}
          numberOfDays={this.state.general!.daysOfEvent.length}
          originalConsumableHasLargeVersion={() => false}
          mode = "EDIT"
          {...this.props}
        />
      )

      /* Displays consumables info page */
    case SubPages.CONSUMABLES:
      return (
        <AddConsumables
          initValues={this.state.consumables!}
          submitInfo={this.submitInfo}
          eventSpan={this.eventSpan}
          originalConsumableHasLargeVersion={this.originalConsumableHasLargeVersion}
          mode = "EDIT"
          {...this.props}
        />
      )

      /* Displays streaks info page */
    case SubPages.STREAKS:
      return (
        <AddStreaks
          initValues={this.state.streaks!}
          submitInfo={this.submitInfo}
          eventSpan={this.eventSpan}
          consumablesList={this.state.consumables!.each}
          originalConsumableHasLargeVersion={() => false}
          {...this.props}
        />
      )

      /* Displays collaborators info page */
    case SubPages.COLLABORATORS:
      return (
        <AddCollaborators
          initValues={this.state.collaborators!.each}
          originalValues={this.originalValues.collaborators!.each}
          submitInfo={this.submitInfo}
          originalConsumableHasLargeVersion={() => false}
          {...this.props}
        />
      )

      /* Displays success page */
    case SubPages.SUCCESS:
      return (
        <EditedSuccessfully
          {...this.props}
          values={this.state}
          originalValues={this.originalValues}
          originalImagesIDs={this.originalImagesIDs}
        />
      )

    default:
      return (null)
    }
  }
}

export default EditEvent
