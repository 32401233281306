import { Component } from 'react'
import { Auth } from 'aws-amplify' // Used to call Cognito and then access it's signUp properties
import { RouteComponentProps } from 'react-router-dom' // Tells our compiler that this component is a child of Router
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { RegisterFormValues } from '../../types/auth'
import { Formik, FormikHelpers } from 'formik' // Needed to create responsive forms
import * as Yup from 'yup' // Used to validate our form
import 'yup-phone'
import AuthButton from '../../components/AuthButton' // Used to submit form
import {
  DateInput,
  PasswordInput,
  PhoneNumberInput,
  TermsCheckboxInput,
  TextInput,
} from '../../components/utils/FormFields' // Form fields
import { checkIfAccountCreationIsValid } from '../../models/person'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import CognitoGroups from '../../constants/cognito-groups.json' /* Used to reference the cognito groups of our app */

import '../../styles/views/auth/register.css'

/**
 * SignUp section component used to register a new user. Holds a form and all it's validation capabilities.
 */
class Register extends Component<RouteComponentProps & GlobalProps> {
  /* Holds our form's initial values */
  private initialValues = {
    name: '',
    handle: '',
    phoneNumber: '',
    dateOfBirth: '',
    password: '',
    confirmPassword: '',
    acceptedTerms: false,
  }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    name: Yup.string().required()
      .max(20, i18next.t('Register.errors.name.max'))
      .matches(/^[-'a-zA-ZÀ-ÖØ-öø-ÿ ]+$/, i18next.t('Register.errors.name.format')),

    handle: Yup.string().required(i18next.t('Register.errors.handle.required'))
      .min(5, i18next.t('Register.errors.handle.min'))
      .max(30, i18next.t('Register.errors.handle.max'))
      .matches(/^[a-z0-9_.]*$/, i18next.t('Register.errors.handle.format')),

    phoneNumber: Yup.string().phone()
      .required(),

    dateOfBirth: Yup.date().required()
      .max(new Date((Date.now() - 567648000000)), i18next.t('Register.errors.dateOfBirthMax')),

    password: Yup.string().required()
      .matches(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,16}$/, i18next.t('Register.errors.password.format')),

    confirmPassword: Yup.string().required()
      .oneOf([Yup.ref('password'), null], i18next.t('Register.errors.confirmPassword.match')),

    acceptedTerms: Yup.boolean().required()
      .oneOf([true], i18next.t('Register.errors.acceptedTerms.required')),
  })

  /**
   * Handles form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   *
   * @return Returns a void promise since it won't have a specific type
   */
  private handleSubmit = async (values: RegisterFormValues, { setSubmitting }:
                         FormikHelpers<RegisterFormValues>): Promise<void> => {
    /* Accesses Cognito and tries to register a new user */
    try {

      // The phone number input that we are using adds some characters to the string in some numbers
      const parsed = values.phoneNumber.match(/\d/g)
      values.phoneNumber = `+${parsed?.join("")}`

      /* Checks if phone number is already being used by a user. If so, blocks registration */
      const res = await checkIfAccountCreationIsValid({ phone: values.phoneNumber, handle: values.handle })
      if (res === 'phone') throw new Error(i18next.t('Register.errors.phoneNumberExists'))

      // If it is an american number, we need to auto confirm the user
      if (values.phoneNumber.substring(0, 2) === '+1') {
        await Auth.signUp({
          username: values.handle,  /* Since we are using email/phone, we need to set up username as the email -> AWS doc */
          password: values.password,
          attributes: {
            name: values.name,
            phone_number: values.phoneNumber,
            'custom:cognitogroup': CognitoGroups.PERSON,
          },
          clientMetadata: {
            handle: values.handle,
            dateOfBirth: new Date(values.dateOfBirth).toISOString()
          },
        })

        /* Enables submit button again */
        setSubmitting(false)

        this.props.history.push('/welcome')
        return;
      }
      const { user } = await Auth.signUp({
        username: values.handle,  /* Since we are using email/phone, we need to set up username as the email -> AWS doc */
        password: values.password,
        attributes: {
          name: values.name,
          phone_number: values.phoneNumber,
          'custom:cognitogroup': CognitoGroups.PERSON,
        },
        clientMetadata: {
          handle: values.handle,
        },
      })

      /* Enables submit button again */
      setSubmitting(false)

      /* Redirects user to our welcome page. Our welcome page also tells him that he needs to check his email */
      this.props.history.push({
        pathname: `/code-verification/${(user as any).username}`,
        state: {
          dateOfBirth: new Date(values.dateOfBirth).toISOString(),  /* Normalizes date of birth to ISO format */
        },
      })
    } catch (error) {
      if ((error as any).message) {
        alert((error as any).message)
      } else {
        alert(JSON.stringify(error))
      }

      /* Enables submit button again */
      setSubmitting(false)
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    return (
      <div className="register-background">
        <div className="register-main">
          {/* Button used to go back to landing page */}
          <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
          <div id="register-left-half" className="main-half">
            <h1>{i18next.t('Register.registerCatchphrase')}</h1>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={this.handleSubmit}
            >
              {(props) => (
                <form className="register-form-wrapper" onSubmit={props.handleSubmit}>
                  <TextInput
                    value={props.values.name}
                    onChange={props.handleChange}
                    label={i18next.t('Register.name')}
                    name="name" />
                  <TextInput
                    value={props.values.handle}
                    onChange={props.handleChange}
                    label={i18next.t('Register.handle')}
                    name="handle"/>
                  <PhoneNumberInput
                    defaultCountry="pt"
                    value={props.values.phoneNumber}
                    onChange={props.handleChange}
                    setfield={props.setFieldValue}
                    label={i18next.t('Register.phoneNumber')}
                    name="phoneNumber"
                    onBlur={props.handleBlur} />
                  <DateInput
                    value={props.values.dateOfBirth}
                    label={i18next.t('Register.dateOfBirth')}
                    onChange={props.handleChange}
                    name={'dateOfBirth'} />
                  <PasswordInput
                    value={props.values.password}
                    onChange={props.handleChange}
                    label={i18next.t('Register.password')}
                    name="password" />
                  <PasswordInput
                    value={props.values.confirmPassword}
                    onChange={props.handleChange}
                    label={i18next.t('Register.confirmPassword')}
                    name="confirmPassword" />
                  <TermsCheckboxInput
                    name="acceptedTerms"
                    path="/"
                    onChange={props.handleChange}>
                  </TermsCheckboxInput>
                  <div id="register-button-wrapper">
                    <AuthButton text={i18next.t('Register.signUp')} type="submit" disable={props.isSubmitting}/>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div id="register-right-half" className="main-half">
            <h2>{i18next.t('Register.login-option.catchphrase')} </h2>
            <p>{i18next.t('Register.login-option.secondaryText')}</p>
            <a href="/login">
              <AuthButton text={i18next.t('Register.login-option.signIn')} property="alternative-color-scheme"/>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Register)
