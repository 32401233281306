import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */

import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
// import i18next from "i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import '../styles/views/privacy.css'

/**
 * This page shows the privacy policy of our platform.
 */
class Privacy extends Component<RouteComponentProps & GlobalProps> {
  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="privacy-total">
        <div className="privacy-main">
          <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft} /></a>
          <h1>{'Privacy Policy - English'}</h1>
          <p>{'The privacy of visitors to our website and our application is very important to us, and we are committed to protecting it. This policy explains what we will do with your personal information.'}</p>
          <p>{'The following types of personal information can be collected, stored and/or used:'}</p>
          <p>{'- Information about the user\'s computer or phone, including their IP address, geographic location, browser type and version, and operating system;'}</p>
          <p>{'- Information about visits and use of this website/application, including reference source, length of visit, page views and navigation paths on the website;'}</p>
          <p>{'- Information you provide when you create a profile on our app – your name, date of birth, address, telephone number and username;'}</p>
          <p>{'- Upon your consent, your contacts might be used (but not collected) in order to determine if any of your contacts have an account on the app;'}</p>
          <p>{'- Information you write while using the services of our website/application;'}</p>
          <p>{'- Information generated when using our website/application, including when, how often and under what circumstances you use it;'}</p>
          <p>{'- Information related to everything you buy, services you use or transactions you carry out through our website/application, including name, address, telephone number, email address and credit card details; '}</p>
          <p>{'- Information that the user publishes on our website/application with the intention of publishing it on the internet, including your username, profile photos and the content of your publications;'}</p>
          <p>{'- Information contained in any communications you send us by email, including the content and metadata of the communication;'}</p>
          <p>{'- Upon your consent, information from the image of your phone camera, might be used (but not collected) in order to allow you to interact with others users personal QRcodes;'} </p>
          <p>{'- Any other personal information that the user sends us.'}</p>
          <p>{'Personal information sent to us via our website/application will be used for the purposes specified in this policy or on the relevant pages of the website. We may use your personal information for the following:'}</p>
          <p>{'- Customize our website/application for the user;'}</p>
          <p>{'- Enable the use of the services available on our website/application;'}</p>
          <p>{'- Provide services purchased through our website/application;'}</p>
          <p>{'- Send statements, invoices and payment notifications, as well as make payments;'}</p>
          <p>{'- Send commercial communications other than marketing;'}</p>
          <p>{'- Send email notifications specifically requested by the user;'}</p>
          <p>{'- Provide third parties with statistical information about our users (third parties will not be able to identify any individual user from this information);'}</p>
          <p>{'- Deal with questions and complaints made by the user regarding our website/application;'}</p>
          <p>{'- Keep our website/application secure and prevent fraud;'}</p>
          <p>{'- Verify compliance with the terms and conditions governing the use of our website/application;'}</p>
          <p>{'- Other uses.'}</p>

          <h1>{'\n\nPolítica De Privacidade - Português'}</h1>
          <p>{'A privacidade dos visitantes do nosso site e da nossa aplicação é muito importante para nós, e estamos comprometidos em protegê-la. Esta política explica o que faremos com suas informações pessoais.'}</p>
          <p>{'Os seguintes tipos de informações pessoais podem ser recolhidos, armazenados e/ou usados:'}</p>
          <p>{'- Informações sobre o computador ou telefone do utilizador, incluindo seu endereço IP, localização geográfica, tipo e versão do browser e sistema operativo;'}</p>
          <p>{'- Informações sobre as visitas e uso deste site/aplicação, incluindo fonte de referência, duração da visita, visualizações da página e caminhos de navegação no site;'}</p>
          <p>{'- Com o seu consentimento, os seus contectos poderão ser usados ( mas não armazenados ) para determinar se algum dos seus contactos tem conta na app;'}</p>
          <p>{'- Informações que o utilizador fornece ao criar um perfil no nosso site – o seu nome, data de nascimento, morada, telemóvel e username;'}</p>
          <p>{'- Informações que você escreve durante o uso dos serviços do nosso site/aplicação;'}</p>
          <p>{'- Informações geradas ao utilizar nosso site/aplicação, incluindo quando, com que frequência e em que circunstâncias o utiliza;'}</p>
          <p>{'- Informações relacionadas a tudo o que o utilizador compra, serviços que usa ou transações que realiza através do nosso site/aplicação, incluindo nome, morada, número de telefone, endereço de e-mail e dados do cartão de crédito;'}</p>
          <p>{'- Informações que o utilizador publica no nosso site/aplicação com intenção de publicá-las na internet, incluindo o seu nome de utilizador, fotos de perfil e o conteúdo das suas publicações;'}</p>
          <p>{'- Informações contidas em quaisquer comunicações que o utilizador nos envia por e-mail, incluindo o conteúdo e os metadados da comunicação;'}</p>
          <p>{'- Com o seu consentimento, as informações da imagem da câmara do seu telemóvel podem ser utilizadas (mas não recolhidas) para permitir que os utilizadores interajam com os códigos QR pessoais uns dos outros;'}</p>
          <p>{'- Qualquer outra informação pessoal que o utilizador nos enviar.'}</p>
          <p>{'As informações pessoais que nos são enviadas por meio de nosso site/aplicação serão usadas para os fins especificados nesta política ou nas páginas relevantes do site. Podemos usar suas informações pessoais para o seguinte:'}</p>
          <p>{'- Personalizar nosso site/aplicação para o utilizador;'}</p>
          <p>{'- Possibilitar o uso dos serviços disponíveis no nosso site/aplicação;'}</p>
          <p>{'- Prestar serviços adquiridos através do nosso site/aplicação;'}</p>
          <p>{'- Enviar extratos, faturas e notificações de pagamento, bem como realizar pagamentos;'}</p>
          <p>{'- Enviar comunicações comerciais que não sejam de marketing;'}</p>
          <p>{'- Enviar notificações por e-mail solicitadas especificamente pelo utilizador;'}</p>
          <p>{'- Fornecer a terceiros informações estatísticas sobre os nossos utilizadores (terceiros não poderão identificar nenhum usuário individual a partir dessas informações)'}</p>
          <p>{'- Lidar com perguntas e reclamações feitas pelo utilizador em relação ao nosso site/aplicação;'}</p>
          <p>{'- Manter nosso site/aplicação seguros e evitar fraudes;'}</p>
          <p>{'- Verificar a conformidade com os termos e condições que regem o uso do nosso site/aplicação;'}</p>
          <p>{'- Outros usos.'}</p>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Privacy)
