import React, { Component } from 'react'
import { PaymentButtonProps } from '../types/payment-button' /* Importing the interface that allow the use of props */
import mbwayImage from '../images/mb-way.png'
import { Triangle } from 'react-loader-spinner'

import '../styles/components/payment-button.css'

/**
 * Creates a button for the auth function (login, sign up, password change etc) with custom text, a normal or
 * alternative colorway, the button type and a method while it's disabled.
 *
 */
class PaymentButton extends Component<PaymentButtonProps> {
  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    if (!this.props.disable) {
      return (
        <button formNoValidate onClick={this.props.function} className="payment-button" id={this.props.property} disabled={this.props.disable}>
          {this.props.image === 'mbWay' && <img src={mbwayImage} alt="mb-way"/>}
          {this.props.text}
        </button>
      )
    }
    return (
      <div className="payment-button-loading" id={this.props.property}>
        <Triangle
          visible={true}
          height={ window.innerWidth < 650 ? '4vw' : '2.4vw'}
          width={ window.innerWidth < 650 ? '4vw' : '2.4vw'}
          color= { this.props.property === 'alternative-color-scheme' ? '#02112E' : '#02112E'}/>
      </div>
    )
  }
}

export default PaymentButton
