import clsx from 'clsx'

// Imports needed due to Material UI
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import { useMediaQuery } from '@material-ui/core'
import React from 'react'

function checkboxStopPropagation(event: React.ChangeEvent<HTMLElement>) {
  event.stopPropagation()
}

/**
 * A customized Checkbox based on the material-ui model
 *
 * @param props used to see if the checkbox is checked or not
 */
export default function StyledCheckbox(props : any) {
  let useStyles: any

  /* This if checks the screen size and sets the styling accordingly */
  if (!useMediaQuery('screen and (max-width: 650px)')) {
    /* Desktop version */
    useStyles = makeStyles({
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      icon: { // Refers to the checkbox before being clicked
        width: '1.11vw',
        height: '1.11vw',

        backgroundColor: '#ffffff',
        border: 'solid 0.14vw #02112E',
        borderRadius: '0.21vw',

        'input:hover ~ &': { // change color when it's hovered on
          backgroundColor: '#ebf1f5',
        },

        'input:disabled ~ &': { // change color when it's disabled
          boxShadow: 'none',
          backgroundColor: '#999A9D',
          borderColor: '#999A9D',
        },
      },
      checkedIcon: { // Refers to the checkbox after being checked
        backgroundColor: '#02112E',
        backgroundImage: '',
        '&:before': {
          width: '1.11vw',
          height: '1.11vw',

          display: 'block',

          backgroundImage:
            'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath'
            + ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 '
            + '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',

          content: '""',
        },

        'input:hover ~ &': {
          backgroundColor: '#1E90FF',
          border: 'solid 0.14vw #1E90FF',
        },
      },
    })
  } else {
    /* Mobile version */
    useStyles = makeStyles({
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      icon: { // Refers to the checkbox before being clicked
        width: '3vw',
        height: '3vw',

        backgroundColor: '#ffffff',
        border: 'solid 0.22vw #02112E',
        borderRadius: '0.6vw',

        'input:hover ~ &': { // change color when it's hovered on
          backgroundColor: '#ebf1f5',
        },

        'input:disabled ~ &': { // change color when it's disabled
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      },
      checkedIcon: { // Refers to the checkbox after being checked
        backgroundColor: '#02112E',
        backgroundImage: '',
        '&:before': {
          width: '3vw',
          height: '3vw',

          display: 'block',

          backgroundImage:
            'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath'
            + ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 '
            + '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',

          content: '""',
        },

        'input:hover ~ &': {
          backgroundColor: '#1E90FF',
          border: 'solid 0.22vw #1E90FF',
        },

        'input:disabled ~ &': { // change color when it's disabled
          boxShadow: 'none',
          backgroundColor: '#999A9D',
          borderColor: '#999A9D',
        },
      },
    })
  }

  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root} // corresponding the classes we programmed to the classes of the checkbox
      disableRipple
      color="default"
      // corresponding the classes we programmed to the classes of the checkbox
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      onClick={checkboxStopPropagation}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}
