import React, { Component } from 'react'
import { LayoutProps } from '../types/layout'

import '../styles/components/layout.css'

/**
 * Component that has the styling of our common website background layout
 *
 */
class Layout extends Component<LayoutProps> {
  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    return (
      <div className="layout-total">
        { this.props.label
        && <div className="layout-title">
          <h2>{this.props.label}</h2>
        </div>
        }
        <div className="layout-main">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Layout
