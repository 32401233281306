import React from 'react'

// Imports needed due to Material UI
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { SelectInputProps } from '../../types/form-field'
import { useMediaQuery } from '@material-ui/core'

/**
 * A customized Autocomplete based on the material-ui model
 *
 * @param label used to pass the text that identifies the filed
 * @param options used to pass the options that we want to let the user select
 * @param setfield function used to change field's current value
 * @param props any other html props that we may want to pass
 */
export const StyledAutoComplete: React.FC<SelectInputProps> = ({ label, options, setfield, ...props }) => {
  let useStyles: any

  /* This if checks the screen size and sets the styling accordingly */
  if (!useMediaQuery('screen and (max-width: 650px)')) {
    /* Desktop version */
    useStyles = makeStyles({
      input: { // Refers to the text the user writes on the autocomplete
        width: '100%',
        height: '1.4vw',

        color: '#02112E',
        fontFamily: 'Montserrat',
        fontSize: '1.25vw',
        fontWeight: 600,

        marginBottom: '0.3vw',
      },
      option: { // Refers to the options that appear on the drop down menu
        width: '100%',
        height: '2.5vw',

        color: '#02112E',
        fontSize: '0.8vw',
        fontFamily: 'Montserrat',
      },
      root: { // Refers to the actual field we are going to fill
        '& label + .MuiInput-formControl': {
          marginTop: '1vw',
        },

        '& label.MuiFormLabel-filled': { // Behavior when it's filled
          fontSize: '0.87vw',
          fontWeight: 400,

          transform: 'translate(0, 0) scale(1)',
        },

        '& label.Mui-focused': { // Behavior when it's clicked
          height: '1vw',

          color: '#02112E',
          fontSize: '0.87vw',
          fontWeight: 400,

          transform: 'translate(0, 0) scale(1)',
        },

        // Programming the underline in different states
        '& .MuiInput-underline:after': {
          left: '0',

          borderBottomColor: '#02112E',
          borderBottomWidth: '0.21vw',

          transformOrigin: 'left center', // So it comes from left to the right
          transition: 'all 0.3s ease',
        },

        '& .MuiInput-underline:before': {
          borderBottomColor: '#02112E',
          borderBottomWidth: '0.07vw',
        },

        '& .MuiInput-underline:hover::before': {
          borderBottomColor: '#02112E',
          borderBottomWidth: '0.07vw',
        },

        // Disabled
        '& label.Mui-disabled': {
          color: '#999A9D',
        },

        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomColor: '#999A9D',
          borderBottomStyle: 'solid',
        },

        '& .Mui-disabled': {
          color: '#808184',
        },
        width: '100%',
        height: '3vw',
      },
      inputRoot: { // Refers to the text that identifies the filed aka "Faculdade" or "University"
        color: '#02112E',
        fontFamily: 'Montserrat',
        fontSize: '1.25vw',

        marginTop: '-0.2vw',

        transform: 'translate(0, 1.7vw) scale(1)',
      },
    })
  } else {
    /* Mobile version */
    useStyles = makeStyles({
      input: { // Refers to the text the user writes on the autocomplete
        width: '100%',
        height: '3.75vw',

        color: '#02112E',
        fontFamily: 'Montserrat',
        fontSize: '3.5vw',
        fontWeight: 600,
      },
      option: { // Refers to the options that appear on the drop down menu
        width: '100%',
        height: '2.5vw',

        color: '#02112E',
        fontSize: '2.4vw',
        fontFamily: 'Montserrat',
      },
      root: { // Refers to the actual field we are going to fill
        '& label + .MuiInput-formControl': {
          marginTop: '2vw',
        },

        '& label.MuiFormLabel-filled': { // Behavior when it's filled
          fontSize: '2.2vw',
          fontWeight: 400,

          transform: 'translate(0, 0) scale(1)',
        },

        '& label.Mui-focused': { // Behavior when it's clicked
          height: '1vw',

          color: '#02112E',
          fontSize: '2.2vw',
          fontWeight: 400,

          transform: 'translate(0, 0) scale(1)',
        },

        // Programming the underline in different states
        '& .MuiInput-underline:after': {
          left: '0',

          borderBottomColor: '#02112E',
          borderBottomWidth: '0.6vw',

          transformOrigin: 'left center', // So it comes from left to the right
          transition: 'all 0.3s ease',
        },

        '& .MuiInput-underline:before': {
          borderBottomColor: '#02112E',
          borderBottomWidth: '0.23vw',
        },

        '& .MuiInput-underline:hover::before': {
          borderBottomColor: '#02112E',
          borderBottomWidth: '0.23vw',
        },
        // Disabled
        '& label.Mui-disabled': {
          color: '#999A9D',
        },

        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomColor: '#999A9D',
          borderBottomStyle: 'solid',
        },

        '& .Mui-disabled': {
          color: '#808184',
        },
        width: '100%',
        height: '10vw',
      },
      inputRoot: { // Refers to the text that identifies the filed aka "Faculdade" or "University"
        color: '#02112E',
        fontFamily: 'Montserrat',
        fontSize: '3.5vw',

        marginTop: '-0.3vw',

        transform: 'translate(0, 4.2vw) scale(1)',
      },
    })
  }

  const classes = useStyles()
  let style

  /* This if checks the screen size and sets the styling accordingly */
  if (!useMediaQuery('screen and (max-width: 650px)')) {
    /* Desktop version */
    style = {
      width: '100%',
      height: '3.6vw',

      marginTop: '0',
      marginBottom: '0',
    }
  } else {
    /* Mobile version */
    style = {
      width: '67vw',
      height: '9.5vw',

      marginTop: '3vw',
      marginBottom: '0',
    }
  }

  return (
    <Autocomplete
      {...props}
      disabled={props.disabled}
      id={props.name}
      style={style}
      options={options}
      classes={{  // corresponding the classes we programmed to the classes of the autocomplete
        root: classes.root,
        option: classes.option,
        input: classes.input,
      }}
      noOptionsText={'No match'}
      disableClearable // customizing options
      freeSolo // customizing options
      autoHighlight // customizing options
      getOptionLabel={(option) => option}
      renderOption={(option) => (
        <React.Fragment>
          <span>{option}</span>
        </React.Fragment>
      )}
      onChange={(event, value) => {
        setfield(props.name, value !== null ? value : '')
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          label={label}
          name={props.name}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          InputLabelProps={{
            classes: { // corresponding the classes we programmed to the classes of the autocomplete
              root: classes.inputRoot,
            },
          }}
        />
      )}
    />
  )
}
