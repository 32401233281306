import React, { Component } from 'react'

import { PopupProps } from '../types/popup' /* Importing the interface that allow the use of props */

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/components/select-language.css'

/**
 * Creates the component that will allow the user to choose a different languages
 */
class SelectLanguage extends Component<PopupProps> {
  /**
  * Closes the SelectLanguage pop up and changes the language
  *
  * @param lang The language we want to change to written in two characters ( Portuguese would be 'pt_PT' )
  */
  changeLang(lang: string) {
    i18next.changeLanguage(lang).then(() => {
      this.props.close()
      i18next.options.lng = lang
    })
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (

      /* This div is used to make the background more opaque. */
      <div onClick={this.props.close} className="background" style={{
        display: this.props.show ? 'flex' : 'none',
      }}>

        <div className="select-language-wrapper"
          style={{
            opacity: this.props.show ? '1' : '0',
          }}
          /* This onClick method won't propagate the click thus it won't "reach" the background and won't close the page */
          onClick={(e) => { e.stopPropagation() }}>

          <div className="select-language-header">

            <h1>{i18next.t('SelectLanguage.select')}</h1>
            <span onClick={this.props.close} className="close-select-language">+</span>

          </div>

          <div className="select-language-body">

            <button className="select-language-button" type="button" onClick={() => { this.changeLang('pt_PT') }}>🇵🇹 Português</button>
            <button className="select-language-button" type="button" onClick={() => { this.changeLang('en_US') }}>🇬🇧 English</button>

          </div>

        </div>

      </div>

    )
  }
}

export default withTranslation()(SelectLanguage)
