import React from 'react'
import { StatsTicketsProps, StatsTicketsState } from '../../types/event-statistics'

import { Chart, registerables } from 'chart.js'
// import { Bar } from 'react-chartjs-2'
// import EventStatsDaysBar from '../../components/EventStatsDaysBar'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

Chart.register(...registerables)

Chart.defaults.font = { family: 'Montserrat', size: 13, style: 'normal', weight: null, lineHeight: 1.2 }

// const hours = ['18:30 - 19:00', '19:00 - 19:30', '19:30 - 20:00', '20:00 - 20:30', '20:30 - 21:00', '21:00 - 21:30', '21:30 - 22:00', '22:00 - 22:30', '22:30 - 23:00']
// const entries = [120, 190, 140, 90, 60, 30, 40, 50, 20]

/**
 * Shows event ticket stats sub page.
 */
class StatsTickets extends React.Component<StatsTicketsProps, StatsTicketsState> {
  constructor(props: StatsTicketsProps) {
    super(props)
    this.state = {
      graphHighlight: -1,
    }
  }

  private changeGraphHighlight = (event: React.MouseEvent, newDay: number): void => {
    this.setState({ graphHighlight: newDay })
  }

  public ticketSoldoutStyle(soldRate?: string): React.CSSProperties {
    if (!soldRate) return {}

    const soldRateHelper = Number(soldRate.substring(
      soldRate.indexOf('(') + 1,
      soldRate.lastIndexOf('%')
    ))
    if (soldRateHelper >= 100) {
      return { color: '#0F6DF9', fontWeight: 700 }
    }
    return {}
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="single-page-container">
        <div className="stat-card-container">
          {/* Taxa de bilhetes que foram vendidos em relação aos disponibilizados. Se n houver limite de bilhetes disponibilizados meter um "-" */}
          <div className="stat-card">
            <p>{i18next.t('EventStatistics.netTicketRevenues')}</p>
            <div className="value-container">
              <h1>{(this.props.totalRevenue - this.props.totalMoneyRefunded).toFixed(2)} €</h1>
            </div>
          </div>
        </div>

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.ticketRevenues')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalRevenue.toFixed(2)} €</h2>
          </div>
        </div>
        {this.props.revenueByTicket.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{value.name}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.info.toFixed(2)} €</h3>
              </div>
            </div>
          )
        })}

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.ticketsSold')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalSoldTickets}</h2>
          </div>
        </div>
        {this.props.soldTicketsByTicket.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{value.name}</h4>
              </div>
              <div className="stat-total">
                <h3 style={this.ticketSoldoutStyle(value.desc)}>{value.desc}</h3>
              </div>
            </div>
          )
        })}

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.refundedValue')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalMoneyRefunded.toFixed(2)} €</h2>
          </div>
        </div>
        {this.props.moneyRefundedByTicket.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{value.name}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.info.toFixed(2)} €</h3>
              </div>
            </div>
          )
        })}

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.nrOfRefundedTicket')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalRefundedTickets}</h2>
          </div>
        </div>
        {this.props.refundedTicketsByTicket.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{value.name}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.info}</h3>
              </div>
            </div>
          )
        })}

        {/* Bilhetes usados, descriminados por tipo de bilhete */}
        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.ticketsUsed')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalUsedTickets}</h2>
          </div>
        </div>
        {this.props.usedTicketsByTicketName.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{value.name}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.info}</h3>
              </div>
            </div>
          )
        })}

        {/*
        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{`${i18next.t('EventStatistics.entriesByTime')}`}</h3>
          </div>
        </div>

        {this.props.nrEventDays > 1
          && <div className="event-stats-days-bar-wrapper">
            <EventStatsDaysBar nrOfDays={this.props.nrEventDays} changeDay={this.changeGraphHighlight} showAll={false}/>
          </div>
        }

        <Bar
          style={{
            marginTop: '1.5vw',
          }}
          options={{
            scales: {
              yAxis: {
                title: {
                  text: i18next.t('EventStatistics.nrEntries'),
                  display: true,
                },
                grid: {
                  display: false,
                },
              },
              xAxis: {
                title: {
                  text: i18next.t('EventStatistics.hour'),
                  display: true,
                },
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  // This more specific font property overrides the global property
                  font: {
                    family: 'Montserrat',
                  },
                },
              },
            },
          }}
          data={{
            labels: hours,
            datasets: [
              {
                label: i18next.t('EventStatistics.nrEntries'),
                data: entries,
                backgroundColor: '#1E90FF',
                borderWidth: 0,
              },
            ],
          }}/>
          */}

      </div>
    )
  }
}

export default withTranslation()(StatsTickets)
