import * as Types from '../API'  // Imports types from our AWS Backend API
import { GraphQLResult } from '@aws-amplify/api'  // Used to type results from our API
import { API } from 'aws-amplify'
import { buildIntervals } from '../helpers/dates'
import { EventStatisticsResult } from 'src/types/event-statistics'

// ------------------------------------ Consumables ------------------------------------ //

/**
 * Gets all the input event's consumables revenue.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchTotalConsumablesRevenueByDays(event: Types.Event):
  Promise<GraphQLResult<Types.SearchConsumableTransactionsQuery>[]> {
  const query = /* GraphQL */ `
      query SearchConsumableTransactions(
        $filter: SearchableConsumableTransactionFilterInput
        $sort: [SearchableConsumableTransactionSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableConsumableTransactionAggregationInput]
      ) {
        searchConsumableTransactions(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          aggregateItems {
            name
            result {
              ... on SearchableAggregateScalarResult {
                __typename
                value
              }
            }
          }
          total
        }
      }
    `

  const promises = event.date?.map(({ start, end }: Types.DateSpan) => {
    const variables: Types.SearchConsumableTransactionsQueryVariables = {
      aggregates: [{
        field: Types.SearchableConsumableTransactionAggregateField.price,
        name: 'totalRevenue',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        date: {
          gte: start,
          lte: end,
        },
        isRefunded: {
          ne: true,
        },
        eventID: {
          eq: event.id,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchConsumableTransactionsQuery>[])
}

/**
 * Gets all the input event's consumables revenue.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchRevenueByConsumable(event: Types.Event):
  Promise<GraphQLResult<Types.SearchPairTransactionsQuery>[]> {
  const query = /* GraphQL */ `
    query SearchPairTransactions(
      $filter: SearchablePairTransactionFilterInput
      $sort: [SearchablePairTransactionSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchablePairTransactionAggregationInput]
    ) {
      searchPairTransactions(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        aggregateItems {
          name
          result {
            ... on SearchableAggregateScalarResult {
              __typename
              value
            }
          }
        }
      }
    }`

  const promises = event.consumables?.items.map((consumable: Types.Consumable | null) => {
    const variables: Types.SearchPairTransactionsQueryVariables = {
      aggregates: [{
        field: Types.SearchablePairTransactionAggregateField.pricePaid,
        name: consumable?.name || 'total revenue',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        pairTransactionConsumableId: {
          eq: consumable?.id,
        },
        isRefunded: {
          ne: true,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchPairTransactionsQuery>[])
}

/**
 * Gets all the input event's consumables count.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchCountByConsumable(event: Types.Event):
  Promise<GraphQLResult<Types.SearchPairTransactionsQuery>[]> {
  const query = /* GraphQL */ `
    query SearchPairTransactions(
      $filter: SearchablePairTransactionFilterInput
      $sort: [SearchablePairTransactionSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchablePairTransactionAggregationInput]
    ) {
      searchPairTransactions(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        aggregateItems {
          name
          result {
            ... on SearchableAggregateScalarResult {
              __typename
              value
            }
          }
        }
      }
    }`

  const promises = event.consumables?.items.map((consumable: Types.Consumable | null) => {
    const variables: Types.SearchPairTransactionsQueryVariables = {
      aggregates: [{
        field: Types.SearchablePairTransactionAggregateField.amount,
        name: consumable?.id || 'total count',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        pairTransactionConsumableId: {
          eq: consumable?.id,
        },
        isRefunded: {
          ne: true,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchPairTransactionsQuery>[])
}

/**
 * Gets all the input event's streaks total number of times completed.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchStreakCount(event: Types.Event): Promise<GraphQLResult<Types.SearchStreakStatesQuery>[]> {
  const query = /* GraphQL */ `
    query SearchStreakStates(
      $filter: SearchableStreakStateFilterInput
      $sort: [SearchableStreakStateSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchableStreakStateAggregationInput]
    ) {
      searchStreakStates(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        aggregateItems {
          name
          result {
            ... on SearchableAggregateScalarResult {
              __typename
              value
            }
          }
        }
      }
    }`

  const promises = event.streaks?.items.map((streak: Types.Streak | null) => {
    const variables: Types.SearchStreakStatesQueryVariables = {
      aggregates: [{
        field: Types.SearchableStreakStateAggregateField.nrOfTimesCompleted,
        name: streak?.name || 'streak',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        streakStateStreakId: {
          eq: streak?.id,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchStreakStatesQuery>[])
}

/**
 * Gets all the input event's total consumables refund value and count.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchRefundedConsumables(event: Types.Event):
  Promise<GraphQLResult<Types.SearchConsumableTransactionsQuery>> {
  const query = /* GraphQL */ `
    query SearchConsumableTransactions(
      $filter: SearchableConsumableTransactionFilterInput
      $sort: [SearchableConsumableTransactionSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchableConsumableTransactionAggregationInput]
    ) {
      searchConsumableTransactions(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        aggregateItems {
          name
          result {
            ... on SearchableAggregateScalarResult {
              __typename
              value
            }
          }
        }
        total
      }
    }`

  const variables: Types.SearchConsumableTransactionsQueryVariables = {
    aggregates: [{
      field: Types.SearchableConsumableTransactionAggregateField.price,
      name: event?.id || 'total refunded',
      type: Types.SearchableAggregateType.sum,
    }],
    filter: {
      isRefunded: {
        eq: true,
      },
      eventID: {
        eq: event.id,
      },
    },
  }

  return API.graphql({ query, variables }) as Promise<GraphQLResult<Types.SearchConsumableTransactionsQuery>>
}

// ------------------------------------ Tickets ------------------------------------ //

/**
 * Gets input event's ticket stats like revenue, total available, total sold.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchTicketsStats(event: Types.Event): Promise<GraphQLResult<Types.SearchTicketTransactionsQuery>[]> {
  const query = /* GraphQL */ `
    query SearchTicketTransactions(
      $filter: SearchableTicketTransactionFilterInput
      $sort: [SearchableTicketTransactionSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchableTicketTransactionAggregationInput]
    ) {
      searchTicketTransactions(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        aggregateItems {
          name
          result {
            ... on SearchableAggregateScalarResult {
              __typename
              value
            }
          }
        }
        total
      }
    }`

  const promises = event.tickets?.items.map((ticket: Types.Ticket | null) => {
    const variables: Types.SearchTicketTransactionsQueryVariables = {
      aggregates: [{
        field: Types.SearchableTicketTransactionAggregateField.price,
        name: ticket?.name || ticket?.validDates[0].start || 'no valid ticket name',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        ticketTransactionTicketId: {
          eq: ticket?.id,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchTicketTransactionsQuery>[])
}

/**
 * Gets input event's ticket refund count.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchRefundedTicketsStats(event: Types.Event): Promise<GraphQLResult<Types.SearchTicketRefundsQuery>[]> {
  const query = /* GraphQL */ `
    query SearchTicketRefunds(
      $filter: SearchableTicketRefundFilterInput
      $sort: [SearchableTicketRefundSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchableTicketRefundAggregationInput]
    ) {
      searchTicketRefunds(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        aggregateItems {
          name
          result {
            ... on SearchableAggregateScalarResult {
              __typename
              value
            }
          }
        }
        total
      }
    }`

  const promises = event.tickets?.items.map((ticket: Types.Ticket | null) => {
    const variables: Types.SearchTicketRefundsQueryVariables = {
      aggregates: [{
        field: Types.SearchableTicketRefundAggregateField.refundedAmount,
        name: ticket?.name || ticket?.validDates[0].start || 'no valid ticket name',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        ticketID: {
          eq: ticket?.id,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchTicketRefundsQuery>[])
}

/**
 * Counts number of times a ticket was read.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchTicketsReadCount(event: Types.Event): Promise<GraphQLResult<Types.SearchPersonTicketsQuery>[]> {
  const query = /* GraphQL */ `
    query SearchPersonTickets(
      $filter: SearchablePersonTicketFilterInput
      $sort: [SearchablePersonTicketSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchablePersonTicketAggregationInput]
    ) {
      searchPersonTickets(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        total
      }
    }`

  const promises = event.tickets?.items.map((ticket: Types.Ticket | null) => {
    const variables: Types.SearchPersonTicketsQueryVariables = {
      filter: {
        readDates: {
          exists: true,
        },
        personTicketTicketId: {
          eq: ticket?.id,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchPersonTicketsQuery>[])
}

/**
 * Counts number of entries by 30m by each event day.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function _fetchTicketsEntriesByHourByDay(event: Types.Event):
  Promise<GraphQLResult<Types.SearchPersonTicketsQuery>[][]> {
  const query = /* GraphQL */ `
    query SearchPersonTickets(
      $filter: SearchablePersonTicketFilterInput
      $sort: [SearchablePersonTicketSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchablePersonTicketAggregationInput]
    ) {
      searchPersonTickets(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        total
      }
    }`

  const promises = event.date?.map(({ start, end }) => {
    const intervals = buildIntervals(Date.parse(start), Date.parse(end), 1800000)  // Jump is 30m
    const innerPromises = []  // Holds all promises between each interval

    for (let i = 0; i < intervals.length - 1; i += 1) {
      const variables: Types.SearchPersonTicketsQueryVariables = {
        filter: {
          and: [{
            readDates: {
              gte: intervals[i],
            },
          }, {
            readDates: {
              lt: intervals[i + 1],
            },
          }],
        },
      }

      innerPromises.push(API.graphql({ query, variables }))
    }
    return Promise.all(innerPromises as GraphQLResult<Types.SearchPersonTicketsQuery>[])
  })

  return Promise.all(promises)
}

// ------------------------------------ General ------------------------------------ //

/**
 * Gets all the input event's total number of attenders.
 *
 * @param event event whose statistics are being fetched
 *
 * @return objects or error
 */
function fetchNumberOfPeopleInEvent(event: Types.Event): Promise<GraphQLResult<Types.SearchPersonEventStatesQuery>> {
  const query = /* GraphQL */ `
    query SearchPersonEventStates(
      $filter: SearchablePersonEventStateFilterInput
      $sort: [SearchablePersonEventStateSortInput]
      $limit: Int
      $nextToken: String
      $from: Int
      $aggregates: [SearchablePersonEventStateAggregationInput]
    ) {
      searchPersonEventStates(
        filter: $filter
        sort: $sort
        limit: $limit
        nextToken: $nextToken
        from: $from
        aggregates: $aggregates
      ) {
        total
      }
    }`

  const variables: Types.SearchPersonEventStatesQueryVariables = {
    filter: {
      eventID: {
        eq: event.id,
      },
    },
  }

  return API.graphql({ query, variables }) as Promise<GraphQLResult<Types.SearchPersonEventStatesQuery>>
}

/**
 * Gets outgoing fee applied to the company.
 *
 * @param companyID company id that published this event
 *
 * @return objects or error
 */
function fetchCompanyFee(companyID: string): Promise<GraphQLResult<Types.GetCompanyQuery>> {
  const query = /* GraphQL */ `
    query GetCompany($id: ID!) {
      getCompany(id: $id) {
        ticketsFee
        consumablesFee
      }
    }`

  const variables: Types.GetCompanyQueryVariables = {
    id: companyID,
  }

  return API.graphql({ query, variables }) as Promise<GraphQLResult<Types.GetCompanyQuery>>
}
// ------------------------------------ Collaborators ------------------------------------ //
/**
 * Gets all the collaborators working at the event.
 *
 * @param event event whose collaborators are being fetched
 * @param collaboratorID collaborator id to fetch
 *
 * @return objects or error
 */
function fetchCollaboratorInfo(event: Types.Event, collaboratorID: string):
  Promise<GraphQLResult<Types.SearchConsumableTransactionsQuery>[]> {
  const query = `
      query SearchConsumableTransactions(
        $filter: SearchableConsumableTransactionFilterInput
        $sort: [SearchableConsumableTransactionSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableConsumableTransactionAggregationInput]
      ) {
        searchConsumableTransactions(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          aggregateItems {
            name
            result {
              ... on SearchableAggregateScalarResult {
                __typename
                value
              }
            }
          }
          total
        }
      }
    `

  const promises = event.date?.map(({ start, end }: Types.DateSpan) => {
    const variables: Types.SearchConsumableTransactionsQueryVariables = {
      aggregates: [{
        field: Types.SearchableConsumableTransactionAggregateField.price,
        name: 'totalRevenue',
        type: Types.SearchableAggregateType.sum,
      }],
      filter: {
        date: {
          gte: start,
          lte: end,
        },
        isRefunded: {
          ne: true,
        },
        eventID: {
          eq: event.id,
        },
        collaboratorID: {
          eq: collaboratorID,
        },
      },
    }

    return API.graphql({ query, variables })
  })

  return Promise.all(promises as GraphQLResult<Types.SearchConsumableTransactionsQuery>[])
}

/**
 * Gets all the collaborators working at the event.
 *
 * @param event event whose collaborators are being fetched
 * @param collaboratorID collaborator id to fetch
 *
 * @return objects or error
 */
function fetchCollaboratorReturnInfo(event: Types.Event, collaboratorID: string):
  Promise<GraphQLResult<Types.SearchConsumableTransactionsQuery>> {
  const query = `
      query SearchConsumableTransactions(
        $filter: SearchableConsumableTransactionFilterInput
        $sort: [SearchableConsumableTransactionSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableConsumableTransactionAggregationInput]
      ) {
        searchConsumableTransactions(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          aggregateItems {
            name
            result {
              ... on SearchableAggregateScalarResult {
                __typename
                value
              }
            }
          }
          total
        }
      }
    `
  const variables: Types.SearchConsumableTransactionsQueryVariables = {
    aggregates: [{
      field: Types.SearchableConsumableTransactionAggregateField.price,
      name: 'totalRevenue',
      type: Types.SearchableAggregateType.sum,
    }],
    filter: {
      isRefunded: {
        ne: false,
      },
      eventID: {
        eq: event.id,
      },
      collaboratorID: {
        eq: collaboratorID,
      },
    },
  }

  return API.graphql({ query, variables }) as Promise<GraphQLResult<Types.SearchConsumableTransactionsQuery>>
}

/**
 * Gets all the collaborators working at the event.
 *
 * @param event event whose collaborators are being fetched
 *
 * @return objects or error
 */
function fetchCollaborators(event: Types.Event): Promise<GraphQLResult<Types.ListCollaboratorEventConnectionsQuery>> {
  const query = `
    query ListCollaboratorEventConnections(
        $filter: ModelCollaboratorEventConnectionFilterInput
        $limit: Int
        $nextToken: String
    ) {
      listCollaboratorEventConnections(
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
          items {
              collaborator {
                  id
                  name
                  handle
              }
          }
      }
    }`

  const variables: Types.ListCollaboratorEventConnectionsQueryVariables = {
    filter: {
      eventID: {
        eq: event.id,
      },
    },
  }

  return API.graphql({ query, variables }) as Promise<GraphQLResult<Types.ListCollaboratorEventConnectionsQuery>>
}
// ------------------------------------- Stats ------------------------------------- //

/**
 * Gets all the input event's statistics.
 *
 * @param event event whose statistics have to be fetched
 *
 * @return objects or error
 */
export async function fetchEventStatistics(event: Types.Event): Promise<EventStatisticsResult> {
  try {
    const result = await Promise.all([
      fetchTotalConsumablesRevenueByDays(event),
      fetchCountByConsumable(event),
      fetchStreakCount(event),
      fetchTicketsStats(event),
      fetchTicketsReadCount(event),
      fetchNumberOfPeopleInEvent(event),
      fetchCompanyFee(event.companyID),
      fetchRevenueByConsumable(event),
      fetchRefundedTicketsStats(event),
      fetchRefundedConsumables(event),
      // fetchTicketsEntriesByHourByDay(event),
      fetchCollaborators(event),
    ])

    // Declares variables that will be returned to the frontend. We do this beforehand to improve performance
    // by minimizing the amount of loops
    const [consumables, tickets, general, collaborators]: [Consumables, Tickets, General, Collaborators] = [
      {
        nrEventDays: event.date.length,
        revenueByDates: [],
        totalRevenue: 0,
        nrOfTransactionsByDates: [],
        totalNrOfTransactions: 0,
        medianConsumerism: 0,
        leaderboardConsumables: [],
        leaderboardStreaks: [],
        mostPopularConsumable: '',
        nrOfRefunded: 0,
        totalRefunded: 0,
      },
      {
        nrEventDays: event.date.length,
        revenueByTicket: [],
        totalRevenue: 0,
        soldTicketsByTicket: [],
        totalSoldTickets: 0,
        refundedTicketsByTicket: [],
        totalRefundedTickets: 0,
        moneyRefundedByTicket: [],
        totalMoneyRefunded: 0,
        usedTicketsByTicketName: [],
        totalUsedTickets: 0,
        entriesByHourByDay: [],
      },
      {
        ticketRevenueByTicket: [],
        consumableRevenueByDates: [],
        grossRevenue: 0,
        refundedTicketsValue: 0,
        taxedRevenue: 0,
        netRevenue: 0,
        nrOfPeopleInEvent: 0,
      },
      {
        nrEventDays: event.date.length,
        infoCollaborators: [],
      },
    ]

    // tickets.entriesByHourByDay = event.date?.map(({ start, end }, dayIndex) => {
    //   const intervals = buildIntervals(Date.parse(start), Date.parse(end), 1800000)  // Jump is 30m
    //   const entries = []
    //   for (let i = 0; i < intervals.length - 1; i += 1) {
    //     const spanStart = new Date(intervals[i])
    //     const spanEnd = new Date(intervals[i + 1])
    //     entries.push({
    //       name: `${spanStart.getHours()}:${spanStart.getMinutes()} - ${spanStart.getHours()}:${spanEnd.getMinutes()}`,
    //       info: result[7][dayIndex][i].data?.searchPersonTickets?.total || 0,
    //     })
    //   }
    //   return entries
    // })

    /* Builds information for each ticket */
    for (let i = 0; i < (event.tickets?.items?.length || 0); i += 1) {
      const name = event.tickets?.items[i]?.name
        || new Date(event.tickets?.items[i]?.validDates[0].start || 0).toLocaleString()
        || 'ticket does not have a name'

      tickets.usedTicketsByTicketName.push({
        name: name,
        info: result[4][i].data?.searchPersonTickets?.total || 0,
      })
      tickets.revenueByTicket.push({
        name: name,
        info: (
          result[3][i].data?.searchTicketTransactions?.aggregateItems[0]
            ?.result as Types.SearchableAggregateScalarResult).value,
      })
      tickets.moneyRefundedByTicket.push({
        name: name,
        info: result[8][i].data?.searchTicketRefunds?.aggregateItems[0]
          ?.result
          ? (result[8][i].data?.searchTicketRefunds?.aggregateItems[0]
            ?.result as Types.SearchableAggregateScalarResult).value
          : 0,
      })
      tickets.refundedTicketsByTicket.push({
        name: name,
        info: result[8][i].data?.searchTicketRefunds?.total || 0,
      })
      const stock = event.tickets?.items[i]?.initialStock
      const sold = result[3][i].data?.searchTicketTransactions?.total || 0
      tickets.soldTicketsByTicket.push({
        name: name,
        info: sold,
        desc: stock ? `${sold} / ${stock} (${(sold / stock * 100).toFixed(2)}%)` : `${sold}`,
      })
    }

    /* Builds rest of ticket related info */
    tickets.totalMoneyRefunded = tickets.moneyRefundedByTicket.reduce((acc, curr) => acc + curr.info, 0)
    tickets.totalRefundedTickets = tickets.refundedTicketsByTicket.reduce((acc, curr) => acc + curr.info, 0)
    tickets.totalUsedTickets = tickets.usedTicketsByTicketName.reduce((acc, curr) => acc + curr.info, 0)
    tickets.totalRevenue = tickets.revenueByTicket.reduce((acc, curr) => acc + curr.info, 0)
    tickets.totalSoldTickets = tickets.soldTicketsByTicket.reduce((acc, curr) => acc + curr.info, 0)

    /* Loops over each day of the event (since we are basically sectioning based on days) and builds the info that it can */
    for (let i = 0; i < event.date.length; i += 1) {
      consumables.revenueByDates.push((
        result[0][i].data?.searchConsumableTransactions
          ?.aggregateItems[0]?.result as Types.SearchableAggregateScalarResult
      ).value)
      consumables.nrOfTransactionsByDates.push(result[0][i].data?.searchConsumableTransactions?.total || 0)
    }

    /* Updates refunded consumables amount */
    consumables.nrOfRefunded = result[9].data?.searchConsumableTransactions?.total || 0
    consumables.totalRefunded = result[9].data?.searchConsumableTransactions?.aggregateItems[0]?.result
      ? (result[9].data?.searchConsumableTransactions?.aggregateItems[0]
        ?.result as Types.SearchableAggregateScalarResult).value
      : 0

    /* Uses previous info to calculate total values and other info since they compound of of them */
    general.nrOfPeopleInEvent += result[5].data?.searchPersonEventStates?.total || 0
    consumables.totalRevenue = consumables.revenueByDates.reduce((partial, acc) => partial + acc, 0)
    consumables.totalNrOfTransactions = consumables.nrOfTransactionsByDates
      .reduce((partial, acc) => partial + acc, 0)
    consumables.medianConsumerism += consumables.totalNrOfTransactions / general.nrOfPeopleInEvent

    /* Calculates gross, taxed and net revenue of this event */
    const consumablesFee = result[6].data?.getCompany?.consumablesFee || 0
    general.refundedTicketsValue = tickets.totalMoneyRefunded
    general.grossRevenue = consumables.totalRevenue + tickets.totalRevenue - tickets.totalMoneyRefunded
    general.taxedRevenue = parseFloat((consumables.totalRevenue * consumablesFee / 100).toFixed(2))
    general.netRevenue = general.grossRevenue - general.taxedRevenue

    /* Builds and sorts info from consumables so that we can have a leaderboard based on total revenue from each consumable */
    consumables.leaderboardConsumables = result[1].map((item, index) => {
      const r = (result[7][index].data?.searchPairTransactions
        ?.aggregateItems[0]?.result as Types.SearchableAggregateScalarResult
      ).value as number
      const c = (
        item.data?.searchPairTransactions?.aggregateItems[0]?.result as Types.SearchableAggregateScalarResult
      ).value as number

      return {
        revenue: r,
        quantity: c,
        name: event.consumables?.items[index]?.name || '',
        medianPrice: parseFloat((r / c).toFixed(2)),
      }
    })
    consumables.leaderboardConsumables.sort((a, b) => { return b.revenue - a.revenue })
    consumables.mostPopularConsumable = consumables.leaderboardConsumables.length > 0 ? consumables.leaderboardConsumables[0].name : 'N/A'

    /* Builds and sorts info from streak so that we can have a leaderboard based on total number of streaks completed */
    consumables.leaderboardStreaks = result[2].map((item) => {
      const c = (
        item.data?.searchStreakStates?.aggregateItems[0]?.result as Types.SearchableAggregateScalarResult
      ).value
      const n = item.data?.searchStreakStates?.aggregateItems[0]?.name || ''

      return {
        nrOfCompletedStreaks: c,
        name: n || '',
      }
    })
    consumables.leaderboardStreaks.sort((a, b) => { return b.nrOfCompletedStreaks - a.nrOfCompletedStreaks })

    /* Discriminates revenue by consumables and tickets */
    general.ticketRevenueByTicket = tickets.revenueByTicket
    general.consumableRevenueByDates = consumables.revenueByDates

    // console.log('Event: ', event)
    // console.log('Result: ', result)

    let auxCollaborator = null
    let auxRevenue = null
    let auxReturn = null

    for (let i = 0; i < (result[10].data?.listCollaboratorEventConnections?.items.length || 0); i += 1) {
      auxCollaborator = result[10].data?.listCollaboratorEventConnections?.items[i]
      collaborators.infoCollaborators.push({
        name: auxCollaborator?.collaborator.name as string,
        handle: auxCollaborator?.collaborator.handle as string,
        id: auxCollaborator?.collaborator.id as string,
        revenueByDate: [],
        nrOfTransactionsByDate: [],
        totalRefunded: 0,
      })
    }

    for (let j = 0; j < (collaborators.infoCollaborators.length || 0); j += 1) {

      auxRevenue = await Promise.all(await fetchCollaboratorInfo(event, collaborators.infoCollaborators[j].id))
      auxReturn = await fetchCollaboratorReturnInfo(event, collaborators.infoCollaborators[j].id)
      collaborators.infoCollaborators[j].totalRefunded = (auxReturn.data?.searchConsumableTransactions?.aggregateItems[0]?.result as Types.SearchableAggregateScalarResult).value

      for (let i = 0; i < event.date.length; i += 1) {
        collaborators.infoCollaborators[j].revenueByDate.push((
          auxRevenue[i].data?.searchConsumableTransactions
            ?.aggregateItems[0]?.result as Types.SearchableAggregateScalarResult
        ).value)
        collaborators.infoCollaborators[j]
          .nrOfTransactionsByDate.push(auxRevenue[i].data?.searchConsumableTransactions?.total || 0)
      }
    }

    console.log('Collaborators:', JSON.stringify(collaborators))

    return {
      general: general,
      tickets: tickets,
      consumables: consumables,
      collaborators: collaborators,
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching event statistics from database ', error)
    let message = 'Failed to gather statistics'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}

// ------------------------------------- Stats helper types ------------------------------------- //

type TicketInfo = {
  name: string,
  info: number,
  desc?: string,
}

type CollaboratorInfo = {
  id: string,
  name: string,
  handle: string,
  revenueByDate: number[],
  nrOfTransactionsByDate: number[],
  totalRefunded: number
}

type Tickets = {
  nrEventDays: number,
  revenueByTicket: TicketInfo[],
  totalRevenue: number,
  soldTicketsByTicket: TicketInfo[],
  totalSoldTickets: number,
  refundedTicketsByTicket: TicketInfo[],
  totalRefundedTickets: number,
  moneyRefundedByTicket: TicketInfo[],
  totalMoneyRefunded: number,
  usedTicketsByTicketName: TicketInfo[],
  totalUsedTickets: number,
  entriesByHourByDay: TicketInfo[][],
}

type General = {
  consumableRevenueByDates: number[],
  ticketRevenueByTicket: TicketInfo[],
  nrOfPeopleInEvent: number,
  grossRevenue: number,
  refundedTicketsValue: number,
  taxedRevenue: number,
  netRevenue: number,
}

type Consumables = {
  nrEventDays: number,
  revenueByDates: number[],
  totalRevenue: number,
  nrOfTransactionsByDates: number[],
  totalNrOfTransactions: number,
  mostPopularConsumable: string,
  medianConsumerism: number,
  nrOfRefunded: number,
  totalRefunded: number,
  leaderboardConsumables: {
    name: string,
    quantity: number,
    medianPrice: number,
    revenue: number,
  }[],
  leaderboardStreaks: {
    name: string,
    nrOfCompletedStreaks: number
  }[],
}

type Collaborators = {
  nrEventDays: number,
  infoCollaborators: CollaboratorInfo[]
}
