import * as Types from '../API'  // Imports types from our AWS Backend API
import { GraphQLResult } from '@aws-amplify/api'  // Used to type results from our API
import { API } from 'aws-amplify'
import {
  createStreak as createStreakMutation,
  updateStreak as updateStreakMutation,
  deleteStreak as deleteStreakMutation,
} from '../graphql/mutations'  // Mutation definition

/**
 * Gets all the event's streaks.
 *
 * @return objects or error
 */
export async function listEventStreaks(input: Types.StreakByEventQueryVariables): Promise<Types.Streak[]> {
  try {
    const optimizedQuery = `
      query StreakByEvent(
        $eventID: ID!
        $name: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelStreakFilterInput
        $limit: Int
        $nextToken: String
      ) {
        streakByEvent(
          eventID: $eventID
          name: $name
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            owner
            name
            eventID
            promoPrice
            promoPair {
              id
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                prices {
                  price
                  memberPrice
                  promoPrices {
                    dateSpan {
                      start
                      end
                    }
                    memberPrice
                    price
                  }
                }
                eventID
                smallOrLargeRef {
                  id
                  owner
                  type
                  size
                  name
                  prices {
                    price
                    memberPrice
                    promoPrices {
                      dateSpan {
                        start
                        end
                      }
                      memberPrice
                      price
                    }
                  }
                  eventID
                  consumableSmallOrLargeRefId
                }
                consumableSmallOrLargeRefId
              }
              pairStreakConsumableId
              owner
            }
            set {
              items {
                id
                streakID
                amount
                consumable {
                  id
                  owner
                  type
                  size
                  name
                  prices {
                    price
                    memberPrice
                    promoPrices {
                      dateSpan {
                        start
                        end
                      }
                      memberPrice
                      price
                    }
                  }
                  eventID
                  smallOrLargeRef {
                    id
                    owner
                    type
                    size
                    name
                    prices {
                      price
                      memberPrice
                      promoPrices {
                        dateSpan {
                          start
                          end
                        }
                        memberPrice
                        price
                      }
                    }
                    eventID
                    consumableSmallOrLargeRefId
                  }
                  consumableSmallOrLargeRefId
                }
                pairStreakConsumableId
                owner
              }
              nextToken
            }
            streakPromoPairId
          }
          nextToken
        }
      }
    `

    /* Creates our graphql input object */
    const inputObject = { query: optimizedQuery, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.StreakByEventQuery>

    /* Transforms gathered data into an array of object with this properties. If not found, we return an empty list */
    return (response.data?.streakByEvent?.items as Types.Streak[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the event\'s streaks from database: ', error)

    let message = 'Failed to gather event streaks'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Gets a specific streak from our database.
 *
 * @param input object with the needed info to call this query
 *
 * @return object or error
 */
export async function getStreak(input: Types.GetStreakQueryVariables): Promise<Types.Streak> {
  try {
    const query = `
    query GetStreak($id: ID!) {
      getStreak(id: $id) {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
            }
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              eventID
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              eventID
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            pairStreakConsumableId
          }
          nextToken
        }
        streakPromoPairId
      }
    }
  `


    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetStreakQuery>

    /* Returns the gathered event */
    return (response.data?.getStreak) as Types.Streak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching streak ', input.id, ' from the database: ', error)

    let message = 'Failed to gather streak'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Creates a streak inside our database.
 *
 * @param input inputs used to create a new streak
 *
 * @return object or error
 */
export async function createStreak(input: Types.CreateStreakInput): Promise<Types.Streak> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createStreakMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateStreakMutation>

    /* Returns created event */
    return (response.data?.createStreak) as Types.Streak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new streak in the database: ', error)

    let message = 'Failed to create streak'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Updates an already existing streak in our database.
 *
 * @param input inputs used to update an streak
 *
 * @return object or error
 */
export async function updateStreak(input: Types.UpdateStreakInput): Promise<Types.Streak> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updateStreakMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdateStreakMutation>

    /* Returns created event */
    return (response.data?.updateStreak) as Types.Streak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while updating a streak in the database: ', error)

    let message = 'Failed to update streak'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Removes a streak from our database. A streak can only be removed by the company that created it.
 *
 * @param input inputs used to delete a streak
 *
 * @return object or error
 */
export async function deleteStreak(input: Types.DeleteStreakInput): Promise<Types.Streak> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteStreakMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteStreakMutation>

    /* Returns created event */
    return (response.data?.deleteStreak) as Types.Streak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a streak from the database: ', error)

    let message = 'Failed to delete a streak'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}
