import React from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { StatsConsumablesProps, StatsConsumablesState } from '../../types/event-statistics'


/**
 * Shows event consumables stats sub page.
 */
class StatsConsumables extends React.Component<StatsConsumablesProps, StatsConsumablesState> {
  constructor(props: StatsConsumablesProps) {
    super(props)
    this.state = {
      consumableHighlight: -1,
      streakHighlight: -1,
    }
  }

  public NaNCheck(number: number, decimals = 0): string {
    if (isNaN(number)) {
      return '-'
    }
    return number.toFixed(decimals)
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="single-page-container">

        <div className="stat-card-container">
          <div className="stat-card">
            <p>{i18next.t('EventStatistics.averageConsumption')}</p>
            <div className="value-container">
              <h1>{this.NaNCheck(this.props.medianConsumerism, 2)}</h1>
            </div>
          </div>

          <div className="stat-card">
            <p>{i18next.t('EventStatistics.mostPopularConsumable')}</p>
            <div className="value-container">
              <h1>{this.props.mostPopularConsumable}</h1>
            </div>
          </div>

          <div className="stat-card" style={ { visibility: 'hidden' } }/>
        </div>

        <div className="stat-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.consumableRevenues')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalRevenue.toFixed(2)} €</h2>
          </div>
        </div>
        {this.props.revenueByDates.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{`${i18next.t('EventStatistics.revenueConsumablesByDay')} ${index + 1}`}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.toFixed(2)} €</h3>
              </div>
            </div>
          )
        })}

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.nrTransactions')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalNrOfTransactions}</h2>
          </div>
        </div>
        {this.props.nrOfTransactionsByDates.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{`${i18next.t('EventStatistics.transactionByDay')} ${index + 1}`}</h4>
              </div>
              <div className="stat-total">
                <h3>{value}</h3>
              </div>
            </div>
          )
        })}

        <div className="leaderboard">
          <div className="stat-title">
            <h3>{`${i18next.t('EventStatistics.consumablesLeaderboard')}`}</h3>
          </div>
          <div className="leaderboard-stats">
            <h4>{`${i18next.t('EventStatistics.name')}`}</h4>
            <div className="leaderboard-values">
              <h4>{`${i18next.t('EventStatistics.quantity')}`}</h4>
              <h4>{`${i18next.t('EventStatistics.avgPrice')}`}</h4>
              <h4>{`${i18next.t('EventStatistics.revenues')}`}</h4>
            </div>
          </div>
          { this.props.leaderboardConsumables.map((consumable, index) => (
            <div className="leaderboard-entry" key={index}>
              <h4>{consumable.name}</h4>
              <div className="leaderboard-values">
                <p>{consumable.quantity}</p>
                <p>{this.NaNCheck(consumable.medianPrice, 2)} €</p>
                <h4>{consumable.revenue.toFixed(2)} €</h4>
              </div>
            </div>
          ))}
        </div>
        { this.props.leaderboardStreaks.length > 0
        && <div className="leaderboard">
          <div className="stat-title">
            <h3>{`${i18next.t('EventStatistics.streakLeaderboard')}`}</h3>
          </div>
          <div className="leaderboard-stats">
            <h4>{`${i18next.t('EventStatistics.name')}`}</h4>
            <h4 id="completed-streaks-title">{`${i18next.t('EventStatistics.completedStreaks')}`}</h4>
          </div>
          { this.props.leaderboardStreaks.map((streak, index) => (
            <div className="leaderboard-entry" key={index}>
              <h4>{streak.name}</h4>
              <h4 id="completed-streaks">{streak.nrOfCompletedStreaks}</h4>
            </div>
          ))}
        </div>
        }

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.refundedValue')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.totalRefunded.toFixed(2)} €</h2>
          </div>
        </div>

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.nrOfRefundedTransactions')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.nrOfRefunded.toFixed(0)}</h2>
          </div>
        </div>

      </div>
    )
  }
}

export default withTranslation()(StatsConsumables)
