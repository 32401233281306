import { Component } from 'react'
import { CSSProperties } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */
import SearchBar from '../components/utils/SearchBar'
import EventCard from '../components/EventCard'
import { TailSpin } from 'react-loader-spinner'
import { listEvents, listEventsNoLocation } from '../models/event'
import { fetchSetOfImages } from '../models/images'
import { AllEventsState } from '../types/all-events'
import { Event } from '../API'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { Link } from 'react-router-dom'

import '../styles/views/all-events.css'

import { getGeo } from 'geoplugin'

/**
 * This page shows all the events.
 */
class AllEvents extends Component<RouteComponentProps & GlobalProps, AllEventsState> {
  /**
   * AllEvents class constructor.
   *
   * @param props required props
   */
  constructor(props: RouteComponentProps & GlobalProps) {
    super(props)
    this.state = {
      search: '',
      events: [],
      eventImages: [],
      shownEvents: [],
      noEvents: true,
      loading: true,
    }
  }

  /**
   * Fetches events in database.
   */
  async componentDidMount() {
    let events: Event[] = []

    // Since some people have location disabled, we have to account for that and fetch the events without location
    try {
      const loc = await getGeo()
      events = await listEvents({
        lon: parseFloat(loc.longitude),
        lat: parseFloat(loc.latitude),
      }, 300)
    } catch (error) {
      console.error('Could not load location error: ', error)
      events = await listEventsNoLocation()
    }

    try {
      /* Gathers events' images */
      const eventImages = await fetchSetOfImages(Array.from(events, (event) => {
        return {
          filename: event.banner?.filename ? event.banner?.filename : '',
          identityId: event.banner?.identityID ? event.banner?.identityID : '',
        }
      }))

      /* Updates state */
      this.setState({ events, eventImages, loading: false, noEvents: !(events.length > 0) })
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      } else if (error instanceof String) {
        console.log(error)
      }
    }
  }

  /**
   * Changes search value and checks if there are any events with such expressions.
   *
   * @param newSearch new search value from the search bar
   */
  private changeSearch = (newSearch: string): void => {
    if (this.state.events.findIndex((event) => { return this.filterFunction(event.name, newSearch) }) === -1) {
      this.setState({ search: newSearch, noEvents: true })
    } else {
      this.setState({ search: newSearch, noEvents: false })
    }
  }

  /**
   * Search bar filter function.
   *
   * @param eventName event's name
   * @param searchExpression typed word
   * @private
   */
  private filterFunction(eventName: string, searchExpression: string): boolean {
    /* Makes sure that the comparison isn't case sensitive nor accent sensitive */
    const eventWords = eventName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')
    const keyWords = searchExpression.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')

    for (const keyword in keyWords) {
      let passed = false
      for (const word in eventWords) {
        if (eventWords[word].startsWith(keyWords[keyword])) {
          passed = true
          break
        }
      }
      if (!passed) return false
    }
    return true
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="all-events-total">
        <div className="all-events-title">
          <h2>{i18next.t('AllEvents.title')}</h2>
        </div>
        <div className="all-events-main">

          <div className="all-events-horiz-container">
            <div className="search-bar-wrapper">
              <SearchBar text={this.state.search} changeText={this.changeSearch}/>
            </div>
          </div>

          { this.state.loading
              && <div className="no-events-div">
                <TailSpin
                  visible={true}
                  height="150"
                  width="150"
                  color="#02112E"
                  radius="0"
                />
              </div>
          }

          { !this.state.loading && window.innerWidth < 650
          && <div style={eventsDivStyleMobile}>
            {this.state.events.map((event, index) => {
              if (this.filterFunction(event.name, this.state.search)) {
                return (
                  // TODO: CHANGE FOR PROD
                  <a href={'/redirect-stores'} style={{ marginBottom: '1vw', marginRight: '0.33vw', color: 'black' }} key={index}>
                    <EventCard
                      key={index}
                      event={event}
                      eventImage={this.state.eventImages[index]}
                    />
                  </a>
                )
              }
              return (<div key={index} />)
            })}
          </div>}

          { !this.state.loading && window.innerWidth >= 650
            && <div style={eventsDivStyle}>
              {this.state.events.map((event, index) => {
                if (this.filterFunction(event.name, this.state.search)) {
                  return (
                    // TODO: CHANGE FOR PROD
                    <a href={'/redirect-stores'} style={{ marginBottom: '1vw', marginRight: '0.33vw' }} key={index}>
                      <EventCard
                        key={index}
                        event={event}
                        eventImage={this.state.eventImages[index]}
                      />
                    </a>
                  )
                }
                return (<div key={index} />)
              })}
            </div>}

          { this.state.noEvents && !this.state.loading
            && <div className="no-events-div">
              <h3>{i18next.t('AllEvents.noEvent')}</h3>
            </div>
          }

        </div>
      </div>
    )
  }
}

const eventsDivStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginTop: '2vw',
  flexWrap: 'wrap',
}

const eventsDivStyleMobile: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '0.5vw',
  flexWrap: 'wrap',
}

export default withTranslation()(AllEvents)
