import { DateSpan } from 'src/API'
import en from '../lang/en.json' /* English translations file */
import pt from '../lang/pt.json' /* Portuguese translations file */

/**
 * Function created to display numbers below 10 with two digits ( ex: 01 instead of 1)
 *
 * @param number number to add digits
 *
 * @returns corrected number
 */
export function twoDigitNumber(number: number) {
  return (`0${number}`).slice(-2)
}

/**
 *
 * Function that adds the proper ending to english numbers ( 1st, 2nd, 3rd, 4th ...)
 *
 * @param n number to be handled
 *
 * @returns number with the correct ending
 */
function nth(n: number) {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
}

/**
* Function that generates a string of the date of a single event day with the hour
*
* @param eventDateSpan: start and end date of an event day
*
* @returns Single event day date in string format
* */
export function singleDateGenerator(
  eventDateSpan: DateSpan,
  hasHour: boolean,
  hasYear: boolean, selectedLanguage: string,
) {
  const languageObj = {
    en,
    pt,
  }
  const months = languageObj[selectedLanguage as 'pt' | 'en'].Months

  const dateStart = new Date(Date.parse(eventDateSpan.start))
  const dateEnd = new Date(Date.parse(eventDateSpan.end))
  const hourHelper = `${twoDigitNumber(dateStart.getHours())}:${twoDigitNumber(dateStart.getMinutes())} - ${twoDigitNumber(dateEnd.getHours())}:${twoDigitNumber(dateEnd.getMinutes())}`

  if (selectedLanguage === 'pt') {
    return `${dateStart.getDate()} de ${months[dateStart.getMonth()]} ${hasYear ? `de ${dateStart.getFullYear()}` : ''} ${hasHour ? hourHelper : ''}`
  }
  return `${dateStart.getDate()}${nth(dateStart.getDate())} of ${months[dateStart.getMonth()]}${hasYear ? ` of ${dateStart.getFullYear()}` : ''} ${hasYear ? hourHelper : ''}`
}

/**
 * Function that generates a string that includes all the event days
 *
 * @param eventDates: array composed of the event day start date and end date
 *
 * @returns String with all the event days included on the date
 * */
export function dateGenerator(
  eventDates: DateSpan[],
  hasHour: boolean,
  hasYear: boolean,
  hasAmpersand: boolean,
  selectedLanguage: string,
) {
  if (eventDates === undefined) return ''

  let final = ''
  for (let i = 0; i < eventDates.length; i += 1) {
    final += singleDateGenerator(eventDates[i], hasHour, hasYear, selectedLanguage)
    if (i !== eventDates.length - 1) {
      hasAmpersand ? final += '\n& ' : final += '\n'
    }
  }
  return final
}

export function currentlyInsideDateSpan(validDates?: DateSpan[]) {
  if (!validDates) return false

  const currentDate = new Date().toISOString()

  for (const pair of validDates) {
    if (pair.start < currentDate && currentDate < pair.end) {
      return true
    }
  }

  return false
}
