import * as enTranslations from './locales/en' /* This import refers to all of the texts in english */
import * as ptTranslations from './locales/pt' /* This import refers to all of the texts in portuguese */
import { initReactI18next } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import LanguageDetector from 'i18next-browser-languagedetector' /* Import needed for the use of the dictionary/translation  */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */

/* Extract the translations */
const resources = {
  en_US: { messages: enTranslations },
  pt_PT: { messages: ptTranslations },
}

/* Setting up the dictionary/translator */
const i18n = i18next.use(LanguageDetector).use(initReactI18next)

i18n.init({
  resources,
  lng: i18n.options.lng,
  fallbackLng: 'pt_PT',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  ns: ['messages'],
  defaultNS: 'messages',
  fallbackNS: [],
  returnNull: false,
})

export default i18n
