import { Prices, PromoPrices } from '../API'

export function promoChecker(promoPrices?: PromoPrices[]) {
  const currentDate = new Date()

  if (!promoPrices) return undefined

  for (const promo of promoPrices) {
    if (Date.parse(promo.dateSpan.start) <= currentDate.valueOf()
      && currentDate.valueOf() <= Date.parse(promo.dateSpan.end)) {
      return promo
    }
  }

  return undefined
}

export const practicedPriceCalculator = (prices: Prices, isMember: boolean) => {
  const currentPromo = promoChecker(prices.promoPrices)

  if (currentPromo) {
    // eslint-disable-next-line max-len
    return isMember ? (currentPromo.memberPrice ?? typeNumber(currentPromo.price)) : typeNumber(currentPromo.price)
  }
  return isMember ? (prices.memberPrice ?? typeNumber(prices.price)) : typeNumber(prices.price)
}

export const practicedFeeCalculator = (validPrice: number, ticketsFee: number) => {
  if (validPrice < 5) {
    return typeNumber((Math.ceil(validPrice * ticketsFee) / 100) + 0.2)
  }
  return typeNumber((Math.ceil(validPrice * ticketsFee) / 100))
}

export const amountAdjustCard = (validPrice: number) => {
  if (validPrice < 10) {
    return typeNumber(validPrice - 0.2)
  }
  return typeNumber(validPrice)
}

export const amountAdjustMBway = (validPrice: number) => {
  if (validPrice < 5) {
    return typeNumber(validPrice - 0.2)
  }
  return typeNumber(validPrice)
}

export const typeNumber = (aux : any) => {
  if (typeof aux === 'number') {
    return aux.toFixed(2)
  }
  aux = Number(aux)
  return aux.toFixed(2)
}
