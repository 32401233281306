 import React from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { StatsGeneralProps, StatsGeneralState } from '../../types/event-statistics'

/**
 * Shows event general stats sub page.
 */
class StatsGeneral extends React.Component<StatsGeneralProps, StatsGeneralState> {
  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="single-page-container">

        <div className="stat-card-container">
          <div className="stat-card">
            <p>{i18next.t('EventStatistics.totalRevenues')}</p>
            <div className="value-container">
              <h1>{this.props.netRevenue.toFixed(2)} €</h1>
            </div>
          </div>
        </div>

        <div className="stat-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.revenueConsumables')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.consumableRevenueByDates.reduce((acc, curr) => acc + curr, 0).toFixed(2)} €</h2>
          </div>
        </div>
        {this.props.consumableRevenueByDates.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{`${i18next.t('EventStatistics.revenueConsumablesByDay')} ${index + 1}`}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.toFixed(2)} €</h3>
              </div>
            </div>
          )
        })}

        <div className="stat-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.revenueTickets')}</h3>
          </div>
          <div className="stat-total">
            <h2>{(this.props.ticketRevenueByTicket
              .reduce((acc, curr) => acc + curr.info, 0) - this.props.refundedTicketsValue).toFixed(2)} €</h2>
          </div>
        </div>
        {this.props.ticketRevenueByTicket.map((value, index) => {
          return (
            <div key={index} className="stat-line">
              <div className="stat-title">
                <h4>{value.name}</h4>
              </div>
              <div className="stat-total">
                <h3>{value.info.toFixed(2)} €</h3>
              </div>
            </div>
          )
        })}
        <div className="stat-line">
          <div className="stat-title">
            <h4>{i18next.t('EventStatistics.totalRefundedValue')}</h4>
          </div>
          <div className="stat-total">
            <h3>- {this.props.refundedTicketsValue.toFixed(2)} €</h3>
          </div>
        </div>


        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.nrPeople')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.nrOfPeopleInEvent}</h2>
          </div>
        </div>

        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.grossRevenue')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.grossRevenue.toFixed(2)} €</h2>
          </div>
        </div>
        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.taxedRevenue')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.taxedRevenue.toFixed(2)} €</h2>
          </div>
        </div>
        <div className="stat-line" id="new-line">
          <div className="stat-title">
            <h3>{i18next.t('EventStatistics.netRevenue')}</h3>
          </div>
          <div className="stat-total">
            <h2>{this.props.netRevenue.toFixed(2)} €</h2>
          </div>
        </div>


      </div>
    )
  }
}

export default withTranslation()(StatsGeneral)
