import { Component } from 'react'
import { Link } from 'react-router-dom'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */

import EventStatsCard from '../components/EventStatsCard'
import EventsTimeBar from '../components/EventsTimeBar'
import SearchBar from '../components/utils/SearchBar'
import { TailSpin } from 'react-loader-spinner'

import { Event } from '../API'
import { listCompanyEvents } from '../models/event'
import { fetchSetOfImages } from '../models/images'
import { MyEventsState } from '../types/my-events'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/my-events.css'

/**
 * This page shows all the company's events.
 */
class MyEvents extends Component<GlobalProps, MyEventsState> {
  /**
   * MyEvents class constructor.
   *
   * @param props required props
   */
  constructor(props: GlobalProps) {
    super(props)
    this.state = {
      time: -1,
      search: '',
      events: [],
      images: [],
      noEvents: true,
      loading: true,
    }
  }

  /**
   * Fetches company's events.
   */
  async componentDidMount() {
    try {
      /* Fetches company's events */
      const events = (await listCompanyEvents({ companyID: this.props.auth.user.getSub() })).sort((a, b) => (
        a.startDate > b.startDate ? -1 : 1
      ))

      /* Fetches event's banners */
      const eventImages = await fetchSetOfImages(Array.from(events, (event) => {
        return {
          filename: event.banner?.filename ? event.banner?.filename : '',
          identityId: event.banner?.identityID ? event.banner?.identityID : '',
        }
      }))

      /* Shows new state */
      this.setState({ events, images: eventImages, loading: false, noEvents: !(events.length > 0) })
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Checks if event time span is in valid EventTimeBar range.
   *
   * @param event event to be checked
   */
  private isInValidTimeBar = (event: Event): boolean => {
    /* Gets end date of this event to be checked if it is within boundaries */
    const endDate = new Date(Date.parse(event.date[event.date.length - 1].end))

    /* Checks if it is in a valid time bar status */
    if (this.state.time === -1) return true
    else if (this.state.time === 0 && endDate >= new Date()) return true
    return this.state.time === 1 && endDate <= new Date()
  }

  /**
   * Changes displayed category.
   *
   * @param event clicking of a button
   * @param time new highlighted category
   */
  private changeTime = (event: React.MouseEvent, time: number): void => {
    this.setState({ time })
  }

  /**
   * Changes search value and checks if there are any events with such expressions.
   *
   * @param newSearch new search value from the search bar
   */
  private changeSearch = (newSearch: string): void => {
    if (this.state.events.findIndex((event) => { return this.filterFunction(event.name, newSearch) }) === -1) {
      this.setState({ search: newSearch, noEvents: true })
    } else {
      this.setState({ search: newSearch, noEvents: false })
    }
  }

  /**
   * Search bar filter function.
   *
   * @param eventName event's name
   * @param searchExpression typed word
   * @private
   */
  private filterFunction(eventName: string, searchExpression: string): boolean {
    /* Makes sure that the comparison isn't case sensitive nor accent sensitive */
    const eventWords = eventName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')
    const keyWords = searchExpression.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')

    for (const keyword in keyWords) {
      let passed = false
      for (const word in eventWords) {
        if (eventWords[word].startsWith(keyWords[keyword])) {
          passed = true
          break
        }
      }
      if (!passed) return false
    }
    return true
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="my-events-total">
        <div className="my-events-title">
          <h2>{i18next.t('MyEvents.title')}</h2>
        </div>
        <Link to="/create-event">
          <button id="add-event" className="top-button">
            <h4>{i18next.t('MyEvents.createEvent')}</h4>
            <div className="add-button">+</div>
          </button>
        </Link>
        <div className="my-events-main">
          <div className="my-events-horiz-container">
            <EventsTimeBar changeTime={this.changeTime}/>
            <div className="search-bar-wrapper">
              <SearchBar text={this.state.search} changeText={this.changeSearch}/>
            </div>
          </div>
          { this.state.loading
              && <div className="no-events-div">
                <TailSpin
                  visible={true}
                  height="150"
                  width="150"
                  color="#02112E"
                  radius="0"
                />
              </div>
          }
          { !this.state.loading
            && <div style={{ marginTop: '2vw' }}>
              {this.state.events.map((event, index) => {
                if (this.filterFunction(event.name, this.state.search) && this.isInValidTimeBar(event)) {
                  return (
                    <div key={index}>
                      <EventStatsCard
                        eventCardImage={this.state.images[index] as string}
                        event={event}
                      />
                    </div>
                  )
                }
                return (<div key={index} />)
              })}
            </div>}
          { this.state.noEvents && !this.state.loading
            && <div className="no-events-div">
              <h3>{i18next.t('AllEvents.noEvent')}</h3>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withTranslation()(MyEvents)
