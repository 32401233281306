import React from 'react'

// Imports needed due to Material UI
import { PhoneNumberProps } from '../../types/form-field'
import { useMediaQuery } from '@material-ui/core'
import {
  createTheme, MuiThemeProvider,
} from '@material-ui/core/styles'
import MuiPhoneNumber from 'material-ui-phone-number'

/**
 * A customized phone
 *
 * @param label used to pass the text that identifies the filed
 * @param setfield function used to change field's current value
 * @param defaultCountry default country entry
 * @param props any other html props that we may want to pass
 */
export const StyledPhoneNumber: React.FC<PhoneNumberProps> = ({ setfield, defaultCountry, ...props }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#02112E',
        light: '#02112E',
        dark: '#02112E',
        contrastText: '#02112E',
      },
      secondary: {
        main: '#02112E',
        light: '#02112E',
        dark: '#02112E',
        contrastText: '#02112E',
      },
    },
  })

  let style

  /* This if checks the screen size and sets the styling accordingly */
  if (!useMediaQuery('screen and (max-width: 650px)')) {
    /* Desktop version */
    style = {
      width: '100%',
    }
  } else {
    /* Mobile version */
    style = {
      width: '67vw',
      height: '9.5vw',
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPhoneNumber
        {...props}
        id={props.name}
        style={style}
        autoComplete={'off'}
        defaultCountry={defaultCountry}
        onChange={(value) => {
          setfield(props.name, value !== null ? value : '')
        }}
      />
    </MuiThemeProvider>
  )
}
