import React, { Component } from 'react'
import { GlobalProps } from '../../../types/global'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import AddEditStreak from '../../../components/events/AddEditStreak'
import StreakCard from '../../../components/events/StreakCard'
import ProgressBar from '../../../components/ProgressBar' // Import of the bar that shows the progress in creating event
import {
  AddEditStreakFormValues, AddStreaksProps,
  EventStreaksState,
  SubPagesProps,
} from '../../../types/create-edit-event'

import '../../../styles/views/create-event/add-streaks.css'
import { nextPage } from '../util/types'

/**
 * In this page the user will add all the streaks related to this event.
 */
class AddStreaks extends Component<GlobalProps & SubPagesProps & AddStreaksProps, EventStreaksState> {
  /* Holds our page's initial values */
  private initialValues = this.props.initValues as EventStreaksState

  /**
   * AddStreaks class constructor.
   *
   * @param props all the needed props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      ...this.initialValues,
    }
  }

  /**
   * Handles submission of all the newly added streaks.
   */
  private handleSubmit = (nextPageParam: number): void => {
    /* Creates info input object */
    const input: object = {
      each: [
        ...this.state.each,
      ],
      showAddStreakPopup: false,
    }

    /* Sends info to parent component and tells it to render next phase */
    this.props.submitInfo(input, nextPageParam)
  }

  /**
   * Associates a new streak to the event.
   *
   * @param input inputs needed to create a new streak
   */
  private addNewStreak = (input: AddEditStreakFormValues): void => {
    this.setState({ each: [
      ...this.state.each,
      input,
    ] })
  }

  /**
   * React component render function. Holds our extended html code.
   */
  render() {
    return (
      <div className="add-streaks-total">
        <div className="add-streaks-title">
          <h2>{i18next.t('AddEvent.addStreaks.title')}</h2>
        </div>
        {/* We only let a streak to be added if the even t hasn't started */}
        {this.props.eventSpan![0] > (new Date()).getTime()
        && <AddEditStreak
          eventSpan={this.props.eventSpan!}
          updateInfo={this.addNewStreak}
          consumablesList={this.props.consumablesList}
          show={this.state.showAddStreakPopup}
          close={() => this.setState({ showAddStreakPopup: false })}
        />
        }
        {this.props.eventSpan![0] > (new Date()).getTime()
        && <button id="create-streak" className="top-button" onClick={() => this.setState({ showAddStreakPopup: true })}>
          <h4>{i18next.t('AddEvent.addStreaks.createStreak')}</h4>
          <div className="add-button">+</div>
        </button>
        }
        { this.state.each.length !== 0
          ? <div className="add-streaks-main">
            { this.state.each.map((streak: AddEditStreakFormValues, index: number) => (
              <StreakCard key={index} auth={this.props.auth} consumablesList={this.props.consumablesList}
                eventSpan={this.props.eventSpan!}
                setStreak={(input: AddEditStreakFormValues) => {
                  const newEach = this.state.each
                  newEach[index] = input
                  this.setState({ each: newEach })
                }}
                streak={streak}
                deleteStreak={() => {
                  this.state.each.splice(index, 1)
                  this.setState({ each: this.state.each })
                }}
              />
            )) }
          </div>
          : <div className="add-streaks-no-streak-wrapper">
            <h3>{i18next.t('AddEvent.addStreaks.noStreaks')}</h3>
          </div>}
        <div className="add-streaks-bottom">
          <ProgressBar filled={4} total={5}/>
          <div className="navigation-buttons-total">
            <button id="previous" className="navigation-button" onClick={() => this.handleSubmit(nextPage.PREVIOUS)}>
              {i18next.t('AddEvent.navigationButtons.previous')}
            </button>
            <button className="navigation-button" onClick={() => this.handleSubmit(nextPage.NEXT)}>
              {i18next.t('AddEvent.navigationButtons.next')}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(AddStreaks)
