import React, { Component } from 'react'
import { UserTicketInfoWrapperProps } from '../types/user-ticket-info-wrapper'

import '../styles/components/user-ticket-info-wrapper.css'

/**
 * Creates a button for the auth function (login, sign up, password change etc) with custom text, a normal or
 * alternative colorway, the button type and a method while it's disabled.
 *
 */
class UserTicketInfoWrapper extends Component<UserTicketInfoWrapperProps> {
  /**
  * React component render function. Holds our extended html code.
  */
  public render(): JSX.Element {
    return (
      <div className="overall-container">
        <div className="user-info-wrapper">
          <h3>{this.props.person.name}</h3>
          <h4>{`@${this.props.person.handle} | ${this.props.person.phone}`}</h4>
        </div>
        <div className="ticket-info-wrapper">
          <h3>{this.props.ticket.name}</h3>
          <h4>{`${this.props.ticket.prices.price} €`}</h4>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default UserTicketInfoWrapper
