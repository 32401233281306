import React from 'react'
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import {
  StatsCollaboratorsProps,
  StatsCollaboratorsState,
} from '../../types/event-statistics'

class StatsCollaborators extends React.Component<StatsCollaboratorsProps, StatsCollaboratorsState> {
  // eslint-disable-next-line no-useless-constructor
  constructor(props: StatsCollaboratorsProps) {
    super(props)
  }

  public NaNCheck(number: number, decimals = 0): string {
    if (isNaN(number)) {
      return '-'
    }
    return number.toFixed(decimals)
  }

  public render(): JSX.Element {
    return (
      <div>
        {this.props.infoCollaborators.map((collaborator) => {
          return (
            <div>
              <div className="single-page-container">
                <div className="stat-name">
                  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" >
                    <circle cx="19.059" cy="19.1425" r="17.8573" fill="#0F6DF9" />
                    <path d="M19.437 17.874C16.9905 17.874 15 15.8836 15 13.437C15 10.9905 16.9905 9 19.437 9C21.8836 9 23.874 10.9905 23.874 13.437C23.874 15.8836 21.8836 17.874 19.437 17.874ZM19.437 10.2419C17.6752 10.2419 16.2419 11.6752 16.2419 13.437C16.2419 15.1989 17.6752 16.6322 19.437 16.6322C21.1988 16.6322 22.6322 15.1989 22.6322 13.437C22.6322 11.6752 21.1988 10.2419 19.437 10.2419Z" stroke="White"/>
                    <path d="M23.9982 28.0164H15.2289C15.1936 28.0164 15.1584 28.0134 15.1237 28.0075C13.5474 27.7385 12.4033 26.4517 12.4033 24.9478V23.692C12.4033 21.616 13.3976 19.6837 15.1314 18.3905C16.4178 17.431 17.9677 16.9238 19.6135 16.9238C21.2167 16.9238 22.7351 17.4082 24.0045 18.3247C24.1046 18.3969 24.1929 18.4636 24.2745 18.5286C25.8945 19.8182 26.8236 21.7002 26.8236 23.692V24.9478C26.8236 26.4517 25.6796 27.7385 24.1032 28.0075C24.0686 28.0134 24.0334 28.0164 23.9982 28.0164ZM15.285 26.9129H23.942C24.9336 26.7215 25.6481 25.9025 25.6481 24.9478V23.692C25.6481 22.025 24.8703 20.4498 23.514 19.3701C23.4468 19.3166 23.3729 19.2608 23.2878 19.1993C22.2258 18.4326 20.9551 18.0273 19.6134 18.0273C18.2359 18.0273 16.9389 18.4516 15.8626 19.2544C14.4112 20.337 13.5788 21.9545 13.5788 23.692V24.9478C13.5789 25.9025 14.2934 26.7215 15.285 26.9129Z" stroke="White"/>
                  </svg>
                  <h2>{collaborator.name}</h2>
                </div>
              </div>


              <div className="stat-line">
                <div className="stat-title">
                  <h3>{i18next.t('EventStatistics.consumableRevenues')}</h3>
                </div>
              </div>
              {collaborator.revenueByDate.map((value, index) => {
                return (
                  <div key={index} className="stat-line">
                    <div className="stat-title">
                      <h4>{`${i18next.t('EventStatistics.revenueConsumablesByDay')} ${index + 1}`}</h4>
                    </div>
                    <div className="stat-total">
                      <h3>{value.toFixed(2)} €</h3>
                    </div>
                  </div>
                )
              })}

              <div className="stat-line" id="new-line">
                <div className="stat-title">
                  <h3>{i18next.t('EventStatistics.nrTransactions')}</h3>
                </div>
              </div>
              {collaborator.nrOfTransactionsByDate.map((value, index) => {
                return (
                  <div key={index} className="stat-line">
                    <div className="stat-title">
                      <h4>{`${i18next.t('EventStatistics.transactionByDay')} ${index + 1}`}</h4>
                    </div>
                    <div className="stat-total">
                      <h3>{value}</h3>
                    </div>
                  </div>
                )
              })}

              <div className="stat-line" id="new-line">
                <div className="stat-title">
                  <h3>{i18next.t('EventStatistics.refundedValue')}</h3>
                </div>
                <div className="stat-total">
                  <h3>{collaborator.totalRefunded.toFixed(0)} €</h3>
                </div>
              </div>

            </div>
          )
        })}
      </div>
    )
  }
}


export default withTranslation()(StatsCollaborators)
