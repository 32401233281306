import React, { Component } from 'react'
import { Auth } from 'aws-amplify'  // Calls cognito to change user's password
import { RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { GlobalProps } from '../../types/global' /* Importing the interface that allow the use of props */
import { ForgotPasswordVerificationFormValues } from '../../types/auth'
import { Formik, FormikHelpers } from 'formik'  // Needed to create responsive forms
import * as Yup from 'yup'  // Used to validate our form
import { TextInput, PasswordInput } from '../../components/utils/FormFields'  // Form fields
import AuthButton from '../../components/AuthButton'  // Used to submit form
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Needed to get the desired font elements */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../../styles/views/auth/forgot-password-verification.css'  /* Used for styling */

/**
 * Forgot password verification page component. Prompts user for the code previously sent via email by AWS Cognito upon
 * trying to reset password. It's practically equal to ForgotPasswordVerification does it shares the same css and html structure.
 */
class ChangePasswordVerification extends Component<RouteComponentProps<{id: string}> & GlobalProps> {
  /* Holds our form's initial values */
  private initialValues = {
    verificationCode: '',
    password: '',
    confirmPassword: '',
  }

  /* Holds validation for our form */
  private validationSchema = Yup.object().shape({
    verificationCode: Yup.string().required()
      .max(20)
      .matches(/^\d{6}$/, i18next.t('ForgotPasswordVeri.errors.code')),

    password: Yup.string().required()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,16}$/,
        i18next.t('ForgotPasswordVeri.errors.newPassword'),
      ),

    confirmPassword: Yup.string().required()
      .oneOf([Yup.ref('password'), null], i18next.t('ForgotPasswordVeri.errors.confirmNewPassword')),
  })

  /**
   * Handles password verification form submit.
   *
   * @param values all the values inside each field in our form at the time of submitting
   * @param setSubmitting function called to change the state of the button
   *
   * @return Returns a void promise since it won't have a specific type
   */
  private passwordVerificationHandler = async (values: ForgotPasswordVerificationFormValues, { setSubmitting }:
                                        FormikHelpers<ForgotPasswordVerificationFormValues>): Promise<void> => {
    /* Calls Cognito and tells it verify user's code and, if correct, change his account's password */
    try {
      /* Receives code from user, checks if is correct and changes previous password to a new one */
      await Auth.forgotPasswordSubmit(this.props.match.params.id, values.verificationCode, values.password)

      /* Enables submit button again */
      setSubmitting(false)

      /* Redirects user to password change confirmation page */
      this.props.history.push('/login')
    } catch (error) {
      if ((error as any).message) {
        alert((error as any).message)
      } else {
        alert(JSON.stringify(error))
      }

      /* Enables submit button again */
      setSubmitting(false)
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    return (
      <div className="forgot-password-veri-background">
        <div className="forgot-password-veri-main">
          <div className="forgot-password-veri-container">
            {/* Button used to go back to the login page */}
            <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft}/></a>
            <h1>
              {i18next.t('ForgotPasswordVeri.catchphrase')}
            </h1>
            <p id="forgot-password-veri-secondary-text">
              {i18next.t('ForgotPasswordVeri.secondaryText')}
            </p>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={this.passwordVerificationHandler}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <TextInput
                    value={props.values.verificationCode}
                    onChange={props.handleChange}
                    label={i18next.t('ForgotPasswordVeri.veriCode')}
                    name="verificationCode" />
                  <PasswordInput
                    value={props.values.password}
                    onChange={props.handleChange}
                    label={i18next.t('ForgotPasswordVeri.newPassword')}
                    name="password" />
                  <PasswordInput
                    value={props.values.confirmPassword}
                    onChange={props.handleChange}
                    label={i18next.t('ForgotPasswordVeri.confirmNewPassword')}
                    name="confirmPassword" />
                  <div id="forgot-password-veri-button-wrapper">
                    <AuthButton text = {i18next.t('ForgotPasswordVeri.create')} type="submit"
                      disable={props.isSubmitting}/>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(ChangePasswordVerification)
