import { Component } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'  // Tells our compiler that this component is a child of Router
import { LandingPageState } from '../types/landing-page'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */

import Banner from '../components/landing-page/Banner'  // Main banner
import EventCard from '../components/EventCard'  // Card representing event
import Footer from '../components/landing-page/Footer'  // Page footer
import StoreButton from '../components/landing-page/StoreButton'  // Google/Apple button
import Carousel from 'react-multi-carousel'  // Event card slider

import { fetchSetOfImages } from '../models/images'
import { listEvents } from '../models/event'

import eventTicketAndQRCodeLeft from '../images/eventTicketAndQRCodeLeft.png'
import consumeAndSendLeft from '../images/consumeAndSendLeft.png'
import leaderboardAndFriendsLeft from '../images/leaderboardAndFriendsLeft.png'

import miniLogo from '../images/minilogo.png'

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import '../styles/views/landing-page.css'
import 'react-multi-carousel/lib/styles.css'

import { getGeo } from 'geoplugin'

/**
 * This is the Landing Page which presents our app and features. Allows changing Language and move to other pages
 */
class LandingPage extends Component<RouteComponentProps & GlobalProps, LandingPageState> {
  /**
   * LandingPage class constructor.
   *
   * @param props required props
   */
  constructor(props: RouteComponentProps & GlobalProps) {
    super(props)
    this.state = {
      events: [],
      eventImages: [],
    }
  }

  /**
   * Fetches events in database.
   */
  async componentDidMount() {
    try {
      /* Gets user's current location */
      const res = await getGeo()

      /* Gathers events info */
      const newEvents = await listEvents({
        lon: parseFloat(res.longitude),
        lat: parseFloat(res.latitude),
      }, 100)

      /* Gathers events' images */
      const eventImages = await fetchSetOfImages(Array.from(newEvents, (event) => {
        return {
          filename: event.banner?.filename ? event.banner?.filename : '',
          identityId: event.banner?.identityID ? event.banner?.identityID : '',
        }
      }))

      /* Updates state */
      this.setState({ events: newEvents, eventImages })
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message)
      } else if (error instanceof String) {
        console.log(error)
      }
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    /* This const stores some settings for the carousel */
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 650 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 650, min: 0 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
      },
    }

    return (
      <div className="landing-page">
        <Banner />
        <div className="div-tut-and-action">
          {/* We just need to remove this "false" condition when we want to show the carousel */}
          { false && this.state.events.length > 0
            && <Carousel responsive={responsive}
              containerClass="carousel-container"
              itemClass="carousel-item"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              showDots={true}>
              {this.state.events.map((event, index) => {
                return (
                  <EventCard
                    key={index}
                    event={event}
                    eventImage={this.state.eventImages[index]}
                  />
                )
              })}
            </Carousel>
          }
          {/* We just need to remove this "false" condition when we want to show the carousel */}
          { false && this.state.events.length > 0
            && <Link className="all-events-link" to="/all-events">
              <h1>{i18next.t('LandingPage.viewAllEvents')}</h1>
            </Link>
          }
          <div className="div-explanation-app">
            <img src={eventTicketAndQRCodeLeft} className="mockup-image" alt="outGoing" id="mockup-events"/>
            <div className="div-explanation-app-right-wrapper">
              <h2 className="app-offer">{i18next.t('LandingPage.outGoingFeatures')}</h2>
              <div className="div-explanation-app-title">
                <img src={miniLogo} alt="O"/>
                <h3>{i18next.t('LandingPage.tickets.mainText')}</h3>
              </div>
              <h3 className="explanation-text">{i18next.t('LandingPage.tickets.secondaryText')}</h3>
              <div className="explanation-line" id="line-one"/>
            </div>
          </div>
          <div className="div-explanation-app">
            <img src={consumeAndSendLeft} className="mockup-image" alt="outGoing"/>
            <div className="div-explanation-app-right-wrapper">
              <div className="div-explanation-app-title">
                <img src={miniLogo} alt="O"/>
                <h3>{i18next.t('LandingPage.consume.mainText')}</h3>
              </div>
              <h3 className="explanation-text">{i18next.t('LandingPage.consume.secondaryText')}</h3>
              <div className="explanation-line" id="line-two"/>
            </div>
          </div>
          <div className="div-explanation-app">
            <img src={leaderboardAndFriendsLeft} className="mockup-image" alt="outGoing"/>
            <div className="div-explanation-app-right-wrapper">
              <div className="div-explanation-app-title">
                <img src={miniLogo} alt="O"/>
                <h3>{i18next.t('LandingPage.challenge.mainText')}</h3>
              </div>
              <h3 className="explanation-text">{i18next.t('LandingPage.challenge.secondaryText')}</h3>
            </div>
          </div>
        </div>
        <h1 className="call-to-action">{i18next.t('LandingPage.callToAction')}</h1>

        <div className="div-store-buttons">
          <a href="https://apps.apple.com/pt/app/outgoing/id1585889075">
            <StoreButton store="apple" alternative={false}/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.rodrigofernandes.outgoing">
            <StoreButton store="google" alternative={false}/>
          </a>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withTranslation()(LandingPage)
