import * as Types from '../API'  // Imports types from our AWS Backend API
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api'  // Used to type results from our API
import { API, Auth } from 'aws-amplify'
import { CognitoUser } from '../types/auth'
import {
  createCollaboratorEventConnection as createCollaboratorEventConnectionMutation,
  deleteCollaboratorEventConnection as deleteCollaboratorEventConnectionMutation,
  deleteEvent as deleteEventMutation,
  updateEvent as updateEventMutation,
  createPoster as createPosterMutation,
  updatePoster as updatePosterMutation,
} from '../graphql/mutations'

/**
 * Gets all the events in our database. Needs to have an 'await' before calling. Can return null if an error pops up
 *
 * @param centroid set of coordinates that represent the center of the circle to search in
 * @param distance circle radius in Km
 *
 * @return objects or error
 */
export async function listEvents(centroid: Types.LocationInput, distance: number): Promise<Types.Event[]> {
  try {
    const nearbyEvents = `
      query NearbyEvents(
        $byLocation: LocationQueryInput!
        $endDate: AWSDateTime!
        $limit: Int
        $nextToken: String
      ) {
        nearbyEvents(
          byLocation: $byLocation
          endDate: $endDate
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            location {
              lon
              lat
            }
            addressAlias
            startDate
            endDate
            date {
              start
              end
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
            }
            tickets {
              items {
                id
                prices {
                  price
                  memberPrice
                  promoPrices {
                    dateSpan {
                      start
                      end
                    }
                    memberPrice
                    price
                  }
                }
              }
            }
            consumables {
              items {
                id
                owner
                type
                prices {
                  price
                  memberPrice
                  promoPrices {
                    dateSpan {
                      start
                      end
                    }
                    memberPrice
                    price
                  }
                }
              }
            }
            eventBannerId
          }
          total
          nextToken
        }
      }
    `

    const auth = await Auth.currentUserInfo() as CognitoUser

    /* Creates our graphql input object */
    const inputObject = {
      query: nearbyEvents,
      variables: {
        byLocation: {
          location: centroid,
          radius: distance,
        },
        endDate: (new Date()).toISOString(),
      },
      authMode: (auth === null || auth === undefined) ? GRAPHQL_AUTH_MODE.AWS_IAM : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.NearbyEventsQuery>

    /* Transforms gathered data into an array of object with these properties. If not found, we return an empty list */
    return (response.data?.nearbyEvents?.items as Types.Event[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the events from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to gather events')
  }
}

/**
 * Counts number of people going to an event.
 *
 * @param eventID id of the event for which we will be counting
 *
 * @return nr of people going or error
 */
export async function getNrOfPeopleGoingToEvent(eventID: string): Promise<number> {
  try {
    const query = /* GraphQL */ `
      query SearchPersonTickets(
        $filter: SearchablePersonTicketFilterInput
        $sort: [SearchablePersonTicketSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchablePersonTicketAggregationInput]
      ) {
        searchPersonTickets(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          total
        }
      }`

    const variables: Types.SearchPersonTicketsQueryVariables = {
      filter: {
        eventID: {
          eq: eventID,
        },
      },
    }

    /* Creates our graphql input object */
    const inputObject = {
      query: query,
      variables: variables,
    }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.SearchPersonTicketsQuery>

    /* Transforms gathered data into an array of object with these properties. If not found, we return an empty list */
    return response.data?.searchPersonTickets?.total || 0

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the events from database: ', error)

    throw new Error('Failed to gather events')
  }
}

/**
 * Gets all the events in our database.
 *
 *
 * @return objects or error
 */
export async function listEventsNoLocation(): Promise<Types.Event[]> {
  try {
    const nearbyEventsNoLocation = `
      query ListEvents(
        $filter: ModelEventFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listEvents(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            name
            location {
              lon
              lat
            }
            addressAlias
            startDate
            endDate
            date {
              start
              end
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
            }
            tickets {
              items {
                id
                prices {
                  price
                  memberPrice
                  promoPrices {
                    dateSpan {
                      start
                      end
                    }
                    memberPrice
                    price
                  }
                }
              }
            }
            consumables {
              items {
                id
                owner
                type
                prices {
                  price
                  memberPrice
                  promoPrices {
                    dateSpan {
                      start
                      end
                    }
                    memberPrice
                    price
                  }
                }
              }
            }
            eventBannerId
          }
          nextToken
        }
      }`

    const variables: Types.ListEventsQueryVariables = {
      filter: {
        endDate: { ge: (new Date()).toISOString() },
      },
    }

    const auth = await Auth.currentUserInfo() as CognitoUser

    /* Creates our graphql input object */
    const inputObject = {
      query: nearbyEventsNoLocation,
      variables: variables,
      authMode: (auth === null || auth === undefined) ? GRAPHQL_AUTH_MODE.AWS_IAM : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }

    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ListEventsQuery>

    /* Transforms gathered data into an array of object with these properties. If not found, we return an empty list */
    return (response.data?.listEvents?.items as Types.Event[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the events from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to gather events')
  }
}

/**
 * Gets all the events of an input company in our database.
 *
 * @return objects or error
 */
export async function listCompanyEvents(input: Types.EventsByCompanyByDateQueryVariables): Promise<Types.Event[]> {
  try {
    const query = `
    query EventsByCompanyByDate(
      $companyID: ID!
      $startDate: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      eventsByCompanyByDate(
        companyID: $companyID
        startDate: $startDate
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          name
          promotions
          addressAlias
          companyID
          startDate
          endDate
          date {
            start
            end
          }
          banner {
            id
            eventID
            filename
            identityID
          }
          eventBannerId
          eventPosterId
        }
        nextToken
      }
    }
  `


    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.EventsByCompanyByDateQuery>

    /* Transforms gathered data into an array of object with this properties. If not found, we return an empty list */
    return (response.data?.eventsByCompanyByDate?.items as Types.Event[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the company\'s events from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to gather company events')
  }
}

/**
 * Gets a specific event from our database.
 *
 * @param input object with the needed info to call this query
 *
 * @return object or error
 */
export async function getEventLite(input: Types.GetEventQueryVariables): Promise<Types.Event> {
  try {
    const query = /* GraphQL */ `
      query GetEvent($id: ID!) {
        getEvent(id: $id) {
          id
          name
          companyID
          date {
            start
            end
          }
          banner {
            filename
            identityID
          }
          tickets {
            items {
              id
              name
              initialStock
              validDates {
                start
                end
              }
            }
          }
          consumables {
            items {
              id
              name
            }
          }
          streaks {
            items {
              id
              name
            }
          }
        }
      }`
    /* Creates our graphql input object */
    const inputObject = { query: query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetEventQuery>

    /* Returns the gathered event */
    return (response.data?.getEvent) as Types.Event

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching event ', input.id, ' from the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to gather event')
  }
}

/**
 * Gets a specific event from our database.
 *
 * @param input object with the needed info to call this query
 *
 * @return object or error
 */
export async function getEvent(input: Types.GetEventQueryVariables): Promise<Types.Event> {
  try {
    const query = /* GraphQL */ `
      query GetEvent($id: ID!) {
        getEvent(id: $id) {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          company {
            ticketsFee
          }
          maxCapacity
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                consumable {
                  id
                  owner
                  type
                  size
                  name
                  prices {
                    price
                    memberPrice
                    promoPrices {
                      dateSpan {
                        start
                        end
                      }
                      memberPrice
                      price
                    }
                  }
                  eventID
                  smallOrLargeRef {
                    id
                    owner
                    type
                    size
                    name
                    prices {
                      price
                      memberPrice
                      promoPrices {
                        memberPrice
                        price
                      }
                    }
                    eventID
                    createdAt
                    updatedAt
                    consumableSmallOrLargeRefId
                  }
                  createdAt
                  updatedAt
                  consumableSmallOrLargeRefId
                }
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                items {
                  id
                  owner
                  streakID
                  amount
                  consumable {
                    id
                    owner
                    type
                    size
                    name
                    prices {
                      price
                      memberPrice
                      promoPrices {
                        memberPrice
                        price
                      }
                    }
                    eventID
                    smallOrLargeRef {
                      id
                      owner
                      type
                      size
                      name
                      prices {
                        price
                        memberPrice
                      }
                      eventID
                      createdAt
                      updatedAt
                      consumableSmallOrLargeRefId
                    }
                    createdAt
                    updatedAt
                    consumableSmallOrLargeRefId
                  }
                  createdAt
                  updatedAt
                  pairStreakConsumableId
                }
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
                promoPrices {
                  dateSpan {
                    start
                    end
                  }
                  memberPrice
                  price
                }
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                avatar {
                  id
                  owner
                  eventID
                  filename
                  identityID
                  createdAt
                  updatedAt
                }
                events {
                  items {
                    id
                    collaboratorID
                    eventID
                    collaborator {
                      id
                      owner
                      name
                      email
                      handle
                      phone
                      activity
                      companyID
                      createdAt
                      updatedAt
                      collaboratorAvatarId
                    }
                    event {
                      id
                      owner
                      name
                      companyID
                    }
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                }
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
                promoPrices {
                  dateSpan {
                    start
                    end
                  }
                  memberPrice
                  price
                }
              }
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                prices {
                  price
                  memberPrice
                  promoPrices {
                    dateSpan {
                      start
                      end
                    }
                    memberPrice
                    price
                  }
                }
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
      }`
    /* Creates our graphql input object */
    const inputObject = { query: query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetEventQuery>

    /* Returns the gathered event */
    return (response.data?.getEvent) as Types.Event

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching event ', input.id, ' from the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to gather event')
  }
}

/**
 * Creates an event inside our database. An event can only be created by a company.
 *
 * @param input inputs used to create a new event
 *
 * @return object or error
 */
export async function createEvent(input: Types.CreateEventInput): Promise<Types.Event> {
  try {
    const query = /* GraphQL */ `
      mutation CreateEvent(
        $input: CreateEventInput!
        $condition: ModelEventConditionInput
      ) {
        createEvent(input: $input, condition: $condition) {
          id
        }
      }`

    /* Creates our graphql input object */
    const inputObject = { query, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateEventMutation>

    /* Returns created event */
    return (response.data?.createEvent) as Types.Event

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new event in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to create event')
  }
}

/**
 * Updates an already existing event in our database.
 *
 * @param input inputs used to update an event
 *
 * @return object or error
 */
export async function updateEvent(input: Types.UpdateEventInput): Promise<Types.Event> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updateEventMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdateEventMutation>

    /* Returns created event */
    return (response.data?.updateEvent) as Types.Event

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while updating an event in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to update event')
  }
}

/**
 * Removes an event from our database.
 *
 * @param input inputs used to delete an event
 *
 * @return object or error
 */
export async function deleteEvent(input: Types.DeleteEventInput): Promise<Types.Event> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteEventMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteEventMutation>

    /* Returns created event */
    return (response.data?.deleteEvent) as Types.Event

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting an event from the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to delete an event')
  }
}

/**
 * Relates a collaborator with an event.
 *
 * @param input inputs used to create a new collaborator event connection
 *
 * @return object or error
 */
export async function createCollaboratorEventRelation(input: Types.CreateCollaboratorEventConnectionInput):
  Promise<Types.CollaboratorEventConnection> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createCollaboratorEventConnectionMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateCollaboratorEventConnectionMutation>

    /* Returns created event */
    return (response.data?.createCollaboratorEventConnection) as Types.CollaboratorEventConnection

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new collaborator-event connection in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to create collaborator-event connection')
  }
}

/**
 * Deletes a relationship between an event and a collaborator.
 *
 * @param input inputs used to delete relationship
 *
 * @return object or error
 */
export async function deleteCollaboratorEventRelation(input: Types.DeleteCollaboratorEventConnectionInput):
  Promise<Types.CollaboratorEventConnection> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteCollaboratorEventConnectionMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteCollaboratorEventConnectionMutation>

    /* Returns created event */
    return (response.data?.deleteCollaboratorEventConnection) as Types.CollaboratorEventConnection

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a collaborator-event connection in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to delete collaborator-event connection')
  }
}

/**
 * Gets all the people that are going to an input event.
 *
 * @param eventID event's id which we want to fetch who is going
 * @param nextToken next token for pagination control
 *
 * @return objects or error
 */
export async function listEventTicketHolders(eventID: string, nextToken?: string): Promise<{
  items: Array<Types.PersonTicket>,
  nextToken: string | null
}> {
  try {
    /* Creates GraphQL query that is going to be made on our database */
    const query = `
      query ListPersonTickets(
        $filter: ModelPersonTicketFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listPersonTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            person {
              id
              name
              handle
              phone
            }
            privacy
            ticket {
              id
              validDates {
                start
                end
              }
              name
              prices {
                price
              }
            }
          }
          nextToken
        }
      }`

    /* Creates our graphql input object */
    const variables: Types.ListPersonTicketsQueryVariables = {
      filter: {
        and: [{
          eventID: {
            eq: eventID,
          },
        }, {
          personTicketRefundId: {  // Does not fetch people that have already refunded their tickets
            attributeExists: false,
          },
        }],
      },
      limit: 50,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql({ query, variables })) as GraphQLResult<any>

    /* Returns our data with a token to fetch more data */
    return {
      items: response.data.listPersonTickets.items,
      nextToken: response.data?.listPersonTickets?.nextToken,
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the event goers from database: ', error)

    throw new Error('Failed to gather event goers')
  }
}

/**
 * Creates an event poster inside our database.
 *
 * @param input inputs used to create a new poster
 *
 * @return object or error
 */
export async function createEventPoster(input: Types.CreatePosterInput): Promise<Types.Poster> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createPosterMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreatePosterMutation>

    /* Returns created event */
    return (response.data?.createPoster) as Types.Poster

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new poster in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to create poster')
  }
}

/**
 * Updates an event poster inside our database.
 *
 * @param input inputs used to update a poster
 *
 * @return object or error
 */
export async function updateEventPoster(input: Types.UpdatePosterInput): Promise<Types.Poster> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updatePosterMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdatePosterMutation>

    /* Returns created event */
    return (response.data?.updatePoster) as Types.Poster

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while update a poster in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to update poster')
  }
}

/**
 * Gets a specific poster from our database.
 *
 * @param input object with the needed info to call this query
 *
 * @return object or error
 */
export async function getPoster(input: Types.GetPosterQueryVariables): Promise<Types.Poster> {
  try {
    const query = `
    query GetPoster($id: ID!) {
      getPoster(id: $id) {
        id
        owner
        artists {
          name
          performanceDateTime
        }
      }
    }
  `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetPosterQuery>

    /* Returns the gathered event */
    return (response.data?.getPoster) as Types.Poster

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching poster ', input.id, ' from the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    throw new Error('Failed to gather poster')
  }
}
