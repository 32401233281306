/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      owner
      name
      email
      iban
      initials
      handle
      consumablesFee
      ticketsFee
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      collaborators {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          readTickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          history {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          chargeHistory {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          collaboratorAvatarId
        }
        nextToken
      }
      events {
        items {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        nextToken
      }
      promoters {
        items {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          name
          internalID
          email
          phone
          companyID
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAvatarId
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      owner
      name
      email
      iban
      initials
      handle
      consumablesFee
      ticketsFee
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      collaborators {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          readTickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          history {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          chargeHistory {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          collaboratorAvatarId
        }
        nextToken
      }
      events {
        items {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        nextToken
      }
      promoters {
        items {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          name
          internalID
          email
          phone
          companyID
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAvatarId
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      owner
      name
      email
      iban
      initials
      handle
      consumablesFee
      ticketsFee
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      collaborators {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          readTickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          history {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          events {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          chargeHistory {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          collaboratorAvatarId
        }
        nextToken
      }
      events {
        items {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        nextToken
      }
      promoters {
        items {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      members {
        items {
          id
          owner
          name
          internalID
          email
          phone
          companyID
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      companyAvatarId
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      owner
      name
      internalID
      email
      phone
      companyID
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      owner
      name
      internalID
      email
      phone
      companyID
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      owner
      name
      internalID
      email
      phone
      companyID
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCollaborator = /* GraphQL */ `
  mutation CreateCollaborator(
    $input: CreateCollaboratorInput!
    $condition: ModelCollaboratorConditionInput
  ) {
    createCollaborator(input: $input, condition: $condition) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const updateCollaborator = /* GraphQL */ `
  mutation UpdateCollaborator(
    $input: UpdateCollaboratorInput!
    $condition: ModelCollaboratorConditionInput
  ) {
    updateCollaborator(input: $input, condition: $condition) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const deleteCollaborator = /* GraphQL */ `
  mutation DeleteCollaborator(
    $input: DeleteCollaboratorInput!
    $condition: ModelCollaboratorConditionInput
  ) {
    deleteCollaborator(input: $input, condition: $condition) {
      id
      owner
      name
      email
      handle
      phone
      activity
      companyID
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      readTickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      history {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      events {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      chargeHistory {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      collaboratorAvatarId
    }
  }
`;
export const createPromoter = /* GraphQL */ `
  mutation CreatePromoter(
    $input: CreatePromoterInput!
    $condition: ModelPromoterConditionInput
  ) {
    createPromoter(input: $input, condition: $condition) {
      id
      owner
      name
      companyID
      events {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePromoter = /* GraphQL */ `
  mutation UpdatePromoter(
    $input: UpdatePromoterInput!
    $condition: ModelPromoterConditionInput
  ) {
    updatePromoter(input: $input, condition: $condition) {
      id
      owner
      name
      companyID
      events {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePromoter = /* GraphQL */ `
  mutation DeletePromoter(
    $input: DeletePromoterInput!
    $condition: ModelPromoterConditionInput
  ) {
    deletePromoter(input: $input, condition: $condition) {
      id
      owner
      name
      companyID
      events {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      owner
      name
      promotions
      location {
        lon
        lat
      }
      addressAlias
      companyID
      description
      startDate
      endDate
      date {
        start
        end
      }
      maxCapacity
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      banner {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      poster {
        id
        owner
        artists {
          name
          performanceDateTime
        }
        createdAt
        updatedAt
      }
      streaks {
        items {
          id
          owner
          name
          isRepeatable
          promotions
          eventID
          promoPrice
          promoPair {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          set {
            items {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            nextToken
          }
          createdAt
          updatedAt
          streakPromoPairId
        }
        nextToken
      }
      photos {
        items {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      collaborators {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      consumables {
        items {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        nextToken
      }
      peopleState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      transactionsTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      transactionsConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      promoters {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      eventBannerId
      eventPosterId
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      owner
      name
      promotions
      location {
        lon
        lat
      }
      addressAlias
      companyID
      description
      startDate
      endDate
      date {
        start
        end
      }
      maxCapacity
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      banner {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      poster {
        id
        owner
        artists {
          name
          performanceDateTime
        }
        createdAt
        updatedAt
      }
      streaks {
        items {
          id
          owner
          name
          isRepeatable
          promotions
          eventID
          promoPrice
          promoPair {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          set {
            items {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            nextToken
          }
          createdAt
          updatedAt
          streakPromoPairId
        }
        nextToken
      }
      photos {
        items {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      collaborators {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      consumables {
        items {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        nextToken
      }
      peopleState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      transactionsTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      transactionsConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      promoters {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      eventBannerId
      eventPosterId
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      owner
      name
      promotions
      location {
        lon
        lat
      }
      addressAlias
      companyID
      description
      startDate
      endDate
      date {
        start
        end
      }
      maxCapacity
      company {
        id
        owner
        name
        email
        iban
        initials
        handle
        consumablesFee
        ticketsFee
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        collaborators {
          items {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          nextToken
        }
        events {
          items {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          nextToken
        }
        promoters {
          items {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        members {
          items {
            id
            owner
            name
            internalID
            email
            phone
            companyID
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        companyAvatarId
      }
      banner {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      poster {
        id
        owner
        artists {
          name
          performanceDateTime
        }
        createdAt
        updatedAt
      }
      streaks {
        items {
          id
          owner
          name
          isRepeatable
          promotions
          eventID
          promoPrice
          promoPair {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          set {
            items {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            nextToken
          }
          createdAt
          updatedAt
          streakPromoPairId
        }
        nextToken
      }
      photos {
        items {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        nextToken
      }
      collaborators {
        items {
          id
          collaboratorID
          eventID
          collaborator {
            id
            owner
            name
            email
            handle
            phone
            activity
            companyID
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            readTickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            history {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            events {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            chargeHistory {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            collaboratorAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      consumables {
        items {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        nextToken
      }
      peopleState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      transactionsTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      transactionsConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      promoters {
        items {
          id
          promoterID
          eventID
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      eventBannerId
      eventPosterId
    }
  }
`;
export const createPersonEventState = /* GraphQL */ `
  mutation CreatePersonEventState(
    $input: CreatePersonEventStateInput!
    $condition: ModelPersonEventStateConditionInput
  ) {
    createPersonEventState(input: $input, condition: $condition) {
      id
      owner
      personID
      eventID
      score
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      streaks {
        items {
          id
          owner
          personStateID
          currentState
          prizeState
          nrOfTimesCompleted
          streak {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          createdAt
          updatedAt
          streakStateStreakId
        }
        nextToken
      }
      previous {
        id
        owner
        score
        streaks {
          id
          currentState
          prizeState
          nrOfTimesCompleted
          streakID
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personEventStatePreviousId
    }
  }
`;
export const updatePersonEventState = /* GraphQL */ `
  mutation UpdatePersonEventState(
    $input: UpdatePersonEventStateInput!
    $condition: ModelPersonEventStateConditionInput
  ) {
    updatePersonEventState(input: $input, condition: $condition) {
      id
      owner
      personID
      eventID
      score
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      streaks {
        items {
          id
          owner
          personStateID
          currentState
          prizeState
          nrOfTimesCompleted
          streak {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          createdAt
          updatedAt
          streakStateStreakId
        }
        nextToken
      }
      previous {
        id
        owner
        score
        streaks {
          id
          currentState
          prizeState
          nrOfTimesCompleted
          streakID
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personEventStatePreviousId
    }
  }
`;
export const deletePersonEventState = /* GraphQL */ `
  mutation DeletePersonEventState(
    $input: DeletePersonEventStateInput!
    $condition: ModelPersonEventStateConditionInput
  ) {
    deletePersonEventState(input: $input, condition: $condition) {
      id
      owner
      personID
      eventID
      score
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      streaks {
        items {
          id
          owner
          personStateID
          currentState
          prizeState
          nrOfTimesCompleted
          streak {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          createdAt
          updatedAt
          streakStateStreakId
        }
        nextToken
      }
      previous {
        id
        owner
        score
        streaks {
          id
          currentState
          prizeState
          nrOfTimesCompleted
          streakID
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      personEventStatePreviousId
    }
  }
`;
export const createStreakState = /* GraphQL */ `
  mutation CreateStreakState(
    $input: CreateStreakStateInput!
    $condition: ModelStreakStateConditionInput
  ) {
    createStreakState(input: $input, condition: $condition) {
      id
      owner
      personStateID
      currentState
      prizeState
      nrOfTimesCompleted
      streak {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          nextToken
        }
        createdAt
        updatedAt
        streakPromoPairId
      }
      createdAt
      updatedAt
      streakStateStreakId
    }
  }
`;
export const updateStreakState = /* GraphQL */ `
  mutation UpdateStreakState(
    $input: UpdateStreakStateInput!
    $condition: ModelStreakStateConditionInput
  ) {
    updateStreakState(input: $input, condition: $condition) {
      id
      owner
      personStateID
      currentState
      prizeState
      nrOfTimesCompleted
      streak {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          nextToken
        }
        createdAt
        updatedAt
        streakPromoPairId
      }
      createdAt
      updatedAt
      streakStateStreakId
    }
  }
`;
export const deleteStreakState = /* GraphQL */ `
  mutation DeleteStreakState(
    $input: DeleteStreakStateInput!
    $condition: ModelStreakStateConditionInput
  ) {
    deleteStreakState(input: $input, condition: $condition) {
      id
      owner
      personStateID
      currentState
      prizeState
      nrOfTimesCompleted
      streak {
        id
        owner
        name
        isRepeatable
        promotions
        eventID
        promoPrice
        promoPair {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        set {
          items {
            id
            owner
            streakID
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            pairStreakConsumableId
          }
          nextToken
        }
        createdAt
        updatedAt
        streakPromoPairId
      }
      createdAt
      updatedAt
      streakStateStreakId
    }
  }
`;
export const createPreviousEventState = /* GraphQL */ `
  mutation CreatePreviousEventState(
    $input: CreatePreviousEventStateInput!
    $condition: ModelPreviousEventStateConditionInput
  ) {
    createPreviousEventState(input: $input, condition: $condition) {
      id
      owner
      score
      streaks {
        id
        currentState
        prizeState
        nrOfTimesCompleted
        streakID
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePreviousEventState = /* GraphQL */ `
  mutation UpdatePreviousEventState(
    $input: UpdatePreviousEventStateInput!
    $condition: ModelPreviousEventStateConditionInput
  ) {
    updatePreviousEventState(input: $input, condition: $condition) {
      id
      owner
      score
      streaks {
        id
        currentState
        prizeState
        nrOfTimesCompleted
        streakID
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePreviousEventState = /* GraphQL */ `
  mutation DeletePreviousEventState(
    $input: DeletePreviousEventStateInput!
    $condition: ModelPreviousEventStateConditionInput
  ) {
    deletePreviousEventState(input: $input, condition: $condition) {
      id
      owner
      score
      streaks {
        id
        currentState
        prizeState
        nrOfTimesCompleted
        streakID
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      owner
      name
      initialStock
      stock
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      validDates {
        start
        end
      }
      eventName
      metadata
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      owner
      name
      initialStock
      stock
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      validDates {
        start
        end
      }
      eventName
      metadata
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      owner
      name
      initialStock
      stock
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      validDates {
        start
        end
      }
      eventName
      metadata
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const createPoster = /* GraphQL */ `
  mutation CreatePoster(
    $input: CreatePosterInput!
    $condition: ModelPosterConditionInput
  ) {
    createPoster(input: $input, condition: $condition) {
      id
      owner
      artists {
        name
        performanceDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePoster = /* GraphQL */ `
  mutation UpdatePoster(
    $input: UpdatePosterInput!
    $condition: ModelPosterConditionInput
  ) {
    updatePoster(input: $input, condition: $condition) {
      id
      owner
      artists {
        name
        performanceDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePoster = /* GraphQL */ `
  mutation DeletePoster(
    $input: DeletePosterInput!
    $condition: ModelPosterConditionInput
  ) {
    deletePoster(input: $input, condition: $condition) {
      id
      owner
      artists {
        name
        performanceDateTime
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTicketTransaction = /* GraphQL */ `
  mutation CreateTicketTransaction(
    $input: CreateTicketTransactionInput!
    $condition: ModelTicketTransactionConditionInput
  ) {
    createTicketTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      fee
      price
      personID
      eventID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      ticketTransactionTicketId
      ticketTransactionRefundId
      ticketTransactionPersonTicketId
      ticketTransactionPromoterId
    }
  }
`;
export const updateTicketTransaction = /* GraphQL */ `
  mutation UpdateTicketTransaction(
    $input: UpdateTicketTransactionInput!
    $condition: ModelTicketTransactionConditionInput
  ) {
    updateTicketTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      fee
      price
      personID
      eventID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      ticketTransactionTicketId
      ticketTransactionRefundId
      ticketTransactionPersonTicketId
      ticketTransactionPromoterId
    }
  }
`;
export const deleteTicketTransaction = /* GraphQL */ `
  mutation DeleteTicketTransaction(
    $input: DeleteTicketTransactionInput!
    $condition: ModelTicketTransactionConditionInput
  ) {
    deleteTicketTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      fee
      price
      personID
      eventID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      ticketTransactionTicketId
      ticketTransactionRefundId
      ticketTransactionPersonTicketId
      ticketTransactionPromoterId
    }
  }
`;
export const createWalletTransaction = /* GraphQL */ `
  mutation CreateWalletTransaction(
    $input: CreateWalletTransactionInput!
    $condition: ModelWalletTransactionConditionInput
  ) {
    createWalletTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const updateWalletTransaction = /* GraphQL */ `
  mutation UpdateWalletTransaction(
    $input: UpdateWalletTransactionInput!
    $condition: ModelWalletTransactionConditionInput
  ) {
    updateWalletTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const deleteWalletTransaction = /* GraphQL */ `
  mutation DeleteWalletTransaction(
    $input: DeleteWalletTransactionInput!
    $condition: ModelWalletTransactionConditionInput
  ) {
    deleteWalletTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      amount
      method
      status
      metadata
      sourceID
      transactionID
      personID
      provider
      createdAt
      updatedAt
    }
  }
`;
export const createConsumableTransaction = /* GraphQL */ `
  mutation CreateConsumableTransaction(
    $input: CreateConsumableTransactionInput!
    $condition: ModelConsumableTransactionConditionInput
  ) {
    createConsumableTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      isRefunded
      personHandle
      price
      collaboratorID
      personID
      eventID
      cart {
        items {
          id
          owner
          personHandle
          transactionID
          amount
          isRefunded
          wasUsedInPromo
          streakID
          wasPersonConsumable
          pricePaid
          whoGaveIt
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairTransactionConsumableId
        }
        nextToken
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConsumableTransaction = /* GraphQL */ `
  mutation UpdateConsumableTransaction(
    $input: UpdateConsumableTransactionInput!
    $condition: ModelConsumableTransactionConditionInput
  ) {
    updateConsumableTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      isRefunded
      personHandle
      price
      collaboratorID
      personID
      eventID
      cart {
        items {
          id
          owner
          personHandle
          transactionID
          amount
          isRefunded
          wasUsedInPromo
          streakID
          wasPersonConsumable
          pricePaid
          whoGaveIt
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairTransactionConsumableId
        }
        nextToken
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsumableTransaction = /* GraphQL */ `
  mutation DeleteConsumableTransaction(
    $input: DeleteConsumableTransactionInput!
    $condition: ModelConsumableTransactionConditionInput
  ) {
    deleteConsumableTransaction(input: $input, condition: $condition) {
      id
      owner
      date
      isRefunded
      personHandle
      price
      collaboratorID
      personID
      eventID
      cart {
        items {
          id
          owner
          personHandle
          transactionID
          amount
          isRefunded
          wasUsedInPromo
          streakID
          wasPersonConsumable
          pricePaid
          whoGaveIt
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairTransactionConsumableId
        }
        nextToken
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPersonTicket = /* GraphQL */ `
  mutation CreatePersonTicket(
    $input: CreatePersonTicketInput!
    $condition: ModelPersonTicketConditionInput
  ) {
    createPersonTicket(input: $input, condition: $condition) {
      id
      owner
      readDates
      privacy
      eventID
      collaboratorID
      personID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      createdAt
      updatedAt
      personTicketTicketId
      personTicketRefundId
      personTicketTransactionId
    }
  }
`;
export const updatePersonTicket = /* GraphQL */ `
  mutation UpdatePersonTicket(
    $input: UpdatePersonTicketInput!
    $condition: ModelPersonTicketConditionInput
  ) {
    updatePersonTicket(input: $input, condition: $condition) {
      id
      owner
      readDates
      privacy
      eventID
      collaboratorID
      personID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      createdAt
      updatedAt
      personTicketTicketId
      personTicketRefundId
      personTicketTransactionId
    }
  }
`;
export const deletePersonTicket = /* GraphQL */ `
  mutation DeletePersonTicket(
    $input: DeletePersonTicketInput!
    $condition: ModelPersonTicketConditionInput
  ) {
    deletePersonTicket(input: $input, condition: $condition) {
      id
      owner
      readDates
      privacy
      eventID
      collaboratorID
      personID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      person {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      refund {
        id
        owner
        refundedAmount
        refundedPercentage
        ticketID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        eventID
        personID
        createdAt
        updatedAt
        ticketRefundPersonTicketId
        ticketRefundTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      createdAt
      updatedAt
      personTicketTicketId
      personTicketRefundId
      personTicketTransactionId
    }
  }
`;
export const createTicketRefund = /* GraphQL */ `
  mutation CreateTicketRefund(
    $input: CreateTicketRefundInput!
    $condition: ModelTicketRefundConditionInput
  ) {
    createTicketRefund(input: $input, condition: $condition) {
      id
      owner
      refundedAmount
      refundedPercentage
      ticketID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      eventID
      personID
      createdAt
      updatedAt
      ticketRefundPersonTicketId
      ticketRefundTransactionId
    }
  }
`;
export const updateTicketRefund = /* GraphQL */ `
  mutation UpdateTicketRefund(
    $input: UpdateTicketRefundInput!
    $condition: ModelTicketRefundConditionInput
  ) {
    updateTicketRefund(input: $input, condition: $condition) {
      id
      owner
      refundedAmount
      refundedPercentage
      ticketID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      eventID
      personID
      createdAt
      updatedAt
      ticketRefundPersonTicketId
      ticketRefundTransactionId
    }
  }
`;
export const deleteTicketRefund = /* GraphQL */ `
  mutation DeleteTicketRefund(
    $input: DeleteTicketRefundInput!
    $condition: ModelTicketRefundConditionInput
  ) {
    deleteTicketRefund(input: $input, condition: $condition) {
      id
      owner
      refundedAmount
      refundedPercentage
      ticketID
      ticket {
        id
        owner
        name
        initialStock
        stock
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        validDates {
          start
          end
        }
        eventName
        metadata
        eventID
        createdAt
        updatedAt
      }
      personTicket {
        id
        owner
        readDates
        privacy
        eventID
        collaboratorID
        personID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        person {
          id
          owner
          name
          email
          phone
          dateOfBirth
          wallet
          privacy
          handle
          currentEventID
          address {
            street
            zipCode
            city
            country
          }
          auxIdentity {
            id
            owner
            validDate
            personID
            createdAt
            updatedAt
          }
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          consumables {
            items {
              id
              owner
              personID
              whoGaveIt
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              eventID
              pricePaid
              createdAt
              updatedAt
              pairPersonConsumableId
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            nextToken
          }
          eventState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          historyTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          historyConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          historyWallet {
            items {
              id
              owner
              date
              amount
              method
              status
              metadata
              sourceID
              transactionID
              personID
              provider
              createdAt
              updatedAt
            }
            nextToken
          }
          friends {
            items {
              id
              personID
              friendID
              owner
              friend {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              request
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          createdAt
          updatedAt
          personAuxIdentityId
          personAvatarId
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        transaction {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        createdAt
        updatedAt
        personTicketTicketId
        personTicketRefundId
        personTicketTransactionId
      }
      transaction {
        id
        owner
        date
        fee
        price
        personID
        eventID
        ticket {
          id
          owner
          name
          initialStock
          stock
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          validDates {
            start
            end
          }
          eventName
          metadata
          eventID
          createdAt
          updatedAt
        }
        refund {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        personTicket {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        promoter {
          id
          owner
          name
          companyID
          events {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ticketTransactionTicketId
        ticketTransactionRefundId
        ticketTransactionPersonTicketId
        ticketTransactionPromoterId
      }
      eventID
      personID
      createdAt
      updatedAt
      ticketRefundPersonTicketId
      ticketRefundTransactionId
    }
  }
`;
export const createPerson = /* GraphQL */ `
  mutation CreatePerson(
    $input: CreatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    createPerson(input: $input, condition: $condition) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const updatePerson = /* GraphQL */ `
  mutation UpdatePerson(
    $input: UpdatePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    updatePerson(input: $input, condition: $condition) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      owner
      name
      email
      phone
      dateOfBirth
      wallet
      privacy
      handle
      currentEventID
      address {
        street
        zipCode
        city
        country
      }
      auxIdentity {
        id
        owner
        validDate
        personID
        createdAt
        updatedAt
      }
      avatar {
        id
        owner
        eventID
        filename
        identityID
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      consumables {
        items {
          id
          owner
          personID
          whoGaveIt
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          eventID
          pricePaid
          createdAt
          updatedAt
          pairPersonConsumableId
        }
        nextToken
      }
      tickets {
        items {
          id
          owner
          readDates
          privacy
          eventID
          collaboratorID
          personID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          createdAt
          updatedAt
          personTicketTicketId
          personTicketRefundId
          personTicketTransactionId
        }
        nextToken
      }
      eventState {
        items {
          id
          owner
          personID
          eventID
          score
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          streaks {
            items {
              id
              owner
              personStateID
              currentState
              prizeState
              nrOfTimesCompleted
              streak {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              createdAt
              updatedAt
              streakStateStreakId
            }
            nextToken
          }
          previous {
            id
            owner
            score
            streaks {
              id
              currentState
              prizeState
              nrOfTimesCompleted
              streakID
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          personEventStatePreviousId
        }
        nextToken
      }
      historyTickets {
        items {
          id
          owner
          date
          fee
          price
          personID
          eventID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          refund {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          promoter {
            id
            owner
            name
            companyID
            events {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          ticketTransactionTicketId
          ticketTransactionRefundId
          ticketTransactionPersonTicketId
          ticketTransactionPromoterId
        }
        nextToken
      }
      historyConsumables {
        items {
          id
          owner
          date
          isRefunded
          personHandle
          price
          collaboratorID
          personID
          eventID
          cart {
            items {
              id
              owner
              personHandle
              transactionID
              amount
              isRefunded
              wasUsedInPromo
              streakID
              wasPersonConsumable
              pricePaid
              whoGaveIt
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairTransactionConsumableId
            }
            nextToken
          }
          person {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          event {
            id
            owner
            name
            promotions
            location {
              lon
              lat
            }
            addressAlias
            companyID
            description
            startDate
            endDate
            date {
              start
              end
            }
            maxCapacity
            company {
              id
              owner
              name
              email
              iban
              initials
              handle
              consumablesFee
              ticketsFee
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              collaborators {
                nextToken
              }
              events {
                nextToken
              }
              promoters {
                nextToken
              }
              members {
                nextToken
              }
              createdAt
              updatedAt
              companyAvatarId
            }
            banner {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            poster {
              id
              owner
              artists {
                name
                performanceDateTime
              }
              createdAt
              updatedAt
            }
            streaks {
              items {
                id
                owner
                name
                isRepeatable
                promotions
                eventID
                promoPrice
                createdAt
                updatedAt
                streakPromoPairId
              }
              nextToken
            }
            photos {
              items {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            collaborators {
              items {
                id
                collaboratorID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            consumables {
              items {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              nextToken
            }
            peopleState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            transactionsTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            transactionsConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            promoters {
              items {
                id
                promoterID
                eventID
                createdAt
                updatedAt
                owner
              }
              nextToken
            }
            createdAt
            updatedAt
            eventBannerId
            eventPosterId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      historyWallet {
        items {
          id
          owner
          date
          amount
          method
          status
          metadata
          sourceID
          transactionID
          personID
          provider
          createdAt
          updatedAt
        }
        nextToken
      }
      friends {
        items {
          id
          personID
          friendID
          owner
          friend {
            id
            owner
            name
            email
            phone
            dateOfBirth
            wallet
            privacy
            handle
            currentEventID
            address {
              street
              zipCode
              city
              country
            }
            auxIdentity {
              id
              owner
              validDate
              personID
              createdAt
              updatedAt
            }
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            consumables {
              items {
                id
                owner
                personID
                whoGaveIt
                amount
                eventID
                pricePaid
                createdAt
                updatedAt
                pairPersonConsumableId
              }
              nextToken
            }
            tickets {
              items {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              nextToken
            }
            eventState {
              items {
                id
                owner
                personID
                eventID
                score
                createdAt
                updatedAt
                personEventStatePreviousId
              }
              nextToken
            }
            historyTickets {
              items {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              nextToken
            }
            historyConsumables {
              items {
                id
                owner
                date
                isRefunded
                personHandle
                price
                collaboratorID
                personID
                eventID
                createdAt
                updatedAt
              }
              nextToken
            }
            historyWallet {
              items {
                id
                owner
                date
                amount
                method
                status
                metadata
                sourceID
                transactionID
                personID
                provider
                createdAt
                updatedAt
              }
              nextToken
            }
            friends {
              items {
                id
                personID
                friendID
                owner
                request
                createdAt
                updatedAt
              }
              nextToken
            }
            ticketRefunds {
              items {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              nextToken
            }
            createdAt
            updatedAt
            personAuxIdentityId
            personAvatarId
          }
          request
          createdAt
          updatedAt
        }
        nextToken
      }
      ticketRefunds {
        items {
          id
          owner
          refundedAmount
          refundedPercentage
          ticketID
          ticket {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          personTicket {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          transaction {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          eventID
          personID
          createdAt
          updatedAt
          ticketRefundPersonTicketId
          ticketRefundTransactionId
        }
        nextToken
      }
      createdAt
      updatedAt
      personAuxIdentityId
      personAvatarId
    }
  }
`;
export const createAuxiliaryIdentity = /* GraphQL */ `
  mutation CreateAuxiliaryIdentity(
    $input: CreateAuxiliaryIdentityInput!
    $condition: ModelAuxiliaryIdentityConditionInput
  ) {
    createAuxiliaryIdentity(input: $input, condition: $condition) {
      id
      owner
      validDate
      personID
      createdAt
      updatedAt
    }
  }
`;
export const updateAuxiliaryIdentity = /* GraphQL */ `
  mutation UpdateAuxiliaryIdentity(
    $input: UpdateAuxiliaryIdentityInput!
    $condition: ModelAuxiliaryIdentityConditionInput
  ) {
    updateAuxiliaryIdentity(input: $input, condition: $condition) {
      id
      owner
      validDate
      personID
      createdAt
      updatedAt
    }
  }
`;
export const deleteAuxiliaryIdentity = /* GraphQL */ `
  mutation DeleteAuxiliaryIdentity(
    $input: DeleteAuxiliaryIdentityInput!
    $condition: ModelAuxiliaryIdentityConditionInput
  ) {
    deleteAuxiliaryIdentity(input: $input, condition: $condition) {
      id
      owner
      validDate
      personID
      createdAt
      updatedAt
    }
  }
`;
export const createFriend = /* GraphQL */ `
  mutation CreateFriend(
    $input: CreateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    createFriend(input: $input, condition: $condition) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const updateFriend = /* GraphQL */ `
  mutation UpdateFriend(
    $input: UpdateFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    updateFriend(input: $input, condition: $condition) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const deleteFriend = /* GraphQL */ `
  mutation DeleteFriend(
    $input: DeleteFriendInput!
    $condition: ModelFriendConditionInput
  ) {
    deleteFriend(input: $input, condition: $condition) {
      id
      personID
      friendID
      owner
      friend {
        id
        owner
        name
        email
        phone
        dateOfBirth
        wallet
        privacy
        handle
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        auxIdentity {
          id
          owner
          validDate
          personID
          createdAt
          updatedAt
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        event {
          id
          owner
          name
          promotions
          location {
            lon
            lat
          }
          addressAlias
          companyID
          description
          startDate
          endDate
          date {
            start
            end
          }
          maxCapacity
          company {
            id
            owner
            name
            email
            iban
            initials
            handle
            consumablesFee
            ticketsFee
            avatar {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            collaborators {
              items {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              nextToken
            }
            events {
              items {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              nextToken
            }
            promoters {
              items {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            members {
              items {
                id
                owner
                name
                internalID
                email
                phone
                companyID
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            companyAvatarId
          }
          banner {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          poster {
            id
            owner
            artists {
              name
              performanceDateTime
            }
            createdAt
            updatedAt
          }
          streaks {
            items {
              id
              owner
              name
              isRepeatable
              promotions
              eventID
              promoPrice
              promoPair {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              set {
                nextToken
              }
              createdAt
              updatedAt
              streakPromoPairId
            }
            nextToken
          }
          photos {
            items {
              id
              owner
              eventID
              filename
              identityID
              createdAt
              updatedAt
            }
            nextToken
          }
          tickets {
            items {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            nextToken
          }
          collaborators {
            items {
              id
              collaboratorID
              eventID
              collaborator {
                id
                owner
                name
                email
                handle
                phone
                activity
                companyID
                createdAt
                updatedAt
                collaboratorAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          consumables {
            items {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            nextToken
          }
          peopleState {
            items {
              id
              owner
              personID
              eventID
              score
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              streaks {
                nextToken
              }
              previous {
                id
                owner
                score
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              personEventStatePreviousId
            }
            nextToken
          }
          transactionsTickets {
            items {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            nextToken
          }
          transactionsConsumables {
            items {
              id
              owner
              date
              isRefunded
              personHandle
              price
              collaboratorID
              personID
              eventID
              cart {
                nextToken
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          ticketRefunds {
            items {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            nextToken
          }
          promoters {
            items {
              id
              promoterID
              eventID
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          createdAt
          updatedAt
          eventBannerId
          eventPosterId
        }
        consumables {
          items {
            id
            owner
            personID
            whoGaveIt
            amount
            consumable {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            eventID
            pricePaid
            createdAt
            updatedAt
            pairPersonConsumableId
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        eventState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        historyTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        historyConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        historyWallet {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        friends {
          items {
            id
            personID
            friendID
            owner
            friend {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            request
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        createdAt
        updatedAt
        personAuxIdentityId
        personAvatarId
      }
      request
      createdAt
      updatedAt
    }
  }
`;
export const createConsumable = /* GraphQL */ `
  mutation CreateConsumable(
    $input: CreateConsumableInput!
    $condition: ModelConsumableConditionInput
  ) {
    createConsumable(input: $input, condition: $condition) {
      id
      owner
      type
      size
      name
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      companyID
      eventID
      smallOrLargeRef {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      consumableSmallOrLargeRefId
    }
  }
`;
export const updateConsumable = /* GraphQL */ `
  mutation UpdateConsumable(
    $input: UpdateConsumableInput!
    $condition: ModelConsumableConditionInput
  ) {
    updateConsumable(input: $input, condition: $condition) {
      id
      owner
      type
      size
      name
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      companyID
      eventID
      smallOrLargeRef {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      consumableSmallOrLargeRefId
    }
  }
`;
export const deleteConsumable = /* GraphQL */ `
  mutation DeleteConsumable(
    $input: DeleteConsumableInput!
    $condition: ModelConsumableConditionInput
  ) {
    deleteConsumable(input: $input, condition: $condition) {
      id
      owner
      type
      size
      name
      prices {
        price
        memberPrice
        promoPrices {
          dateSpan {
            start
            end
          }
          memberPrice
          price
        }
      }
      companyID
      eventID
      smallOrLargeRef {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      consumableSmallOrLargeRefId
    }
  }
`;
export const createStreak = /* GraphQL */ `
  mutation CreateStreak(
    $input: CreateStreakInput!
    $condition: ModelStreakConditionInput
  ) {
    createStreak(input: $input, condition: $condition) {
      id
      owner
      name
      isRepeatable
      promotions
      eventID
      promoPrice
      promoPair {
        id
        owner
        streakID
        amount
        consumable {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        pairStreakConsumableId
      }
      set {
        items {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        nextToken
      }
      createdAt
      updatedAt
      streakPromoPairId
    }
  }
`;
export const updateStreak = /* GraphQL */ `
  mutation UpdateStreak(
    $input: UpdateStreakInput!
    $condition: ModelStreakConditionInput
  ) {
    updateStreak(input: $input, condition: $condition) {
      id
      owner
      name
      isRepeatable
      promotions
      eventID
      promoPrice
      promoPair {
        id
        owner
        streakID
        amount
        consumable {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        pairStreakConsumableId
      }
      set {
        items {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        nextToken
      }
      createdAt
      updatedAt
      streakPromoPairId
    }
  }
`;
export const deleteStreak = /* GraphQL */ `
  mutation DeleteStreak(
    $input: DeleteStreakInput!
    $condition: ModelStreakConditionInput
  ) {
    deleteStreak(input: $input, condition: $condition) {
      id
      owner
      name
      isRepeatable
      promotions
      eventID
      promoPrice
      promoPair {
        id
        owner
        streakID
        amount
        consumable {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        pairStreakConsumableId
      }
      set {
        items {
          id
          owner
          streakID
          amount
          consumable {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          pairStreakConsumableId
        }
        nextToken
      }
      createdAt
      updatedAt
      streakPromoPairId
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      owner
      eventID
      filename
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      owner
      eventID
      filename
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      owner
      eventID
      filename
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const createPairPerson = /* GraphQL */ `
  mutation CreatePairPerson(
    $input: CreatePairPersonInput!
    $condition: ModelPairPersonConditionInput
  ) {
    createPairPerson(input: $input, condition: $condition) {
      id
      owner
      personID
      whoGaveIt
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      eventID
      pricePaid
      createdAt
      updatedAt
      pairPersonConsumableId
    }
  }
`;
export const updatePairPerson = /* GraphQL */ `
  mutation UpdatePairPerson(
    $input: UpdatePairPersonInput!
    $condition: ModelPairPersonConditionInput
  ) {
    updatePairPerson(input: $input, condition: $condition) {
      id
      owner
      personID
      whoGaveIt
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      eventID
      pricePaid
      createdAt
      updatedAt
      pairPersonConsumableId
    }
  }
`;
export const deletePairPerson = /* GraphQL */ `
  mutation DeletePairPerson(
    $input: DeletePairPersonInput!
    $condition: ModelPairPersonConditionInput
  ) {
    deletePairPerson(input: $input, condition: $condition) {
      id
      owner
      personID
      whoGaveIt
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      eventID
      pricePaid
      createdAt
      updatedAt
      pairPersonConsumableId
    }
  }
`;
export const createPairStreak = /* GraphQL */ `
  mutation CreatePairStreak(
    $input: CreatePairStreakInput!
    $condition: ModelPairStreakConditionInput
  ) {
    createPairStreak(input: $input, condition: $condition) {
      id
      owner
      streakID
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairStreakConsumableId
    }
  }
`;
export const updatePairStreak = /* GraphQL */ `
  mutation UpdatePairStreak(
    $input: UpdatePairStreakInput!
    $condition: ModelPairStreakConditionInput
  ) {
    updatePairStreak(input: $input, condition: $condition) {
      id
      owner
      streakID
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairStreakConsumableId
    }
  }
`;
export const deletePairStreak = /* GraphQL */ `
  mutation DeletePairStreak(
    $input: DeletePairStreakInput!
    $condition: ModelPairStreakConditionInput
  ) {
    deletePairStreak(input: $input, condition: $condition) {
      id
      owner
      streakID
      amount
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairStreakConsumableId
    }
  }
`;
export const createPairTransaction = /* GraphQL */ `
  mutation CreatePairTransaction(
    $input: CreatePairTransactionInput!
    $condition: ModelPairTransactionConditionInput
  ) {
    createPairTransaction(input: $input, condition: $condition) {
      id
      owner
      personHandle
      transactionID
      amount
      isRefunded
      wasUsedInPromo
      streakID
      wasPersonConsumable
      pricePaid
      whoGaveIt
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairTransactionConsumableId
    }
  }
`;
export const updatePairTransaction = /* GraphQL */ `
  mutation UpdatePairTransaction(
    $input: UpdatePairTransactionInput!
    $condition: ModelPairTransactionConditionInput
  ) {
    updatePairTransaction(input: $input, condition: $condition) {
      id
      owner
      personHandle
      transactionID
      amount
      isRefunded
      wasUsedInPromo
      streakID
      wasPersonConsumable
      pricePaid
      whoGaveIt
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairTransactionConsumableId
    }
  }
`;
export const deletePairTransaction = /* GraphQL */ `
  mutation DeletePairTransaction(
    $input: DeletePairTransactionInput!
    $condition: ModelPairTransactionConditionInput
  ) {
    deletePairTransaction(input: $input, condition: $condition) {
      id
      owner
      personHandle
      transactionID
      amount
      isRefunded
      wasUsedInPromo
      streakID
      wasPersonConsumable
      pricePaid
      whoGaveIt
      consumable {
        id
        owner
        type
        size
        name
        prices {
          price
          memberPrice
          promoPrices {
            dateSpan {
              start
              end
            }
            memberPrice
            price
          }
        }
        companyID
        eventID
        smallOrLargeRef {
          id
          owner
          type
          size
          name
          prices {
            price
            memberPrice
            promoPrices {
              dateSpan {
                start
                end
              }
              memberPrice
              price
            }
          }
          companyID
          eventID
          smallOrLargeRef {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          createdAt
          updatedAt
          consumableSmallOrLargeRefId
        }
        createdAt
        updatedAt
        consumableSmallOrLargeRefId
      }
      createdAt
      updatedAt
      pairTransactionConsumableId
    }
  }
`;
export const createCollaboratorEventConnection = /* GraphQL */ `
  mutation CreateCollaboratorEventConnection(
    $input: CreateCollaboratorEventConnectionInput!
    $condition: ModelCollaboratorEventConnectionConditionInput
  ) {
    createCollaboratorEventConnection(input: $input, condition: $condition) {
      id
      collaboratorID
      eventID
      collaborator {
        id
        owner
        name
        email
        handle
        phone
        activity
        companyID
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        readTickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        history {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        chargeHistory {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        collaboratorAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCollaboratorEventConnection = /* GraphQL */ `
  mutation UpdateCollaboratorEventConnection(
    $input: UpdateCollaboratorEventConnectionInput!
    $condition: ModelCollaboratorEventConnectionConditionInput
  ) {
    updateCollaboratorEventConnection(input: $input, condition: $condition) {
      id
      collaboratorID
      eventID
      collaborator {
        id
        owner
        name
        email
        handle
        phone
        activity
        companyID
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        readTickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        history {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        chargeHistory {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        collaboratorAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCollaboratorEventConnection = /* GraphQL */ `
  mutation DeleteCollaboratorEventConnection(
    $input: DeleteCollaboratorEventConnectionInput!
    $condition: ModelCollaboratorEventConnectionConditionInput
  ) {
    deleteCollaboratorEventConnection(input: $input, condition: $condition) {
      id
      collaboratorID
      eventID
      collaborator {
        id
        owner
        name
        email
        handle
        phone
        activity
        companyID
        avatar {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        readTickets {
          items {
            id
            owner
            readDates
            privacy
            eventID
            collaboratorID
            personID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            createdAt
            updatedAt
            personTicketTicketId
            personTicketRefundId
            personTicketTransactionId
          }
          nextToken
        }
        history {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        events {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        chargeHistory {
          items {
            id
            owner
            date
            amount
            method
            status
            metadata
            sourceID
            transactionID
            personID
            provider
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        collaboratorAvatarId
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPromoterEventConnection = /* GraphQL */ `
  mutation CreatePromoterEventConnection(
    $input: CreatePromoterEventConnectionInput!
    $condition: ModelPromoterEventConnectionConditionInput
  ) {
    createPromoterEventConnection(input: $input, condition: $condition) {
      id
      promoterID
      eventID
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePromoterEventConnection = /* GraphQL */ `
  mutation UpdatePromoterEventConnection(
    $input: UpdatePromoterEventConnectionInput!
    $condition: ModelPromoterEventConnectionConditionInput
  ) {
    updatePromoterEventConnection(input: $input, condition: $condition) {
      id
      promoterID
      eventID
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePromoterEventConnection = /* GraphQL */ `
  mutation DeletePromoterEventConnection(
    $input: DeletePromoterEventConnectionInput!
    $condition: ModelPromoterEventConnectionConditionInput
  ) {
    deletePromoterEventConnection(input: $input, condition: $condition) {
      id
      promoterID
      eventID
      promoter {
        id
        owner
        name
        companyID
        events {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      event {
        id
        owner
        name
        promotions
        location {
          lon
          lat
        }
        addressAlias
        companyID
        description
        startDate
        endDate
        date {
          start
          end
        }
        maxCapacity
        company {
          id
          owner
          name
          email
          iban
          initials
          handle
          consumablesFee
          ticketsFee
          avatar {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          collaborators {
            items {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            nextToken
          }
          events {
            items {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            nextToken
          }
          promoters {
            items {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          members {
            items {
              id
              owner
              name
              internalID
              email
              phone
              companyID
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          companyAvatarId
        }
        banner {
          id
          owner
          eventID
          filename
          identityID
          createdAt
          updatedAt
        }
        poster {
          id
          owner
          artists {
            name
            performanceDateTime
          }
          createdAt
          updatedAt
        }
        streaks {
          items {
            id
            owner
            name
            isRepeatable
            promotions
            eventID
            promoPrice
            promoPair {
              id
              owner
              streakID
              amount
              consumable {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              pairStreakConsumableId
            }
            set {
              items {
                id
                owner
                streakID
                amount
                createdAt
                updatedAt
                pairStreakConsumableId
              }
              nextToken
            }
            createdAt
            updatedAt
            streakPromoPairId
          }
          nextToken
        }
        photos {
          items {
            id
            owner
            eventID
            filename
            identityID
            createdAt
            updatedAt
          }
          nextToken
        }
        tickets {
          items {
            id
            owner
            name
            initialStock
            stock
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            validDates {
              start
              end
            }
            eventName
            metadata
            eventID
            createdAt
            updatedAt
          }
          nextToken
        }
        collaborators {
          items {
            id
            collaboratorID
            eventID
            collaborator {
              id
              owner
              name
              email
              handle
              phone
              activity
              companyID
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              readTickets {
                nextToken
              }
              history {
                nextToken
              }
              events {
                nextToken
              }
              chargeHistory {
                nextToken
              }
              createdAt
              updatedAt
              collaboratorAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        consumables {
          items {
            id
            owner
            type
            size
            name
            prices {
              price
              memberPrice
              promoPrices {
                memberPrice
                price
              }
            }
            companyID
            eventID
            smallOrLargeRef {
              id
              owner
              type
              size
              name
              prices {
                price
                memberPrice
              }
              companyID
              eventID
              smallOrLargeRef {
                id
                owner
                type
                size
                name
                companyID
                eventID
                createdAt
                updatedAt
                consumableSmallOrLargeRefId
              }
              createdAt
              updatedAt
              consumableSmallOrLargeRefId
            }
            createdAt
            updatedAt
            consumableSmallOrLargeRefId
          }
          nextToken
        }
        peopleState {
          items {
            id
            owner
            personID
            eventID
            score
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            streaks {
              items {
                id
                owner
                personStateID
                currentState
                prizeState
                nrOfTimesCompleted
                createdAt
                updatedAt
                streakStateStreakId
              }
              nextToken
            }
            previous {
              id
              owner
              score
              streaks {
                id
                currentState
                prizeState
                nrOfTimesCompleted
                streakID
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            personEventStatePreviousId
          }
          nextToken
        }
        transactionsTickets {
          items {
            id
            owner
            date
            fee
            price
            personID
            eventID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            refund {
              id
              owner
              refundedAmount
              refundedPercentage
              ticketID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              eventID
              personID
              createdAt
              updatedAt
              ticketRefundPersonTicketId
              ticketRefundTransactionId
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            ticketTransactionTicketId
            ticketTransactionRefundId
            ticketTransactionPersonTicketId
            ticketTransactionPromoterId
          }
          nextToken
        }
        transactionsConsumables {
          items {
            id
            owner
            date
            isRefunded
            personHandle
            price
            collaboratorID
            personID
            eventID
            cart {
              items {
                id
                owner
                personHandle
                transactionID
                amount
                isRefunded
                wasUsedInPromo
                streakID
                wasPersonConsumable
                pricePaid
                whoGaveIt
                createdAt
                updatedAt
                pairTransactionConsumableId
              }
              nextToken
            }
            person {
              id
              owner
              name
              email
              phone
              dateOfBirth
              wallet
              privacy
              handle
              currentEventID
              address {
                street
                zipCode
                city
                country
              }
              auxIdentity {
                id
                owner
                validDate
                personID
                createdAt
                updatedAt
              }
              avatar {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              event {
                id
                owner
                name
                promotions
                addressAlias
                companyID
                description
                startDate
                endDate
                maxCapacity
                createdAt
                updatedAt
                eventBannerId
                eventPosterId
              }
              consumables {
                nextToken
              }
              tickets {
                nextToken
              }
              eventState {
                nextToken
              }
              historyTickets {
                nextToken
              }
              historyConsumables {
                nextToken
              }
              historyWallet {
                nextToken
              }
              friends {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              createdAt
              updatedAt
              personAuxIdentityId
              personAvatarId
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        ticketRefunds {
          items {
            id
            owner
            refundedAmount
            refundedPercentage
            ticketID
            ticket {
              id
              owner
              name
              initialStock
              stock
              prices {
                price
                memberPrice
              }
              validDates {
                start
                end
              }
              eventName
              metadata
              eventID
              createdAt
              updatedAt
            }
            personTicket {
              id
              owner
              readDates
              privacy
              eventID
              collaboratorID
              personID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              person {
                id
                owner
                name
                email
                phone
                dateOfBirth
                wallet
                privacy
                handle
                currentEventID
                createdAt
                updatedAt
                personAuxIdentityId
                personAvatarId
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              transaction {
                id
                owner
                date
                fee
                price
                personID
                eventID
                createdAt
                updatedAt
                ticketTransactionTicketId
                ticketTransactionRefundId
                ticketTransactionPersonTicketId
                ticketTransactionPromoterId
              }
              createdAt
              updatedAt
              personTicketTicketId
              personTicketRefundId
              personTicketTransactionId
            }
            transaction {
              id
              owner
              date
              fee
              price
              personID
              eventID
              ticket {
                id
                owner
                name
                initialStock
                stock
                eventName
                metadata
                eventID
                createdAt
                updatedAt
              }
              refund {
                id
                owner
                refundedAmount
                refundedPercentage
                ticketID
                eventID
                personID
                createdAt
                updatedAt
                ticketRefundPersonTicketId
                ticketRefundTransactionId
              }
              personTicket {
                id
                owner
                readDates
                privacy
                eventID
                collaboratorID
                personID
                createdAt
                updatedAt
                personTicketTicketId
                personTicketRefundId
                personTicketTransactionId
              }
              promoter {
                id
                owner
                name
                companyID
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              ticketTransactionTicketId
              ticketTransactionRefundId
              ticketTransactionPersonTicketId
              ticketTransactionPromoterId
            }
            eventID
            personID
            createdAt
            updatedAt
            ticketRefundPersonTicketId
            ticketRefundTransactionId
          }
          nextToken
        }
        promoters {
          items {
            id
            promoterID
            eventID
            promoter {
              id
              owner
              name
              companyID
              events {
                nextToken
              }
              createdAt
              updatedAt
            }
            event {
              id
              owner
              name
              promotions
              location {
                lon
                lat
              }
              addressAlias
              companyID
              description
              startDate
              endDate
              date {
                start
                end
              }
              maxCapacity
              company {
                id
                owner
                name
                email
                iban
                initials
                handle
                consumablesFee
                ticketsFee
                createdAt
                updatedAt
                companyAvatarId
              }
              banner {
                id
                owner
                eventID
                filename
                identityID
                createdAt
                updatedAt
              }
              poster {
                id
                owner
                createdAt
                updatedAt
              }
              streaks {
                nextToken
              }
              photos {
                nextToken
              }
              tickets {
                nextToken
              }
              collaborators {
                nextToken
              }
              consumables {
                nextToken
              }
              peopleState {
                nextToken
              }
              transactionsTickets {
                nextToken
              }
              transactionsConsumables {
                nextToken
              }
              ticketRefunds {
                nextToken
              }
              promoters {
                nextToken
              }
              createdAt
              updatedAt
              eventBannerId
              eventPosterId
            }
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        eventBannerId
        eventPosterId
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const ticketPurchase = /* GraphQL */ `
  mutation TicketPurchase($input: TicketPurchaseInput!) {
    ticketPurchase(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const consumablePurchase = /* GraphQL */ `
  mutation ConsumablePurchase($input: ConsumablePurchaseInput!) {
    consumablePurchase(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const consumablePurchaseV2 = /* GraphQL */ `
  mutation ConsumablePurchaseV2($input: ConsumablePurchaseInput!) {
    consumablePurchaseV2(input: $input) {
      reply {
        status
        error
        metadata
      }
      personName
      personHandle
      personWallet
      transactionID
    }
  }
`;
export const hasTicket = /* GraphQL */ `
  mutation HasTicket($input: HasTicketInput!) {
    hasTicket(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const scannerEncryption = /* GraphQL */ `
  mutation ScannerEncryption($input: ScannerEncryptionInput!) {
    scannerEncryption(input: $input)
  }
`;
export const scannerDecryption = /* GraphQL */ `
  mutation ScannerDecryption($input: ScannerDecryptionInput!) {
    scannerDecryption(input: $input)
  }
`;
export const sendConsumable = /* GraphQL */ `
  mutation SendConsumable($input: SendConsumableInput!) {
    sendConsumable(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const checkoutMBway = /* GraphQL */ `
  mutation CheckoutMBway($input: CheckoutMBWayInput!) {
    checkoutMBway(input: $input) {
      formContext
      transactionID
      amount
    }
  }
`;
export const doStatusMBWay = /* GraphQL */ `
  mutation DoStatusMBWay($input: StatusMBWayInput!) {
    doStatusMBWay(input: $input)
  }
`;
export const doFriendRequest = /* GraphQL */ `
  mutation DoFriendRequest($input: FriendRequestInput!) {
    doFriendRequest(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const doAddFriendWithID = /* GraphQL */ `
  mutation DoAddFriendWithID($input: AddFriendWithIDInput!) {
    doAddFriendWithID(input: $input) {
      statusCode
      headers
      body
      status
    }
  }
`;
export const doLinkPersonWithAux = /* GraphQL */ `
  mutation DoLinkPersonWithAux($input: LinkPersonWithAuxInput!) {
    doLinkPersonWithAux(input: $input)
  }
`;
export const doSibsPing = /* GraphQL */ `
  mutation DoSibsPing($input: SibsPingInput!) {
    doSibsPing(input: $input)
  }
`;
export const scannerEncryptionV2 = /* GraphQL */ `
  mutation ScannerEncryptionV2($input: ScannerEncryptionInput!) {
    scannerEncryptionV2(input: $input)
  }
`;
export const scannerDecryptionV2 = /* GraphQL */ `
  mutation ScannerDecryptionV2($input: ScannerDecryptionInput!) {
    scannerDecryptionV2(input: $input)
  }
`;
export const doRefundTicket = /* GraphQL */ `
  mutation DoRefundTicket($input: RefundTicketsInput!) {
    doRefundTicket(input: $input) {
      status
      error
      metadata
    }
  }
`;
export const doCheckMinimumVersion = /* GraphQL */ `
  mutation DoCheckMinimumVersion($input: CheckMinimumVersion) {
    doCheckMinimumVersion(input: $input)
  }
`;
export const doPaymentMBWay = /* GraphQL */ `
  mutation DoPaymentMBWay($input: DoPaymentMBWay) {
    doPaymentMBWay(input: $input) {
      formContext
      transactionID
      amount
    }
  }
`;
export const doPingMBWay = /* GraphQL */ `
  mutation DoPingMBWay($input: PingMBWayInput!) {
    doPingMBWay(input: $input)
  }
`;
export const doConsumableRollback = /* GraphQL */ `
  mutation DoConsumableRollback($input: ConsumablePurchaseRollbackInput!) {
    doConsumableRollback(input: $input) {
      reply {
        status
        error
        metadata
      }
      personName
      personHandle
      personWallet
      transactionID
    }
  }
`;
export const doConsumableRefund = /* GraphQL */ `
  mutation DoConsumableRefund($input: ConsumableRefundInput!) {
    doConsumableRefund(input: $input) {
      reply {
        status
        error
        metadata
      }
      personName
      personHandle
      personWallet
      transactionID
    }
  }
`;
