import * as Types from '../API'  // Imports types from our AWS Backend API
import { GraphQLResult } from '@aws-amplify/api'  // Used to type results from our API
import { API } from 'aws-amplify'
import {
  deleteCollaborator as deleteCollaboratorMutation,
  createCollaborator as createCollaboratorMutation,
  updateCollaborator as updateCollaboratorMutation,
  deleteUser as deleteUserMutation, consumablePurchase,
} from '../graphql/mutations'
import { Status } from '../API'  // Mutation definition

/**
 * Gets all the company's collaborators.
 *
 * @param input object with the needed info to call this query
 *
 * @return objects or error
 */
export async function listCompanyCollaborators(input: Types.CollaboratorByCompanyQueryVariables):
Promise<Types.Collaborator[]> {
  try {
    const query = `
    query CollaboratorByCompany(
      $companyID: ID!
      $name: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelCollaboratorFilterInput
      $limit: Int
      $nextToken: String
    ) {
      collaboratorByCompany(
        companyID: $companyID
        name: $name
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          owner
          name
          email
          handle
          phone
          activity
          companyID
          events {
            items {
              id
              collaboratorID
              eventID
              owner
              event {
                name
              }
            }
            nextToken
          }
          collaboratorAvatarId
        }
        nextToken
      }
    }
  `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CollaboratorByCompanyQuery>

    /* Transforms gathered data into an array of object with this properties. If not found, we return an empty list */
    return (response.data?.collaboratorByCompany?.items as Types.Collaborator[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the company\'s collaborators from database: ', error)

    let message = 'Failed to gather company collaborators'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}

/**
 * Gets a specific collaborator from our database.
 *
 * @param input object with the needed info to call this query
 *
 * @return object or error
 */
export async function getCollaborator(input: Types.GetCollaboratorQueryVariables): Promise<Types.Collaborator> {
  try {
    const query = `
    query GetCollaborator($id: ID!) {
      getCollaborator(id: $id) {
        id
        owner
        name
        phone
        handle
        activity
        companyID
        events {
          items {
            id
            collaboratorID
            eventID
            event {
              id
              name
              date {
                start
                end
              }
            }
            owner
          }
          nextToken
        }
        collaboratorAvatarId
      }
    }
  `
    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetCollaboratorQuery>

    /* Returns the gathered event */
    return (response.data?.getCollaborator) as Types.Collaborator

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching collaborator ', input.id, ' from the database: ', error)
    let message = 'Failed to gather collaborator'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}

/**
 * Creates a collaborator inside our database.
 *
 * @param input inputs used to create a new collaborator
 *
 * @return object or error
 */
export async function createCollaborator(input: Types.CreateCollaboratorInput): Promise<Types.Collaborator> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createCollaboratorMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateCollaboratorMutation>

    /* Returns created event */
    return (response.data?.createCollaborator) as Types.Collaborator

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new collaborator in the database: ', error)
    let message = 'Failed to create collaborator'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}

/**
 * Updates an already existing collaborator in our database.
 *
 * @param input inputs used to update an collaborator
 *
 * @return object or error
 */
export async function updateCollaborator(input: Types.UpdateCollaboratorInput): Promise<Types.Collaborator> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updateCollaboratorMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdateCollaboratorMutation>

    /* Returns created event */
    return (response.data?.updateCollaborator) as Types.Collaborator

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while updating a collaborator in the database: ', error)
    let message = 'Failed to update collaborator'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}

/**
 * Scans a consumable order.
 *
 * @param input inputs used to purchase consumables for a user
 *
 * @return object or error
 */
export async function scanConsumable(input: Types.ConsumablePurchaseInput): Promise<object> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: consumablePurchase, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ConsumablePurchaseMutation>

    return {
      status: response.data?.consumablePurchase?.status === Status.SUCCESS,
      message: '',
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while scanning consumables: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    return {
      status: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      message: error.errors[0].message,
    }
  }
}

/**
 * Removes a collaborator from our database. A collaborator can only be removed by the company that created it.
 *
 * @param input inputs used to delete a group
 *
 * @return object or error
 */
export async function deleteCollaborator(input: Types.DeleteCollaboratorInput): Promise<Types.Collaborator> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteCollaboratorMutation, variables: { input } }
    const lambdaObject = { query: deleteUserMutation, variables: { input: { username: input.id } } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteCollaboratorMutation>
    await API.graphql(lambdaObject)

    /* Returns created event */
    return (response.data?.deleteCollaborator) as Types.Collaborator

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a collaborator from the database: ', error)
    let message = 'Failed to delete a collaborator'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}
