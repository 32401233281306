import * as Types from '../API'  // Imports types from our AWS Backend API
import { GraphQLResult } from '@aws-amplify/api'  // Used to type results from our API
import { API } from 'aws-amplify'

/**
 * Checks if a user has a membership with a company.
 *
 * @param phone user's phone
 * @param companyID id of the company
 *
 * @return boolean
 */
export async function checkIfUserIsMember(phone: string, companyID: string): Promise<boolean> {
  if (!phone || !companyID) throw new Error('Phone and companyID are required to check if user is member')

  try {
    const query = /* GraphQL */ `
      query MemberByPhone(
        $phone: AWSPhone!
        $sortDirection: ModelSortDirection
        $filter: ModelMemberFilterInput
        $limit: Int
        $nextToken: String
      ) {
        memberByPhone(
          phone: $phone
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            companyID
          }
        }
      }
    `

    const variables: Types.MemberByPhoneQueryVariables = {
      phone: phone,
    }

    /* Creates our graphql input object */
    const inputObject = { query, variables }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.MemberByPhoneQuery>

    const userMemberCompanies = response.data?.memberByPhone?.items.map((membership) => { return membership?.companyID ?? '' })

    /* Transforms gathered data into an array of object with these properties. If not found, we return an empty list */
    return userMemberCompanies?.includes(companyID) ?? false
    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while checking if user is company member: ', error)

    let message = 'Failed to check if user is member'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}

export async function listMembers(_companyID: string): Promise<Types.Member[]> {
  try {
    const query = /* GraphQL */ `
      query ListMembers(
        $filter: ModelMemberFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            owner
            name
            phone
            companyID
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `

    const variables: Types.ListMembersQueryVariables = {
      filter: {
        companyID: {
          eq: _companyID,
        },
      },
    }

    /* Creates our graphql input object */
    const inputObject = {
      query: query,
      variables: variables,
    }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ListMembersQuery>

    return (response.data?.listMembers?.items as Types.Member[]) ?? []
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching members: ', error)

    let message = 'Failed to fetch members'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}


/**
 * Receives a set of member inputs and creates them.
 *
 * @param inputs inputs used to create a set of company members
 */
export async function createMembers(inputs: Types.CreateMemberInput[]): Promise<void> {
  try {
    const query = /* GraphQL */ `
      mutation CreateMember(
        $input: CreateMemberInput!
        $condition: ModelMemberConditionInput
      ) {
        createMember(input: $input, condition: $condition) {
          id
        }
      }
    `

    const currentMembers = await listMembers(inputs[0].companyID)

    const filteredInputs = inputs.filter((input) => {
      return !currentMembers.some((member) => {
        return member.phone === input.phone
      })
    })

    await Promise.all(filteredInputs.map(async (input) => {
      const inputObject = { query, variables: { input: input } }

      const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateMemberMutation>

      return response.data?.createMember?.id
    }))

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating members: ', error)

    let message = 'Failed to create members'

    if (error instanceof Error) {
      message = error.message
    }
    throw new Error(message)
  }
}
