import * as Types from '../API'  // Imports types from our AWS Backend API
import {
  createConsumable as createConsumableMutation,
  createPairStreak as createPairStreakMutation,
  updatePairStreak as updatePairStreakMutation,
  deletePairStreak as deletePairStreakMutation,
  deleteConsumable as deleteConsumableMutation,
  updateConsumable as updateConsumableMutation,
  doPaymentMBWay as doPaymentMBWayMutation,
  doPingMBWay as doPingMBWayMutation,
  checkoutMBway as checkoutMBwayMutation,
} from '../graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api'  // Used to type results from our API
import { API } from 'aws-amplify'

/**
 * Gets all the consumables from an input event.
 *
 * @param input inputs used to gather all the consumables from an event
 *
 * @return objects or error
 */
export async function listEventConsumables(input: Types.ConsumablesByEventQueryVariables): Promise<Types.Consumable[]> {
  try {
    const query = `
      query ConsumablesByEvent(
        $eventID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelConsumableFilterInput
        $limit: Int
        $nextToken: String
      ) {
        consumablesByEvent(
          eventID: $eventID
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            type
            name
            eventID
          }
          nextToken
        }
      }
    `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ConsumablesByEventQuery>

    /* Transforms gathered data into an array of object with these properties. If not found, we return an empty list */
    return (response.data?.consumablesByEvent?.items as Types.Consumable[]) || []

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching all the consumables of an event from database: ', error)

    let message = 'Failed to gather event consumables'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Creates a consumable for an event.
 *
 * @param input inputs used to create a new consumable
 *
 * @return object or error
 */
export async function createEventConsumable(input: Types.CreateConsumableInput): Promise<Types.Consumable> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createConsumableMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreateConsumableMutation>

    /* Returns created event */
    return (response.data?.createConsumable) as Types.Consumable

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new consumable in the database: ', error)

    let message = 'Failed to create consumable'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Updates a consumable + amount pair reference in a streak.
 *
 * @param input inputs used to update a pair
 *
 * @return object or error
 */
export async function updatePairStreak(input: Types.UpdatePairStreakInput): Promise<Types.PairStreak> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updatePairStreakMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdatePairStreakMutation>

    /* Returns created event */
    return (response.data?.updatePairStreak) as Types.PairStreak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while updating a pair in the database: ', error)

    let message = 'Failed to update pair'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Deletes a pair streak reference in our database.
 *
 * @param input inputs used to delete a pair
 *
 * @return object or error
 */
export async function deletePairStreak(input: Types.DeletePairStreakInput): Promise<Types.PairStreak> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deletePairStreakMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeletePairStreakMutation>

    /* Returns created event */
    return (response.data?.deletePairStreak) as Types.PairStreak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a pair in the database: ', error)

    let message = 'Failed to delete pair'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Creates a consumable + amount pair inside our database for a streak.
 *
 * @param input inputs used to create a new pair
 *
 * @return object or error
 */
export async function createPairStreak(input: Types.CreatePairStreakInput): Promise<Types.PairStreak> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: createPairStreakMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CreatePairStreakMutation>

    /* Returns created event */
    return (response.data?.createPairStreak) as Types.PairStreak

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while creating a new pair in the database: ', error)

    let message = 'Failed to create pair'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Updates a consumable inside our database.
 *
 * @param input inputs used to update a consumable
 *
 * @return object or error
 */
export async function updateConsumable(input: Types.UpdateConsumableInput): Promise<Types.Consumable> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updateConsumableMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdateConsumableMutation>

    /* Returns created event */
    return (response.data?.updateConsumable) as Types.Consumable

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while update a consumable in the database: ', error)

    let message = 'Failed to update consumable'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Removes a consumable from our database.
 *
 * @param input inputs used to delete a consumable
 *
 * @return object or error
 */
export async function deleteConsumable(input: Types.DeleteConsumableInput): Promise<Types.Consumable> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: deleteConsumableMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DeleteConsumableMutation>

    /* Returns created event */
    return (response.data?.deleteConsumable) as Types.Consumable

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a consumable from the database: ', error)

    let message = 'Failed to delete a consumable'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/*
* Recebe o transactionID da transação e o telefone que é para mandar o pagamento no formato "962333069"
* */
export async function doPaymentMBWay(input: Types.DoPaymentMBWay): Promise<Types.CheckoutMBwayResponse> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: doPaymentMBWayMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CheckoutMBwayResponse>

    /* Returns created event */
    return (response.data) as Types.CheckoutMBwayResponse

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while doing Payment MBWay: ', error)

    let message = 'Failed to send payment'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/**
 * Creates a consumable for an event.
 *
 * @param input inputs used to create a new consumable
 *
 * @return object or error
 */
export async function checkoutMBway(input: Types.CheckoutMBWayInput): Promise<Types.CheckoutMBwayResponse> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: checkoutMBwayMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.CheckoutMBwayMutation>

    /* Returns created event */
    return (response.data?.checkoutMBway) as Types.CheckoutMBwayResponse

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while doing Payment Checkout: ', error)

    let message = 'Failed to do Payment Checkout'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}

/*
* Recebe só o transaction ID
* */
export async function doPingMBWay(input: Types.PingMBWayInput): Promise<string> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: doPingMBWayMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DoPingMBWayMutation>

    /* Returns created event */
    return (response.data?.doPingMBWay) as string

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while doing ping', error)

    let message = 'Failed to ping payment servers'

    if (error instanceof Error) {
      message = error.message
    }

    throw new Error(message)
  }
}
