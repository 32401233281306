import { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { GlobalProps } from '../types/global' /* Importing the interface that allow the use of props */

import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons' /* Import needed to get the desired font elements */

import '../styles/views/privacy.css'

/**
 * This page shows the terms of our platform.
 */
class Terms extends Component<RouteComponentProps & GlobalProps> {
  /**
   * React component render function. Holds our extended html code.
   */
  public render(): JSX.Element {
    return (
      <div className="privacy-total">
        <div className="privacy-main">
          <a href="/" className="back"><FontAwesomeIcon icon={faChevronLeft} /></a>
          <h2>{i18next.t('Terms.returnPolicyTitle')}</h2>
          <p>{i18next.t('Terms.returnPolicyText')}</p>
          <h2>{i18next.t('Terms.title1')}</h2>
          <p>{i18next.t('Terms.text1Sub1')}</p>
          <p>{i18next.t('Terms.text1Sub2')}</p>
          <p>{i18next.t('Terms.text1Sub3')}</p>
          <h2>{i18next.t('Terms.title2')}</h2>
          <p>{i18next.t('Terms.text2Sub1')}</p>
          <h2>{i18next.t('Terms.title3')}</h2>
          <p>{i18next.t('Terms.text3Sub1')}</p>
          <p>{i18next.t('Terms.text3Sub2')}</p>
          <p>{i18next.t('Terms.text3Sub3')}</p>
          <p>{i18next.t('Terms.text3Sub4')}</p>
          <p>{i18next.t('Terms.text3Sub5')}</p>
          <p>{i18next.t('Terms.text3Sub6')}</p>
          <h2>{i18next.t('Terms.title4')}</h2>
          <p>{i18next.t('Terms.text4Sub1')}</p>
          <h2>{i18next.t('Terms.title5')}</h2>
          <p>{i18next.t('Terms.text5Sub1')}</p>
          <p>{i18next.t('Terms.text5Sub2')}</p>
          <p>{i18next.t('Terms.text5Sub3')}</p>
          <p>{i18next.t('Terms.text5Sub4')}</p>
          <p>{i18next.t('Terms.text5Sub5')}</p>
          <p>{i18next.t('Terms.text5Sub6')}</p>
          <p>{i18next.t('Terms.text5Sub7')}</p>
          <p>{i18next.t('Terms.text5Sub8')}</p>
          <p>{i18next.t('Terms.text5Sub9')}</p>
          <p>{i18next.t('Terms.text5Sub10')}</p>
          <p>{i18next.t('Terms.text5Sub11')}</p>
          <p>{i18next.t('Terms.text5Sub12')}</p>
          <p>{i18next.t('Terms.text5Sub13')}</p>
          <p>{i18next.t('Terms.text5Sub14')}</p>
          <p>{i18next.t('Terms.text5Sub15')}</p>
          <p>{i18next.t('Terms.text5Sub16')}</p>
          <p>{i18next.t('Terms.text5Sub17')}</p>
          <p>{i18next.t('Terms.text5Sub18')}</p>
          <p>{i18next.t('Terms.text5Sub19')}</p>
          <p>{i18next.t('Terms.text5Sub20')}</p>
          <p>{i18next.t('Terms.text5Sub21')}</p>
          <p>{i18next.t('Terms.text5Sub22')}</p>
          <p>{i18next.t('Terms.text5Sub23')}</p>
          <p>{i18next.t('Terms.text5Sub24')}</p>
          <p>{i18next.t('Terms.text5Sub25')}</p>
          <h2>{i18next.t('Terms.title6')}</h2>
          <p>{i18next.t('Terms.text6Sub1')}</p>
          <p>{i18next.t('Terms.text6Sub2')}</p>
          <p>{i18next.t('Terms.text6Sub3')}</p>
          <p>{i18next.t('Terms.text6Sub4')}</p>
          <p>{i18next.t('Terms.text6Sub5')}</p>
          <p>{i18next.t('Terms.text6Sub6')}</p>
          <p>{i18next.t('Terms.text6Sub7')}</p>
          <p>{i18next.t('Terms.text6Sub8')}</p>
          <p>{i18next.t('Terms.text6Sub9')}</p>
          <p>{i18next.t('Terms.text6Sub10')}</p>
          <p>{i18next.t('Terms.text6Sub11')}</p>
          <p>{i18next.t('Terms.text6Sub12')}</p>
          <p>{i18next.t('Terms.text6Sub13')}</p>
          <p>{i18next.t('Terms.text6Sub14')}</p>
          <h2>{i18next.t('Terms.title7')}</h2>
          <h2>{i18next.t('Terms.title8')}</h2>
          <p>{i18next.t('Terms.text8Sub1')}</p>
          <h2>{i18next.t('Terms.title9')}</h2>
          <p>{i18next.t('Terms.text9Sub1')}</p>
          <h2>{i18next.t('Terms.title10')}</h2>
          <p>{i18next.t('Terms.text10Sub1')}</p>
          <h2>{i18next.t('Terms.title11')}</h2>
          <p>{i18next.t('Terms.text11Sub1')}</p>
          <h2>{i18next.t('Terms.title12')}</h2>
          <p>{i18next.t('Terms.text12Sub1')}</p>
          <h2>{i18next.t('Terms.title13')}</h2>
          <p>{i18next.t('Terms.text13Sub1')}</p>
          <h2>{i18next.t('Terms.title14')}</h2>
          <p>{i18next.t('Terms.text14Sub1')}</p>
          <h2>{i18next.t('Terms.title15')}</h2>
          <p>{i18next.t('Terms.text15Sub1')}</p>
          <p>{i18next.t('Terms.text15Sub2')}</p>
          <h2>{i18next.t('Terms.title16')}</h2>
          <p>{i18next.t('Terms.text16Sub1')}</p>
          <h2>{i18next.t('Terms.title17')}</h2>
          <p>{i18next.t('Terms.text17Sub1')}</p>
          <h2>{i18next.t('Terms.title18')}</h2>
          <p>{i18next.t('Terms.text18Sub1')}</p>
          <h2>{i18next.t('Terms.title19')}</h2>
          <p>{i18next.t('Terms.text19Sub1')}</p>
          <h2>{i18next.t('Terms.title20')}</h2>
          <p>{i18next.t('Terms.text20Sub1')}</p>
          <h2>{i18next.t('Terms.title21')}</h2>
          <p>{i18next.t('Terms.text21Sub1')}</p>
          <h2>{i18next.t('Terms.title22')}</h2>
          <p>{i18next.t('Terms.text22Sub1')}</p>
          <h2>{i18next.t('Terms.title23')}</h2>
          <p>{i18next.t('Terms.text23Sub1')}</p>
          <h2>{i18next.t('Terms.title24')}</h2>
          <p>{i18next.t('Terms.text24Sub1')}</p>
          <h2>{i18next.t('Terms.title25')}</h2>
          <p>{i18next.t('Terms.text25Sub1')}</p>
          <h2>{i18next.t('Terms.title26')}</h2>
          <p>{i18next.t('Terms.text26Sub1')}</p>
          <p>{i18next.t('Terms.company')}</p>
          <p>{i18next.t('Terms.contacts')}</p>
          <p>{i18next.t('Terms.email')}</p>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Terms)
