import React, { Component } from 'react'
import { GlobalProps } from '../../types/global'
import { CollaboratorInfoProps } from '../../types/collaborator-info' /* Import needed for the props */
import i18next from 'i18next' /* Import needed for the use of the dictionary/translation  */
import { withTranslation } from 'react-i18next' /* Import needed for the use of the dictionary/translation  */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' /* Import needed to be able to use the custom FontAwesome font */
import { faUser, faEdit } from '@fortawesome/free-regular-svg-icons' /* Import needed to get the desired font elements */
import AddEditCollaborator from '../../components/collaborators/AddEditCollaborator'
import { Collaborator } from 'src/API'

import '../../styles/components/collaborators/collaborators-info.css'

/**
 * Creates the side panel that showcases the full information about a certain Collaborator. Props are explained on
 * the @ type file.
 */
class CollaboratorsInfo extends Component<CollaboratorInfoProps & GlobalProps, {showAddEditCollaborator: boolean}> {
  /**
   * CollaboratorsInfo class constructor.
   *
   * @param props required props
   */
  constructor(props: any) {
    super(props)
    this.state = {
      showAddEditCollaborator: false, /* The default value is false because we don't want to be showing the popup */
    }
  }

  /**
   * React component render function. Holds our extended html code.
   */
  public render() {
    /* The CollaboratorInfo component is always on the page but if no collaborator is passed we just render nothing*/
    if (this.props.collaborator !== undefined) {
      /* If the collaborator has been to events we render this option with a list of events otherwise we render
        the other one*/
      if (this.props.collaborator.events !== undefined) {
        return (
          <div className="collaborator-info-total">
            <AddEditCollaborator
              collaborator={this.props.collaborator}
              auth={this.props.auth}
              addEditCollaborator={(newCollaborator: Collaborator) => this.props.addEditCollaborator(newCollaborator)}
              show={this.state.showAddEditCollaborator}
              close={() => this.setState({ showAddEditCollaborator: false })}/>
            <div className="collaborator-info-main">
              <div className="collaborator-info-main-left">
                <FontAwesomeIcon id="user-icon" icon={faUser}/>
                <div className="collaborator-info-text">
                  <h3>{this.props.collaborator.name}</h3>
                  <h4>{`${this.props.collaborator.phone}   |   @${this.props.collaborator.handle}`}</h4>
                  <p>{this.props.collaborator.activity}</p>
                </div>
              </div>
              <FontAwesomeIcon id="edit-icon" icon={faEdit} onClick={() => this.setState({ showAddEditCollaborator: true })}/>
            </div>
            <div className="collaborator-info-events">
              <h3>{i18next.t('Collaborators.collaboratorInfo.attendedEvents')}</h3>
              {this.props.collaborator.events!.items?.map((event, index) => {
                return (
                  <div key={index} className="collaborator-info-events-line">
                    <h3>{event?.event.name}</h3>
                    {/* Hidden while we aren't able to show transactions
                        <div className="collaborator-info-transactions">
                          <h4>{i18next.t('Collaborators.collaboratorInfo.transactions')}</h4>
                          <FontAwesomeIcon id="chevron-icon" icon={faChevronRight}/>
                        </div>*/}
                  </div>
                )
              })}
            </div>
          </div>
        )
      }
      /* This is if the collaborator as never attended an event */
      return (
        <div className="collaborator-info-total">
          <AddEditCollaborator
            auth={this.props.auth}
            collaborator={this.props.collaborator}
            addEditCollaborator={(newCollaborator: Collaborator) => this.props.addEditCollaborator(newCollaborator)}
            show={this.state.showAddEditCollaborator}
            close={() => this.setState({ showAddEditCollaborator: false })}/>
          <div className="collaborator-info-main">
            <div className="collaborator-info-main-left">
              <FontAwesomeIcon id="user-icon" icon={faUser}/>
              <div className="collaborator-info-text">
                <h3>{this.props.collaborator.name}</h3>
                <h4>{this.props.collaborator.phone}</h4>
                <p>{this.props.collaborator.activity}</p>
              </div>
            </div>
            <FontAwesomeIcon id="edit-icon" icon={faEdit} onClick={() => this.setState({ showAddEditCollaborator: true })}/>
          </div>
          <h3 className="collaborator-info-no-events">
            {i18next.t('Collaborators.collaboratorInfo.noAttendedEvents')}
          </h3>
        </div>
      )
    }
    return null
  }
}

export default withTranslation()(CollaboratorsInfo)
