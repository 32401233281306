import * as Types from '../API' // Imports types from our AWS Backend API
import { deleteUser,
  scannerDecryptionV2,
  scannerEncryptionV2,
  doLinkPersonWithAux,
  updatePerson as updatePersonMutation,
  doFriendRequest } from '../graphql/mutations' // Mutation definition
import { GraphQLResult, GRAPHQL_AUTH_MODE } from '@aws-amplify/api' // Used to type results from our API
import { API } from 'aws-amplify'
import { Friend, Person, Ticket } from '../API'

/**
 * Gets info related to an input user.
 *
 * @param input inputs used to gather user info
 *
 * @return return Person object
 */
export async function getsPersonInfo(input: Types.GetPersonQueryVariables): Promise<Types.Person> {
  try {
    const query = `
    query GetPerson($id: ID!) {
      getPerson(id: $id) {
        id
        owner
        name
        phone
        handle
        wallet
        privacy
        currentEventID
        address {
          street
          zipCode
          city
          country
        }
        event {
          name
          date {
            start
            end
          }
        }
        avatar {
          id
          owner
          eventID
          filename
          identityID
        }
        tickets {
          items {
            readDates
            eventID
            personID
            personTicketRefundId
            ticket {
              validDates {
                start
                end
              }
              name
              eventName
            }
            personTicketTicketId
          }
          nextToken
        }
      }
    }
  `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetPersonQuery>

    /* Returns gathered info related to user */
    return response.data?.getPerson as Types.Person

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching person from database: ', error)

    throw new Error('Failed to gather person information')
  }
}

/**
 * Gets just the relevant person info for the collaborator
 *
 * @param input inputs used to gather user info
 *
 * @return return Person object
 */
export async function getsPersonInfoForCollaborator(input: Types.GetPersonQueryVariables): Promise<Types.Person> {
  try {
    const query = `
    query GetPerson($id: ID!) {
      getPerson(id: $id) {
        id
        name
        handle
        wallet
      }
    }
  `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.GetPersonQuery>

    /* Returns gathered info related to user */
    return response.data?.getPerson as Types.Person

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching person from database: ', error)

    throw new Error('Failed to gather person information')
  }
}

/**
 * Updates an already existing person in our database.
 *
 * @param input inputs used to update a person
 *
 * @return object or error
 */
export async function updatePerson(input: Types.UpdatePersonInput): Promise<Types.Person> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: updatePersonMutation, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdatePersonMutation>

    /* Returns created event */
    return (response.data?.updatePerson) as Types.Person

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while updating a person in the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to update person'
    throw new Error(message)
  }
}

/**
 * Disables a user.
 *
 * @param input inputs used to disable a user before deleting it
 *
 * @return object or error
 */
export async function deletePerson(input: Types.DeleteUserInput): Promise<boolean> {
  try {
    /* Creates our graphql input object */
    const lambdaObject = { query: deleteUser, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(lambdaObject)) as GraphQLResult<Types.DeleteUserMutation>

    /* Returns created event */
    return response.data?.deleteUser?.statusCode === 201

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while deleting a person from the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to delete a person'
    throw new Error(message)
  }
}

/**
 * Links a user with a physical qr code (aux identity).
 *
 * @param personID id of the person to be linked
 * @param qrcode string read from the qr code
 *
 * @return boolean value to tell if we had success or not
 */
export async function linkPersonWithQrCode(personID: string, qrcode: string): Promise<boolean> {
  try {
    const variables: Types.DoLinkPersonWithAuxMutationVariables = {
      input: {
        personID: personID,
        auxIdentity: qrcode,
      },
    }

    console.log('VARS: ', variables)

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql({
      query: doLinkPersonWithAux, variables: variables,
    })) as GraphQLResult<Types.DoLinkPersonWithAuxMutation>

    /* Returns gathered info related to user */
    return response.data?.doLinkPersonWithAux === true

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while linking person: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to link person'
    throw new Error(message)
  }
}

/**
 * Encrypts user's QR Code.
 *
 * @param username user's username
 *
 * @return return encrypted string
 */
export async function encryptUsername(username: string): Promise<string> {
  try {
    /* Creates our graphql input object */
    console.log(username)
    const inputObject = { query: scannerEncryptionV2, variables: { input: { info: username } } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ScannerEncryptionV2Mutation>
    console.log(response)

    /* Returns gathered info related to user */
    return response.data?.scannerEncryptionV2 as string

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while encrypting username: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to encrypt username'
    throw new Error(message)
  }
}

/**
 * Decrypts user's QR Code.
 *
 * @param encryptedQR qr code string that is encrypted
 *
 * @return {String} user's username
 */
export async function decryptQRCode(encryptedQR: string): Promise<string> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: scannerDecryptionV2, variables: { input: { info: encryptedQR } } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ScannerDecryptionV2Mutation>

    /* Returns gathered info related to user */
    return response.data?.scannerDecryptionV2 as string

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while decrypting qr code: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to decrypt qr code'
    throw new Error(message)
  }
}

/**
 * Updates user's current address information
 *
 * @param address new address information
 * @param personID id of the user whose information is going to be updated
 * @param email email of the user
 *
 * @return return boolean confirmation
 */
export async function updatePersonAddress(address: Types.AddressInput, personID: string, email: string)
: Promise<boolean> {
  try {
    const optimizedQuery = `
     mutation UpdatePerson(
      $input: UpdatePersonInput!
      $condition: ModelPersonConditionInput
     ) {
       updatePerson(input: $input, condition: $condition) {
         id
       }
     }`

    const input: Types.UpdatePersonInput = {
      address,
      id: personID,
      email: email,
    }

    /* Creates our graphql input object */
    const inputObject = {
      query: optimizedQuery,
      variables: {
        input,
      },
    }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.UpdatePersonMutation>

    /* Returns gathered info related to user */
    return response.data?.updatePerson?.id === personID

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error trying to update user\'s address information: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to update user address'
    throw new Error(message)
  }
}

/**
 * Function that updates de Friend status of the users
 *
 * @param  input passes the two friends and status that need to be updated
 *
 * @return objects or error
 */
export async function friendRequest(input: Types.FriendRequestInput): Promise<Types.DoFriendRequestMutation> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: doFriendRequest, variables: { input } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DoFriendRequestMutation>

    if (response.data?.doFriendRequest === null) {  // Used to check if retro compatibility worked
      throw new Error('Update the app to add friends!')
    }

    /* Returns gathered info related to user */
    return response.data?.doFriendRequest as Types.DoFriendRequestMutation

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while sending a friend request: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to send friend request'
    throw new Error(message)
  }
}

/**
 * Function that updates de Friend status of the users by ID
 *
 * @param  input passes the two friends and status that need to be updated
 *
 * @return objects or error
 */
export async function friendRequestByID(input: Types.AddFriendWithIDInput): Promise<Types.DoAddFriendWithIDMutation> {
  try {
    /* Creates our graphql input object */
    const inputObject = { query: doFriendRequest, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.DoAddFriendWithIDMutation>

    /* Returns gathered info related to user */
    return response.data?.doAddFriendWithID as Types.DoAddFriendWithIDMutation

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while encrypting username: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to encrypt username'
    throw new Error(message)
  }
}

export async function getsUserScore(personID: string): Promise<number> {
  /* Builds a query that will count the total score of a user */
  const queryGetUserScore = `
      query SearchPersonEventStates(
        $filter: SearchablePersonEventStateFilterInput
        $sort: [SearchablePersonEventStateSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchablePersonEventStateAggregationInput]
      ) {
        searchPersonEventStates(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
            aggregateItems {
              name
              result {
                ... on SearchableAggregateScalarResult {
                  __typename
                  value
                }
              }
            }
            items {
              id
            }
          }
        }
      `
  const variablesGetScore: Types.SearchPersonEventStatesQueryVariables = {
    filter: {
      personID: {
        eq: personID,
      },
    },
    aggregates: [{
      field: Types.SearchablePersonEventStateAggregateField.score,
      name: 'totalScore',
      type: Types.SearchableAggregateType.sum,
    }],
  }

  /* Creates our graphql input object */
  const inputGetScore = { query: queryGetUserScore, variables: variablesGetScore }

  /* Accesses our database and return an object with all the gathered info */
  /* If we were able to get the requested data, shows it. Else, throws an error */
  const responseGetScore = (await API.graphql(inputGetScore)) as GraphQLResult<Types.SearchPersonEventStatesQuery>

  /* Score is zero unless the user has already interacted with the app and gotten some points */
  let score = 0
  if (responseGetScore.data?.searchPersonEventStates?.aggregateItems.length !== 0) {
    // eslint-disable-next-line max-len
    score = ((responseGetScore.data?.searchPersonEventStates?.aggregateItems[0]?.result) as Types.SearchableAggregateScalarResult).value
  }
  return score
}

/**
 * Gets a list of total scores of each input user's phone numbers
 *
 * @param nextToken pagination token
 * @param personID pagination token*

 * @return return Person(only with consumables) object
 */
export async function getFriendsWithScore(personID: string, nextToken?: string): Promise<{
  items: Array<{friend: Types.Person, score: number}>,
  nextToken: string | null
}> {
  try {
    const queryListPeople = `
      query ListPeople(
        $filter: ModelFriendFilterInput
        $limit: Int
        $nextToken: String
        $personID: ID!
      ) {
        personFriendsByPerson(filter: $filter, limit: $limit, nextToken: $nextToken, personID: $personID) {
          items {
            friendID
            id
            owner
            personID
            request
            friend {
              id
              name
              phone
              handle
              privacy
              event {
                name
                date {
                  start
                  end
                }
              }
            }
          }
          nextToken
        }
      }`

    const inputListPeople: Types.PersonFriendsByPersonQueryVariables = {
      personID: personID,
      filter: {
        request: {
          eq: Types.FriendRequest.ACCEPTED,
        },
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputGetFriends = { query: queryListPeople, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const responseListPeople = (await API.graphql(inputGetFriends)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    /* Returns gathered info related to user */
    const returnedFriends = responseListPeople.data?.personFriendsByPerson?.items as Types.Friend[]

    const listFriends = returnedFriends.map((person) => person.friend) as Types.Person[]

    /* Builds a query that will count the total score of a user */
    const queryGetUserScore = `
      query SearchPersonEventStates(
        $filter: SearchablePersonEventStateFilterInput
        $sort: [SearchablePersonEventStateSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchablePersonEventStateAggregationInput]
      ) {
        searchPersonEventStates(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
            aggregateItems {
              name
              result {
                ... on SearchableAggregateScalarResult {
                  __typename
                  value
                }
              }
            }
            items {
              id
            }
          }
        }
      `

    return {
      items: await Promise.all(listFriends.map(async (friend): Promise<{friend: Types.Person, score: number}> => {
        const variablesGetScore: Types.SearchPersonEventStatesQueryVariables = {
          filter: {
            personID: {
              eq: friend.id,
            },
          },
          aggregates: [{
            field: Types.SearchablePersonEventStateAggregateField.score,
            name: 'totalScore',
            type: Types.SearchableAggregateType.sum,
          }],
        }

        /* Creates our graphql input object */
        const inputGetScore = { query: queryGetUserScore, variables: variablesGetScore }

        /* Accesses our database and return an object with all the gathered info */
        /* If we were able to get the requested data, shows it. Else, throws an error */
        const responseGetScore = (await API.graphql(inputGetScore)) as GraphQLResult<Types.SearchPersonEventStatesQuery>

        /* Score is zero unless the user has already interacted with the app and gotten some points */
        let score = 0
        if (responseGetScore.data?.searchPersonEventStates?.aggregateItems.length !== 0) {
          // eslint-disable-next-line max-len
          score = ((responseGetScore.data?.searchPersonEventStates?.aggregateItems[0]?.result) as Types.SearchableAggregateScalarResult).value
        }

        return {
          friend: friend,
          score,
        }
      })),
      nextToken: responseListPeople.data?.personFriendsByPerson?.nextToken ?? null,
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching friends total score from the database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather friends score'
    throw new Error(message)
  }
}

export async function getsPendingFriends(personID: string, nextToken?: string): Promise<{
  items: Types.Person[],
  nextToken: string | null
}> {
  try {
    const query = `
    query ListPeople(
      $filter: ModelFriendFilterInput
      $limit: Int
      $nextToken: String
      $personID: ID!
    ){
      personFriendsByPerson(filter: $filter, limit: $limit, nextToken: $nextToken, personID: $personID) {
        items {
          friend {
            name
            handle
            id
            phone
            privacy
          }
        }
        nextToken
      }
    }`

    const inputListPeople: Types.PersonFriendsByPersonQueryVariables = {
      personID: personID,
      filter: {
        request: {
          eq: Types.FriendRequest.RECEIVED,
        },
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputObject = { query, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const data = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    const response = data?.data?.personFriendsByPerson?.items.map((item) => { return item?.friend })

    /* Returns gathered info related to user */
    return {
      items: response as Types.Person[],
      nextToken: data.data?.personFriendsByPerson?.nextToken ?? null,
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching person from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather person information'
    throw new Error(message)
  }
}

export async function getsFriendsTicketsForEvent(personID: string, eventID: string, nextToken?: string): Promise<{
  items: Array<{person: Person, ticket: Ticket}>,
  nextToken: string | null
}> {
  try {
    const query = `
    query ListPeople(
      $filter: ModelFriendFilterInput
      $limit: Int
      $nextToken: String
      $personID: ID!
      $eventID: ID!
    ) {
      personFriendsByPerson(filter: $filter, limit: $limit, nextToken: $nextToken, personID: $personID) {
        items {
          friend {
            name
            handle
            id
            tickets (filter: {eventID: {eq: $eventID}}) {
              items {
                ticket {
                  name
                  validDates {
                    start
                    end
                  }
                  eventName
                }
                eventID
              }
            }
          }
        }
        nextToken
      }
    }`

    const inputListPeople = {
      personID: personID,
      eventID: eventID,
      filter: {
        request: {
          eq: Types.FriendRequest.ACCEPTED,
        },
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputObject = { query, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    // filters array to only include friends with tickets
    const tickets = response.data?.personFriendsByPerson?.items.filter((items) => items?.friend?.tickets?.items && items?.friend?.tickets?.items?.length > 0 && items?.friend?.privacy !== 'PRIVATE')

    /* Returns gathered info related to user */
    return {
      items: tickets?.map((item) => (
        { person: item?.friend, ticket: item?.friend.tickets?.items[0]?.ticket }
      )) as Array<{person: Person, ticket: Ticket}>,
      nextToken: response.data?.personFriendsByPerson?.nextToken ?? null,
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching Friends tickets from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather person information'
    throw new Error(message)
  }
}

export async function getsFriendsAtEvent(personID: string, eventID: string, nextToken?: string): Promise<{
  items: Array<Friend>,
  nextToken: string | null,
}> {
  try {
    const query = `
    query ListPeople(
      $filter: ModelFriendFilterInput
      $limit: Int
      $nextToken: String
      $personID: ID!
    ) {
      personFriendsByPerson(filter: $filter, limit: $limit, nextToken: $nextToken, personID: $personID) {
        items {
          friend {
            privacy
            name
            handle
            phone
            currentEventID
          }
        }
        nextToken
      }
    }`

    const inputListPeople = {
      personID: personID,
      filter: {
        request: {
          eq: Types.FriendRequest.ACCEPTED,
        },
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputObject = { query, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    // filters array to only include friends with tickets to a given event and have open privacy
    const friends = response.data?.personFriendsByPerson?.items.filter((friend) => friend?.friend?.privacy !== 'PRIVATE' && friend?.friend?.currentEventID === eventID)

    /* Returns gathered info related to user */
    return { items: friends as Friend[], nextToken: response.data?.personFriendsByPerson?.nextToken ?? null }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching Friends tickets from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather person information'
    throw new Error(message)
  }
}

/* This function behaves just like getsFriendsAtEvent but a limit isn't defined for pagination since due to the frontend
dynamic of the page where this function is called it is not possible to have a scrollable flatList */
export async function getsFriendsAtEventWithoutLimit(personID: string, eventID: string): Promise<Array<Friend>> {
  try {
    const query = `
    query ListPeople(
      $filter: ModelFriendFilterInput
      $personID: ID!
    ) {
      personFriendsByPerson(filter: $filter, personID: $personID) {
        items {
          friend {
            id
            name
            handle
            phone
            currentEventID
          }
        }
      }
    }`

    const inputListPeople = {
      personID: personID,
      filter: {
        request: {
          eq: Types.FriendRequest.ACCEPTED,
        },
      },
    }

    /* Creates our graphql input object */
    const inputObject = { query, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    // filters array to only include friends with tickets to a given event and have open privacy
    const friends = response.data?.personFriendsByPerson?.items
      .filter((friend) => friend?.friend?.currentEventID === eventID)

    /* Returns gathered info related to user */
    return friends as Friend[]

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching Friends tickets from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather person information'
    throw new Error(message)
  }
}

// eslint-disable-next-line max-len
export async function getsFriendsComingToEvent(personID: string, eventID: string, nextToken?: string): Promise<{
  items: Array<Friend>,
  nextToken: string | null,
}> {
  try {
    const query = `
    query ListPeople(
      $filter: ModelFriendFilterInput
      $limit: Int
      $nextToken: String
      $personID: ID!
      $eventID: ID!
    ) {
      personFriendsByPerson(filter: $filter, limit: $limit, nextToken: $nextToken, personID: $personID) {
        items {
          friend {
            privacy
            name
            handle
            currentEventID
            tickets(filter: {eventID: {eq: $eventID}}) {
              items {
                ticket {
                  validDates {
                    start
                    end
                  }
                  eventName
                  name
                }
                eventID
              }
            }
          }
        }
        nextToken
      }
    }`

    const inputListPeople = {
      personID: personID,
      eventID: eventID,
      filter: {
        request: {
          eq: Types.FriendRequest.ACCEPTED,
        },
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputObject = { query, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    // filters array to only include friends with tickets to a given event
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let friends = response.data?.personFriendsByPerson?.items.filter((items) => items?.friend?.tickets?.items?.length > 0 && items?.friend?.privacy !== 'PRIVATE')

    // filters array to only include friends who are not in given event
    friends = friends?.filter((items) => !(items?.friend?.tickets?.items[0]?.eventID === items?.friend?.currentEventID))

    friends = friends?.filter((friend) => {
      return friend?.friend.tickets?.items.some((ticket) => {
        const currentDateAux = new Date()
        // eslint-disable-next-line max-len
        const currentDate = new Date(currentDateAux.getTime() - (currentDateAux.getTimezoneOffset() * 60000)).toISOString()
        // eslint-disable-next-line max-len,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line max-len
        return ticket?.ticket?.validDates?.filter((dateSpan) => (dateSpan.start <= currentDate && currentDate < dateSpan.end)).length > 0
      })
    })

    /* Returns gathered info related to user */
    return { items: friends as Friend[], nextToken: response.data?.personFriendsByPerson?.nextToken ?? null }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching Friends tickets from database: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather person information'
    throw new Error(message)
  }
}

export async function getsFriendsSuggestions(personID: string, phones: Array<string>, nextToken?: string):Promise<{
  items: Array<{id: string, name: string, phone: string, handle: string, relationshipStatus: string}>,
  nextToken: string | null
}> {
  try {
    const query = `
    query ListPeople ($personID: ID!) {
      personFriendsByPerson(personID: $personID) {
        items {
          friend {
            name
            phone
            handle
          }
        }
      }
    }`

    /* Creates our graphql input object */
    const inputObject = { query, variables: { personID: personID } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    const friends = response?.data?.personFriendsByPerson?.items.map((person) => ({ name: person?.friend.name, phone: person?.friend.phone, handle: person?.friend.handle, relationshipStatus: 'add' }))

    const queryListPeople = `
      query ListPeople(
        $filter: ModelPersonFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            phone
            handle
          }
          nextToken
        }
      }
      `

    const inputListPeople: Types.ListPeopleQueryVariables = {
      filter: {
        or: phones.map((phone) => { return { phone: { eq: phone } } }),
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputGetFriends = { query: queryListPeople, variables: inputListPeople }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const responseListPeople = (await API.graphql(inputGetFriends)) as GraphQLResult<Types.ListPeopleQuery>

    /* Extract friends information from query */
    const suggestionsFetched = responseListPeople.data?.listPeople?.items as Types.Person[]

    const returnPhones = suggestionsFetched.map((person) => ({ id: person.id, name: person.name, phone: person.phone, handle: person.handle, relationshipStatus: 'add' }))

    const notFriends = returnPhones.filter((returnPhoneItem) => {
      return !(friends?.some((friendItem) => friendItem.handle === returnPhoneItem.handle))
    })

    return {
      items: notFriends as Array<{id: string, name: string, phone: string, handle: string, relationshipStatus: string}>,
      nextToken: responseListPeople.data?.listPeople?.nextToken ?? null,
    }

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching friends suggestions ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather friends suggestions'
    throw new Error(message)
  }
}

/**
 * Gets info related to an input user.
 *
 * @param personID
 * @param handle inputs used to gather user info
 * @param nextToken pagination token
 *
 * @return return Person object
 */
export async function getsHandle(personID: string, handle: string, nextToken?: string): Promise<{
  items: { id: string, name: string, handle: string, phone: string, relationshipStatus: string, }[],
  nextToken: string | null
}> {
  try {
    const query = `
      query ListPeople(
        $filter: ModelPersonFilterInput
        $limit: Int
        $nextToken: String
      ){
        listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            phone
            handle
          }
          nextToken
        }
      }`

    /* Creates our graphql input object */
    const inputObject : Types.ListPeopleQueryVariables = {
      filter: {
        handle: {
          beginsWith: handle,
        },
      },
      limit: 40,
      ...(nextToken !== undefined) && { nextToken },
    }

    /* Creates our graphql input object */
    const inputGetHandle = { query: query, variables: inputObject }
    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const responseHandle = (await API.graphql(inputGetHandle)) as GraphQLResult<Types.ListPeopleQuery>

    const query2 = `
    query ListPeople ($personID: ID!) {
      personFriendsByPerson(personID: $personID) {
        items {
          friend {
            handle
          }
          request
        }
      }
    }`

    /* Creates our graphql input object */
    const inputObject2 = { query: query2, variables: { personID: personID } }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const responseFriends = (await API.graphql(inputObject2)) as GraphQLResult<Types.PersonFriendsByPersonQuery>

    const search = responseHandle?.data?.listPeople?.items
      .map((item) => ({ id: item?.id, name: item?.name, handle: item?.handle, phone: item?.phone }))

    const friends = responseFriends?.data?.personFriendsByPerson?.items
      .map((item) => ({ handle: item?.friend?.handle }))

    const searchWithStatus = search?.map((itemSearch) => {
      const index = friends!.findIndex((itemFriends) => (itemSearch.handle === itemFriends.handle))

      if (index === -1) {
        return { id: itemSearch?.id ?? '', name: itemSearch?.name ?? '', handle: itemSearch?.handle ?? '', phone: itemSearch?.phone ?? '', relationshipStatus: 'add' }
      }

      const request = responseFriends?.data?.personFriendsByPerson?.items[index]?.request

      if (request === 'SENT' || request === 'RECEIVED') {
        return { id: itemSearch?.id ?? '', name: itemSearch?.name ?? '', handle: itemSearch?.handle ?? '', phone: itemSearch?.phone ?? '', relationshipStatus: 'pending' }
      }

      if (request === 'ACCEPTED') {
        return { id: itemSearch?.id ?? '', name: itemSearch?.name ?? '', handle: itemSearch?.handle ?? '', phone: itemSearch?.phone ?? '', relationshipStatus: 'friend' }
      }

      if (request === 'DENIED') {
        return { id: itemSearch?.id ?? '', name: itemSearch?.name ?? '', handle: itemSearch?.handle ?? '', phone: itemSearch?.phone ?? '', relationshipStatus: 'denied' }
      }

      return { id: '', name: '', handle: '', phone: '', relationshipStatus: '' }
    })

    /* Returns gathered info related to user */
    return {
      items: searchWithStatus ? searchWithStatus : [],
      nextToken: responseHandle.data?.listPeople?.nextToken ?? null,
    }
    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching person from database: ', error)
    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to gather person information'
    throw new Error(message)
  }
}

/**
 * Checks if we can register this account by checking the input phone and the handle.
 *
 * @param input values that can be used to check if the creation of the account is valid
 *
 * @return return string value that identifies the result
 */
export async function checkIfAccountCreationIsValid(input: { phone?: string, handle?: string }): Promise<'handle' | 'phone' | 'error' | 'success'> {
  try {
    const checkPerson = async () => {
      const queryPerson = /* GraphQL */ `
        query PersonByPhone($phone: AWSPhone!, $limit: Int, $nextToken: String) {
          personByPhone(phone: $phone, limit: $limit, nextToken: $nextToken) {
            items {
              id
              phone
              handle
            }
          }
        }`

      const variables = {
        phone: input.phone,
      }

      /* Creates our graphql input object */
      const inputObject = {
        query: queryPerson,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }

      /* Accesses our database and return an object with all the gathered info */
      /* If we were able to get the requested data, shows it. Else, throws an error */
      const response = (await API.graphql(inputObject)) as GraphQLResult<Types.PersonByPhoneQuery>

      /* If we found someone with the specified information, then we alert the user that we can't create the account*/
      if (response.data?.personByPhone?.items.length !== 0) {
        return response.data?.personByPhone?.items[0]?.handle === input.handle ? 'handle' : 'phone'
      }

      return 'success'
    }

    const checkCollaborator = async () => {
      const queryCollaborator = /* GraphQL */ `
        query ListCollaborators($filter: ModelCollaboratorFilterInput, $limit: Int, $nextToken: String) {
          listCollaborators(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              phone
              handle
            }
          }
        }`

      const variables = {
        filter: {
          or: [
            ...(input.handle ? [{
              handle: {
                eq: input.handle,
              },
            }] : []),
            ...(input.phone ? [{
              phone: {
                eq: input.phone,
              },
            }] : []),
          ],
        },
      }

      /* Creates our graphql input object */
      const inputObject = {
        query: queryCollaborator,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }

      /* Accesses our database and return an object with all the gathered info */
      /* If we were able to get the requested data, shows it. Else, throws an error */
      const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ListCollaboratorsQuery>

      /* If we found someone with the specified information, then we alert the user that we can't create the account */
      if (response.data?.listCollaborators?.items.length !== 0) {
        return response.data?.listCollaborators?.items[0]?.handle === input.handle ? 'handle' : 'phone'
      }

      return 'success'
    }

    const checkCompany = async () => {
      const queryCompany = /* GraphQL */ `
        query ListCompanies($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
          listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              handle
            }
          }
        }`

      const variables = {
        filter: {
          or: [
            ...(input.handle ? [{
              handle: {
                eq: input.handle,
              },
            }] : []),
          ],
        },
      }

      /* Creates our graphql input object */
      const inputObject = {
        query: queryCompany,
        variables: variables,
        authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
      }

      /* Accesses our database and return an object with all the gathered info */
      /* If we were able to get the requested data, shows it. Else, throws an error */
      const response = (await API.graphql(inputObject)) as GraphQLResult<Types.ListCompaniesQuery>

      /* If we found someone with the specified information, then we alert the user that we can't create the account */
      if (response.data?.listCompanies?.items.length !== 0) {
        return response.data?.listCompanies?.items[0]?.handle === input.handle ? 'handle' : 'phone'
      }

      return 'success'
    }

    /* Tries to find a match to the phone number or the user handle */
    const result = await Promise.all([checkPerson(), checkCollaborator(), checkCompany()])
    const val = result.find((ele) => {
      return ele === 'phone' || ele === 'handle'
    })

    return val ? val : 'success'

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error trying to check if phone or handle are being used: ', error)

    /* Sends error to frontend and tells it how to proceed with the error */
    const message = error.message ? error.message : 'Failed to check usage of phone or handle'
    throw new Error(message)
  }
}

/**
 * Gets info related to an input user.
 *
 * @param input inputs used to gather user info
 *
 * @return return Person object
 */
export async function WalletTransactionByTransactionID(input: Types.WalletTransactionByTransactionIDQueryVariables): Promise<Types.WalletTransaction> {
  try {
    const query = `
    query WalletTransactionByTransactionID($transactionID: String!) {
      walletTransactionByTransactionID(transactionID: $transactionID) {
        items {
          amount
          createdAt
          date
          metadata
          id
          method
          owner
          status
         }
      }
    }
  `

    /* Creates our graphql input object */
    const inputObject = { query, variables: input }

    /* Accesses our database and return an object with all the gathered info */
    /* If we were able to get the requested data, shows it. Else, throws an error */
    const response = (await API.graphql(inputObject)) as GraphQLResult<Types.WalletTransactionByTransactionIDQuery>

    /* Returns gathered info related to user */
    return response.data?.walletTransactionByTransactionID?.items[0] as Types.WalletTransaction

    /* Since we caught an error, we have to process it in the frontend */
  } catch (error: any) {
    /* Logs error on the console. Mainly done for debug purposes */
    console.error('Error while fetching person from database: ', error)

    throw new Error('Failed to gather person information')
  }
}
