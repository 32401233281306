import { CardPaymnetState } from '../../types/payments'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Helmet } from 'react-helmet'
import { getSibsEndpointURL } from 'web/src/helpers/Backend'
import '../../styles/components/card-payment.css'


export function CardPayment(props: CardPaymnetState) {
  const { formContext } = props
  const { ticket } = props

  return (
    <div className="child">
      <form
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        class="paymentSPG"
        spg-context={`${formContext?.formContext}`}
        spg-config={`{ "paymentMethodList": ["CARD"] ,
                            "amount": { "value": ${formContext?.amount}, "currency": "EUR" },
                            "language": "en",
                            "redirectUrl": "http://localhost:3000/payment-feedback/${formContext?.transactionID}?ticketID=${ticket.id}&eventID=${ticket?.eventID}",
                            "customerData": null}  `}
        spg-style='{ "layout": "default",
                "color": {
                  "primary": "#02112E",
                  "secondary": "#02112E",
                  "body":  {
                    "text": "#ffffff",
                    "background": "#1A2338"
                  },
                  "header":  {
                    "text": "#02112E",
                    "background": "#ffffff"
                  }
                },
                "font": "Arial" }'/>
      <Helmet>
        <script src={`${getSibsEndpointURL()}?id=${formContext?.transactionID}`}></script>
      </Helmet>
    </div>
  )
}
