/* eslint-disable */
/**
 * Consumable keys corresponding to the ones from i18next file. Used to translate consumables.
 * DO NOT CHANGE THE KEYS NOR THEIR ORDER.
 */
export const consumablesKeys: string[] = [
  'beer',
  'sangria',
  'cider',
  'spirits',
  'shots',
  'food',
  'nonalcoholic',
  'cup',
  'activities',
  'charity',
  'misc',
]

/**
 * DO NOT CHANGE THE KEYS NOR THEIR ORDER. This is used to map each category index to their corresponding subcategories
 * (consumableKeys). This is a very fragile system and should not be altered.
 */
export const consumableCategoryMapping = [
  ['BEER', 'SANGRIA', 'CIDER'],
  ['SPIRITS', 'SHOTS'],
  ['FOOD', 'NONALCOHOLIC', 'CUP'],
  ['CHARITY', 'ACTIVITIES', 'MISC'],
]

/**
 * Represents a new datetime of an event.
 */
export const eventDate = {
  time: '',
  date: '',
}

/**
 * Represents an artist that is going to perform in this event.
 */
export const eventArtist = {
  name: '',
  startDateTime: {
    time: '',
    date: '',
  },
}

/**
 * Enum that represents the next page to render
 */
export enum nextPage {
  NEXT,
  PREVIOUS
}

/**
 * Enum used to decide which progress page to render in CreateEvent
 */
export enum SubPages {
  GENERAL,
  TICKETS,
  CONSUMABLES,
  STREAKS,
  COLLABORATORS,
  SUCCESS
}
